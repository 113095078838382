import {
  SearchOutlined,
  SettingFilled,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Divider, Select } from "antd";
import moment from "moment";
import lodash from "lodash";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, InputField } from "../../../../components/_shared";
import Loading from "../../../../components/_shared/Loader/Loading";
import { UserContext } from "../../../../context/userInfo";
import useViewport from "../../../../hooks/useViewport";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  addGroupInit,
  addGroupMemberInit,
  deleteGroupInit,
  deleteGroupMemberInit,
  fetchMyGroupsInit,
  fetchStudioMembers,
} from "../../../../store/actions/studio";
import {
  makeSelectGroups,
  makeSelectGroupsStudio,
  makeSelectMembers,
  makeSelectStudioLoading,
} from "../../../../store/selectors";
import { joinedUser } from "../../../../utils/joinedUser";
import AddGroupModal from "./AddGroupModel";
import ManageGroupModal from "./ManageGroupModal";
import { useNavigate, useParams } from "react-router-dom";
import { dragDropGroupInit } from "../../../../store/actions/auth";

const { Option } = Select;

const Groups = () => {
  const { isMobile } = useViewport();
  const navigate = useNavigate();
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [showManageGroup, setShowManageGroup] = useState(false);
  const dispatch = useDispatch();
  const studioMembers = useSelector(makeSelectMembers());
  const groups = useSelector(makeSelectGroups());
  const studionName = useSelector(makeSelectGroupsStudio());
  const [groupDetails, setGroupDetails] = useState("");
  const { userData, getUserinfo } = useContext(UserContext);
  const isLoading = useSelector(makeSelectStudioLoading());
  const [searchField, setSearchField] = useState("");
  const [itemslist, setItemslist] = useState([]);
  const [customlist, setCustomList] = useState([]);
  const getItemStyle = (isDragging, draggableStyle) => ({

    ...draggableStyle,
  });

  useEffect(() => {
    dispatch(fetchStudioMembers());
    dispatch(fetchMyGroupsInit());
    getUserinfo();
  }, []);
  useEffect(() => {
    setItemslist(groups);
    setCustomList(groups);
  }, [groups]);
  console.log(itemslist, 'itemslist');

  const addGroup = (e) => {
    dispatch(addGroupInit({ data: { groupName: e?.gname }, setShowAddGroup }));
  };

  const deleteGroup = (gid) => {
    dispatch(deleteGroupInit({ data: gid, setShowManageGroup }));
  };

  const addGroupMember = (gid, userId) => {
    dispatch(addGroupMemberInit({ data: { userId }, gid }));
  };

  const deleteGroupMember = (gid, mid) => {
    dispatch(deleteGroupMemberInit({ gid, mid }));
  };

  const refreshGroup = () => {
    dispatch(fetchMyGroupsInit());
  };

  const [order, setOrder] = useState("Custom");
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      itemslist,
      result.source.index,
      result.destination.index
    );
    let timelineId = [];
    for (let tline of items) {
      timelineId.push(tline?.id);
    }
    setItemslist(items);
    setCustomList(items);
    dispatch(
      dragDropGroupInit(timelineId)
    );
  };

  // const gropsOrder =
  //   order === "Descending" ? setItemslist(lodash?.sortBy(groups, "groupName")?.reverse()) : order === "Ascending" ? setItemslist(lodash?.sortBy(groups, "groupName")) : groups

  return (
    <>
      <Loading isLoading={isLoading} />
      <AddGroupModal
        onAddGroup={addGroup}
        visible={showAddGroup}
        setVisible={setShowAddGroup}
      />
      {showManageGroup && (
        <ManageGroupModal
          onDeleteGroup={deleteGroup}
          groupDetails={groupDetails}
          studioMembers={studioMembers}
          visible={showManageGroup}
          setVisible={setShowManageGroup}
          addGroupMember={addGroupMember}
          deleteGroupMember={deleteGroupMember}
          userData={userData}
          refreshGroup={refreshGroup}
        />
      )}
      <div className="container">
        <div className="row">
          <h4 className="primary-green">{studionName} - GROUPS</h4>
        </div>
        <Divider />
        <div className="row">
          <div className="col-md-6">
            <Button
              lite
              size="larze"
              title="Add new Group"
              className="rounded"
              onClick={() => setShowAddGroup(!showAddGroup)}
            />
          </div>
          <div
            style={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              display: "flex",
            }}
            className="col-md-6"
          >

            <Select
              placeholder="Sort"
              value={order}
              onChange={(e) => {
                if (e == 'Descending') {
                  setItemslist(lodash?.sortBy(groups, "groupName")?.reverse());
                } else if (e == 'Ascending') {
                  setItemslist(lodash?.sortBy(groups, "groupName"));
                } else if (e == 'Custom') {
                  setItemslist(customlist);
                }
                else {
                  setItemslist(groups);
                }
                setOrder(e);
              }}
            >
              <Option value="Custom">Custom</Option>
              <Option value="Descending">Descending</Option>
              <Option value="Ascending">Ascending</Option>
            </Select>
          </div>
        </div>
        <div className="col-md-12 d-flex ">
          <InputField
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className="mt-3"
            style={{ width: `${isMobile ? "" : "100%"}` }}
          />
        </div>
      </div>
      {order == 'Custom' ?
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppable"
          >
            {(droppableProvided, droppableSnapshot) => (
              <div
                className="row  gx-3"
                ref={droppableProvided.innerRef}
              // style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                {itemslist
                  .filter((member) =>
                    `${member?.groupName}`
                      .toLowerCase()
                      .includes(searchField.toLowerCase())
                  )
                  ?.map((group, index) => (
                    <Draggable
                      key={group.id}
                      draggableId={group.id}
                      index={index}
                    >
                      {(draggableProvided, draggableSnapshot) => (
                        <div className="col-md-6 mt-4 d-flex align-self-stretch"
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={getItemStyle(
                            draggableSnapshot.isDragging,
                            draggableProvided.draggableProps.style
                          )}
                        >
                          <div className="container bg-secondary-gray shadow p-3">
                            <div className="col-md-12 d-flex justify-content-end cursor-pointer">
                              <SettingFilled
                                className="h5"
                                onClick={() => {
                                  setShowManageGroup(!showManageGroup);
                                  setGroupDetails(group);
                                }}
                              />
                            </div>
                            <div onClick={() => {
                              navigate(`/timeline?id=${group?.id}`);
                            }} style={{ cursor: "pointer" }} className="h5 font-light-gray col-md-12">
                              {group?.groupName}
                            </div>

                            <div className="col-md-6">
                              <span className="font-light-gray">
                                {group?.members?.length} Members
                              </span>
                            </div>

                            <div className="col-md-12">
                              <Divider />
                            </div>
                            <div className="row">
                              {studioMembers.map(
                                (member) =>
                                  joinedUser(member?.id, group?.members) && (
                                    <div className="col-md-6 d-flex mt-3 ">
                                      <div className="">
                                        <img
                                          src={`${member?.profilepic}`}
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = `${process.env.PUBLIC_URL}user.png`;
                                          }}
                                          style={{ objectFit: "cover" }} width={70} height={70}
                                        />
                                      </div>
                                      <div className="d-flex flex-column mt-1">
                                        <span className="h5 mx-3 font-light-gray">
                                          {member?.name}
                                        </span>

                                      </div>
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>

                  ))}
                {droppableProvided.placeholder}
              </div>

            )}

          </Droppable>

        </DragDropContext>
        :
        <div className="row  gx-3">
          {itemslist
            .filter((member) =>
              `${member?.groupName}`
                .toLowerCase()
                .includes(searchField.toLowerCase())
            )
            ?.map((group, index) => (
              <div className="col-md-6 mt-4 d-flex align-self-stretch">
                <div className="container bg-secondary-gray shadow p-3">
                  <div className="col-md-12 d-flex justify-content-end cursor-pointer">
                    <SettingFilled
                      className="h5"
                      onClick={() => {
                        setShowManageGroup(!showManageGroup);
                        setGroupDetails(group);
                      }}
                    />
                  </div>
                  <div onClick={() => {
                    navigate(`/timeline?id=${group?.id}`);
                  }} style={{ cursor: "pointer" }} className="h5 font-light-gray col-md-12">
                    {group?.groupName}
                  </div>

                  <div className="col-md-6">
                    <span className="font-light-gray">
                      {group?.members?.length} Members
                    </span>
                  </div>

                  <div className="col-md-12">
                    <Divider />
                  </div>
                  <div className="row">
                    {studioMembers.map(
                      (member) =>
                        joinedUser(member?.id, group?.members) && (
                          <div className="col-md-6 d-flex mt-3 ">
                            <div className="">
                              <img
                                src={`${member?.profilepic}`}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = `${process.env.PUBLIC_URL}user.png`;
                                }}
                                width={70}
                              />
                            </div>
                            <div className="d-flex flex-column mt-1">
                              <span className="h5 mx-3 font-light-gray">
                                {member?.name}
                              </span>

                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            ))
          }
        </div>}

    </>
  );
};
export default Groups;