import { notification } from "antd";
import moment from "moment";

export const notify = (type, msg) => {
  console.log(type,msg,'typeOfMsg')
  switch (true) {
    case type === "error":
      // message.error(msg);
      if(msg != '{}'){
        notification.error({
          message: `Error`,
          description: msg,
          placement: "topRight",
        });
      }
      break;
    case type === "warn":
      notification.info({
        message: `Warning`,
        description: msg,
        placement: "topRight",
      });
      break;
    case type === "success":
    default:
      notification.success({
        message: `Success`,
        description: msg,
        placement: "topRight",
      });
      break;
  }
};

export const getFileExt = (url) => {
  return url?.split(/[#?]/)?.[0]?.split?.(".")?.pop()?.trim()?.toLowerCase();
};

export const isExpired = (expiredDate) => {
  const expiry = moment(expiredDate);
  const currentDate = moment().utc();
  const isExpired = expiry.diff(currentDate, "days");

  if (isExpired > 0) return false;
  return true;
};
export const getAvailableStudiodate = (expiredDate) => {
  
  const expiry = moment(expiredDate);
  const currentDate = moment().utc();
  return expiry.diff(currentDate, "days");
};



export const MP3 = [
  '3gp',
  'aa',
  'aac',
  'aax',
  'act',
  'aiff',
  'aif',
  'alac',
  'amr',
  'ape',
  'au',
  'awb',
  'dss',
  'dvf',
  'flac',
  'gsm',
  'iklax',
  'mp3',
  'ivs',
  'm4a',
  'm4b',
  'm4p',
  'mmf',
  'mpc',
  'msv',
  'nmf',
  'ogg',
  'oga',
  'mogg',
  'opus',
  'ra',
  'rm',
  'raw',
  'rf64',
  'tta',
  'voc',
  'vox',
  'wav',
  'wma',
  'wv',
  'webm',
  'cda',
];