import React, { useContext } from "react";
import { useEffect, useState } from "react";
import portfolio from "../../../../services/portfolio";
import Vimeo from "@u-wave/react-vimeo";
import timeline from "../../../../services/timeline";
import { notify, SERVER_URL } from "../../../../utils";
import { BoxInputFiled, BoxTextArea, Wrapper } from "./component/component";
import { Button, Card, Col, Row, Upload, Form, Tooltip } from "antd";
import { Carousel } from "react-responsive-carousel";
import ReactHtmlParser from "react-html-parser";

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  MailOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons/lib/icons";
import FileViewer from "react-file-viewer";
import { SketchPicker } from "react-color";
import styled from "styled-components";
import { UserContext } from "../../../../context/userInfo";
import { useNavigate, useParams } from "react-router-dom";
import { apiPost } from "../../../../utils/axios";
import { returnFileType } from "../../../../utils/imageAccept";
import PortfolioModal from "./component/PortfolioModel";
import { InputField } from "../../../../components/_shared";
import { GrayInputField } from "../../../../components/_shared/Input";
import moment from "moment";
import Loading from "../../../../components/_shared/Loader/Loading";
import { MEDIA_URL } from "../../../../utils/server";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { getFileExt, MP3 } from "../../../../utils/helper";
import useViewport from "../../../../hooks/useViewport";
import ReactPlayer from "react-player";
import { ViewProfileContext } from "../../../../context/viewProfile";
import { ROUTES } from "../../../../route/constant";
import VideoPlayer from "../../../../components/VideoPlayer";

const { Meta } = Card;

const CardWrapper = styled(Card)`
  .ant-card-body {
    padding: 0px !important;
  }
`;
const PortfolioDetails = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const { profileToken } = useContext(ViewProfileContext);
  const query = useParams();
  const navigate = useNavigate();

  const [color, setColor] = useState("red");
  const [showcolor, setShowcolor] = useState(false);
  const [edit, setShowedit] = useState(false);
  const [timelines, setTimelines] = useState([]);
  const [showTimelineModel, setShowTimelineModel] = useState(false);
  const [myPortoflio, setMyPortfolio] = useState({});
  const [primary, setPrimary] = useState({ show: false, color: "#C6BAA3" });
  const [secondary, setSecondary] = useState({ show: false, color: "#F4F1EC" });
  const [border, setBorder] = useState({ show: false, color: "#9A9A9A" });
  const [heading, setHeading] = useState({ show: false, color: "#FFFFFF" });
  const [text, setText] = useState({ show: false, color: "#666666" });
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [orderTimeline, setOrderTimeline] = useState([]);
  const [comment, setComment] = useState("");
  const { isMobile } = useViewport();

  useEffect(() => {
    setPrimary({
      show: false,
      color: myPortoflio.primaryColor ? myPortoflio.primaryColor : "#C6BAA3",
    });
    setSecondary({
      show: false,
      color: myPortoflio.secondaryColor
        ? myPortoflio.secondaryColor
        : "#F4F1EC",
    });
    setBorder({
      show: false,
      color: myPortoflio.borderColor ? myPortoflio.borderColor : "#9A9A9A",
    });

    setHeading({
      show: false,
      color: myPortoflio.headingColor ? myPortoflio.headingColor : "#FFFFFF",
    });

    setText({
      show: false,
      color: myPortoflio.textColor ? myPortoflio.textColor : "#666666",
    });

    if (myPortoflio) {
      form.setFieldsValue({
        title: myPortoflio?.title,
        subtitle: myPortoflio?.subtitle,
        description: myPortoflio?.description,
        contacts: myPortoflio?.contact,
      });
    }
  }, [myPortoflio]);

  useEffect(() => {
    getPortfolioTimeline();
    getUserinfo();
    getPortfolioById();
    fetchMyPortoflio();
  }, []);

  const fetchMyPortoflio = () => {
    portfolio
      .getPortfolio(profileToken?.access?.token)
      .then((res) => {
        if (res.status === 200) {
          const ids = res?.data?.portfolios?.map((i) => i.id);
          if (ids?.includes(query?.id) === false) {
            navigate({
              pathname: "/timeline",
            });
          }
        }
      })
      .catch((err) => {});
  };

  // if()

  const getPortfolioTimeline = () => {
    portfolio
      .getPortfolioTimeline(query?.id)
      .then((res) => {
        if (res.status === 200) {
          setTimelines(res?.data?.timelines);
        }
      })
      .catch((err) => {
        // notify("error", JSON.stringify(err));
      });
  };

  const updatePortfolio = (e) => {
    portfolio
      .updatePortfolio(query?.id, {
        primaryColor: primary.color,
        secondaryColor: secondary.color,
        borderColor: border.color,
        textColor: text.color,
        headingColor: heading.color,
        title: e?.title,
        subtitle: e?.subtitle,
        description: e?.description,
        contact: e?.contacts,
        timeline: myPortoflio?.timeline,
      })
      .then((res) => {
        if (res.status === 200) {
          notify("success", "Portfolio saved successfully");
          setShowedit(!edit);
          getPortfolioById();
          orderTimelineUpdate();
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  const insertTimeline = (tid, type) => {
    if (type === "remove") {
      let newTimeline = myPortoflio?.timeline?.filter(
        (id) => String(id) !== String(tid)
      );
      portfolio
        .updatePortfolio(query?.id, { timeline: newTimeline })
        .then((res) => {
          if (res.status === 200) {
            getPortfolioById();
            getPortfolioTimeline();
          }
        })
        .catch((err) => {
          notify("error", JSON.stringify(err));
        });
    } else {
      portfolio
        .updatePortfolio(query?.id, {
          timeline: [
            ...(myPortoflio.timeline ? myPortoflio.timeline : []),
            tid,
          ],
        })
        .then((res) => {
          if (res.status === 200) {
            getPortfolioById();
            getPortfolioTimeline();
          }
        })
        .catch((err) => {
          notify("error", JSON.stringify(err));
        });
    }
  };

  const orderTimelineUpdate = () => {
    portfolio
      .updatePortfolio(query?.id, {
        timeline: orderTimeline.length ? orderTimeline : myPortoflio?.timeline,
      })
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  const onUpload = (e) => {
    setIsLoading(true);

    let formData = new FormData();
    formData.append("avatar", e?.file);
    axios
      .post(MEDIA_URL, formData)
      .then((res) => {
        setIsLoading(false);
        // window.location.href  = "/profile"
        if (res?.status === 200) {
          uploadPortfolioPic(res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        notify("error", "Something went wrong");
      });
  };

  const uploadPortfolioPic = (pic) => {
    setIsLoading(true);
    portfolio
      .updatePortfolio(query?.id, {
        portfolioPic: pic,
      })
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          getPortfolioById();
          // window.location.href = `/portfolio/${query?.id}`;
        }
      })
      .catch((err) => {
        setIsLoading(false);

        notify("error", JSON.stringify(err));
      });
  };

  const getPortfolioById = () => {
    setIsLoading(true);
    portfolio
      .getPortfolioById(query.id)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setMyPortfolio(res?.data?.portfolio);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        notify("error", JSON.stringify(err));
      });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "red",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      timelines,
      result.source.index,
      result.destination.index
    );

    let timelineId = [];
    for (let tline of items) {
      timelineId.push(tline?.id);
    }
    setOrderTimeline(timelineId);
    setTimelines(items);
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "grey",
  });

  const handleResetColor = () => {
    setPrimary({ show: false, color: "#C6BAA3" });
    setSecondary({ show: false, color: "#F4F1EC" });
    setBorder({ show: false, color: "#9A9A9A" });
    setHeading({ show: false, color: "#FFFFFF" });
    setText({ show: false, color: "#666666" });
  };

  const handleAddComment = () => {
    if (!comment) return notify("Please enter your comment");

    portfolio
      .addComment(query?.id, { comment })
      .then((res) => {
        if (res.status === 200) {
          getPortfolioById();
          setComment("");
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  const handleDeleteComment = (cid) => {
    portfolio
      .deleteComment(query?.id, cid)
      .then((res) => {
        if (res.status === 200) {
          getPortfolioById();
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  return (
    <Wrapper
      primary={primary.color}
      secondary={secondary.color}
      border={border.color}
      heading={heading.color}
      text={text.color}
    >
      <Loading isLoading={isLoading} />
      <PortfolioModal
        insertTimeline={insertTimeline}
        portoflio={myPortoflio}
        visible={showTimelineModel}
        setVisible={setShowTimelineModel}
      />
      <Form form={form} layout="vertical" onFinish={updatePortfolio}>
        <div className="container bg-white p-2   ">
          <div style={{ position: "relative" }}>
            <img className="cover" src={`${myPortoflio?.portfolioPic}`} />
            <img
              src={`${
                myPortoflio?.user?.profilepic
                  ? myPortoflio?.user?.profilepic
                  : `${process.env.PUBLIC_URL}user.png`
              }`}
              // onError={(e) => {
              //   e.target.onerror = null;
              //   e.target.src = `${process.env.PUBLIC_URL}user.png`;
              // }}
              width={150}
              className="rounded cursor-pointer profile-left"
              alt=""
            />

            <div className="edit-right">
              {edit && (
                <Upload
                  beforeUpload={() => false}
                  onChange={onUpload}
                  multiple={false}
                  showUploadList={false}
                >
                  <Button size="large" type="primary mx-2">
                    Upload
                  </Button>
                </Upload>
              )}

              <Button
                onClick={() => {
                  setShowedit(!edit);
                }}
                size="large"
                type="primary"
                // icon={<EditOutlined />}
              >
                {edit ? "Cancel" : "Edit"}
              </Button>
            </div>
            {edit && (
              <div className="box-container">
                <div className="box-sub-container">
                  <div
                    className="box box1"
                    onClick={() => {
                      setPrimary({ show: !primary.show, color: primary.color });
                    }}
                  >
                    {primary.show && (
                      <SketchPicker
                        color={primary.color}
                        onChange={(e) => {
                          setPrimary({ show: primary.show, color: e.hex });
                        }}
                      />
                    )}
                  </div>
                  <h6 className="mt-1">Primary</h6>
                </div>

                <div className="box-sub-container">
                  <div
                    className="box box2"
                    onClick={() => {
                      setSecondary({
                        show: !secondary.show,
                        color: secondary.color,
                      });
                    }}
                  >
                    {secondary.show && (
                      <SketchPicker
                        color={secondary.color}
                        onChange={(e) => {
                          setSecondary({ show: secondary.show, color: e.hex });
                        }}
                        // onChangeComplete={() => {
                        //   setShowcolor(!showcolor);
                        // }}
                      />
                    )}
                  </div>
                  <h6 className="mt-1">Secondary</h6>
                </div>

                <div className="box-sub-container">
                  <div
                    className="box box3"
                    onClick={() => {
                      setBorder({ show: !border.show, color: border.color });
                    }}
                  >
                    {border.show && (
                      <SketchPicker
                        color={border.color}
                        onChange={(e) => {
                          setBorder({ show: border.show, color: e.hex });
                        }}
                        // onChangeComplete={() => {
                        //   setShowcolor(!showcolor);
                        // }}
                      />
                    )}
                  </div>
                  <h6 className="mt-1">Border</h6>
                </div>

                <div className="box-sub-container">
                  <div
                    className="box box4"
                    onClick={() => {
                      setHeading({ show: !heading.show, color: heading.color });
                    }}
                  >
                    {heading.show && (
                      <SketchPicker
                        color={heading.color}
                        onChange={(e) => {
                          setHeading({ show: heading.show, color: e.hex });
                        }}
                        // onChangeComplete={() => {
                        //   setShowcolor(!showcolor);
                        // }}
                      />
                    )}
                  </div>
                  <h6 className="mt-1">Heading</h6>
                </div>

                <div className="box-sub-container">
                  <div
                    className="box box5"
                    onClick={() => {
                      setText({ show: !text.show, color: text.color });
                    }}
                  >
                    {text.show && (
                      <SketchPicker
                        color={text.color}
                        onChange={(e) => {
                          setText({ show: text.show, color: e.hex });
                        }}
                        // onChangeComplete={() => {
                        //   setShowcolor(!showcolor);
                        // }}
                      />
                    )}
                  </div>
                  <h6 className="mt-1">Text</h6>
                </div>

                <Button
                  style={{
                    position: "absolute",
                    right: 20,
                    top: isMobile ? "-40px" : "",
                  }}
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  Save
                </Button>
                <Button
                  style={{
                    position: "absolute",
                    right: 100,
                    top: isMobile ? "-40px" : "",
                  }}
                  type="default"
                  size="large"
                  onClick={handleResetColor}
                >
                  Reset to Default
                </Button>
              </div>
            )}
          </div>
          <div className="secondary-color p-3" style={{ width: "100%" }}>
            <Row gutter={16} className="mt-3">
              <Col xs={24} sm={24} md={24} xl={7} xxl={7} span={7}>
                <CardWrapper>
                  <div className="primary-color" style={{ padding: 10 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {!edit ? (
                        <>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "30px",
                              color: text?.color,
                              width: "100%",
                            }}
                          >
                            {myPortoflio?.title}
                          </div>
                          :
                          <EditOutlined
                            color="white"
                            style={{ fontSize: 30, color: "white" }}
                            onClick={() => setShowedit(!edit)}
                          />
                        </>
                      ) : (
                        <Form.Item name="title" style={{ width: "100%" }}>
                          <BoxInputFiled
                            text={text.color}
                            border={border.color}
                          />
                        </Form.Item>
                      )}
                    </div>
                    <div
                      style={{
                        color: "white",
                        marginTop: "10px",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      <span>
                        Created: {moment(portfolio?.date).format("LL")}
                      </span>
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                      }}
                    >
                      By {myPortoflio?.name}
                    </div>
                    <hr color={border.color} style={{ margin: 3 }} />
                    <div
                      style={{
                        // color: "white",

                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                    >
                      {edit ? (
                        <Form.Item name="subtitle">
                          <BoxInputFiled
                            text={text.color}
                            border={border.color}
                          />
                        </Form.Item>
                      ) : (
                        <span className="text-color">
                          {myPortoflio?.subtitle}
                        </span>
                      )}
                    </div>
                  </div>
                  <div style={{ background: "white", padding: 10 }}>
                    {/* <div style={{ color: "black" }}>Testing</div> */}
                    {edit ? (
                      <Form.Item name="description">
                        <BoxTextArea text={text.color} border={border.color} />
                      </Form.Item>
                    ) : (
                      <span className="text-color">
                        {myPortoflio?.description}
                      </span>
                    )}
                  </div>
                </CardWrapper>

                <CardWrapper className="mt-3">
                  <div className="primary-color" style={{ padding: 10 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: heading.color,
                        }}
                      >
                        Contact
                      </div>
                      {!edit && (
                        <EditOutlined
                          color="white"
                          style={{
                            fontSize: 30,
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowedit(!edit)}
                        />
                      )}
                    </div>
                  </div>
                  <div style={{ padding: 10 }}>
                    {!edit ? (
                      myPortoflio?.contact?.map((info) => (
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h6 className="text-color">{info?.contact}</h6>
                          <MailOutlined
                            color="white"
                            style={{ fontSize: 30, color: text.color }}
                          />
                        </div>
                      ))
                    ) : (
                      <Form.List name="contacts" className="mb-0">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(
                              (
                                { key, name, fieldKey, ...restField },
                                index
                              ) => (
                                <Row>
                                  <Col
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    xl={4}
                                    xxl={4}
                                    span={4}
                                  >
                                    <MailOutlined
                                      color="white"
                                      className="mt-1"
                                      style={{
                                        fontSize: 30,
                                        color: text.color,
                                      }}
                                    />
                                  </Col>
                                  <Col
                                    xs={18}
                                    sm={18}
                                    md={18}
                                    xl={18}
                                    xxl={18}
                                    span={18}
                                  >
                                    <Form.Item
                                      style={{ marginBottom: "15px" }}
                                      {...restField}
                                      name={[name, "contact"]}
                                      fieldKey={[fieldKey, "contact"]}
                                    >
                                      <BoxInputFiled
                                        text={text.color}
                                        border={border.color}
                                        placeholder=""
                                      />
                                    </Form.Item>

                                    {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                                  </Col>
                                  <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    xl={2}
                                    xxl={2}
                                    span={2}
                                  >
                                    <div
                                      className="float-right mt-1"
                                      style={{
                                        fontSize: "18px",
                                        marginTop: "",
                                      }}
                                    >
                                      <DeleteOutlined
                                        className="cursor-pointer h4 text-color"
                                        onClick={() => remove(name)}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )
                            )}
                            <Col
                              xs={20}
                              sm={20}
                              md={20}
                              xl={20}
                              xxl={20}
                              span={20}
                              className=""
                            >
                              <Form.Item>
                                <div className="">
                                  <Button
                                    className=" cursor-pointer d-flex align-content-center"
                                    onClick={() => add()}
                                    style={{
                                      background: primary.color,
                                      border: `1px solid ${border.color}`,
                                      color: heading.color,
                                    }}
                                    size="large"
                                  >
                                    <PlusCircleOutlined className="mt-1 mx-1" />
                                    <span>Add Contact</span>
                                  </Button>
                                </div>
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Form.List>
                    )}
                  </div>
                </CardWrapper>

                {/* <CardWrapper className="mt-3">
                  <div className="primary-color" style={{ padding: 10 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: heading.color,
                        }}
                      >
                        Comments
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: 10 }}>
                    <div className="row">
                      {myPortoflio?.comments?.map((comm) => (
                        <>
                          <div className="col-md-12 mt-3 d-flex justify-content-between">
                            <h6
                              style={{ fontWeight: "600" }}
                              className="text-color"
                            >
                              {comm?.name}
                            </h6>
                            {comm?.user === userData?.id && (
                              <DeleteOutlined
                                onClick={() => handleDeleteComment(comm?._id)}
                                className="text-color h6"
                              />
                            )}
                          </div>
                          <div className="col-md-12">
                            <span className="text-color">{comm?.comment}</span>
                          </div>
                          <div className="col-md-12">
                            <span className="text-color">
                              {moment(comm?.date).calendar()}
                            </span>
                          </div>
                        </>
                      ))}
                      <div className="col-md-12 mt-4">
                        <BoxTextArea
                          onChange={(e) => setComment(e?.target?.value)}
                          border={border?.color}
                          text={text?.color}
                          placeholder="Comments"
                        />
                      </div>

                      <div className="col-md-12 d-flex justify-content-end mt-3">
                        <Button
                          type="primary"
                          onClick={handleAddComment}
                          size="large"
                        >
                          Post
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardWrapper> */}
              </Col>

              <Col xs={24} sm={24} md={24} xl={17} xxl={17} span={17}>
                {edit && (
                  <div className="row mt-3">
                    <Button
                      className="primary-color"
                      style={{
                        color: heading.color,
                        zIndex: "0",
                        border: ` 1px solid ${border.color}`,
                      }}
                      onClick={() => setShowTimelineModel(!showTimelineModel)}
                    >
                      Insert From Timeline
                    </Button>
                  </div>
                )}

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    isDropDisabled={edit ? false : true}
                    droppableId="droppable"
                  >
                    {(droppableProvided, droppableSnapshot) => (
                      <div
                        ref={droppableProvided.innerRef}
                        style={getListStyle(droppableSnapshot.isDraggingOver)}
                      >
                        {timelines?.map((timeline, index) => (
                          <Draggable
                            isDragDisabled={edit ? false : true}
                            key={timeline.id}
                            draggableId={timeline.id}
                            index={index}
                          >
                            {(draggableProvided, draggableSnapshot) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                style={getItemStyle(
                                  draggableSnapshot.isDragging,
                                  draggableProvided.draggableProps.style
                                )}
                                className="bg-primary-gray mx-auto shadow mt-4"
                              >
                                <Card
                                  title={
                                    <div className="d-flex">
                                      <div>
                                        <img
                                          src={`${
                                            timeline?.user?.profilepic
                                              ? timeline?.user?.profilepic
                                              : `${process.env.PUBLIC_URL}user.png`
                                          }`}
                                          // onError={(e) => {
                                          //   e.target.onerror = null;
                                          //   e.target.src = `${process.env.PUBLIC_URL}user.png`;
                                          // }}
                                          alt=""
                                          width="50"
                                          height={50}
                                          className="rounded-circle"
                                        />
                                      </div>
                                      <div
                                        className="mx-3 d-flex flex-column "
                                        style={{
                                          width: "50%",
                                          whiteSpace: "pre-wrap",
                                        }}
                                      >
                                        <h6 className="mb-0 font-white text-color">
                                          {timeline?.title}
                                        </h6>
                                        <span className="font-white text-color">
                                          {moment(
                                            timeline?.timelineDate
                                          ).format("DD/MM/YYYY")}
                                        </span>
                                      </div>
                                    </div>
                                  }
                                  extra={
                                    <div className="text-white h5 cursor-pointer ">
                                      {edit && (
                                        <DeleteOutlined
                                          className="text-color"
                                          onClick={() =>
                                            insertTimeline(
                                              timeline?.id,
                                              "remove"
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  }
                                  style={{ width: "100%", overflow: "hidden" }}
                                  bordered={false}
                                >
                                  <div className="row">
                                    <Carousel showArrows={true}>
                                      {timeline?.vimeoUrl?.map((url) => (
                                        <div
                                          className="mx-auto"
                                          style={{ height: "600px" }}
                                        >
                                          <Vimeo
                                            video={url}
                                            // width={"100%"}
                                            // responsive = {true}

                                            height={600}
                                            showTitle={false}
                                            showByline={false}
                                          />
                                        </div>
                                      ))}

                                      {timeline?.videoId?.map((id) =>
                                        timeline?.isS3 ? (
                                          // <ReactPlayer
                                          //   width="100%"
                                          //   height={isMobile ? "100%" : "400px"}
                                          //   url={`https://movitanz.s3.ap-southeast-2.amazonaws.com/${id}.mp4`}
                                          //   controls
                                          //   controlsList={"nodownload"}
                                          // />
                                          <>
                                            {timeline?.makeCopy && (
                                             <div style={{marginLeft:50}}>
                                                <Tooltip
                                                  title="Download"
                                                  color={"#0eaaa5"}
                                                  key={"download"}
                                                >
                                                  <DownloadOutlined
                                                    style={{
                                                      marginLeft: 50,
                                                      marginBottom: 40,
                                                    }}
                                                    onClick={() => {
                                                      window.open(
                                                        `https://movitanz.s3.ap-southeast-2.amazonaws.com/${id}.mp4`,
                                                        "_blank"
                                                      );
                                                    }}
                                                    className=" h4 cursor-pointer"
                                                  />
                                                </Tooltip>
                                              </div>
                                            )}
                                            <VideoPlayer
                                              src={`https://dum3sasv4ib5u.cloudfront.net/${id}.mp4`}
                                              preload="auto"
                                              controls
                                              style={{
                                                width: "100%",
                                                height: "400px",
                                              }}
                                              controlslist="nodownload"
                                            />
                                          </>
                                        ) : (
                                          // <div
                                          //   className="mx-auto"
                                          //   style={{ height: "600px" }}
                                          // >
                                          //   <Vimeo
                                          //     // responsive = {true}
                                          //     // width={"100%"}
                                          //     video={id}
                                          //     height={600}
                                          //     showTitle={false}
                                          //     showByline={false}
                                          //   />
                                          // </div>
                                          <>
                                            {timeline?.makeCopy && (
                                              <div style={{marginLeft:50}}>
                                                <Tooltip
                                                  title="Download"
                                                  color={"#0eaaa5"}
                                                  key={"download"}
                                                >
                                                  <DownloadOutlined
                                                    style={{
                                                      marginLeft: 50,
                                                      marginBottom: 40,
                                                    }}
                                                    onClick={() => {
                                                      window.open(
                                                        `https://movitanz.s3.ap-southeast-2.amazonaws.com/${id}.mp4`,
                                                        "_blank"
                                                      );
                                                    }}
                                                    className=" h4 cursor-pointer"
                                                  />
                                                </Tooltip>
                                              </div>
                                            )}

                                            <VideoPlayer
                                              src={`https://dum3sasv4ib5u.cloudfront.net/${id}.mp4`}
                                              preload="auto"
                                              controls
                                              style={{
                                                width: "100%",
                                                height: "400px",
                                              }}
                                              controlslist="nodownload"
                                            />
                                          </>
                                        )
                                      )}

                                      {timeline?.postPic?.map((pic, index) => (
                                        <div
                                          className="mx-auto"
                                          // style={{ height: "600px" }}
                                        >
                                          {returnFileType(pic) == "jpg" ||
                                          returnFileType(pic) == "png" ||
                                          returnFileType(pic) == "jpeg" ||
                                          returnFileType(pic) == "svg" ? (
                                            <>
                                              {timeline?.makeCopy && (
                                                <div style={{marginLeft:50}}>
                                                  <Tooltip
                                                    title="Download"
                                                    color={"#0eaaa5"}
                                                    key={"download"}
                                                  >
                                                    <DownloadOutlined
                                                      style={{
                                                        marginLeft: 50,
                                                        marginBottom: 40,
                                                      }}
                                                      onClick={() => {
                                                        window.open(
                                                          pic,
                                                          "_blank"
                                                        );
                                                      }}
                                                      className=" h4 cursor-pointer"
                                                    />
                                                  </Tooltip>
                                                </div>
                                              )}
                                              <img
                                                src={pic}
                                                // onError={(e) => {
                                                //   e.target.onerror = null;
                                                //   e.target.src = `${process.env.PUBLIC_URL}uploadDefault.png`;
                                                // }}
                                                alt=""
                                                style={{
                                                  maxWidth: "100%",
                                                  maxHeight: "100%",
                                                }}
                                              />
                                            </>
                                          ) : [
                                              "pdf",
                                              "csv",
                                              "xslx",
                                              "docx",
                                              "mp4",
                                              "webm",
                                              "mp3",
                                            ]?.includes(getFileExt(pic)) ? (
                                            <div style={{ height: "600px" }}>
                                              {timeline?.makeCopy && (
                                               <div style={{marginLeft:50}}>
                                                  <Tooltip
                                                    title="Download"
                                                    color={"#0eaaa5"}
                                                    key={"download"}
                                                  >
                                                    <DownloadOutlined
                                                      style={{
                                                        marginLeft: 50,
                                                        marginBottom: 40,
                                                      }}
                                                      onClick={() => {
                                                        window.open(
                                                          pic,
                                                          "_blank"
                                                        );
                                                      }}
                                                      className=" h4 cursor-pointer"
                                                    />
                                                  </Tooltip>
                                                </div>
                                              )}
                                              <FileViewer
                                                fileType={getFileExt(pic)}
                                                filePath={pic}
                                              />
                                            </div>
                                          ) : (
                                            MP3.includes(getFileExt(pic)) && (
                                              <>
                                                {timeline?.makeCopy && (
                                                <div style={{marginLeft:50}}>
                                                    <Tooltip
                                                      title="Download"
                                                      color={"#0eaaa5"}
                                                      key={"download"}
                                                    >
                                                      <DownloadOutlined
                                                        style={{
                                                          marginLeft: 50,
                                                          marginBottom: 40,
                                                        }}
                                                        onClick={() => {
                                                          window.open(
                                                            pic,
                                                            "_blank"
                                                          );
                                                        }}
                                                        className=" h4 cursor-pointer"
                                                      />
                                                    </Tooltip>
                                                  </div>
                                                )}
                                                <ReactPlayer
                                                  width="100%"
                                                  height={
                                                    isMobile ? "100%" : "400px"
                                                  }
                                                  url={pic}
                                                  controls
                                                />
                                              </>
                                            )
                                          )}
                                        </div>
                                      ))}

                                      {timeline?.youtubeUrl?.map((url) => (
                                        <div
                                          className="mx-auto"
                                          style={{ maxHeight: "600px" }}
                                        >
                                          <ReactPlayer
                                            width="100%"
                                            height={isMobile ? "100%" : 600}
                                            url={url}
                                          />
                                        </div>
                                      ))}
                                    </Carousel>
                                  </div>
                                  <div className="row">
                                    <p className="font-light-gray">
                                      {ReactHtmlParser(
                                        ReactHtmlParser(timeline?.description)
                                      )?.[0] === "[object Object]"
                                        ? ReactHtmlParser(timeline?.description)
                                        : ReactHtmlParser(
                                            ReactHtmlParser(
                                              timeline?.description
                                            )
                                          )}{" "}
                                    </p>
                                    {timeline?.googleData?.map((i) => {
                                      return (
                                        <a href={i} target="_blank">
                                          {i}
                                        </a>
                                      );
                                    })}
                                    {timeline?.dropboxData?.map((i) => {
                                      return (
                                        <a href={i} target="_blank">
                                          {i}
                                        </a>
                                      );
                                    })}
                                  </div>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}

                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Wrapper>
  );
};

export default PortfolioDetails;
