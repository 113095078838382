import { Table } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import Loading from "../../../components/_shared/Loader/Loading";
import program from "../../../services/program";
import { notify } from "../../../utils";
import BankModel from "./component/BankModel";

const Transection = () => {
  const [programs, setPrograms] = useState([]);
  const [bankModelShow, setBankModelShow] = useState(false)
  const [bankDetails, setBankDetails] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchProgram()
  }, []);

  const fetchProgram = () => {
    setIsLoading(true)
    program
      .getSubscribeProgram()
      .then((res) => {
        setIsLoading(false)
        if (res?.status === 200) {
          setPrograms(res?.data?.program);
        }
      })
      .catch((err) => {
        setIsLoading(false)
        notify("error",JSON.stringify(err));
      });
  }

  const closeProgram = (id) =>{
    program.closeProgram(id).then(res => {

      if(res?.status === 200) {
        fetchProgram()
      }
    }
      ).catch(err => {
        notify("error",err)
    })
  } 

  const columns = [
    { title: "Program Title", dataIndex: "title", key: "title" },
    { title: "Purchased By", dataIndex: "buyer", key: "buyer" },
    { title: "Created By", dataIndex: "created", key: "created" },

    { title: "Price", dataIndex: "price", key: "price" },
    {
      title: "Tax (10%)",
      dataIndex: "tax",
      key: "tax",
    },

    {
      title: "Payable Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Bank Details",
      dataIndex: "bank",
      key: "bank",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  let data = [];
  let index = 1;

  if (programs?.length)
    for (let i of programs) {
      data.push({
        key: index,
        title: i?.title,
        buyer: i?.name,
        created: i?.createdBy,
        price: <div>${i?.price}</div>,
        tax: <div>${(Number(i?.price) * 10) / 100}</div>,
        amount: <div>${Number(i?.price) - (Number(i?.price) * 10) / 100}</div>,
        bank: (
          <button
            onClick={() => {setBankDetails(i?.bank); setBankModelShow(!bankModelShow)}}
            className="btn btn-primary mx-3"
          >
            Bank Details
          </button>
        ),
        status: (
          <div>
            {i?.isAmountRecived ? (
              <span className="text-success">Paid</span>
            ) : (
              <button
                onClick={() => closeProgram(i?.id)}
                className="btn btn-success mx-3"
              >
                Close
              </button>
            )}
          </div>
        ),
      });

      index++;
    }

  return (
    <>
    <Loading isLoading = {isLoading}/>
    <BankModel bankDetails = {bankDetails} visible = {bankModelShow} setVisible = {setBankModelShow}/>
    <AdminLayout>

      <Table columns={columns} pagination={true} dataSource={data} />
    </AdminLayout>

    </>
  );
};

export default Transection;
