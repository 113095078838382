import React, { useState,useEffect } from "react";

const JoinMember = ({isJoin, group, userData,leftGroup, joinGroup}) => {
  const [isJoined, setIsJoined] = useState(false)
  
  useEffect(() => {
    setIsJoined(isJoin)
  },[isJoin])

  return (
    <div className="col-md-12  mt-3" key={group?._id}>
      {isJoined ? (
        <button
          className="btn btn-primary px-3"
          onClick={() => leftGroup(group?._id, userData?.id)}
        >
          Leave
        </button>
      ) : (
        <button
          className="btn btn-success px-4"
          onClick={() => joinGroup(group?._id, userData?.id)}
        >
          {" "}
          Join
        </button>
      )}

      <span className=" text-secondary ml-3" style={{ marginLeft: "20px" }}>
        {" "}
        {group?.groupName}
      </span>
    </div>
  );
};

export default JoinMember;
