import React, { useState } from "react";
import {
  Col,
  Divider,
  Row,
  Tabs,
  Form,
  Upload,
  Button,
  Select,
  Carousel,
} from "antd";
import ReactHtmlParser from "react-html-parser";
import ModalWrapper from "../../../../../components/Modal";
import { InputField, TextAreaField } from "../../../../../components/_shared";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useEffect } from "react";

import { notify, SERVER_URL } from "../../../../../utils";
import timeline from "../../../../../services/timeline";
import { returnFileType } from "../../../../../utils/imageAccept";
import { joinedUser } from "../../../../../utils/joinedUser";
import useViewport from "../../../../../hooks/useViewport";
import ReactPlayer from "react-player";

const { TabPane } = Tabs;

const { Option } = Select;

const PortfolioModal = ({ visible, setVisible, portoflio, insertTimeline }) => {
  const [timelines, setTimelines] = useState([]);
  const { isMobile } = useViewport();

  useEffect(() => {
    getMyTimeline();
  }, []);

  const getMyTimeline = () => {
    timeline
      .getMyTimeline()
      .then((res) => {
        if (res.status === 200) {
          setTimelines(
            res?.data?.timelines?.length > 0
              ? res?.data?.timelines?.reverse()
              : []
          );
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <div
          className="center-column"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            htmlType="submit"
            size={"large"}
            type="primary"
            className="rounded "
            // style = {{heigth : "200px"}}
          >
            <h5
              className="font-white"
              onClick={() => {
                setVisible(false);
                notify("success", "Timeline added successfully");
              }}
            >
              {" "}
              Share
            </h5>
          </Button>
        </div>
        <Form layout="vertical">
          <div className="row">
            <div className="col-md-12">{/* <h3>{portfolio?.title}</h3> */}</div>

            <>
              {timelines?.map((timeline) => (
                <div className="row cursor-pointer ">
                  <div className="col-md-1">
                    <Checkbox
                      onChange={(e) => {
                        if (joinedUser(timeline?.id, portoflio?.timeline)) {
                          insertTimeline(timeline?.id, "remove");
                        } else {
                          insertTimeline(timeline?.id, "add");
                        }
                      }}
                      checked={joinedUser(timeline?.id, portoflio?.timeline)}
                      className="  mt-3"
                    />
                  </div>

                  {timeline.postPic.length || timeline.youtubeUrl.length ? (
                    <div className="col-md-3">
                      <Carousel showArrows={true}>
                        {timeline?.postPic?.map((pic, index) => (
                          <div className="mx-auto" style={{ height: "100px" }}>
                            {returnFileType(pic) == "jpg" ||
                            returnFileType(pic) == "png" ||
                            returnFileType(pic) == "jpeg" ||
                            returnFileType(pic) == "svg" ? (
                              <img
                                src={`${pic}`}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = `${process.env.PUBLIC_URL}user.png`;
                                }}
                                alt=""
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {returnFileType(pic) == "pdf" && (
                              <embed
                                src={`${pic}`}
                                width="100%"
                                height="100px"
                              />

                              // </iframe>
                            )}
                          </div>
                        ))}

                        {timeline?.youtubeUrl?.map((url) => (
                          <div className="mx-auto" style={{ height: "100px" }}>
                            <ReactPlayer
                              width="100%"
                              height={isMobile ? "100%" : 100}
                              url={url}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-8 d-flex flex-column">
                    <h5>{timeline?.title}</h5>
                    <span>
                      {" "}
                      {ReactHtmlParser(
                        ReactHtmlParser(timeline?.description)
                      )?.[0] === "[object Object]"
                        ? ReactHtmlParser(timeline?.description)
                        : ReactHtmlParser(
                            ReactHtmlParser(timeline?.description)
                          )}
                    </span>
                  </div>
                  <div className="col-md-12">
                    <Divider />
                  </div>
                </div>
              ))}
            </>
          </div>
        </Form>
      </div>
    </ModalWrapper>
  );
};

export default PortfolioModal;
