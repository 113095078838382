import { SearchOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../../../components/_shared";
import Loading from "../../../../components/_shared/Loader/Loading";
import { ViewProfileContext } from "../../../../context/viewProfile";
import program from "../../../../services/program";
import { notify, SERVER_URL } from "../../../../utils";

const PurchasedProgram = () => {
  const [programs, setPrograms] = useState([]);
  const naviagte = useNavigate();
  const { profileToken } = useContext(ViewProfileContext);
  const [searchField, setSearchField] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchMyPrograms();
  }, []);

  const fetchMyPrograms = async () => {
    try {
      setIsLoading(true)
      const res = await program.fetchSubscribedProgram(
        profileToken?.access?.token
      );

      if (res.status === 200) {
        setIsLoading(false)
        setPrograms(res?.data?.programs);
      }
    } catch (err) {
      setIsLoading(false)
      notify("error", "Something went wrong");
    }
  };

  return (
    <>
        <Loading isLoading = {isLoading}/>

      <InputField
        value={searchField}
        onChange={(e) => setSearchField(e.target.value)}
        placeholder="Search"
        suffix={<SearchOutlined />}
        style={{ width: "350px" }}
      />
      {programs
        ?.filter((program) =>
          `${program?.title}`.toLowerCase().includes(searchField.toLowerCase())
        )
        ?.map((program, index) => (
          <div className="row p-4 bg-secondary-gray mt-3  border-20">
            <div className="col-md-1 my-auto">
              <img
                src={`${program?.img}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${process.env.PUBLIC_URL}user.png`;
                }}
                width={"80px"}
                alt=""
              />
            </div>
            <div className="col-md-11 flex-column ">
              <div className="">
                <h5 className="cursor-pointer"> {program?.title}</h5>
              </div>
              <div>
                <span>{program?.description}</span>
              </div>
            </div>
            {!profileToken && (
              <div className="col-md-12 d-flex justify-content-end mt-4">
                <button
                  onClick={() =>
                    naviagte(`/program/${program?.id}/purchased/details`)
                  }
                  className="btn btn-success"
                >
                  View
                </button>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

export default PurchasedProgram;
