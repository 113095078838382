import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AppLayout } from "../components";
import Signup from "../pages/Authentication/Signup";
import Signin from "../pages/Authentication/Signin";

import Home from "../pages/Home";
import { ROUTES } from "./constant";
import Dashboard from "../pages/Dashboard";
import Timeline from "../pages/Dashboard/Timeline";
import PublicRoute from "./Public.route";
import PrivateRoute from "./Private.route";
import Program from "../pages/Dashboard/Program";
import Portfolio from "../pages/Dashboard/Portfolio";
import Groups from "../pages/Dashboard/Group";
import { HeaderTab } from "../pages/Dashboard/components";
import Studio from "../pages/Dashboard/Studio";
import Profile from "../pages/Dashboard/Profile";
import PortfolioDetails from "../pages/Dashboard/Portfolio/PortfolioDetails";
import SharePortfolioDetails from "../pages/Dashboard/Portfolio/SharedPortfolio/PortfolioDetails";

import Pricing from "../pages/Pricing";

import ManageUser from "../pages/ManageUser";
import ReqProgram from "../pages/Admin/Program";
import ProgramDetails from "../pages/Dashboard/Program/component/ProgramDetails";
import Info from "../pages/Info";
import Fourm from "../pages/Dashboard/Fourm";
import RecievedPayment from "../pages/RecievedPayment";
import PaymentDetails from "../pages/PaymentDetails";
import Transection from "../pages/Admin/Transection";
import About from "../pages/About";
import AdminLogin from "../pages/Admin/Login";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import FolderDetails from "../pages/Dashboard/Portfolio/Folder";
import CurrentPlan from "../pages/Admin/CurrentPlan";
import SelfSubscription from "../pages/SelfSubscription";
import StorageUpgrade from "../pages/StorageUpgrade";
import AddonsUpgrade from "../pages/AddonseUpgrade";
import Faq from "../pages/Faq";
import Why from "../pages/why";
import GetStarted from "../pages/GetStrated";
import EditContent from "../pages/Admin/Content";
import Tnc from "../pages/Tnc";
import Privacy from "../pages/Privacy";
import Notification from "../pages/Admin/Notification";
import Coupon from "../pages/Admin/Coupon";
import TimelineDetails from "../pages/Dashboard/Timeline/TimelineDetails";
import MobilePlayer from "../pages/Player";

const Routers = () => {
	const publicRoutes = [
		{
			key: 1,
			path: ROUTES.INDEX,
			component: Home,
			restricted: true,
		},
		{
			key: 2,
			path: ROUTES.SIGNIN,
			component: Signin,
			restricted: true,
		},
		{
			key: 3,
			path: ROUTES.SIGNUP,
			component: Signup,
			restricted: true,
		},
		{
			key: 4,
			path: ROUTES.PRICING,
			component: Pricing,
			restricted: true,
		},
		{
			key: 5,
			path: ROUTES.ABOUT,
			component: About,

			restricted: false,
		},
		{
			key: 6,
			path: ROUTES.FORGOT_PASSWORD,
			component: ForgotPassword,
			restricted: true,
		},
		{
			key: 7,
			path: ROUTES.RESET_PASSWORD,
			component: ResetPassword,
			restricted: true,
		},
		{
			key: 8,
			path: ROUTES.FAQ,
			component: Faq,
			restricted: true,
		},
		{
			key: 9,
			path: ROUTES.WHY,
			component: Why,
			restricted: true,
		},
		{
			key: 10,
			path: ROUTES.GET_STARTED,
			component: GetStarted,
			restricted: true,
		},
		{
			key: 11,
			path: ROUTES.ADMIN_LOGIN,
			component: AdminLogin,
			restricted: true,
		},
		{
			key: 12,
			path: ROUTES.TNC,
			component: Tnc,
		},
		{
			key: 13,
			path: ROUTES.PRIVACY,
			component: Privacy,
		},
	];

	const privateRoutes = [
		{
			key: 1,
			path: ROUTES.TIMELINE,
			component: Timeline,
		},

		{
			key: 2,
			path: ROUTES.PROGRAM,
			component: Program,
		},
		{
			key: 3,
			path: ROUTES.PORTFOLIO,
			component: Portfolio,
		},
		{
			key: 4,
			path: ROUTES.GROUP,
			component: Groups,
		},
		{
			key: 5,
			path: ROUTES.STUDIO_DETAILS,
			component: Studio,
		},
		{
			key: 6,
			path: ROUTES.PROFILE,
			component: Profile,
		},

		{
			key: 7,
			path: ROUTES.PURCHASED_PROGRAM_DETAILS,
			component: ProgramDetails,
		},
		{
			key: 8,
			path: ROUTES.INFO,
			component: Info,
		},
		{
			key: 9,
			path: ROUTES.FOURM,
			component: Fourm,
		},

		{
			key: 10,
			path: ROUTES.RECIEVED_PAYMENT,
			component: RecievedPayment,
		},
		{
			key: 11,
			path: ROUTES.PAYMENT_DETAILS,
			component: PaymentDetails,
		},
		{
			key: 12,
			path: ROUTES.PROGRAM_DETAILS,
			component: ProgramDetails,
		},

		{
			key: 13,
			path: ROUTES.FOLDER_DETAILS,
			component: FolderDetails,
		},
		{
			key: 14,
			path: ROUTES.UPGRADE_STORAGE,
			component: StorageUpgrade,
		},
		{
			key: 15,
			path: ROUTES.UPGRADE_ADDONS,
			component: AddonsUpgrade,
		},
		{
			key: 16,
			path: ROUTES.TIMELINE_BY_ID,
			component: TimelineDetails,
		},
	];

	const privateRouteWithoutHeader = [
		{
			key: 1,
			path: ROUTES.PORTFOLIO_DETAILS,
			component: PortfolioDetails,
		},
		{
			key: 2,
			path: ROUTES.SHARE_PORTFOLIO_DETAILS,
			component: SharePortfolioDetails,
		},

		{
			key: 3,
			path: ROUTES.MANAGE_USER,
			component: ManageUser,
		},

		{
			key: 4,
			path: ROUTES.REQ_PROGRAM,
			component: ReqProgram,
		},

		{
			key: 5,
			path: ROUTES.TRANSACTION,
			component: Transection,
		},
		{
			key: 6,
			path: ROUTES.CURRENT_PLAN,
			component: CurrentPlan,
		},
		{
			key: 7,
			path: ROUTES.SELF_SUBSCRIPTION_PLAN,
			component: SelfSubscription,
		},
		{
			key: 8,
			path: ROUTES.EDIT_CONTENT,
			component: EditContent,
		},

		{
			key: 9,
			path: ROUTES.NOTIFICATION,
			component: Notification,
		},
		{
			key: 9,
			path: ROUTES.COUPONS,
			component: Coupon,
		},
	];
	const publicRouteWithoutheader = [
		{
			key: 1,
			path: ROUTES.MOBILE_PLAYER,
			component: MobilePlayer,
		},
	];

	return (
		<Router>
			{window?.location?.pathname?.startsWith("/mobile-player") ? (
				<Routes>
					{publicRouteWithoutheader.map((i) => (
						<Route
							key={i.key}
							path={i?.path}
							element={
								<PublicRoute
									restricted={false}
									key={i?.key}
									component={i?.component}
								/>
							}
						/>
					))}
				</Routes>
			) : (
				<AppLayout>
					<Routes>
						{publicRoutes.map((i) => (
							<Route
								path={i?.path}
								element={
									<PublicRoute
										restricted={i?.restricted}
										key={i?.key}
										component={i?.component}
									/>
								}
							/>
						))}
						<Route path={ROUTES.INDEX} element={<HeaderTab />}>
							{privateRoutes.map((i) => (
								<Route
									path={i?.path}
									element={
										<PrivateRoute key={i?.key} component={i?.component} />
									}
								/>
							))}
						</Route>
						{privateRouteWithoutHeader.map((i) => (
							<Route
								key={i.key}
								path={i?.path}
								element={<PrivateRoute key={i?.key} component={i?.component} />}
							/>
						))}
					</Routes>
				</AppLayout>
			)}
		</Router>
	);
};

export default Routers;
