import React, { useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import styled from "styled-components";
import moment from "moment";
import auth from "../../../services/auth";
import { notify } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { fetchJoinedStudioInit } from "../../../store/actions/studio";
import { useDispatch } from "react-redux";
import { STRIPE_KEY } from "../../../utils/server";
import { getAvailableStudiodate } from "../../../utils/helper";

const StripeContainer = styled.div`
  .StripeCheckout {
    background: #0eaaa5 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 4px #00000029;
    border: 1px solid #ffffff40;
    border-radius: 4px;
    color: #fff;
    border-radius: 4px;
    opacity: 1;
    padding: 10px;

    span {
      font-size: 20px !important;
      background: none !important;
      box-shadow: none !important;
    }
  }
`;

const StripeStorage = ({
  price,
  storage,
  userData,
  getUserinfo,
  maxStudents,
}) => {
  const priceForStripe = Number(price) * 100;
  const publishableKey = STRIPE_KEY;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const newPrice =
  // Number(price) -
  // (userData?.storageLimit / 30 * 30) -
  // getAvailableStudiodate(userData?.studioMonthExp);
  // console.log(  (userData?.storageLimit / 30 * 30) -
  // getAvailableStudiodate(userData?.studioMonthExp))

  // console.log({
  //   stripe: {
  //     source:" token.id",
  //     amount: Number(newPrice) * 100,
  //     currency: "aud",
  //   },
  //   storage: storage,
  //   signupCode: userData?.signupCode,
  //   studentEnrolledLimit: maxStudents,
  //   studioMonthExp: moment().add(30, "day"),
  // });
  const onToken = async (token) => {
    try {
      if (Number(price)  < userData?.storageLimit) {
        return notify("error", "You can not upgrade less then your current plan");
      }
      if (getAvailableStudiodate(userData?.studioMonthExp) > 0) {
        const availableDays =
          30 - getAvailableStudiodate(userData?.studioMonthExp);
        const paidCharges = userData?.storageLimit / 30;
        const totalPaidCharges = Number(paidCharges) * availableDays;
        const newPrice = userData?.storageLimit - totalPaidCharges;
        const actualPrice = Number(price) - Number(newPrice);

        //  if( Number(price) <  Number(userData?.storageLimit)) return notify("error", "You can not upgrade less then your current plan")

        const res = await auth.upgradeStorage({
          stripe: {
            source: token.id,
            amount: Math.floor(actualPrice * 100),
            currency: "aud",
          },
          storage: storage,
          signupCode: userData?.signupCode,
          studentEnrolledLimit: maxStudents,
          studioMonthExp: moment().add(30, "day"),
          price: Number(price),
        });

        if (res?.status === 200) {
          notify("Success");
          if (userData?.role !== "studioOwner") {
            window.location.href = "/";
          } else navigate("/current-plan");
        }
      } else {
        const res = await auth.upgradeStorage({
          stripe: { source: token.id, amount: priceForStripe, currency: "aud" },
          storage: storage,
          signupCode: userData?.signupCode,
          studentEnrolledLimit: maxStudents,
          studioMonthExp: moment().add(30, "day"),
          price: Number(price),
        });

        if (res?.status === 200) {
          notify("Success");
          if (userData?.role !== "studioOwner") {
            window.location.href = "/";
          } else navigate("/current-plan");
        }
      }
    } catch (err) {
      notify("error",JSON.stringify(err));
    }
  };

  const openStripe = () => {
    if (Number(price)  < userData?.storageLimit) {
      return notify("error", "You can not upgrade less then your current plan");
    }
  };

  return (
    <StripeContainer>
      <StripeCheckout
        label={"Upgrade"}
        name="Movitanz"
        billingAddress
        shippingAddress
        description={`Your total is ${price} AUD`}
        amount={priceForStripe}
        currency="AUD"
        panelLabel="Pay now"
        token={onToken}
        stripeKey={publishableKey}
        // opened={openStripe}
      />
    </StripeContainer>
  );
};

export default StripeStorage;
