import React, { useState } from "react";
import Vimeo from "@u-wave/react-vimeo";

import { useEffect } from "react";

import LoadingVideo from "../../components/_shared/Loader/LoadingVideo";
import { useParams,useLocation,useSearchParams } from "react-router-dom";

const MobilePlayer = () => {
  const [error, setError] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setIsError(!isError);
      }, 3000);
    }
  }, [error, isError]);
  const [searchParams] = useSearchParams();

  const id =searchParams?.get("id")
  return (
    <>
      {error && isError ? (
        <>
          <LoadingVideo isLoading={true} />
        </>
      ) : (
        <div
          // className="mx-auto"
          className="mobile-player"
          style={{ height: "600px", width: "100%" }}
        >
          <Vimeo
            video={Number(id)}
            width={"100%"}
            className="vimeo-player-iframe"
            height={600}
            onError={(e) => {
              setError(true);
            }}
            onLoaded={() => {
              setError(false);
            }}
            showTitle={false}
            showByline={false}
            autoplay={false}
          />
        </div>
      )}
    </>
  );
};

export default MobilePlayer;
