import React, { useState } from "react";
import { Col, Row, Form, Divider } from "antd";


import { GrayInputField, InputField } from "../../../components/_shared/Input";

import { Button } from "../../../components/_shared";

import Loading from "../../../components/_shared/Loader/Loading";
import auth from "../../../services/auth";
import { notify } from "../../../utils";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();


  const onForgotPassword = async (e) => {
    try {
      setIsLoading(true)
      const res = await auth.forgotPassword({ email: e?.email });
      if (res.status === 200) {
        setIsLoading(false)
        form.resetFields();
        notify("success", "Please check your email for reset your password.");
      }
    } catch (err) {
      setIsLoading(false)
      notify("error",JSON.stringify(err));
    }
  };
  return (
    <div className="container  mt-5 bg-white p-5">
      <Loading isLoading={isLoading} />
      <div className="row">
    
        <div className=" col-md-6 mt-0 bg-secondary-gray py-2 mt-5    ">
          <h5 className="primary-green my-auto">Forgot Password</h5>
        </div>
        <div className="offset-md-6"></div>
        <Form
          layout="vertical"
          className="mt-4"
          style={{ width: "100%" }}
          form = {form}
          onFinish={onForgotPassword}
        >
          <div className="col-md-6">
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Enter valid email address",
                },
              ]}
            >
              <GrayInputField
                className="font-600"
                type="email"
                placeholder="Email"
              />
            </Form.Item>
          </div>

        <div className="row d-flex ">
          <div className="col-md-2 ">
            <Button
              lite
              htmlType="submit"
              size={"large"}
              // style={{ marginLeft: isMobile ? 0 : 20 }}
              title="Forgot Password"
              className=""
              />
          </div>
        </div>
              </Form>
        <div className="col-md-6">
          <Divider />
        </div>
        <div className="md-offset-6"></div>
      </div>
    </div>
  );
};

export default ForgotPassword;
