import React, { useEffect, useState } from "react";
import { parseJwt } from "../../utils";
import Api from "../../services/profile";
import { useNavigate } from "react-router-dom";
export const UserContext = React.createContext({});
/**
 *
 * @param {*} param0 component
 * @returns current user info provider
 */
const UserProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [userData, setUserdata] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const getUserinfo = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (token !== null) {
        const userinfo = parseJwt(token);
        setUser(userinfo);
        const info = await Api.getmyProfile();        
        setUserdata(info?.data?.user);
      }
    }
    catch(err) {
      if(err === "Please authenticate") {
        localStorage.clear()
        window.location.href = "/signin"
      }
    }
  };

  useEffect(() => {
    getUserinfo();
    localStorage.getItem("authToken") ? setIsLoggedIn(true) : setIsLoggedIn(false);
  }, []);

  return (
    <UserContext.Provider value={{ user, getUserinfo, setUser, userData,setUserdata, setIsLoggedIn, isLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
