import styled from "styled-components";
import { Input } from "antd";

const { TextArea } = Input;

export const Wrapper = styled.div`
iframe { 
  width : 95% !important
}
  .cover {
    object-fit: contain;
    width: 100%;
    max-height: 367px;
    height: 367px;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 60%);
    background :#0eaaa5;
  }
  .profile-left {
    position: absolute;
    z-index: 99;
    left: 0px;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 60%);
  }
  .edit-right {
    position: absolute;
    z-index: 9999;
    right: 0px;
    top: 0px;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 60%);
  }
  .box {
    height: 35px;
    width: 35px;
  }

  .box1{
    background: ${(props) => props.primary};
  }

  .box2{
    background: ${(props) => props.secondary};

  }

  .box3{
    background: ${(props) => props.border};

  }

  .box4 {
    background: ${(props) => props.heading};

  }

  .box5{
    background: ${(props) => props.text};

  }

  .ant-card { 
    border: ${(props) =>`1px solid   ${props.border}`};;

  }

  .ant-card-head {
    background: ${(props) => props.primary};
    color: ${(props) => props.text};

  }
  .ant-card-body {
    background: #f4f1ec;
  }

  .ant-collapse-header {
    background: #6c6c6c;
  }
  .ant-collapse-content {
    background: #f4f1ec;
  }

  .ant-select-selector {
    height: 42px !important;
    border: 1px solid #b7b5b5;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    margin: auto auto;
  }

  .ant-select-arrow {
    font-size: 15px;
    color: #0eaaa5;
  }

  .primary-color {
    background: ${(props) => props.primary};
  }

  .secondary-color {
    background: ${(props) => props.secondary};
  }

  .border-color {
    border-color: ${(props) => props.border};
  }

  .heading-color {
    color: ${(props) => props.heading};
    font-family: "Poppins";

  }

  .text-color {
    color: ${(props) => props.text};
    font-family: "Poppins";
    font-weight: 500;
  }


  .box-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background: rgba(255, 255, 255, 0.8);
  }

  .box-sub-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    margin : 0 20px;
  }
`;

export const BoxInputFiled = styled(Input).attrs({
  className: "font-source-sans",
})`
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  border: ${(props) =>`1px solid   ${props.border}`};
  padding: 5px 10px;
  color: ${(props) => props.text};

  height: 40px;
  font-size: 16px;
  font-weight: 500;
  input {
    font-weight: 600 !important;

  }
  &.search-input {
    input {
      background: #fff !important;
    }
  }
  &:hover {
    border: none;
    outline: none;
  }
`;

export const BoxTextArea = styled(TextArea).attrs({
  className: "font-source-sans",
})`
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  padding: 10px 20px;
  border: ${(props) =>`1px solid ${props.border}`};
  color: ${(props) => props.text};
  height: 60px;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    border: none;
    outline: none;
  }
`;
