import { all, fork } from "redux-saga/effects";
import {watchAuth} from './auth'
import {watchTimeline} from './timeline'
import {watchStudio} from './studio'
import { watchPortfolio } from "./portfolio";



export default function* rootSaga() {
  yield all([fork(watchAuth)]);
  yield all([fork(watchTimeline)]);
  yield all([fork(watchStudio)]);
  yield all([fork(watchPortfolio)]);

}
