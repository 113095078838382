import { SERVER_URL } from "../utils";
import { apiDelete, apiGet, apiPatch, apiPost } from "../utils/axios";

const PROGRAM = SERVER_URL + "/program"

export default {
  createProgram : (payload) => apiPost(`${PROGRAM}`, payload),
  getMyProgram : (profileToken) => apiGet(`${PROGRAM}/my-program`, profileToken),
  getProgramById : (id) => apiGet(`${PROGRAM}/${id}`),
  deleteProgramById : (id) => apiDelete(`${PROGRAM}/${id}`),
  updateStatus : (id, payload) => apiPatch(`${PROGRAM}/${id}/status`, payload),
  fetchPendingProgram  : () => apiGet(`${PROGRAM}/pending`),
  fetchApprvedProgram : () => apiGet(`${PROGRAM}/approved`),
  subscribeProgram : (id, payload) => apiPost(`${PROGRAM}/${id}/subscribe`, payload),
  fetchSubscribedProgram : (profileToken) => apiGet(`${PROGRAM}/my-subscribe`, profileToken),
  getPurchasedProgramById : (id) => apiGet(`${PROGRAM}/${id}/purchased`),
  getProgramDetailsById : (id) => apiGet(`${PROGRAM}/${id}/program-details`),

  getPurchasedMyProgram : () => apiGet(`${PROGRAM}/purchased-my-program`),
  getSubscribeProgram : () => apiGet(`${PROGRAM}/subscribe-program`),
  closeProgram : (id) => apiPost(`${PROGRAM}/${id}/close-session`),
  updateProgram : (id,payload) => apiPatch(`${PROGRAM}/${id}`, payload),

}