import produce from "immer";
import actionTypes from "../actionTypes/sutdio";
const initialState = {
  isProceeding: false,
  studio: [],
  studioMembers: [],
  groups: [],
  studioName:""
};

const studioReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.FETCH_JOINED_STUDIO_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.FETCH_JOINED_STUDIO_SUCCESS:
        draft.isProceeding = false;
        draft.studio = action.studio;
        break;
      case actionTypes.FETCH_JOINED_STUDIO_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.JOIN_STUDIO_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.JOIN_STUDIO_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.JOIN_STUDIO_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.LEFT_STUDIO_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.LEFT_STUDIO_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.LEFT_STUDIO_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.JOIN_GROUP_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.JOIN_GROUP_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.JOIN_GROUP_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.LEFT_GROUP_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.LEFT_GROUP_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.LEFT_GROUP_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.FETCH_STUDIO_MEMBERS_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.FETCH_STUDIO_MEMBERS_SUCCESS:
        draft.isProceeding = false;
        draft.studioMembers = action.studioMembers;
        break;

      case actionTypes.FETCH_STUDIO_MEMBERS_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.FETCH_STUDIO_GROUP_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.FETCH_STUDIO_GROUP_SUCCESS:
        draft.isProceeding = false;
        draft.groups = action.groups;
        draft.studioName=action.studioName
        break;

      case actionTypes.FETCH_STUDIO_GROUP_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.ADD_GROUP_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.ADD_GROUP_SUCCESS:
        draft.isProceeding = false;
        break;

      case actionTypes.ADD_GROUP_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.DELETE_GROUP_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.DELETE_GROUP_SUCESS:
        draft.isProceeding = false;
        break;

      case actionTypes.DELETE_GROUP_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.ADD_GROUP_MEMBER_INIT:
        draft.isProceeding = false;
        break;
      case actionTypes.ADD_GROUP_MEMBER_SUCCESS:
        draft.isProceeding = false;
        break;

      case actionTypes.ADD_GROUP_MEMBER_FAILED:
        draft.isProceeding = false;
        break;
      case actionTypes.DELETE_GROUP_MEMBER_INIT:
        draft.isProceeding = false;
        break;
      case actionTypes.DELETE_GROUP_MEMBER_SUCESS:
        draft.isProceeding = false;
        break;

      case actionTypes.DELETE_GROUP_MEMBER_FAILED:
        draft.isProceeding = false;
        break;

      default:
        break;
    }
  });

export default studioReducer;
