import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import { Container } from "./component/component";
import useViewport from "../../../hooks/useViewport";
import AdminLayout from "../../../components/AdminLayout";
import Guide from "./component/Guide";
import admin from "../../../services/admin";
import { notify } from "../../../utils";
import Home from "./component/Home";
import Loader from "../../../components/_shared/Loader/Loading";
import GetStarted from "./component/GetStrated";
import Why from "./component/Why";
import About from "./component/About";
import Faq from "./component/Faq";
import Pricing from "./component/Pricing";
import Email from "./component/email";
import Notification from "./component/notification";

const { TabPane } = Tabs;

const Profile = () => {
  const [defaultActiveKey, setActivekey] = useState("1");
  const { isMobile } = useViewport();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState();

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    setIsLoading(true);
    admin
      .getContent({
        home: 1,
        getStarted: 1,
        why: 1,
        faq: 1,
        pricing: 1,
        guide: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
        setIsLoading(false);
      });
  };

  const onUpdateContent = (content) => {
    setIsLoading(true);
    // console.log(Object.keys(files));

    admin
      .updateContent(content)
      .then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            setIsLoading(false);
            notify("Success!");
            getContent();
          }, 2000);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        notify("error", JSON.stringify(err));
      });
  };

  return (
    <AdminLayout>
      <Loader isLoading={isLoading} />
      <Container className="container-fluid bg-white mt-5 p-5 shadow">
        <div className="row ">
          <div className="col-md-12">
            <Tabs
              activeKey={String(defaultActiveKey)}
              onChange={(e) => {
                setActivekey(e);
              }}
              // defaultActiveKey={defaultActiveKey}
              type="line"
              // tabBarGutter={0}
              tabPosition="left"
            >
              <TabPane
                className={defaultActiveKey == 1 ? "" : ""}
                tab={
                  <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                    Home
                  </div>
                }
                key="1"
              >
                <Home onUpdate={onUpdateContent} home={content?.home} />
              </TabPane>

              <TabPane
                className={defaultActiveKey == 2 ? "" : ""}
                tab={
                  <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                    Get Started
                  </div>
                }
                key="2"
              >
                <GetStarted
                  onUpdate={onUpdateContent}
                  started={content?.started}
                />
              </TabPane>
              <TabPane
                className={defaultActiveKey == 3 ? "" : ""}
                tab={
                  <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                    Why
                  </div>
                }
                key="3"
              >
                <Why onUpdate={onUpdateContent} why={content?.why} />
              </TabPane>
              <TabPane
                className={defaultActiveKey == 4 ? "" : ""}
                tab={
                  <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                    About
                  </div>
                }
                key="4"
              >
                <About onUpdate={onUpdateContent} about={content?.about} />
              </TabPane>
              <TabPane
                className={defaultActiveKey == 5 ? "" : ""}
                tab={
                  <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                    Faq
                  </div>
                }
                key="5"
              >
                <Faq onUpdate={onUpdateContent} faq={content?.faq} />
              </TabPane>
              <TabPane
                className={defaultActiveKey == 6 ? "" : ""}
                tab={
                  <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                    Pricing
                  </div>
                }
                key="6"
              >
                <Pricing
                  onUpdate={onUpdateContent}
                  pricing={content?.pricing}
                />
              </TabPane>
              <TabPane
                className={defaultActiveKey == 7 ? "" : ""}
                tab={
                  <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                    Guide
                  </div>
                }
                key="7"
              >
                <Guide
                  guide={content?.guide}
                  setIsLoading={setIsLoading}
                  onUpdate={onUpdateContent}
                />
              </TabPane>
              <TabPane
                className={defaultActiveKey == 8 ? "" : ""}
                tab={
                  <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                    Email
                  </div>
                }
                key="8"
              >
                <Email
                  guide={content?.email}
                  setIsLoading={setIsLoading}
                  onUpdate={onUpdateContent}
                />
              </TabPane>

              <TabPane
                className={defaultActiveKey == 9 ? "" : ""}
                tab={
                  <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                    App Notification
                  </div>
                }
                key="9"
              >
                <Notification
                  onUpdate={onUpdateContent}
                  notification={content?.notification}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Container>
    </AdminLayout>
  );
};

export default Profile;
