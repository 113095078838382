import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 20px;

  .infinite-scroll-component  {
    overflow-x:hidden !important ;
    overflow-y: auto !important;
  }
  
`

export const Container = styled.div`
  .tab_tabName {
      width: 8rem;
      text-align: center;
      font-size: 16px;
      font-family: Poppins;
    }
   .ant-tabs-nav-list {
      margin : auto;
      font-size: 16px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #363636 !important;
      font-weight: 500;
    }
    .ant-tabs-tab-btn {
      color: #605A5B;
      font-size: 10px;
      font-weight: 400;
    }
`
