import { SERVER_URL } from "../utils";
import { apiDelete, apiGet, apiPatch, apiPost } from "../utils/axios";

const STUDIO = SERVER_URL + "/auth/studio/joined-studio"
const LEFT_JOINED_STUDIO = SERVER_URL + "/auth/studio/left-studio"
const STUDIO_MEMEBERS = SERVER_URL + "/auth/studio/my-members"
const MY_GROUP = SERVER_URL + "/group/my-group"
const GROUP = SERVER_URL + "/group"

export default{
  getJoinedStudio : (payload) => apiGet(STUDIO,payload?.profileToken),
  joinStudio : (payload) => apiPost(STUDIO, payload),
  leftStudio : (payload) => apiPost(LEFT_JOINED_STUDIO, payload) ,
  getStudioMembers : (payload) => apiGet(STUDIO_MEMEBERS, payload?.profileToken),
  fetchMyGroups :() => apiGet(MY_GROUP),
  addGroup : (payload) => apiPost(GROUP, payload),
  deleteGroup : (gid) => apiDelete(`${GROUP}/${gid}`),
  updateGroup : (gid, payload) => apiPatch(`${GROUP}/${gid}`, payload),
  addGroupMembers : (gid,payload) => apiPost(`${GROUP}/${gid}/member`, payload), 
  deleteGroupMember : (gid, mid) => apiDelete(`${GROUP}/${gid}/member/${mid}`) 
  

}