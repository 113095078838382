import React, { useState } from "react";
import { Col, Divider, Row, Tabs, Form, Upload, Button } from "antd";
import ModalWrapper from "../../../../components/Modal";
import { InputField, TextAreaField } from "../../../../components/_shared";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { isFileAccept, notify } from "../../../../utils";
const { TabPane } = Tabs;

const CreateProgramModel = ({ visible, setVisible, onCreateProgram, logo, setLogo }) => {
  const [defaultActiveKey, setActivekey] = useState("1");

  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <div className="row">
          <div className="col-md-12">
            <h3>Create Your Program </h3>
          </div>
          <div className="col-md-12">
            <Divider />
          </div>
          <div className="col-md-12">
            <Form layout="vertical" onFinish={onCreateProgram}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your title.",
                      },
                    ]}
                  >
                    <InputField className="font-600" placeholder="Title" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                  <Form.Item name="description" label="Description ">
                    <TextAreaField
                      className="font-600"
                      placeholder="Description"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} xl={6} xxl={6} span={6}>
                  <Form.Item name="price"  label="Price"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your price.",
                      },
                    ]}>
                    <InputField
                      className="font-600"
                      type="number"
                      min="1"
                      placeholder="Price"
                    />
                  </Form.Item>
                </Col>

                <Col
                  style={{ display: "flex !important", alignItems: "center" }}
                  className="d-flex align-content-center mt-4"
                  xs={24}
                  sm={24}
                  md={24}
                  xl={12}
                  xxl={12}
                  span={12}
                >
                  <Form.Item name="files">
                    <Upload
                      accept={"image/*"}
                      multiple={false}
                      previewFile={true}
                      fileList={logo ? [logo] : []}
                      onChange={(e) => {
                        if(isFileAccept(e?.file?.name))
                        e?.fileList.length ? setLogo(e?.file) : setLogo("");
                        else notify("error", "Please upload an logo image.")
                      }}
                      beforeUpload={() => false}                    >
                      <Button
                        className="my-auto"
                        type="primary"
                        size={"large"}
                        icon={<UploadOutlined className="h4" />}
                      >
                        Upload Logo Image
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                  <Divider />
                </Col>

                <Form.List name="programs" className="mb-0">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, index) => (
                          <>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              xl={24}
                              xxl={24}
                              span={24}
                            >
                              <h5>Program Step {index + 1}</h5>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              xl={24}
                              xxl={24}
                              span={24}
                            >
                              <Form.Item
                                style={{ marginBottom: "15px" }}
                                {...restField}
                                name={[name, "title"]}
                                fieldKey={[fieldKey, "title"]}
                                label={`Title`}
                              >
                                <InputField placeholder="" />
                              </Form.Item>

                              {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                            </Col>

                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              xl={24}
                              xxl={24}
                              span={24}
                            >
                              <Form.Item
                                style={{ marginBottom: "15px" }}
                                {...restField}
                                name={[name, "description"]}
                                fieldKey={[fieldKey, "description"]}
                                label={`Description`}
                              >
                                <TextAreaField  
                                  className="font-600"
                                  placeholder="Description"
                                />
                              </Form.Item>

                              {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                            </Col>

                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              xl={24}
                              xxl={24}
                              span={24}
                            >
                              <Form.Item
                                style={{ marginBottom: "15px" }}
                                {...restField}
                                name={[name, "video"]}
                                fieldKey={[fieldKey, "video"]}
                                label={`Video`}
                              >
                                <InputField placeholder="" />
                              </Form.Item>

                              {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              xl={24}
                              xxl={24}
                              span={24}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "program"]}
                                fieldKey={[fieldKey, "program"]}
                              >
                                <Upload
                                  accept={"image/*"}
                                  multiple={false}
                                  previewFile={true}
                                  beforeUpload={() => false}
                                >
                                  <Button
                                    type="primary"
                                    size={"large"}
                                    icon={<UploadOutlined className="h4" />}
                                  >
                                    Upload Images
                                  </Button>
                                </Upload>
                              </Form.Item>

                              <div
                                className="float-right "
                                style={{ fontSize: "18px", marginTop: "-10px" }}
                              >
                                <DeleteOutlined
                                  className="cursor-pointer"
                                  onClick={() => remove(name)}
                                />
                              </div>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              xl={24}
                              xxl={24}
                              span={24}
                            >
                              <Divider />
                            </Col>
                          </>
                        )
                      )}
                      <Col
                        xs={20}
                        sm={20}
                        md={20}
                        xl={20}
                        xxl={20}
                        span={20}
                        className=""
                      >
                        <Form.Item>
                          <div className="">
                            <div
                              className=" cursor-pointer d-flex align-content-center"
                              onClick={() => add()}
                            >
                              <PlusCircleOutlined className="mt-1 mx-1" />{" "}
                              <span>Add Program Steps</span>
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Form.List>
              </Row>

              <div
                className="center-column"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  htmlType="submit"
                  size={"large"}
                  type="primary"
                  className="rounded "
                  // style = {{heigth : "200px"}}
                >
                  <h5 className="font-white"> Save Program</h5>
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CreateProgramModel;
