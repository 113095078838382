import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Tabs, Form, Upload, Button, Modal } from "antd";
import styled from "styled-components";
import { usePaymentInputs } from "react-payment-inputs";
import { updateProfileInit } from "../../../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/_shared/Loader/Loading";
import { makeSelectAuthLoading } from "../../../../store/selectors";
import auth from "../../../../services/auth";
import { notify } from "../../../../utils";

const PaymentContainer = styled.div`
  input {
    border-radius: 20px;
    padding: 10px 20px;
    color: black;
    height: 60px;
    font-size: 16px;
    font-weight: 500;

    background: #dee3f3 !important;
    border: none;
    width: 100% !important;

    &:focus {
      border: none;
    }
  }
`;

const OldPaymentDetailsUpdate = ({ userData, getUserinfo }) => {
  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs();
  const [cardNumber, setCardNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiry, setExipiry] = useState("");
  const [name, setName] = useState("");
  const isLoading = useSelector(makeSelectAuthLoading());

  const onUpdatePaymentDetails = () => {
    auth
      .oldPaymentDetailsUpdate({
        accountName: name,
        cardNumber: cardNumber,
        expiry: expiry,
        cvv: cvv,
      })
      .then((res) => {
        if (res?.status === 200) {
          notify("success", "payment details updated")
          getUserinfo();
          window.location.reload()
        }
      })
      .catch((err) => {
        notify("error",JSON.stringify(err));
      });
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Modal
        title="To give you a better experience, we have made some changes in our payment system. This will require that you enter your card details again. Please re-enter your card details."
        visible={!userData?.isPaymentUpdated}
        closable={false}
        footer={null}
      >
        <PaymentContainer>
          <Form
            layout="vertical"
            className="mt-4"
            className="row mt-4"
            // style={{ width: "100%" }}
            // form={form}
            onFinish={onUpdatePaymentDetails}
          >
            <div className="col-md-12">
              <Form.Item
                label="Card Holder Name"
                rules={[
                  {
                    required: true,

                    whitespace: true,
                  },
                ]}
              >
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Card Holder Name"
                />
              </Form.Item>
            </div>

            <div className="col-md-12">
              <Form.Item
                label="Card Number"
                rules={[
                  {
                    required: true,

                    whitespace: true,
                  },
                ]}
              >
                <input
                  suffix={<div>16 digits only</div>}
                  {...getCardNumberProps({
                    onChange: (e) => setCardNumber(e.target.value),
                  })}
                  placeholder="Card Number"
                  value={cardNumber}
                />
              </Form.Item>
            </div>

            <div className="col-md-12">
              <Form.Item
                label="Exp. Date"
                rules={[
                  {
                    required: true,

                    whitespace: true,
                  },
                ]}
              >
                <input
                  className="ant-input"
                  {...getExpiryDateProps({
                    onChange: (e) => setExipiry(e.target.value),
                  })}
                  value={expiry}
                  placeholder="Exp. Date"
                />
              </Form.Item>
            </div>

            <div className="col-md-12">
              <Form.Item
                label="CVV Number"
                rules={[
                  {
                    required: true,

                    whitespace: true,
                  },
                ]}
              >
                <input
                  {...getCVCProps({
                    onChange: (e) => setCvv(e.target.value),
                  })}
                  value={cvv}
                  placeholder="CVV"
                  type="password"
                />
              </Form.Item>
            </div>

            <div className=" col-md-12 mt-4 ">
              <div className="d-flex justify-content-center">
                <Button
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={cardNumber && name && cvv && expiry ? false : true}
                  // style={{ marginLeft: isMobile ? 0 : 20 }}
                >
                  {" "}
                  Update
                </Button>
              </div>
            </div>
          </Form>
        </PaymentContainer>
      </Modal>
    </>
  );
};

export default OldPaymentDetailsUpdate;
