import styled from "styled-components";

export const StripeContainer = styled.div`
.StripeCheckout {
  background: #0eaaa5 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 4px #00000029;
    border: 1px solid #ffffff40;
    border-radius: 4px;
    color: #fff;
    border-radius: 4px;
    opacity: 1;
  /* padding: 10px; */

    span {
      font-size: 14px !important;
      background: none !important;
      box-shadow : none !important;
    }

    .button>.inner>button{
  background-color:#0eaaa5 !important ;
}
}

`