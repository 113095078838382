import React, { useContext, useEffect, useState } from "react";
import { Form, Checkbox } from "antd";
import { Button } from "../../components/_shared";
import { GrayInputField } from "../../components/_shared/Input";
import { updateProfileInit } from "../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../context/userInfo";
import {
  makeSelectAuthLoading,
  makeSelectStudioLoading,
} from "../../store/selectors";
import Loading from "../../components/_shared/Loader/Loading";
import PaymentIcon from "react-payment-icons";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { PaymentContainer } from "./component/component";

const PaymentDetails = () => {
  const [form] = Form.useForm();

  const { userData, getUserinfo } = useContext(UserContext);
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectAuthLoading());
  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs();
  const [cardNumber, setCardNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiry, setExipiry] = useState("");
  const [name, setName] = useState("");




  const onUpdatePaymentDetails = (e) => {
    dispatch(
      updateProfileInit({
        accountName: name,
        cardNumber: cardNumber,
        expiry: expiry,
        cvv: cvv,
      })
    );

    setTimeout(() => {
      getUserinfo();
    }, 1000);
  };

  useEffect(() => {
    getUserinfo();
  }, []);

  useEffect(() => {
    if (userData?.id) {
      setCardNumber(userData?.cardNumber)
      setName(userData?.accountName)
      setCvv(userData?.cvv)
      setExipiry(userData?.expiry)
    }
  }, [userData]);


  return (
    <>
      <PaymentContainer className="container bg-white p-5">
        <Loading isLoading={isLoading} />
        <Form
          layout="vertical"
          className="mt-4"
          className="row mt-4"
          // style={{ width: "100%" }}
          // form={form}
          onFinish={onUpdatePaymentDetails}
        >
          <div className="col-md-12">
            <Form.Item
              label="Card Holder Name"
              rules={[
                {
                  required: true,

                  whitespace: true,
                },
              ]}
            >
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Card Holder Name"
              />
            </Form.Item>
          </div>

          <div className="col-md-12">
            <Form.Item
              label="Card Number"
              rules={[
                {
                  required: true,

                  whitespace: true,
                },
              ]}
            >
              <input
                prefix={
                  <svg {...getCardImageProps({ images })} className="mr-2" />
                }
                suffix={<div>16 digits only</div>}
                {...getCardNumberProps({
                  onChange: (e) => setCardNumber(e.target.value),
                })}
                placeholder="Card Number"
                value={cardNumber}
              />
            </Form.Item>
          </div>

          <div className="col-md-12">
            <Form.Item
              label="Exp. Date"
              rules={[
                {
                  required: true,

                  whitespace: true,
                },
              ]}
            >
              <input
                className="ant-input"
                {...getExpiryDateProps({
                  onChange: (e) => setExipiry(e.target.value),
                })}
                value={expiry}
                placeholder="Exp. Date"
              />
            </Form.Item>
          </div>

          <div className="col-md-12">
            <Form.Item
              label="CVV Number"
              rules={[
                {
                  required: true,

                  whitespace: true,
                },
              ]}
            >
              <input
                {...getCVCProps({
                  onChange: (e) => setCvv(e.target.value),
                })}
                value={cvv}
                placeholder="CVV"
                type="password"
              />
            </Form.Item>
          </div>

   
          <div className=" col-md-12 mt-4 ">
            <div className="d-flex justify-content-center">
              <Button
                htmlType="submit"
                lite
                size={"large"}
                disabled={
                  cardNumber && name && cvv && expiry ? false : true
                }
                // style={{ marginLeft: isMobile ? 0 : 20 }}
                title="Save"
                className=""
              />
            </div>
          </div>
        </Form>
      </PaymentContainer>
    </>
  );
};

export default PaymentDetails;
