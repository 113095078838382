import React, { useState } from "react";
import { Col, Row, Form, Divider } from "antd";

import SigninModal from "../../../components/Modal/Signin";
import { SignupWrapper } from "../components";
import useViewport from "../../../hooks/useViewport";
import { GrayInputField, InputField } from "../../../components/_shared/Input";
import { Link, useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button } from "../../../components/_shared";
import { signinInit } from "../../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/_shared/Loader/Loading";
import { makeSelectAuthLoading } from "../../../store/selectors";

const Signup = () => {
  const [showpass, setShowpass] = useState(false);

  const { isMobile } = useViewport();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoading = useSelector (makeSelectAuthLoading())

  const onSignin = (e) => {
    dispatch(signinInit({form : {email : e?.email, password : e?.password}, navigate}))
  }
  return (
    <div className="container  mt-5 bg-white p-5">
      <Loading isLoading = {isLoading}/>
      <div className="row">
        {/* <div className="col-md-12">
          <img
            src={process.env.PUBLIC_URL + "/movitae-logo.png"}
            alt=""
            width="200px"
          />
        </div> */}

        <div className="col-md-12 mt-4 ">
          <p className="primary-green mb-2"> Log In</p>
        </div>

        <div className=" col-md-6 mt-0 bg-secondary-gray py-2    ">
          <h5 className="primary-green my-auto">Member Login</h5>
        </div>
        <div className="offset-md-6"></div>
        <Form
          layout="vertical"
          className="mt-4"
          style={{ width: "100%" }}
          onFinish={onSignin}
        >
          <div className="col-md-6">
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Enter valid email address",
                },
              ]}
            >
              <GrayInputField
                className="font-600"
                type="email"
                placeholder="Email"
              />
            </Form.Item>
          </div>
          {/* <div className="offset-md-6"></div> */}
          <div className="col-md-6 ">
            <Form.Item
              label="password"
              name="password"
              rules={[
                { required: true, message: "Password is required field" },
              ]}
            >
              <GrayInputField
                type={showpass ? "text" : "password"}
                suffix={
                  showpass ? (
                    <EyeInvisibleOutlined
                      onClick={() => {
                        setShowpass(!showpass);
                      }}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setShowpass(!showpass);
                      }}
                    />
                  )
                }
                className="font-600"
                placeholder="Password"
              />
            </Form.Item>
          </div>{" "}
          <div className="row d-flex ">
            <div className="col-md-5 my-auto order-sm-2 order-xl-1 order-xs-2 order-md-2 order-2 mt-2 ">
              <Link to="/forgot-password" className="text-secondary">
                I've lost my password
              </Link>
            </div>
            <div className="col-md-2 order-sm-1 order-xl-2 order-xs-1 order-md-1 order-1">
              <Button
                lite
                htmlType="submit"
                size={"large"}
                // style={{ marginLeft: isMobile ? 0 : 20 }}
                title="Login"
                className=""
              />
            </div>
          </div>
        </Form>
        <div className="col-md-6">
          <Divider />
        </div>
        <div className="md-offset-6"></div>
      </div>
    </div>
    // <SignupWrapper isMobile={isMobile}>

    //   <div className={isMobile ? "" : "container  containerSignin"}>
    //     <SigninModal

    //       // onFinish={onSignin}
    //     />
    //   </div>
    // </SignupWrapper>
  );
};

export default Signup;
