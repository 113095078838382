// import React from "react";
// import timeline from "../../services/timeline";

// export const VideoUpdateContext = React.createContext({});

// const VideoUploadProvider = ({ children }) => {
//   const uoloadVideotoVimeo = (
//     tid,
//     timelineVidoes,
//     setTimelineVimeo,
//     setTiemlineVideos
//   ) => {
//     let formData = new FormData();
//     for (let video of timelineVidoes) {
//       // let videoId = await onUpload(video?.originFileObj);
//       formData.append("videoId", video?.originFileObj);
//     }

//     formData.append("tid", tid);

//     timeline
//       .uploadTimelineVimeo(formData)
//       .then((res) => {
//         setTimelineVimeo("");
//         setTiemlineVideos("");
//       })
//       .catch((err) => {});
//   };
//   return (
//     <VideoUpdateContext.Provider value={{ uoloadVideotoVimeo }}>
//       {children}
//     </VideoUpdateContext.Provider>
//   );
// };

// export default VideoUploadProvider;

import React, { useState } from "react";
import AWS from "aws-sdk";

import timeline from "../../services/timeline";
import { v4 as uuidv4 } from "uuid";

import * as tus from "tus-js-client";
import axios from "axios";
import { headerPost } from "../../utils/server";
import { notify } from "../../utils";

const S3_BUCKET = "movitanz";
const REGION = "ap-south-1";

AWS.config.update({
	accessKeyId: "AKIAYP3Y7UZOTSVZK5PP",
	secretAccessKey: "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",
});

const myBucket = new AWS.S3({
	params: { Bucket: S3_BUCKET },
	region: REGION,
});

export const VideoUpdateContext = React.createContext({});

const VideoUploadProvider = ({ children }) => {
	const [progress, setProgress] = useState(0);
	const [timeline_id, setTid] = useState(0);
	const uoloadVideotoVimeo = async (
		tid,
		timelineVidoes,
		setTimelineVimeo,
		setTiemlineVideos
	) => {
		// videoId
		// tid

		for (let video of timelineVidoes) {
			const videoid = uuidv4();
			const file = video?.originFileObj;
			setTid(tid);
			// const fileName = video?.originFileObj?.name;
			// const fileSize = video?.originFileObj?.size.toString();

			const params = {
				ACL: "public-read",
				Body: file,
				Bucket: S3_BUCKET,
				Key: `${videoid}.mp4`,
			};

			myBucket
				.upload(params)
				.on("httpUploadProgress", (evt) => {
					setProgress(Math.round((evt.loaded / evt.total) * 100));
				})
				.send((err, data) => {
					if (err) {
						notify("video upload fail");
					} else {
						timeline
							.uploadTimelineVimeo({
								tid: tid,
								videoId: videoid,
							})
							.then((res) => {
								setTimelineVimeo("");
								setTiemlineVideos("");
							})
							.catch((err) => {});
					}
				});

			// const response = await axios({
			//   method: "post",
			//   url: `https://api.vimeo.com/me/videos`,
			//   headers: headerPost,
			//   data: {
			//     upload: {
			//       approach: "tus",
			//       size: fileSize,
			//     },
			//   },
			// });

			// const upload = new tus.Upload(file, {
			//   endPoint: "https://api.vimeo.com/me/videos",
			//   uploadUrl: response.data.upload.upload_link,
			//   retryDelays: [0, 3000, 5000, 10000, 20000],
			//   metadata: {
			//     filename: new Date() + fileName,
			//     filetype: file.type,
			//   },
			//   headers: {},
			//   onError: function (error) {
			//     console.log("Failed because: " + error);
			//   },
			//   onProgress: function (bytesUploaded, bytesTotal) {
			//     let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
			//     // console.log(bytesUploaded, bytesTotal, percentage + "%");
			//     setProgress(percentage);
			//   },
			//   onSuccess: function () {
			//     // console.log(response?.data?.uri?.replace("/videos/",""))
			//     // console.log("Download %s from %s", upload.file.name, upload.url);

			//     timeline
			//       .uploadTimelineVimeo({
			//         tid: tid,
			//         videoId: Number(response?.data?.uri?.replace("/videos/", "")),
			//       })
			//       .then((res) => {
			//         setTimelineVimeo("");
			//         setTiemlineVideos("");
			//       })
			//       .catch((err) => {});
			//   },
			// });

			// upload.start();
			setTimelineVimeo("");
			setTiemlineVideos("");
		}

		// timeline
		//   .uploadTimelineVimeo(formData)
		//   .then((res) => {
		//     setTimelineVimeo("");
		//     setTiemlineVideos("");
		//   })
		//   .catch((err) => {});
	};
	return (
		<VideoUpdateContext.Provider
			value={{ uoloadVideotoVimeo, progress, timeline_id }}
		>
			{children}
		</VideoUpdateContext.Provider>
	);
};

export default VideoUploadProvider;
