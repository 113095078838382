import React from "react";

import Lottie from "react-lottie";
import Loader from "./icons/loading-colour.json";
import { LoaderWrapper } from "./component/component";
const Loading = ({ isLoading }) => {
  return  isLoading && (
   
    <LoaderWrapper
      // title="Vertically centered modal dialog"
      className="first-time-switch loader-resum loader"
      centered
      visible={isLoading}
      footer={""}
      width={"auto"}
      closable={false}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: Loader,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={210}
        width={210}
      />
    </LoaderWrapper>
  );
};
export default Loading