import React, { useEffect } from "react";

import { Row, Col, Divider, Typography, Card, Button } from "antd";
import {
  RocketOutlined,
  PlusCircleOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { HeroPanel } from "./component";
import useViewport from "../../hooks/useViewport";
import { ROUTES } from "../../route/constant";
import whyImage from "../../assets/images/why2.jpg";
import { useNavigate } from "react-router-dom";
import admin from "../../services/admin";
import { useState } from "react";
import { notify } from "../../utils";

const { Title } = Typography;
const Why = () => {
  const navigate = useNavigate();
  const { isLaptop, isMobile } = useViewport();
  const [content, setContent] = useState({});

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    admin
      .getContent({
        home: 1,
        getStarted: 1,
        why: 1,
        faq: 1,
        pricing: 1,
        guide: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
        notify("error",JSON.stringify(err));
      });
  };

  return (
    <>
      <HeroPanel>
        <div className="hero-image">
          <div className="hero-text">
            <h1 className="title"> {content?.why?.heading1}</h1>
          </div>
        </div>
        <div className="container">
          <section
            style={{ position: "relative" }}
            className="text-center mt-3 mb-3"
          >
            <Title
              className="heading"
              level={2}
              type="primary"
              style={{ color: "#0eaaa5" }}
            >
              {content?.why?.heading2}
            </Title>
            <Divider style={{ padding: 5, margin: 0 }} />
            <Title className="subtitle" style={{ color: "#6b6b6b" }} level={5}>
              {content?.why?.subheading}
            </Title>
          </section>
        </div>
        <section
          style={{ position: "relative", marginTop: 50, background: "#0eaaa5" }}
          className="text-center"
        >
          <div style={{ padding: 10 }} className="container">
            <Title
              className="heading"
              level={2}
              type="primary"
              style={{ color: "white", marginTop: 10 }}
            >
              DANCERS get more value for their investment in dance because they
              get better, faster when embracing Movitanz!
            </Title>
            <Divider style={{ padding: 5, margin: 0 }} />
            <Row style={{ padding: isMobile ? 0 : 0 }} justify="center">
              {content?.why?.started?.map((item) => (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={{ span: 6, offset: 2 }}
                  className="text-center "
                >
                  <Card
                    hoverable
                    className="  mt-3"
                    style={{
                      height: isMobile ? "auto" : "390px",
                      overflow: "auto",
                    }}
                  >
                    <div className="box-heading text-center  mt-3">
                      {item?.heading}
                    </div>
                    <div className="text-center box-subheading">
                      {item?.content}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </section>

        <section
          style={{
            background: "#9e9e9e",
            height: 500,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img src={whyImage} height={490} />
        </section>
        <div className="container">
          <section
            style={{ position: "relative" }}
            className="text-center mt-3 mb-3"
          >
            <Button
              onClick={() => {
                navigate(ROUTES.GET_STARTED);
              }}
              type="primary"
              className="mt-3 btn-home"
              style={{ height: 50 }}
            >
              FIND OUT HOW TO GET STARTED
            </Button>
          </section>
        </div>
      </HeroPanel>
    </>
  );
};

export default Why;
