import { Card, Collapse, Divider, Typography, Form, Checkbox } from "antd";
import { apiPost } from "../../utils/axios";
import moment from "moment";
import { Modal, Button } from "antd";
import styled from "styled-components";

import Meta from "antd/lib/card/Meta";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StripeCheckoutButton from "../../components/_shared/Stripe/Stripe";
import { byteConvert } from "../../utils/utils";
import { SERVER_URL } from "../../utils/server";

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Carousel from "./slider";
import useViewport from "../../hooks/useViewport";
import { HeroPanel } from "./component";
import { InputField } from "../../components/_shared";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import admin from "../../services/admin";
import { notify } from "../../utils";

const { Title } = Typography;
const { Panel } = Collapse;

export const CardField = styled.div.attrs({
  className: "w-full h-12 bg-gray-100 px-4 py-3 rounded-xl flex items-center",
})`
  .StripeElement {
    width: 100%;
  }
`;

export const elementStyles = {
  style: {
    base: {
      color: "gray",
      fontSize: "16px",
      width: "100%",
      lineHeight: 1.5,
      background: "gray",
      border: "none",
      outline: "none",
      "::placeholder": {
        color: "gray",
      },
      ":-webkit-autofill": {
        color: "#e39f48",
      },
    },
    invalid: {
      color: "red",
    },
    focused: {
      border: "1px solid #6cd6ef",
    },
  },
};

const SignupCodeModal = ({ isModalVisible, setIsModalVisible, signupCode }) => {
  return (
    <Modal
      title="Payment Successful"
      visible={isModalVisible}
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}
    >
      <p>
        Thanks for choosing Movitanz, your payment was successful. Now check your inbox for the sign up code and next steps!
        {/* Congratulations, Now you can able to signup as a Studio Owner with{" "}
        <b>{signupCode}</b> code{" "} */}
      </p>
    </Modal>
  );
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: elements.getElement(CardElement),
    // });
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputField type="email" />
      <CardField>
        <CardNumberElement
          name="cardNumber"
          id="cardNumber"
          options={elementStyles}
        />
      </CardField>
      {/* <CardElement /> */}
      {/* <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button> */}
    </form>
  );
};

const PaymentContainer = styled.div`
  input {
    border-radius: 20px;
    padding: 10px 20px;
    color: black;
    height: 60px;
    font-size: 16px;
    font-weight: 500;

    background: #dee3f3 !important;
    border: none;
    width: 100% !important;

    &:focus {
      border: none;
    }
  }
`;

const CheckoutModal = ({
  paymentDetails,
  isModalVisible,
  setIsModalVisible,
  setSignupModalVisible,
  setSignupCode,
}) => {
  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs();
  const [cardNumber, setCardNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiry, setExipiry] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onToken = () => {
    setIsLoading(true);
    apiPost(SERVER_URL + "/auth/payment", {
      email: email,
      // stripe: { source: token.id, amount: priceForStripe, currency: "aud" },
      storageLimit: paymentDetails?.storage,
      // studioMonthExp : moment().add(30, "day")
      type: "Paid",
      studioMonthExp: moment().add(44, "day"),
      studentEnrolledLimit: paymentDetails?.studentEnrolledLimit,
      cardNumber: cardNumber,
      cardHolderName: name,
      expiry: expiry,
      cvv: cvv,
    })
      .then((res) => {
        if (res.status === 200) {
          setIsModalVisible(false);
          setSignupModalVisible(true);
          setSignupCode(res?.data?.signupCode);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("Something went wrong");
        setIsLoading(false);
      });
  };





  return (
    <Modal
      title="Enter payment details"
      visible={isModalVisible}
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}
      footer=""
    >
      <PaymentContainer>
        <Form
          layout="vertical"

          className="row mt-4"
          // style={{ width: "100%" }}
          // form={form}
          onFinish={onToken}
        >
          <div className="col-md-12">
            <Form.Item
              label="Email"
              rules={[
                {
                  required: true,

                  whitespace: true,
                },
              ]}
            >
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <Form.Item
              label="Card Holder Name"
              rules={[
                {
                  required: true,

                  whitespace: true,
                },
              ]}
            >
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Card Holder Name"
              />
            </Form.Item>
          </div>

          <div className="col-md-12">
            <Form.Item
              label="Card Number"
              rules={[
                {
                  required: true,

                  whitespace: true,
                },
              ]}
            >
              <input
                prefix={
                  <svg {...getCardImageProps({ images })} className="mr-2" />
                }
                suffix={<div>16 digits only</div>}
                {...getCardNumberProps({
                  onChange: (e) => setCardNumber(e.target.value),
                })}
                placeholder="Card Number"
                value={cardNumber}
              />
            </Form.Item>
          </div>

          <div className="col-md-12">
            <Form.Item
              label="Exp. Date"
              rules={[
                {
                  required: true,

                  whitespace: true,
                },
              ]}
            >
              <input
                className="ant-input"
                {...getExpiryDateProps({
                  onChange: (e) => setExipiry(e.target.value),
                })}
                value={expiry}
                placeholder="Exp. Date"
              />
            </Form.Item>
          </div>

          <div className="col-md-12">
            <Form.Item
              label="CVV Number"
              rules={[
                {
                  required: true,

                  whitespace: true,
                },
              ]}
            >
              <input
                {...getCVCProps({
                  onChange: (e) => setCvv(e.target.value),
                })}
                value={cvv}
                placeholder="CVV"
                type="password"
              />
            </Form.Item>
          </div>

          <div className="col-md-12">
            <Form.Item
              rules={[
                {
                  required: true,

                  whitespace: true,
                },
              ]}
            >
              <Checkbox>
                Your card will be charged after 14 days for an ongoing monthly
                subscription.
              </Checkbox>
            </Form.Item>
          </div>

          <div className=" col-md-12 mt-4 ">
            <div className="d-flex justify-content-center">
              {!isLoading && (
                <Button
                  disabled={
                    email && cardNumber && name && cvv && expiry ? false : true
                  }
                  size="large"
                  htmlType="submit"
                  type="primary"
                >
                  Pay
                </Button>
              )}
            </div>
          </div>
        </Form>
      </PaymentContainer>
    </Modal>
  );
};

const Pricing = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [signupCode, setSignupCode] = useState("");
  const { isMobile } = useViewport();
  const [checkoutVisible, setCheckoutVisible] = useState(false);
  const [paymentDetals, setPaymentDetails] = useState({});
  const [signupModelVisbile, setSignupModalVisible] = useState(false);
  const [content, setContent] = useState({});

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    admin
      .getContent({
        home: 1,
        getStarted: 1,
        why: 1,
        faq: 1,
        pricing: 1,
        guide: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };


  const GetFreeTrail = (storage, studentEnrolledLimit) => {
    apiPost(SERVER_URL + "/auth/payment", {
      // email: token.email,
      storageLimit: storage,
      studentEnrolledLimit: studentEnrolledLimit,
      // studioMonthExp : moment().add(30, "day")
      studioMonthExp: moment().add(14, "day"),
    })
      .then((res) => {
        if (res.status === 200) {
          setIsModalVisible(true);
          setSignupCode(res?.data?.signupCode);
        }
      })
      .catch((err) => {
        console.log("Something went wrong");
      });
  };
  return (
    <HeroPanel>
      <CheckoutModal
        paymentDetails={paymentDetals}
        isModalVisible={checkoutVisible}
        setIsModalVisible={setCheckoutVisible}
        setSignupModalVisible={setSignupModalVisible}
        setSignupCode={setSignupCode}
      />
      <SignupCodeModal
        isModalVisible={signupModelVisbile}
        setIsModalVisible={setSignupModalVisible}
        signupCode={signupCode}
      />
      <div className="hero-image">
        <div className="hero-text">
          <h1 className="title"> WE KNOW THAT EVERY SCHOOL IS DIFFERENT</h1>
          <h4 className="hero-subtitle">
            {" "}
            CHOOSE THE RIGHT PLAN FOR YOUR STUDIO!{" "}
          </h4>
        </div>
      </div>
      <div className="container">
        <section style={{ position: "relative" }} className=" mt-3 mb-3">
          {/* <Title
            className="heading text-center"
            level={2}
            type="primary"
            style={{ color: "#0eaaa5" }}
          >
            Very different pricing options
          </Title> */}
          {/* <Divider style={{ padding: 5, margin: 0 }} /> */}
          <Title
            className="subtitle text-center"
            style={{ color: "#0eaaa5" }}
            level={5}
          >
            BUY A  SUBSCRIPTION PLAN FOR YOUR STUDIO SIZE AND JUST INCLUDE THE
            COST IN YOUR STUDENT TERM FEES…for less than the price of an ice
            cream!{" "}
          </Title>
          <div style={{
            padding: '12px',
            fontSize: '14px',
            lineHeight: '1.5715',
            backgroundColor: '#fafafa',
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
          }}>
            Start a $1 trial. Movitanz does not store card details,your payment is taken securely by Stripe. You can cancel subscription at any time.
          </div>
          {/* <Collapse accordion>
            <Panel header="Start a 14 days trial for just $1" key="1">
              <p>Cancel subscription any time at no charge </p>
            </Panel>
          </Collapse> */}
          {/* <Collapse accordion>
            <Panel header="School License" key="2">
              <p>
               You buy a License for your whole school and just include the cost in your terms fees
              </p>
            </Panel>
          </Collapse> */}
        </section>
      </div >
      {/* <Carousel  show={isMobile ? 1 : 4}> */}
      < div className="container-fluid p-3" >
        <div className="row ">
          {content?.pricing?.cards.map((item, index) => (
            <div style={{ padding: 8 }} className="col-md-3">
              <Card key={index} className="  mt-5">
                <Meta
                  title={
                    <div className="d-flex justify-content-center mt-4">
                      {" "}
                      <h3>
                        {item?.priceText}
                      </h3>
                      <small className="text-secondary mt-3">Month</small>
                    </div>
                  }
                // description={
                //   <div className="d-flex justify-content-center">
                //     <h5 className="text-secondary"> {item?.storage} GB</h5>
                //   </div>
                // }
                />
                <Divider />
                <div className="mt-3">
                  <ul>
                    <li className="text-secondary font-weight-bold">
                      {item?.studentText}
                    </li>
                    <li className="text-secondary font-weight-bold">
                      {item?.storageText}
                    </li>
                    {/* <li className="text-secondary font-weight-bold mt-1">
                    Lorem ipsum dolor sit, amet sdkndkgnd
                  </li>
                  <li className="text-secondary font-weight-bold mt-1">
                    Lorem ipsum dolor sit, amet sdkndkgnd
                  </li> */}
                  </ul>
                </div>
                <Divider />
                <div className="d-flex justify-content-center">
                  {item?.price ? (
                    <StripeCheckoutButton
                      studentEnrolledLimit={item?.maxStudents}
                      price={item?.price}
                      storage={item?.storage}
                      extra={{
                        studentEnrolledLimit: item?.maxStudents,
                        price: item?.price,
                        storage: item?.storage,
                      }}
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        setPaymentDetails({
                          studentEnrolledLimit: item?.maxStudents,
                          price: item?.price,
                          storage: item?.storage,
                        });
                        // setCheckoutVisible(!checkoutVisible);
                      }}
                      type="primary"
                      style={{
                        background: "#0eaaa5  !important",
                        boxShadow: "0px 2px 4px #00000029",
                        border: "1px solid #ffffff40",
                        borderRadius: "4px",
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontWeight: 600,
                      }}
                    >
                      JOIN AS STUDIO OWNER
                    </Button>
                  )}
                </div>
              </Card>
            </div>
          ))}

          <div style={{ padding: 8 }} className="col-md-3">
            <Card className="  mt-5">
              <Meta
                title={
                  <div className="d-flex justify-content-center mt-4 flex-wrap">
                    <h6 style={{ fontWeight: "600", fontFamily: "Poppins" }}>
                      {" "}
                      STUDENT SELF SUBSCRIBE {" "}
                    </h6>
                  </div>
                }
              // description={
              //   <div className="d-flex justify-content-center">
              //     <h5 className="text-secondary"> {item?.storage} GB</h5>
              //   </div>
              // }
              />

              <Divider />
              <div className="d-flex justify-content-center">
                <h5
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    marginTop: "43px",
                    marginBottom: "78px",
                  }}
                >
                  COMING SOON....
                </h5>
              </div>
            </Card>
          </div>
          {/* </Carousel>  */}

          {/* New Flow Card */}
          {/* 
      
       <div
      className="d-flex container  p-3 justify-content-around"
      style={{ width: "100%" }}
    >
      <Carousel show={4}>
        {[
          {
            title: "Self Subscription For Students",
            price: 5,
            student: "5-50",
            storage: 50,
            maxStudents: 50,
          },
          {
            title: "Self Subscription For Studio Owner",
            price: "Free",
            student: "51-100",
            storage: 100,
            maxStudents: 100,
          },
        ].map((item, index) => (
          <div style={{ padding: 8 }}>
            <Card key={index} className="  mt-5">
              <Meta
                title={
                  <div className="d-flex justify-content-center mt-4">
                    {" "}
                    <h3>
                      {" "}
                      {item?.price} {item?.price === "Free" ? "" : "AU"}{" "}
                    </h3>
                  </div>
                }
                description={
                  <div className="d-flex justify-content-center">
                    <h5 className="text-secondary"> {item?.title} </h5>
                  </div>
                }
              />
              <Divider />
              <div className="mt-3">
                <ul>
                  <li className="text-secondary font-weight-bold">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                    vel voluptatibus magni dolor minus dolorem deserunt impedit
                    autem numquam, aliquam ex iure consectetur exercitationem
                    doloremque delectus eveniet veniam distinctio qui?
                  </li>
             
                </ul>
              </div>
              <Divider />
              <div className="d-flex justify-content-center">
                {item?.price !== "Free" ? (
                  <StripeCheckoutButton
                    price={item?.price}
                    student={item?.maxStudents}
                    storage={byteConvert.GB * item?.storage}
                  />
                ) : (
                  <Button
                    type="primary"
                    style={{
                      background: "#0eaaa5  !important",
                      boxShadow: "0px 2px 4px #00000029",
                      border: "1px solid #ffffff40",
                      borderRadius: "4px",
                      color: "#fff",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                    }}
                  >
                    BECOME A STUDIO OWNER
                  </Button>
                )}
              </div>
            </Card>
          </div>
        ))}
      </Carousel>
    </div>
      
      */}
        </div>
      </div >
    </HeroPanel >
  );
};
export default Pricing;
