import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Select } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { InputField, TextAreaField } from "../../../../components/_shared";
import AWS from "aws-sdk";
import { notify } from "../../../../utils";
const { Option } = Select;

const S3_BUCKET = "movitanz";
const REGION = "ap-south-1";

AWS.config.update({
	accessKeyId: "AKIAYP3Y7UZOTSVZK5PP",
	secretAccessKey: "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",
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const Home = ({ onUpdate, home, setIsLoading }) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState({
    home1: [],
    home2: [],
    cards1: [],
    home3: [],
  });
  console.log(files, 'files')
  const updateContent = (e) => {
    onUpdate({
      home: { ...e, files },
    });
  };
  useEffect(() => {
    if (home)
      form.setFieldsValue(home);
    if (!!home) {
      if (!!home?.files) {
        setFiles(home?.files)
      }
    }
  }, [home])
  const customRequest = (file, index, type) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${type + index}.${file?.name?.split(".").pop()}`,
    };

    console.log(params, 'filefile')
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => { })
      .send((err, data) => {
        console.log(file.name, 'filefile')
        if (err) {
          notify(" upload fail");
        } else {
          console.log(`https://movitanz.s3.ap-southeast-2.amazonaws.com/${type + index
            }.${file?.name?.split(".").pop()}`, 'filefile')

          // setIsLoading(false);
          setFiles({
            ...files,
            [files?.[type]]: files?.[type].push({
              index: index,
              file: `https://movitanz.s3.ap-southeast-2.amazonaws.com/${type + index
                }.${file?.name?.split(".").pop()}`,
            }),
          });
        }
      });
  }
  return (
    <div className="col-md-12">
      <Form form={form} layout="vertical" onFinish={updateContent}>
        {() => (
          <div>
            <Row gutter={16}>
              <div className="col-md-12 ">
                <h4>Home top</h4>
              </div>
              <Form.List name="home1" className="mb-0">
              {(fields) => ( 
                <>
                {fields.map(
                  ({ key, name, fieldKey, ...restField }, index) => (
                <>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  xl={24}
                  xxl={24}
                  span={24}
                >
                  <Form.Item
                    style={{ marginBottom: "15px" }}
                    {...restField}
                    name={[name, "type"]}
                    fieldKey={[fieldKey, "type"]}
                    label={`Banner`}
                    rules={[
                      {
                        required: true,
                        message: "Please select type",
                      },
                    ]}
                  >
                    <Select defaultValue="Select type">
                      {["video", "image"]?.map(
                        (i) => {
                          return <Option value={i}>{i}</Option>;
                        }
                      )}
                    </Select>
                  </Form.Item>
                  {files?.home1.length > 0 && (
                      <a
                        style="margin-bottom: 15px;"
                        style={{ color: "black" }}
                        className="lable-head margin-20  ml-10"
                      >
                        {files?.home1[files?.home1.length - 1].file}
                      </a>
                    )}
                  <input
                    type="file"
                    style={{ marginBottom: "15px" ,marginTop: "15px"}}
                    onChange={(e) => {
                      customRequest(
                        e.target.files[0],
                        index,
                        "home1"
                      );
                    }}
                  />
                </Col>
                </>
                  ))}
                </>
                )}
              </Form.List>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"hometitle"}
                  label={"Title"}
                >
                  <InputField placeholder="Home Title" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"homeheading"}
                  label={"Heading"}
                >
                  <InputField placeholder="Home Heading" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"homebutton"}
                  label={"Button Text"}
                >
                  <InputField placeholder="Home Button Text" />
                </Form.Item>
              </Col>
              <div className="col-md-12 mt-2 ">
                <h4>Home section 2</h4>
              </div>
              <Form.List name="home2" className="mb-0">
              {(fields) => ( 
                <>
                {fields.map(
                  ({ key, name, fieldKey, ...restField }, index) => (
                <>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  xl={24}
                  xxl={24}
                  span={24}
                >
                  <Form.Item
                    style={{ marginBottom: "15px" }}
                    {...restField}
                    name={[name, "type"]}
                    fieldKey={[fieldKey, "type"]}
                    label={`Banner`}
                    rules={[
                      {
                        required: true,
                        message: "Please select type",
                      },
                    ]}
                  >
                    <Select defaultValue="Select type">
                      {["video", "image"]?.map(
                        (i) => {
                          return <Option value={i}>{i}</Option>;
                        }
                      )}
                    </Select>
                  </Form.Item>
                  {files?.home2.length > 0 && (
                      <a
                        style="margin-bottom: 15px;"
                        style={{ color: "black" }}
                        className="lable-head margin-20  ml-10"
                      >
                        {files?.home2[files?.home2.length - 1].file}
                      </a>
                    )}
                  <input
                    type="file"
                    style={{ marginBottom: "15px" ,marginTop: "15px"}}
                    onChange={(e) => {
                      customRequest(
                        e.target.files[0],
                        index,
                        "home2"
                      );
                    }}
                  />
                </Col>
                </>
                      )
                    )}
                </>
                )}
              </Form.List>
              <Form.List name="cards1" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                      <>
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          xl={24}
                          xxl={24}
                          span={24}
                        >
                          <Form.Item
                            style={{ marginBottom: "15px" }}
                            {...restField}
                            name={[name, "type"]}
                            fieldKey={[fieldKey, "type"]}
                            label={`image ${index + 1}`}
                            rules={[
                              {
                                required: true,
                                message: "Please select type",
                              },
                            ]}
                          >
                            <Select defaultValue="Select type">
                              {["image"]?.map(
                                (i) => {
                                  return <Option value={i}>{i}</Option>;
                                }
                              )}
                            </Select>
                          </Form.Item>

                          <>
                              {files?.["cards1"]?.filter(
                                (d) => d?.index === index
                              )?.length > 0 && (
                                  <a
                                    style={{ color: "black" }}
                                    className="lable-head margin-20  ml-10"
                                  >
                                    {
                                      files?.["cards1"]?.filter(
                                        (d) => d?.index === index
                                      )?.[0]?.file
                                    }
                                  </a>
                                )}
                              <input
                                type="file"
                                style={{ marginBottom: "15px",marginTop: "15px" }}
                                onChange={(e) => {
                                  customRequest(
                                    e.target.files[0],
                                    index,
                                    "cards1"
                                  );
                                }}
                              />
                            </>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                          <Form.Item
                            style={{ marginBottom: "15px" }}
                            {...restField}
                            name={[name, "title"]}
                            fieldKey={[fieldKey, "title"]}
                            label={`Title ${index + 1}`}
                          >
                            <InputField placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                          <Form.Item
                            style={{ marginBottom: "15px" }}
                            {...restField}
                            name={[name, "description"]}
                            fieldKey={[fieldKey, "description"]}
                            label={`Description ${index + 1}`}
                          >
                            <InputField placeholder="" />
                          </Form.Item>
                        </Col>
                      </>
                    ))}
                  </>
                )}
              </Form.List>
              <div className="col-md-12 ">
                <h4>Home section 3</h4>
              </div>
              <Form.List name="home3" className="mb-0">
              {(fields) => ( 
                <>
                {fields.map(
                  ({ key, name, fieldKey, ...restField }, index) => (
                <>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  xl={24}
                  xxl={24}
                  span={24}
                >
                  <Form.Item
                    style={{ marginBottom: "15px" }}
                    {...restField}
                    name={[name, "type"]}
                    fieldKey={[fieldKey, "type"]}
                    label={`Banner`}
                    rules={[
                      {
                        required: true,
                        message: "Please select type",
                      },
                    ]}
                  >
                    <Select defaultValue="Select type">
                      {["video", "image"]?.map(
                        (i) => {
                          return <Option value={i}>{i}</Option>;
                        }
                      )}
                    </Select>
                  </Form.Item>
                  {files?.home3.length > 0 && (
                      <a
                        style="margin-bottom: 15px;"
                        style={{ color: "black" }}
                        className="lable-head margin-20  ml-10"
                      >
                        {files?.home3[files?.home3.length - 1].file}
                      </a>
                    )}
                  <input
                    type="file"
                    style={{ marginBottom: "15px" ,marginTop: "15px"}}
                    onChange={(e) => {
                      customRequest(
                        e.target.files[0],
                        index,
                        "home3"
                      );
                    }}
                  />
                </Col>
                </>
                      )
                    )}
                </>
                )}
                </Form.List>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"home3title"}
                  label={"Title"}
                >
                  <InputField placeholder="Home3 Title" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"home3heading"}
                  label={"Heading"}
                >
                  <InputField placeholder="Home3 Heading" />
                </Form.Item>
              </Col>
              <Form.List name="cards2" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                      <>


                        <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                          <Form.Item
                            style={{ marginBottom: "15px" }}
                            {...restField}
                            name={[name, "title"]}
                            fieldKey={[fieldKey, "title"]}
                            label={`Title ${index + 1}`}
                          >
                            <InputField placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                          <Form.Item
                            style={{ marginBottom: "15px" }}
                            {...restField}
                            name={[name, "description"]}
                            fieldKey={[fieldKey, "description"]}
                            label={`Description ${index + 1}`}
                          >{ }
                            <InputField placeholder="" />
                          </Form.Item>
                        </Col>
                      </>
                    ))}
                    <Col
                      xs={20}
                      sm={20}
                      md={20}
                      xl={20}
                      xxl={20}
                      span={20}
                      className=""
                    >
                    </Col>
                  </>
                )}
              </Form.List>

            </Row>

            <div
              className="center-column"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                htmlType="submit"
                size={"large"}
                type="primary"
                className="rounded "
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </Form>

    </div>
  );
};

export default Home;
