import React, { useEffect } from "react";

import { Row, Col, Divider, Typography, Card } from "antd";
import {
  RocketOutlined,
  PlusCircleOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { HeroPanel } from "./component";
import useViewport from "../../hooks/useViewport";
import { notify } from "../../utils";
import admin from "../../services/admin";
import { useState } from "react";

const { Title } = Typography;
const About = () => {
  const { isLaptop, isMobile } = useViewport();
  const [content, setContent] = useState({});

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    admin
      .getContent({
        home: 1,
        getStarted: 1,
        why: 1,
        faq: 1,
        pricing: 1,
        guide: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
        notify("error",JSON.stringify(err));
      });
  };
  return (
    <>
      <HeroPanel>
        <div className="hero-image">
          <div className="hero-text">
            <h1 className="title">{content?.about?.heading1}</h1>
            <h4 className="hero-subtitle">{content?.about?.subheading}</h4>
          </div>
        </div>
        {content?.about?.fature?.map((item) => (
          <div className="container">
            <section
              style={{ position: "relative" }}
              className="text-center mt-3 mb-3"
            >
              <Title
                className="heading"
                level={2}
                type="primary"
                style={{ color: "#0eaaa5" }}
              >
                {item?.heading}
              </Title>
              <Divider style={{ padding: 5, margin: 0 }} />
              <Title
                className="subtitle"
                style={{ color: "#6b6b6b" }}
                level={5}
              >
                {item?.subheading}
              </Title>
              {item?.p1 && (
                <Title
                  style={{ color: "#6b6b6b", fontWeight: "400" }}
                  level={5}
                >
                  {item?.p1}
                </Title>
              )}

              {item?.p2 && (
                <Title
                  style={{ color: "#6b6b6b", fontWeight: "400" }}
                  level={5}
                >
                  {item?.p2}
                </Title>
              )}

              {item?.p3 && (
                <Title
                  style={{ color: "#6b6b6b", fontWeight: "400" }}
                  level={5}
                >
                  {item?.p3}
                </Title>
              )}
            </section>
          </div>
        ))}

        <section style={{ background: "rgb(14,170,165)" }}>
          <div className="container">
            <Row style={{ padding: isMobile ? 0 : 0 }} justify="center">
              {content?.about?.started?.map((item) => (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={{ span: 6, offset: 2 }}
                  className="text-center "
                >
                  <Card
                    hoverable
                    className="  mt-5 mb-5"
                    style={{ height: isMobile ? "auto" : "320px" }}
                  >
                    <RocketOutlined
                      style={{ fontSize: 45, color: "rgb(14,170,165)" }}
                    />
                    <div className="box-heading text-center  mt-3">
                      {item?.heading}
                    </div>
                    <div className="text-center box-subheading">
                     {item?.content}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </section>
      </HeroPanel>
    </>
  );
};

export default About;
