import {  Select } from "antd";
import styled from "styled-components";

export const DropDown = styled(Select)`
  background: #dee3f3;
  border-radius: 20px;

  color: black !important;
  height: 60px !important;
  font-weight: ${(props) => (props.bold ? "600" : "400")};
  &.begig-select .ant-select-selector {
    border-radius: 20px;
    font-weight: ${(props) => (props.bold ? "600" : "400")};
    height: 60px !important;
  }
  & input {
    height: 60px !important;
    font-weight: ${(props) => (props.bold ? "600" : "400")};
  }
  &.ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: #dee3f3 !important;
    border-color: #ff4d4f !important;
  }
  &.begig-select .ant-select-selection-placeholder {
    line-height: 60px !important;
    margin-left: 10px !important;
    font-weight: ${(props) => (props.bold ? "600" : "400")};
  }
  &.begig-select .ant-select-selection-item {
    line-height: 60px !important;
    margin-left: 10px !important;
    font-weight: ${(props) => (props.bold ? "600" : "400")};
  }
`;



export const DropDownTagMode = styled(Select)`
  background: #dee3f3;
  border-radius: 20px;

  color: black !important;
  height: 60px !important;
  overflow:auto;
  font-weight: ${(props) => (props.bold ? "600" : "400")};
  &.begig-select .ant-select-selector {
    border-radius: 20px;
    font-weight: ${(props) => (props.bold ? "600" : "400")};
    height: 60px !important;
  }
  & input {
    // height: 60px !important;
    font-weight: ${(props) => (props.bold ? "600" : "400")};
  }
  &.ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: #dee3f3 !important;
    border-color: #ff4d4f !important;
  }
  &.begig-select .ant-select-selection-placeholder {
    line-height: 60px !important;
    margin-left: 10px !important;
    
    font-weight: ${(props) => (props.bold ? "600" : "400")};
  }
  &.begig-select .ant-select-selection-item {
    line-height: 60px !important;
    margin-left: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${(props) => (props.bold ? "600" : "400")};
  }
`;
