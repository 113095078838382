import { SERVER_URL } from "../utils";
import { apiPost, apiGet, apiDelete } from "../utils/axios";

const USER = SERVER_URL + "/users";
const COUPON = SERVER_URL + "/coupon";

export default {
	getUsers: (page, limit, search, group) =>
		apiGet(
			USER +
				`?page=${page}&limit=${limit}&search=${search ? search : ""}&group=${
					group ? group : "all"
				}`
		),
	activatationAccount: (id, payload) =>
		apiPost(`${USER}/${id}/account-activation`, payload),
	getContent: (payload) => apiPost(`${USER}/get-content`, payload),
	updateContent: (payload) => apiPost(`${USER}/content`, payload),
	deleteUser: (id) => apiDelete(`${USER}/${id}`),
	createCoupon: (payload) => apiPost(`${COUPON}`, payload),
	getAllCoupons: () => apiGet(`${COUPON}`),
	deleteCoupon: (couponId) => apiDelete(`${COUPON}/${couponId}`),
};
