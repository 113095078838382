import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { UserContext } from "../../context/userInfo";
import admin from "../../services/admin";
import { notify } from "../../utils";

const Info = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const [content, setContent] = useState({});
  useEffect(() => {
    getUserinfo();
  }, []);

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    admin
      .getContent({
        home: 1,
        getStarted: 1,
        why: 1,
        faq: 1,
        pricing: 1,
        guide: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  return (
    <div className="container bg-white p-5">
      <div className="row">
        <h5 className="primary-green my-auto">{content?.guide?.top}</h5>
        {content?.guide?.profiletitle?.length > 0 && (
          <div className=" col-md-12 mt-3 bg-secondary-gray py-2    ">
            <h5 className="primary-green my-auto">
              {content?.guide?.profiletitle}
            </h5>
          </div>
        )}
        {content?.guide?.profiletitle?.length > 0 && (
          <div className="col-md-12 mt-4">
            <ul>
              {content?.guide?.profile?.map((item, key) => {
                return item?.type === "image" ? (
                  <img
                    src={
                      content?.guide?.files?.profile?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    style={{ height: 400, width: "100%", objectFit: "contain" }}
                  />
                ) : item?.type === "video" ? (
                  <video
                    src={
                      content?.guide?.files?.profile?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    preload="auto"
                    controls
                    style={{
                      width: "100%",
                      height: "400px",
                    }}
                    controlslist="nodownload"
                  />
                ) : item?.type === "doc" ? (
                  <a
                    href={
                      content?.guide?.files?.profile?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    target="_blank"
                  >
                    {item?.linktext
                      ? item?.linktext
                      : content?.guide?.files?.profile?.filter(
                          (i) => i?.index === key
                        )?.[0]?.file}
                  </a>
                ) : item?.type === "link" ? (
                  <a href={item?.list} target="_blank">
                    {item?.linktext ? item?.linktext : item?.list}
                  </a>
                ) : (
                  <li className="">{item?.list}</li>
                );
              })}
            </ul>
          </div>
        )}
      </div>

      {content?.guide?.grouptitle?.length > 0 && (
        <div className="row mt-3">
          <div className=" col-md-12 mt-0 bg-secondary-gray py-2    ">
            <h5 className="primary-green my-auto">
              {content?.guide?.grouptitle}
            </h5>
          </div>
          <div className="col-md-12 mt-4">
            <ul>
              {content?.guide?.group?.map((item, key) => {
                return item?.type === "image" ? (
                  <img
                    src={
                      content?.guide?.files?.group?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    style={{ height: 400, width: "100%", objectFit: "contain" }}
                  />
                ) : item?.type === "video" ? (
                  <video
                    src={
                      content?.guide?.files?.group?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    preload="auto"
                    controls
                    style={{
                      width: "100%",
                      height: "400px",
                    }}
                    controlslist="nodownload"
                  />
                ) : item?.type === "doc" ? (
                  <a
                    href={
                      content?.guide?.files?.group?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    target="_blank"
                  >
                    {item?.linktext
                      ? item?.linktext
                      : content?.guide?.files?.group?.filter(
                          (i) => i?.index === key
                        )?.[0]?.file}
                  </a>
                ) : item?.type === "link" ? (
                  <a href={item?.list} target="_blank">
                    {item?.linktext ? item?.linktext : item?.list}
                  </a>
                ) : (
                  <li className="">{item?.list}</li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      {content?.guide?.timelinestudiotitle?.length > 0 && (
        <div className="row mt-3">
          <div className=" col-md-12 mt-0 bg-secondary-gray py-2    ">
            <h5 className="primary-green my-auto">
              {content?.guide?.timelinestudiotitle}
            </h5>
          </div>
          <div className="col-md-12 mt-4">
            <ul>
              {userData?.role === "student"
                ? content?.guide?.stuTimeline?.map((item, key) => {
                    return item?.type === "image" ? (
                      <img
                        src={
                          content?.guide?.files?.stuTimeline?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        style={{
                          height: 400,
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : item?.type === "video" ? (
                      <video
                        src={
                          content?.guide?.files?.stuTimeline?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        preload="auto"
                        controls
                        style={{
                          width: "100%",
                          height: "400px",
                        }}
                        controlslist="nodownload"
                      />
                    ) : item?.type === "doc" ? (
                      <a
                        href={
                          content?.guide?.files?.stuTimeline?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        target="_blank"
                      >
                        {item?.linktext
                          ? item?.linktext
                          : content?.guide?.files?.stuTimeline?.filter(
                              (i) => i?.index === key
                            )?.[0]?.file}
                      </a>
                    ) : item?.type === "link" ? (
                      <a href={item?.list} target="_blank">
                        {item?.linktext ? item?.linktext : item?.list}
                      </a>
                    ) : (
                      <li className="">{item?.list}</li>
                    );
                  })
                : content?.guide?.timeline?.map((item, key) => {
                    return item?.type === "image" ? (
                      <img
                        src={
                          content?.guide?.files?.timeline?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        style={{
                          height: 400,
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : item?.type === "video" ? (
                      <video
                        src={
                          content?.guide?.files?.timeline?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        preload="auto"
                        controls
                        style={{
                          width: "100%",
                          height: "400px",
                        }}
                        controlslist="nodownload"
                      />
                    ) : item?.type === "doc" ? (
                      <a
                        href={
                          content?.guide?.files?.timeline?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        target="_blank"
                      >
                        {item?.linktext
                          ? item?.linktext
                          : content?.guide?.files?.timeline?.filter(
                              (i) => i?.index === key
                            )?.[0]?.file}
                      </a>
                    ) : item?.type === "link" ? (
                      <a href={item?.list} target="_blank">
                        {item?.linktext ? item?.linktext : item?.list}
                      </a>
                    ) : (
                      <li className="">{item?.list}</li>
                    );
                  })}
            </ul>
          </div>
        </div>
      )}
      {/* {userData?.role !== "student" && */}
      {content?.guide?.posttitle?.length > 0 && (
        <div className="row mt-3">
          <div className=" col-md-12 mt-0 bg-secondary-gray py-2    ">
            <h5 className="primary-green my-auto">
              {content?.guide?.posttitle}
            </h5>
          </div>
          <div className="col-md-12 mt-4">
            <ul>
              {content?.guide?.createPost?.map((item, key) => {
                return item?.type === "image" ? (
                  <img
                    src={
                      content?.guide?.files?.createPost?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    style={{ height: 400, width: "100%", objectFit: "contain" }}
                  />
                ) : item?.type === "video" ? (
                  <video
                    src={
                      content?.guide?.files?.createPost?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    preload="auto"
                    controls
                    style={{
                      width: "100%",
                      height: "400px",
                    }}
                    controlslist="nodownload"
                  />
                ) : item?.type === "doc" ? (
                  <a
                    href={
                      content?.guide?.files?.createPost?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    target="_blank"
                  >
                    {item?.linktext
                      ? item?.linktext
                      : content?.guide?.files?.createPost?.filter(
                          (i) => i?.index === key
                        )?.[0]?.file}
                  </a>
                ) : item?.type === "link" ? (
                  <a href={item?.list} target="_blank">
                    {item?.linktext ? item?.linktext : item?.list}
                  </a>
                ) : (
                  <li className="">{item?.list}</li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {/* } */}
      {content?.guide?.portfoliotitle?.length > 0 && (
        <div className="row mt-3">
          <div className=" col-md-12 mt-0 bg-secondary-gray py-2    ">
            <h5 className="primary-green my-auto">
              {content?.guide?.portfoliotitle}
            </h5>
          </div>
          <div className="col-md-12 mt-4">
            <ul>
              {userData?.role === "student"
                ? content?.guide?.stuProtfolio?.map((item, key) => {
                    return item?.type === "image" ? (
                      <img
                        src={
                          content?.guide?.files?.stuProtfolio?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        style={{
                          height: 400,
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : item?.type === "video" ? (
                      <video
                        src={
                          content?.guide?.files?.stuProtfolio?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        preload="auto"
                        controls
                        style={{
                          width: "100%",
                          height: "400px",
                        }}
                        controlslist="nodownload"
                      />
                    ) : item?.type === "doc" ? (
                      <a
                        href={
                          content?.guide?.files?.stuProtfolio?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        target="_blank"
                      >
                        {item?.linktext
                          ? item?.linktext
                          : content?.guide?.files?.stuProtfolio?.filter(
                              (i) => i?.index === key
                            )?.[0]?.file}
                      </a>
                    ) : item?.type === "link" ? (
                      <a href={item?.list} target="_blank">
                        {item?.linktext ? item?.linktext : item?.list}
                      </a>
                    ) : (
                      <li className="">{item?.list}</li>
                    );
                  })
                : content?.guide?.protfolio?.map((item, key) => {
                    return item?.type === "image" ? (
                      <img
                        src={
                          content?.guide?.files?.protfolio?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        style={{
                          height: 400,
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : item?.type === "video" ? (
                      <video
                        src={
                          content?.guide?.files?.protfolio?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        preload="auto"
                        controls
                        style={{
                          width: "100%",
                          height: "400px",
                        }}
                        controlslist="nodownload"
                      />
                    ) : item?.type === "doc" ? (
                      <a
                        href={
                          content?.guide?.files?.protfolio?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file
                        }
                        target="_blank"
                      >
                        {item?.linktext
                          ? item?.linktext
                          : content?.guide?.files?.protfolio?.filter(
                              (i) => i?.index === key
                            )?.[0]?.file}
                      </a>
                    ) : item?.type === "link" ? (
                      <a href={item?.list} target="_blank">
                        {item?.linktext ? item?.linktext : item?.list}
                      </a>
                    ) : (
                      <li className="">{item?.list}</li>
                    );
                  })}
            </ul>
          </div>
        </div>
      )}

      {content?.guide?.forumtitle?.length > 0 && (
        <div className="row mt-3">
          <div className=" col-md-12 mt-0 bg-secondary-gray py-2    ">
            <h5 className="primary-green my-auto">
              {content?.guide?.forumtitle}
            </h5>
          </div>
          <div className="col-md-12 mt-4">
            <ul>
              <li className="">
                {content?.guide?.forum?.map((item, key) => {
                  return item?.type === "image" ? (
                    <img
                      src={
                        content?.guide?.files?.forum?.filter(
                          (i) => i?.index === key
                        )?.[0]?.file
                      }
                      style={{
                        height: 400,
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : item?.type === "video" ? (
                    <video
                      src={
                        content?.guide?.files?.forum?.filter(
                          (i) => i?.index === key
                        )?.[0]?.file
                      }
                      preload="auto"
                      controls
                      style={{
                        width: "100%",
                        height: "400px",
                      }}
                      controlslist="nodownload"
                    />
                  ) : item?.type === "doc" ? (
                    <a
                      href={
                        content?.guide?.files?.forum?.filter(
                          (i) => i?.index === key
                        )?.[0]?.file
                      }
                      target="_blank"
                    >
                      {item?.linktext
                        ? item?.linktext
                        : content?.guide?.files?.forum?.filter(
                            (i) => i?.index === key
                          )?.[0]?.file}
                    </a>
                  ) : item?.type === "link" ? (
                    <a href={item?.list} target="_blank">
                      {item?.linktext ? item?.linktext : item?.list}
                    </a>
                  ) : (
                    <li className="">{item?.list}</li>
                  );
                })}
              </li>
            </ul>
          </div>
        </div>
      )}

      {content?.guide?.courcetitle?.length > 0 && (
        <div className="row mt-3">
          <div className=" col-md-12 mt-0 bg-secondary-gray py-2    ">
            <h5 className="primary-green my-auto">
              {content?.guide?.courcetitle}
            </h5>
          </div>
          <div className="col-md-12 mt-4">
            <ul>
              {content?.guide?.shop?.map((item, key) => {
                return item?.type === "image" ? (
                  <img
                    src={
                      content?.guide?.files?.shop?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    style={{ height: 400, width: "100%", objectFit: "contain" }}
                  />
                ) : item?.type === "video" ? (
                  <video
                    src={
                      content?.guide?.files?.shop?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    preload="auto"
                    controls
                    style={{
                      width: "100%",
                      height: "400px",
                    }}
                    controlslist="nodownload"
                  />
                ) : item?.type === "doc" ? (
                  <a
                    href={
                      content?.guide?.files?.shop?.filter(
                        (i) => i?.index === key
                      )?.[0]?.file
                    }
                    target="_blank"
                  >
                    {item?.linktext
                      ? item?.linktext
                      : content?.guide?.files?.shop?.filter(
                          (i) => i?.index === key
                        )?.[0]?.file}
                  </a>
                ) : item?.type === "link" ? (
                  <a href={item?.list} target="_blank">
                    {item?.linktext ? item?.linktext : item?.list}
                  </a>
                ) : (
                  <li className="">{item?.list}</li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Info;
