import styled from "styled-components";
import { Modal } from "antd";

export const LoaderWrapper = styled(Modal).attrs({
  className: "loader",
})`

.ant-modal-wrap {
  z-index: 100000;
}

.first-time-switch  {
    padding: 0px !important;
  }
  .ant-modal-body{

  }
  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;    
  }
  .loader-resume  {
    background-color: unset !important;
    box-shadow: unset !important;
  }

`