
import styled from "styled-components";
import BG from '../../assets/images/get-started.jpg'




export const HeroPanel =styled.section`
.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${BG});
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.title,.hero-subtitle{
  color: white;
}
.subtitle{
  color: #999;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: .3em;
  margin-top: 5px;
  font-weight: 600;
}
.heading{
  line-height: 1.2;
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: .05em;
}
.box-heading{
  color: #4e6278;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .05em;
}
.box-subheading{
  color: #4e6278;
  // line-height: 30px;
  // font-weight: 700;
  // text-transform: uppercase;
  letter-spacing: .05em;
}
.text-center{
  text-align:center;
}
a{
  color:blue;
}





`