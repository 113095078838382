import React, { useContext, useState, useEffect } from "react";
import { Table } from "antd";
import admin from "../../../services/admin";
import { notify } from "../../../utils";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/_shared/Loader/Loading";
import AdminLayout from "../../../components/AdminLayout";
import moment from "moment";

const User = () => {
  const [users, setUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    setIsLoading(true);
    admin
      .getUsers(1, 100000)
      .then((res) => {
        setUsers(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notify("error",JSON.stringify(err));
      });
  };

  return (
    <AdminLayout>
      <Loading isLoading={isLoading} />

      <div className="container-fluid bg-white p-5">
        {users?.results?.map((user) => (
          <div className="row">
            .
            <span className="col-md-12">
              <span> {user?.name}</span> Joined as an{" "}
              <span>
                {user?.role === "teacher"
                  ? "Teacher"
                  : user?.role === "student"
                  ? "Student"
                  : "Studio Owner"}
              </span>
            </span>
            <div className="span col-md-12 text-secondary">
              joined in : {moment(user?.date).format("LL")}
            </div>
          </div>
        ))}
      </div>
    </AdminLayout>
  );
};

export default User;
