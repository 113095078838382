import { createSelector } from "reselect";

const timelineDomain = (state) => state.timeline;
const studioDomain = (state) => state.studio;
const authDomain = (state) => state.auth;

const makeSelectTimeline = () =>
  createSelector(timelineDomain, (subdomain) => {
    return subdomain.timeline || [];
  });

const makeSelectTimelineComment = () =>
  createSelector(timelineDomain, (subdomain) => {
    return subdomain.comment || [];
  });

const makeSelectStudio = () =>
  createSelector(studioDomain, (subdomain) => {
    return subdomain.studio || [];
  });

const makeSelectMembers = () =>
  createSelector(studioDomain, (subdomain) => {
    return subdomain.studioMembers || [];
  });

const makeSelectGroups = () =>
  createSelector(studioDomain, (subdomain) => {
    return subdomain.groups || [];
  });

  const makeSelectGroupsStudio = () =>
  createSelector(studioDomain, (subdomain) => {
    return subdomain.studioName || ""
  });

const makeSelectTimelineLoading = () =>
  createSelector(timelineDomain, (subdomain) => {
    return subdomain.isProceeding;
  });

const makeSelectAuthLoading = () =>
  createSelector(authDomain, (subdomain) => {
    return subdomain.isProceeding;
  });

const makeSelectStudioLoading = () =>
  createSelector(studioDomain, (subdomain) => {
    return subdomain.isProceeding;
  });

export {
  makeSelectTimeline,
  makeSelectTimelineComment,
  makeSelectStudio,
  makeSelectMembers,
  makeSelectGroups,
  makeSelectTimelineLoading,
  makeSelectAuthLoading,
  makeSelectStudioLoading,
  makeSelectGroupsStudio

};
