import React, { useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import styled from "styled-components";
import { STRIPE_KEY } from "../../../../utils/server";

const StripeContainer = styled.div`
  .StripeCheckout {
    background: #0eaaa5 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 4px #00000029;
    border: 1px solid #ffffff40;
    border-radius: 4px;
    color: #fff;
    border-radius: 4px;
    opacity: 1;
    padding: 10px;

    span {
      font-size: 20px !important;
      background: none !important;
      box-shadow: none !important;
    }
  }
`;

const StripeProgram = ({ price, id, subscribeProgram }) => {
  const priceForStripe = Number(price) * 100;
  const publishableKey = STRIPE_KEY;

  const onToken = (token) => {
    subscribeProgram(id, {
      stripe: { source: token.id, amount: priceForStripe, currency: "aud" },
    });
  };

  return (
    <StripeContainer>
      <StripeCheckout
        label={"Subscribe"}
        name="Movitanz"
        billingAddress
        shippingAddress
        currency="AUD"
        description={`Your total is ${price} AUD`}
        amount={priceForStripe}
        panelLabel="Pay now"
        token={onToken}
        stripeKey={publishableKey}
      />
    </StripeContainer>
  );
};

export default StripeProgram;
