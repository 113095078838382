import { createAction } from "redux-actions";
import timelineActionTypes from "../actionTypes/timeline";

const getTimelineInit = createAction(timelineActionTypes.GET_TIMELINE_INIT);
const createTimelineInit = createAction(
  timelineActionTypes.CREATE_TIMELINE_INIT
);
const updateTimelneInit = createAction(
  timelineActionTypes.UPDATE_TIMELINE_INIT
);
const deleteTimelineInit = createAction(
  timelineActionTypes.DELETE_TIMELINE_INIT
);

const getCommentInit = createAction(timelineActionTypes.GET_COMMENT_INIT);
const addCommentInit = createAction(timelineActionTypes.ADD_COMMENT_INIT);
const updateCommentInit = createAction(timelineActionTypes.UPDATE_COMMENT_INIT);
const deleteCommentInit = createAction(timelineActionTypes.DELETE_COMMENT_INIT);

const addReplyInit = createAction(timelineActionTypes.ADD_REPLY_INIT);
const updateReplyInit = createAction(timelineActionTypes.UPDATE_REPLY_INIT);
const deleteReplyInit = createAction(timelineActionTypes.DELETE_REPLY_INIT);

const watchTimelineInit = createAction(timelineActionTypes.WATCH_TIMELINE_INIT);
const unWatchTimelineInit = createAction(
  timelineActionTypes.UN_WATCH_TIMELINE_INIT
);
const likeTimelineInit = createAction(timelineActionTypes.LIKE_TIMELINE_INIT);
const unLikeTimelineInit = createAction(
  timelineActionTypes.UN_LIKE_TIMELINE_INIT
);
const shareGroupInit = createAction(timelineActionTypes.SHARE_GROUP_INIT);
const unShareGroupInit = createAction(timelineActionTypes.UN_SHARE_GROUP_INIT);
const shareMemberInit = createAction(timelineActionTypes.SHARE_MEMBER_INIT);
const unShareMemberInit = createAction(
  timelineActionTypes.UN_SHARE_MEMBER_INIT
);

const favourteTimelineInit = createAction(timelineActionTypes.FAVOURTE_TIMELINE_INIT);
const unFavourteTimelineInit = createAction(
  timelineActionTypes.UN_FAVOURTE_TIMELINE_INIT
);
const timeLinepinpostInit = createAction(
  timelineActionTypes.TIMELINE_PIN_POST_INIT,
);

export {
  getTimelineInit,
  createTimelineInit,
  updateTimelneInit,
  deleteTimelineInit,
  getCommentInit,
  updateCommentInit,
  addCommentInit,
  deleteCommentInit,
  addReplyInit,
  updateReplyInit,
  deleteReplyInit,
  watchTimelineInit,
  unWatchTimelineInit,
  likeTimelineInit,
  unLikeTimelineInit,
  shareGroupInit,
  unShareGroupInit,
  shareMemberInit,
  unShareMemberInit,
  favourteTimelineInit,
  unFavourteTimelineInit,
  timeLinepinpostInit 
};
