import { SearchOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useEffect, useState } from "react";

import ModalWrapper from "../../../../components/Modal";
import { InputField } from "../../../../components/_shared";
import useViewport from "../../../../hooks/useViewport";

import { makeSelectGroups } from "../../../../store/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  addGroupMemberInit,
  deleteGroupMemberInit,
  fetchMyGroupsInit,
} from "../../../../store/actions/studio";

const ManageMemberModal = ({ visible, setVisible, currentmember }) => {
  const dispatch = useDispatch();
  const groups = useSelector(makeSelectGroups());
  const { isMobile } = useViewport();
  const [availableGroups, setAvailableGroups] = useState(groups);
  const [searchField, setSearchField] = useState("");

  const refresh = () => {
    dispatch(fetchMyGroupsInit());
  };

  const addGroupMember = (gid) => {
    dispatch(addGroupMemberInit({ data: { userId: currentmember?.id }, gid }));
    let data = [];
    data = availableGroups?.map((p) =>
      p.id === gid
        ? { ...p, members: p?.members?.concat(currentmember?.id) }
        : p
    );
    setAvailableGroups(data);
  };

  const deleteGroupMember = (gid) => {
    dispatch(deleteGroupMemberInit({ gid, mid: currentmember?.id }));
    let data = [];
    data = availableGroups?.map((p) =>
      p.id === gid
        ? { ...p, members: p?.members?.filter((s) => s !== currentmember?.id) }
        : p
    );
    setAvailableGroups(data);
  };
  useEffect(() => {
    refresh();
  }, []);
  useEffect(() => {
    setAvailableGroups(groups);
  }, [groups]);
  return (
    <ModalWrapper
      visible={visible}
      setVisible={() => {
        setAvailableGroups(groups);
        setVisible();
      }}
    >
      <div className="container p-5">
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-center">
            <h3>{currentmember?.name} </h3>
          </div>
          <div className="col-md-6 d-flex mb-3">
            <InputField
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              placeholder="Search"
              suffix={<SearchOutlined />}
              style={{ width: `${isMobile ? "" : "350px"}` }}
            />
          </div>

          <div
            style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
            className="col-md-6 d-flex mb-3"
          >
            <Button
              size={"large"}
              type="primary"
              className="rounded "
              // style = {{heigth : "200px"}}
            >
              <h5
                className="font-white"
                onClick={() => {
                  setAvailableGroups(groups);
                  setVisible();
                }}
              >
                {" "}
                Share
              </h5>
            </Button>
          </div>
          {availableGroups
            .filter((member) =>
              `${member?.groupName}`
                .toLowerCase()
                .includes(searchField.toLowerCase())
            )
            ?.map((member) => (
              <div className="row " key={member?.groupName}>
                <div className="col-md-12 d-flex align-content-center">
                  <Checkbox
                    checked={member?.members?.includes(currentmember?.id)}
                    className="mx-1  mt-3"
                    onChange={(e) => {
                      e?.target.checked
                        ? addGroupMember(member?.id)
                        : deleteGroupMember(member?.id);
                    }}
                  />
                  <img
                    width={60}
                    src={`${process.env.PUBLIC_URL}group.png`}
                    className="mx-4 "
                  />
                  <span className="h5 mt-3"> {member?.groupName}</span>
                </div>
                <div className="col-md-12">
                  <Divider />
                </div>
              </div>
            ))}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ManageMemberModal;
