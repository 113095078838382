import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, InputField } from "../../../../components/_shared";
import { UserContext } from "../../../../context/userInfo";
import { ViewProfileContext } from "../../../../context/viewProfile";
import program from "../../../../services/program";
import { notify, SERVER_URL } from "../../../../utils";
import { apiPost } from "../../../../utils/axios";
import CreateProgramModel from "./CreateProgramModel";
import Loading from "../../../../components/_shared/Loader/Loading";
import { MEDIA_URL } from "../../../../utils/server";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditProgramModal from "./EditProgramModal";

const MyProgram = () => {
  const [createShow, setCreateShow] = useState(false);
  const [logo, setLogo] = useState("");
  const [programs, setPrograms] = useState([]);
  const { profileToken } = useContext(ViewProfileContext);
  const [searchField, setSearchField] = useState("");
  const { userData, getUserinfo } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateProgram, setUpdateProgram] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    getUserinfo();
    fetchMyPrograms();
  }, []);

  const onCreateProgram = async (e) => {
    try {
      if (!logo) return notify("error", "Please upload an logo image");
      const logoId = await onUpload(logo);
      setIsLoading(true);

      let body = {
        title: e?.title,
        description: e?.description,
        img: logoId,
        price: e?.price,
        program: [],
      };

      if (e?.programs?.length)
        for (let i of e?.programs) {
          let newProgram = {
            title: i?.title,
            description: i?.description,
            video: i?.video,
            img: [],
          };
          if (i?.program?.fileList?.length)
            for (let j of i?.program?.fileList) {
              const id = await onUpload(j?.originFileObj);
              newProgram.img.push(id);
            }
          body?.program?.push(newProgram);
        }
      const programRes = await program.createProgram(body);

      if (programRes?.status === 200) {
        fetchMyPrograms();
        setIsLoading(false);

        notify("Program Create Sucessfully");
        setLogo("")
        setCreateShow(!createShow);
      }
    } catch (err) {
      setIsLoading(false);

      notify("error", "Something went wrong");
    }
  };

  const onUpload = async (file) => {
    try {
      let formData = new FormData();
      formData.append("avatar", file);
      const res = await axios.post(MEDIA_URL, formData);
      return res?.data?.message;
    } catch (err) {
      notify("error", "Something went wrong");
    }
  };

  const fetchMyPrograms = async () => {
    try {
      setIsLoading(true);
      const res = await program.getMyProgram(profileToken?.access?.token);

      if (res.status === 200) {
        setIsLoading(false);
        setPrograms(res?.data?.programs);
      }
    } catch (err) {
      setIsLoading(false);

      notify("error", "Something went wrong");
    }
  };

  const deleteProgram = async (id) => {
    try {
      const res = await program.deleteProgramById(id);
      if (res?.status === 200) {
        notify("Program Deleted Succesfully");
        fetchMyPrograms();
      }
    } catch (err) {
      notify("error",JSON.stringify(err));
    }
  };

  const reqToAdmin = async (id) => {
    try {
      const res = await program.updateStatus(id, { type: "req" });
      if (res?.status === 200) {
        notify("Your request has been submit.");
        fetchMyPrograms();
      }
    } catch (err) {
      notify("error",JSON.stringify(err));
    }
  };

  const onUpdateProgram = async (e) => {
    try {
      let logoId  = ""
      setIsLoading(true);
      if (logo) 
       logoId = await onUpload(logo);


      let body = {
        title: e?.title,
        description: e?.description,
        img: logoId ? logoId : updateProgram?.img,
        price: e?.price,
        program: [],
      };

      if (e?.programs?.length)
        for (let i of e?.programs) {
          
          let newProgram = {
            title: i?.title,
            description: i?.description,
            video: i?.video,
            img: [],
          };
          if (i?.program?.fileList?.length)
            for (let j of i?.program?.fileList) {
              const id = await onUpload(j?.originFileObj);
              newProgram.img.push(id);
            }
          body?.program?.push(newProgram);
        }
      const programRes = await program.updateProgram(updateProgram?.id,body);

      if (programRes?.status === 200) {
        fetchMyPrograms();
        setIsLoading(false);

        notify("Program Update Sucessfully");
        setLogo("")
        setShowUpdate(!showUpdate);
      }
    } catch (err) {
      setIsLoading(false);

      notify("error", "Something went wrong");
    }
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      {createShow && (
        <CreateProgramModel
          setLogo={setLogo}
          logo={logo}
          onCreateProgram={onCreateProgram}
          visible={createShow}
          setVisible={setCreateShow}
        />
      )}

      {showUpdate && (
        <EditProgramModal          logo={logo}
        setLogo={setLogo}

        onUpdateProgram={onUpdateProgram}
        program = {updateProgram}
          visible={showUpdate}
          setVisible={setShowUpdate}
        />
      )}
      {!profileToken && (
        <div className="col-md-12 d-flex justify-content-end align-content-center mb-2">
          <Button
            lite
            title="Create Program"
            onClick={() => {
              if (userData?.cardNumber) {
                setCreateShow(!createShow);
              } else {
                notify("error", "Please save payment details.");
              }
            }}
          />
        </div>
      )}

      <InputField
        value={searchField}
        onChange={(e) => setSearchField(e.target.value)}
        placeholder="Search"
        suffix={<SearchOutlined />}
        style={{ width: "350px" }}
      />
      {programs
        ?.filter((program) =>
          `${program?.title}`.toLowerCase().includes(searchField.toLowerCase())
        )
        ?.map((program, index) => (
          <div className="row p-4 bg-secondary-gray mt-3  border-20">
            <div className="col-md-12 d-flex justify-content-between mb-2">
              <span style={{ fontWeight: 600 }} className="h6">
                Price :<span> ${program?.price}</span>
              </span>
              <span
                className={`${
                  program?.status === "unApproved"
                    ? "text-secondary"
                    : program?.status === "approved"
                    ? "text-success"
                    : program?.status === "pending"
                    ? "text-warning"
                    : "text-danger"
                }`}
              >
                {program?.status === "unApproved"
                  ? "Unapproved"
                  : program?.status === "approved"
                  ? "Approved"
                  : program?.status === "pending"
                  ? "Pending"
                  : "Reject"}{" "}
              </span>
            </div>
            <div className="col-md-1 my-auto">
              <img
                src={`${program?.img}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${process.env.PUBLIC_URL}user.png`;
                }}
                width={"80px"}
                alt=""
              />
            </div>
            <div className="col-md-11 flex-column ">
              <div className="">
                <h5 className="cursor-pointer"> {program?.title}</h5>
              </div>
              <div>
                <span>{program?.description}</span>
              </div>
            </div>

            <div className="col-md-5 flex-column">
              {/* <div>
              <span>Shared with 305 Movitae users</span>
            </div> */}
            </div>

            {!profileToken && (
              <div className="col-md-12 mt-4">
                {program?.status === "unApproved" && (
                  <button
                    onClick={() => { setUpdateProgram(programs[index]);setShowUpdate(!showUpdate)}}
                    className="btn btn-success mx-3 mt-2"
                  >
                    Edit
                  </button>
                )}
                {program?.status === "unApproved" && (
                  <button
                    onClick={() => reqToAdmin(program?.id)}
                    className="btn btn-outline-success mt-2"
                  >
                    Requset To Admin
                  </button>
                )}
                {program?.status === "unApproved" && (
                  <button
                    onClick={() => deleteProgram(program?.id)}
                    className="btn btn-danger mx-3 mt-2"
                  >
                    Delete Program
                  </button>
                )}
              </div>
            )}

            {!profileToken && (
              <div className="col-md-12 d-flex justify-content-end mt-4">
                <button
                  onClick={() => navigate(`/program/${program?.id}/view`)}
                  className="btn btn-success"
                >
                  View
                </button>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

export default MyProgram;
