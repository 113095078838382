import { combineReducers } from "redux";
import auth from './auth'
import timeline from "./timeline";
import studio from "./sutdio";
import portfolio from "./portfolio";


const rootReducer = combineReducers({
  auth,
  timeline,
  studio,
  portfolio
});

export default rootReducer;
