import { Select, Form, Upload } from "antd";
import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../components/_shared";
import { GrayInputField } from "../../../../components/_shared/Input";
import Loading from "../../../../components/_shared/Loader/Loading";
import { UserContext } from "../../../../context/userInfo";
import { updateProfileInit } from "../../../../store/actions/auth";
import {
  makeSelectAuthLoading,
  makeSelectStudioLoading,
} from "../../../../store/selectors";
import { countryList, notify, SERVER_URL } from "../../../../utils";
import { apiPost } from "../../../../utils/axios";
import { MEDIA_URL } from "../../../../utils/server";
const { Option } = Select;
const StudioProfile = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector(makeSelectAuthLoading());
  const [profileLoading, setProfileLoading] = useState(false);

  const onUpload = (e) => {
    setProfileLoading(true);
    let formData = new FormData();
    formData.append("avatar", e?.file);
    axios
      .post(MEDIA_URL, formData)
      .then((res) => {
        // window.location.href  = "/profile"
        if (res?.status === 200) {
          setProfileLoading(false);
          onUploadProfile(res?.data?.message);
        }
      })
      .catch((err) => {
        setProfileLoading(false);
        notify("error", "Something went wrong");
      });
  };

  const onUploadProfile = (logo) => {
    dispatch(
      updateProfileInit({
        studioLogo: logo,
      })
    );

    setTimeout(() => {
      getUserinfo();
    }, 100);
  };

  const onUpdateProfile = (e) => {

    dispatch(
      updateProfileInit({
        studioName: e?.sname,
        country: e?.country,
        website: e?.website,
        name: e?.owner,
        businessNumber: e?.bnumber,
      })
    );

    setTimeout(() => {
      getUserinfo();
    }, 100);
  };

  useEffect(() => {
    getUserinfo();
  }, []);

  useEffect(() => {
    if (userData?.id) {
      form.setFieldsValue({
        sname: userData?.studioName,
        country: userData?.country,
        website: userData?.website,
        owner: userData?.name,
        code: userData?.signupCode,
        bnumber: userData?.businessNumber,
      });
    }
  }, [userData]);

  return (
    <>
      <Loading isLoading={isLoading || profileLoading} />
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">
          <Upload
            beforeUpload={() => false}
            onChange={onUpload}
            multiple={false}
            showUploadList={false}
          >
            <img
              src={`${userData?.studioLogo}`}
              width={150}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.PUBLIC_URL}user.png`;
              }}
              className="rounded cursor-pointer"
              alt=""
            />
          </Upload>
        </div>
      </div>
      <Form
        layout="vertical"
        // className="mt-4"
        className="row mt-4"
        // style={{ width: "100%" }}
        form={form}
        onFinish={onUpdateProfile}
      >
        <div className="col-md-4">
          <Form.Item
            name="sname"
            label="Studio Name"
            rules={[
              {
                required: true,
                message: "Please enter you studio name.",
              },
            ]}
          >
            <GrayInputField className="font-600" placeholder="Studio Name" />
          </Form.Item>
        </div>

        <div className="col-md-4">
          <Form.Item
            name="country"
            label="Country"
            rules={[
              {
                required: true,
                message: "Please enter your country.",
              },
            ]}
          >
         <Select
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
            >
              {countryList?.map((list) => (
                <Option value={list?.name}>{list?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        {/* <div className="col-md-4">
          <Form.Item name="region" label="Region">
            <GrayInputField className="font-600" placeholder="Region" />
          </Form.Item>
        </div> */}

        {/* <div className="offset-md-6"></div> */}

        <div className="col-md-4">
          <Form.Item name="website" label="Website">
            <GrayInputField placeholder="Website" className="font-600" />
          </Form.Item>
        </div>

        <div className="col-md-4">
          <Form.Item name="owner" label="Name of Studio Owner">
            <GrayInputField placeholder="Studio Owner" className="font-600" />
          </Form.Item>
        </div>

        {/* <div className="col-md-4">
          <Form.Item name="enrolled" label="Students Enrolled">
            <GrayInputField
              placeholder="Students Enrolled"
              className="font-600"
            />
          </Form.Item>
        </div> */}
        <div className="col-md-4">
          <Form.Item
            name="bnumber"
            label="ABN/NZBN"
            rules={[
              {
                required: true,
                message: "Please enter ABN/NZBN.",
              },
            ]}
          >
            <GrayInputField placeholder="ABN/NZBN" className="font-600" />
          </Form.Item>
        </div>

        <div className="col-md-4">
          <Form.Item name="code" label="Signup Code">
            <GrayInputField
              disabled
              placeholder="Signup Code"
              className="font-600"
            />
          </Form.Item>
        </div>

        <div className=" col-md-12 mt-4 ">
          <div className="d-flex justify-content-center">
            <Button
              htmlType="submit"
              lite
              size={"large"}
              // style={{ marginLeft: isMobile ? 0 : 20 }}
              title="Save"
              className=""
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default StudioProfile;
