import React, { useEffect, useState } from 'react';
// import Vimeo from "@u-wave/react-vimeo";
// import LoadingVideo from "../../../../components/_shared/Loader/LoadingVideo";
import openSocket from 'socket.io-client';
import LoadingVideo from '../../../../components/_shared/Loader/LoadingVideo';

const VimeoPercentage = ({ socketId, refreshTimeline,progress }) => {
  const [error, setError] = useState(false);
  // const socket = openSocket("https://movitae-api.herokuapp.com");
  const [percent, setPercent] = useState(15);
  const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   if(Number(progress)===100){
  //     refreshTimeline();
  //   }
  //   // setTimeout(() => {
  //   //   refreshTimeline();
  //   // }, [60000]);
  //   // if (percent == 100) {
  //   //   setIsLoaded(true);
  //   //   setPercent(100);
  //   // } else {
  //   //   if (percent !== 90)
  //   //     setTimeout(() => {
  //   //       setPercent(percent + 15);
  //   //     }, [30000]);
  //   // }
  // }, [progress]);

  useEffect(() => {
    // if(Number(progress)===100){
    //   refreshTimeline();
    // }
    setTimeout(() => {
      refreshTimeline();
    }, [60000]);
    if (percent == 100) {
      setIsLoaded(true);
      setPercent(100);
    } else {
      if (percent !== 90)
        setTimeout(() => {
          setPercent(percent + 15);
        }, [30000]);
    }
  }, [percent]);


  // const percentageCount = () => {
  //   try {
  //     socket.on(socketId, (payload) => {
  //       if(payload?.percentage)
  //       setPercent(payload?.percentage);
  //     });
  //   } catch (err) {}
  // };
  // percentageCount();

  return (
    <>
      <LoadingVideo isLoading={true} percent={progress} />
    </>
  );
};

export default VimeoPercentage;
