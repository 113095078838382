import React from "react";
import ModalWrapper from "../../../../components/Modal";


const BankModel = ({ visible, setVisible,bankDetails }) => {
  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <div className="row ">
          <div className="col-md-12 d-flex ">
            <span className = "h4">Card Holder Name : {bankDetails?.cardHolderName} </span>
          </div>
          <div className="col-md-12 d-flex ">
            <span className = "h4">Card Number : {bankDetails?.cardNumber} </span>
          </div>
          <div className="col-md-12 d-flex ">
            <span className = "h4">Expiry : {bankDetails?.expiry}</span>
          </div>
          <div className="col-md-12 d-flex ">
            <span className = "h4">CVV : {bankDetails?.cvv} </span>
          </div>
      
        </div>
      </div>
    </ModalWrapper>
  );
};

export default BankModel;
