import moment from "moment";

export const byteConvert = {
  GB: 1000 * 1000 * 1000,
  MB: 1000 * 1000,
  KB: 1000,
};

export const isExpired = (expiredDate) => {
 const expiry = moment(expiredDate);
 
 const currentDate = moment().utc();
 const isExpired = expiry.diff(currentDate, "days");

  if (isExpired > 0) return false;
  return true;
};

export const sotrageUsedPercent = (limit , reamining) => {
   let usedPercentage = ((limit - reamining) *100) /limit

   return {
     percent : usedPercentage,
     color : usedPercentage >= 80 ? "red" : usedPercentage>=50 ? "orange" : "green"
   }
}