import React, { useEffect, useState } from "react";
import { Row, Col, Divider, Typography, Card, Collapse } from "antd";

import { HeroPanel } from "./component";
import { ROUTES } from "../../route/constant";
import { useNavigate } from "react-router-dom";
import admin from "../../services/admin";
import { notify } from "../../utils";

const { Title } = Typography;
const { Panel } = Collapse;
const Faq = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState({})



  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    admin
      .getContent({
        home: 1,
        getStarted: 1,
        why: 1,
        faq: 1,
        pricing: 1,
        guide: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
        notify("error",JSON.stringify(err));
      });
  };


  return (
    <HeroPanel>
      <div className="hero-image">
        <div className="hero-text">
          <h1 className="title">FREQUENTLY ASKED QUESTIONS</h1>
        </div>
      </div>
      <div className="container">
        <section
          style={{ position: "relative" }}
          className="text-center mt-3 mb-3"
        >
          <Title style={{ color: "#6b6b6b", fontWeight: "400" }} level={5}>
            If you are looking into using Movitanz in your studio you are bound
            to have a few questions, so we have put together a list of the
            common ones.Once you start using Movitanz and have questions, you
            can use the help link in the app to ask our support team. If we
            haven’t answered your question here.. then email{" "}
            <a style={{marginRight:2}}  href="mailto:info@movitanz.com">info@movitanz.com</a>we would
            love to hear from you.
          </Title>
        </section>
      
       {content?.faq?.faq?.map(item => (
        <Collapse accordion>
          <Panel header= {item?.title} key="4">
            {item?.content &&<p>
      {item?.content }
            </p>}
            <ul>
             {item?.p1 && <li>
                 {item?.p1}
              </li>}
              {item?.p2 && <li>
                 {item?.p2}
              </li>}
          
              {item?.p3 && <li>
                 {item?.p3}
              </li>}
          
              {item?.p4 && <li>
                 {item?.p4}
              </li>}
          

              {item?.p5 && <li>
                 {item?.p5}
              </li>}
          
            </ul>
          </Panel>
        </Collapse>
       )) 
}
        
      </div>
    </HeroPanel>
  );
};

export default Faq;
