import { useContext, useState } from "react";
import { Layout, Menu } from "antd";
import { Wrapper } from "./component/component";
import {
	BellOutlined,
	DollarOutlined,
	PaperClipOutlined,
	UserOutlined,
	VideoCameraOutlined,
	PercentageOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../context/userInfo";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ViewProfileContext } from "../../context/viewProfile";

const { Header, Content, Footer, Sider } = Layout;

const AdminLayout = ({ children }) => {
	const { userData, getUserinfo } = useContext(UserContext);
	const [active, setActive] = useState(
		localStorage.getItem("adminKey") ? localStorage.getItem("adminKey") : ""
	);
	const navigate = useNavigate();
	const { profileToken, viewUser } = useContext(ViewProfileContext);

	useEffect(() => {
		getUserinfo();
	}, []);

	const handleChange = (e) => {
		setActive(e?.key);
		localStorage.setItem("adminKey", e?.key);
		if (e?.key == 1) {
			navigate("/manage-user");
		}
		if (e?.key == 2) {
			navigate("/req-program");
		}
		if (e?.key == 3) {
			navigate("/transaction");
		}

		if (e?.key === "cplan") {
			navigate("/current-plan");
		}

		if (e?.key === "4") {
			navigate("/edit-content");
		}

		if (e?.key == "5") {
			navigate("/coupons");
		}
		if (e?.key == "6") {
			navigate("/notification");
		}
	};

	return (
		<Wrapper>
			<Layout>
				{!profileToken?.access?.token && (
					<Sider
						style={{
							overflow: "auto",
							height: "100vh",
							position: "fixed",
							left: 0,
						}}
					>
						<div className="logo" />
						<Menu
							onClick={handleChange}
							theme="light"
							mode="inline"
							defaultSelectedKeys={[active]}
						>
							<Menu.Item key="1" icon={<UserOutlined />}>
								Users
							</Menu.Item>

							{userData?.role === "studioOwner" && (
								<Menu.Item key="cplan" icon={<DollarOutlined />}>
									Current Plan
								</Menu.Item>
							)}
							{userData?.role === "admin" && (
								<>
									<Menu.Item key="2" icon={<VideoCameraOutlined />}>
										Program
									</Menu.Item>
									<Menu.Item key="3" icon={<DollarOutlined />}>
										Transaction
									</Menu.Item>
									<Menu.Item key="4" icon={<PaperClipOutlined />}>
										Content
									</Menu.Item>
									<Menu.Item key="5" icon={<PercentageOutlined />}>
										Coupons
									</Menu.Item>
								</>
							)}
							<Menu.Item key="6" icon={<BellOutlined />}>
								Notification
							</Menu.Item>
						</Menu>
					</Sider>
				)}
				<Layout
					className="site-layout"
					style={{ marginLeft: !profileToken?.access?.token ? 200 : 0 }}
				>
					{/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}
					<Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
						{children}
					</Content>
				</Layout>
			</Layout>
		</Wrapper>
	);
};

export default AdminLayout;
