import styled from 'styled-components'

export const Wrapper = styled.div`
.ant-layout-sider {
  background : #fff !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
}

.ant-menu {
  font-family: "Poppins";
  font-size: 16px;
  font-weight : 400;
}

.ant-input-affix-wrapper > input.ant-input {
    background: #dee3f3 !important;
  }
`