import React, { useEffect } from "react";

import Lottie from "react-lottie";
import Loader from "./icons/loading-colour.json";
import { LoaderWrapper } from "./component/component";
const LoadingVideo = ({ isLoading, percent,type,refreshTimeline }) => {
  useEffect(()=>{
    if(type==="autocheck"){
    setTimeout(() => {
          refreshTimeline()
      }, 60000);
  
    }
  },[])
  return (
    isLoading && (
      <>
        <div
          style={{
            position: "absolute",
            fontSize: "20px",
            left : 20
          }}
        >
          
          {percent ? `${percent}%` : ""}   
        </div>
        <div
          style={{
            position: "absolute",
            fontSize: "20px",
            left : 20,
            top :percent ?  30 : 0
          }}
        >
          
         {percent  ? "Hang on while we upload your video. Do not refresh the page until 100% uploaded." : "Hang on while we process your video. The post will refresh few times while processing."}
        </div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: Loader,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={210}
          width={210}
        />
      </>
    )
  );
};
export default LoadingVideo;
