import styled from "styled-components";
import dotstop2 from "../../assets/images/3.jpg";
import dotstop3 from "../../assets/images/5.jpg";
export const HeroSection = styled.div`
.ant-card-body{
  padding:${props=>props.isLaptop?"5px":"24px"};
}
  .hero-section-home {
    content: "";
    position: relative;
    mrgin-top: 150px;
    width: 100%;
    height: ${props=>props.isMobile?"40vh":"100vh"};
    ${'' /* background: url(${dotstop2});  */}
    transform: translate3d(0px, 0px, 0px);
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .text-center{
    text-align:center;
  }
  .ant-card-meta-title{
    color:#00a5a0;
    text-align:center;
  }
  .ant-card-meta-description{
    text-align:center;
  }
  .scroll-content{
    background-image:url({dotstop3});
    background-repeat:round;
     background-attachment: fixed;
     width:100%;
   }
  .title{
    font-size: 22px;
    color: #0eaaa5;
  }
  .heading{
    line-height: 1.2;
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: .05em;
  }
  .subtitle{
    color: #999;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .3em;
    margin-top: 5px;
    font-weight: 600;
  }
  .btn-home{
    border-radius:10px;
  }
  .btn-home:hover{
    background:black;
    color:white;
    border-radius:10px;
  }
  .box-heading{
    color: #4e6278;
    line-height: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
  }
  .box-heading2{
    color: rgb(14, 170, 165);
    line-height: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
  }
  .box-subheading{
    color: #4e6278;
    // line-height: 30px;
    // font-weight: 700;
    // text-transform: uppercase;
    letter-spacing: .05em;
  }
  .text-center{
    text-align:center;
  }
  .row-overlay{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
  }
  // .bg-parralax{
  //   background: url($dotstop3});
  //   background-repeat: no-repeat;
  //   transform: translate3d(0px, -99.475px, 0px);
  //   height: 193%;
  // }
  .row_content{
    padding-top: 0px;
    padding-bottom: 0px;
    z-index: 1;
    margin: 0;
    display: block;
    padding-top: 60px;
    padding-bottom: 60px;
    overflow: hidden;
  }
  .row-inner-full{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }







.bg-primary{
  background:#0eaaa5!important;
}

  .carousel-root {
    width: 100% !important;
    margin: auto !important;
    // margin-top: 3% !important;
  }
  
  .carousel .slide {
    background: #fff !important;
    color: black;
    height: 100%;
  }
  
  .carousel .slide img {
    width: 139px !important;
    border-radius: 50%;
  }
  
  .myCarousel {
    // background: #fafafa;
    // margin-top: -6%;
    // width: 54%;
    // margin-left: auto;
    // margin-right: auto;
    // padding-top: 6%;
    // padding-bottom: 8%;
    // padding-left: 5%;
    // padding-right: 5%;
    // border: 1px solid #ddd;
    // height: 286px;
    /* background: #fafafa; */
    /* margin-top: -6%; */
    width: 54%;
    margin-left: auto;
    margin-right: auto;
    // padding-top: 6%;
    /* padding-bottom: 8%; */
    padding-left: 5%;
    padding-right: 5%;
    /* border: 1px solid #ddd; */
    height: 150px;
  }
  
  .carousel .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 5% !important;
  }
  
  .myCarousel h3 {
    color: white;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
  }
  
  .myCarousel h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: white;
    font-size: 14px;
  }
  
  .myCarousel p {
    font-weight: 100 !important;
    line-height: 29px !important;
    color: white;
    font-size: 15px;
    font-family: sans-serif;
    max-height: 67px;
  }
  
  .myCarousel p:before {
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
  }
  
  .myCarousel p:after {
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
  }
  
  .carousel .control-dots .dot {
    box-shadow: none !important;
    background: #454545 !important;
    outline: 0;
  }
  
  .carousel.carousel-slider .control-arrow {
    background: #000 !important;
    height: 50px !important;
    position: absolute;
    top: 35% !important;
  }
  
  .App {
    text-align: center;
  }
  
  @media only screen and (max-width: 934px) {
    .carousel-root {
      outline: 0;
      width: 93% !important;
      margin: auto !important;
    }
  
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myCarousel {
      background: #fafafa;
      margin-top: -9%;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 8%;
      padding-bottom: 12.5%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      height: 269px;
    }
  
    .carousel .slide img {
      width: 24% !important;
      border-radius: 50%;
    }
  }

`;
