import { Form, Tabs, Button, Divider } from "antd";
import React from "react";
import { useState } from "react";
import ModalWrapper from "../../../../components/Modal";
import { InputField } from "../../../../components/_shared";

const { TabPane } = Tabs;

const FolderListModal = ({ visible, setVisible, folders , pid, onMove}) => {
  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-center">
            <h3>Folders</h3>
          </div>
        </div>

        {folders?.map((folder) => (
          <>
            <div className="col-md-12  mt-3" key={folder?.id}>
           
                <button
                  className="btn btn-primary px-3"
                  onClick={() => onMove(pid, folder?.id)}
                >
                  Move
                </button>
           

              <span
                className=" text-secondary ml-3"
                style={{ marginLeft: "20px" }}
              >
                
                {folder?.folderName}
              </span>
            </div>
          </>
        ))}
      </div>
    </ModalWrapper>
  );
};

export default FolderListModal;
