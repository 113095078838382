import { createConstants } from "redux-model-tools";

export default createConstants("timeline", [
  "GET_TIMELINE_INIT",
  "GET_TIMELINE_SUCCESS",
  "GET_TIMELINE_FAILED",

  "CREATE_TIMELINE_INIT",
  "CREATE_TIMELINE_SUCCESS",
  "CREATE_TIMELINE_FAILED",

  "UPDATE_TIMELINE_INIT",
  "UPDATE_TIMELINE_SUCCESS",
  "UPDATE_TIMELINE_FAILED",

  "DELETE_TIMELINE_INIT",
  "DELETE_TIMELINE_SUCCESS",
  "DELETE_TIMELINE_FAILED",

  "GET_COMMENT_INIT",
  "GET_COMMENT_SUCCESS",
  "GET_COMMENT_FAILED",

  "ADD_COMMENT_INIT",
  "ADD_COMMENT_SUCCESS",
  "ADD_COMMENT_FAILED",

  "UPDATE_COMMENT_INIT",
  "UPDATE_COMMENT_SUCCESS",
  "UPDATE_COMMENT_FAILED",

  "DELETE_COMMENT_INIT",
  "DELETE_COMMENT_SUCCESS",
  "DELETE_COMMENT_FAILED",

  "ADD_REPLY_INIT",
  "ADD_REPLY_SUCCESS",
  'ADD_REPLY_FAILED',

  'UPDATE_REPLY_INIT',
  "UPDATE_REPLY_SUCCESS",
  'UPDATE_REPLY_FAILED',

  "DELETE_REPLY_INIT",
  "DELETE_REPLY_SUCCESS",
  "DELETE_REPLY_FAILED",

  'WATCH_TIMELINE_INIT',
  'WATCH_TIMELINE_SUCCESS',
  'WATCH_TIMELINE_FAILED',

  'UN_WATCH_TIMELINE_INIT',
  'UN_WATCH_TIMELINE_SUCCESS',
  'UN_WATCH_TIMELINE_FAILED',

  'LIKE_TIMELINE_INIT',
  'LIKE_TIMELINE_SUCCESS',
  'LIKE_TIMELINE_FAILED',

  'UN_LIKE_TIMELINE_INIT',
  'UN_LIKE_TIMELINE_SUCCESS',
  'UN_LIKE_TIMELINE_FAILED',

  'SHARE_GROUP_INIT',
  'SHARE_GROUP_SUCCESS',
  'SHARE_GROUP_FAILED',

  'UN_SHARE_GROUP_INIT',
  'UN_SHARE_GROUP_SUCCESS',
  'UN_SHARE_GROUP_FAILED',

  'SHARE_MEMBER_INIT',
  'SHARE_MEMBER_SUCCESS',
  'SHARE_MEMBER_FAILED',

  'UN_SHARE_MEMBER_INIT',
  'UN_SHARE_MEMBER_SUCCESS',
  'UN_SHARE_MEMBER_FAILED',

  'FAVOURTE_TIMELINE_INIT',
  'FAVOURTE_TIMELINE_SUCCESS',
  'FAVOURTE_TIMELINE_FAILED',

  'UN_FAVOURTE_TIMELINE_INIT',
  'UN_FAVOURTE_TIMELINE_SUCCESS',
  'UN_FAVOURTE_TIMELINE_FAILED',

  'TIMELINE_PIN_POST_INIT',
  'TIMELINE_PIN_POST_SUCCESS',
  'TIMELINE_PIN_POST_FAILED',

]);
