import React, { useState } from "react";
import { Col, Row, Form, Divider } from "antd";

import { GrayInputField, InputField } from "../../../components/_shared/Input";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button } from "../../../components/_shared";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../../services/auth";
import { notify } from "../../../utils";
import Loading from "../../../components/_shared/Loader/Loading";

const ResetPassword = () => {
  const [showpass, setShowpass] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false)
  const [form] = Form.useForm();

  const query = useParams();

  const onResetPassword = async (e) => {
    try {
      setIsLoading(true)
      const res = auth.resetPassword(query?.token, { password: e?.password });
      form.resetFields();

        notify("success", "Password reset successfully!");
        setIsLoading(false);
        navigate("/signin");
      
    } catch (err) {
      setIsLoading(false)
      notify("error", "Invalid url");
    }
  };
  return (
    <div className="container  mt-5 bg-white p-5">
      <Loading isLoading={isLoading} />
      <div className="row">
        {/* <div className="col-md-12">
          <img
            src={process.env.PUBLIC_URL + "/movitae-logo.png"}
            alt=""
            width="200px"
          />
        </div> */}

        <div className=" col-md-6 mt-0 bg-secondary-gray py-2  mt-5  ">
          <h5 className="primary-green my-auto">Reset Password</h5>
        </div>
        <div className="offset-md-6"></div>
        <Form
          layout="vertical"
          className="mt-4"
          style={{ width: "100%" }}
          onFinish={onResetPassword}
          form ={form}
        >
          <div className="col-md-4 ">
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please enter new password" }]}
            >
              <GrayInputField
                type={showpass ? "text" : "password"}
                suffix={
                  showpass ? (
                    <EyeInvisibleOutlined
                      onClick={() => {
                        setShowpass(!showpass);
                      }}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setShowpass(!showpass);
                      }}
                    />
                  )
                }
                className="font-600"
                placeholder="New Password"
              />
            </Form.Item>
          </div>
          <div className="col-md-4 ">
            <Form.Item
              label="Confirm new password"
              name="cpassword"
              dependencies={["password"]}
              // hasFeedback
              rules={[
                {
                  required: true,
                  message: "please enter confirm new password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords entered do not match. Please try again,"
                      )
                    );
                  },
                }),
              ]}
            >
              <GrayInputField
                type={showConfirm ? "text" : "password"}
                suffix={
                  showConfirm ? (
                    <EyeInvisibleOutlined
                      onClick={() => {
                        setShowConfirm(!showConfirm);
                      }}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setShowConfirm(!showConfirm);
                      }}
                    />
                  )
                }
                className="font-600"
                placeholder="Confirm New Password"
              />
            </Form.Item>
          </div>
          <div className="row d-flex ">
            <div className="col-md-6 ">
              <Button
                lite
                htmlType="submit"
                size={"large"}
                // style={{ marginLeft: isMobile ? 0 : 20 }}
                title="Reset Password"
                className=""
              />
            </div>
          </div>
        </Form>
        <div className="col-md-6">
          <Divider />
        </div>
        <div className="md-offset-6"></div>
      </div>
    </div>
  );
};

export default ResetPassword;
