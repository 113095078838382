import { Card, Divider } from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userInfo";

import Carousel from "../Pricing/slider";
import StripeSelfSubscription from "./component/SelfSubscriptionStripe";

const SelfSubscription = () => {
  const { userData, getUserinfo } = useContext(UserContext);

  useEffect(() => {
    getUserinfo()
  },[])
  return (
    <div className="d-flex justify-content-around" style={{ width: "100%" }}>
      {/* <Carousel show={4}> */}
        {[
          { title: "Basic", price: 50, student: "5-50", storage: 50, maxStudents : 50 },
          // { title: "Premium", price: 100, student: "51-100", storage: 100 ,maxStudents : 100},
          // { title: "Standard", price: 150, student: "101-150", storage: 150 ,maxStudents : 150},
          // { title: "Standard", price: 200, student: "151-200", storage: 200 ,maxStudents : 200},
          // { title: "Standard", price: 250, student: "201-250", storage: 250 , maxStudents : 250},
          // { title: "Standard", price: 300, student: "251-300", storage: 300 , maxStudents : 300},
          // { title: "Standard", price: 350, student: "300+", storage: 350 , maxStudents : 350},
        ].map((item, index) => (
          <div style={{ padding: 8 }}>
            <Card key={index} className="  mt-5">
              <Meta
                title={
                  <div className="d-flex justify-content-center mt-4">
                  
                    <h3> {item?.price} AU</h3>
                  </div>
                }
                description={
                  <div className="d-flex justify-content-center">
                    <h5 className="text-secondary"> Extend One Month</h5>
                  </div>
                }
              />
              <Divider />
              <div className="mt-3">
                <ul>
                  <li className="text-secondary font-weight-bold">
                  Unlimited Students Registration                  
                  </li>
                  {/* <li className="text-secondary font-weight-bold mt-1">
                    Lorem ipsum dolor sit, amet sdkndkgnd
                  </li>
                  <li className="text-secondary font-weight-bold mt-1">
                    Lorem ipsum dolor sit, amet sdkndkgnd
                  </li> */}
                </ul>
              </div>
              <Divider />
              <div className="d-flex justify-content-center">
              <StripeSelfSubscription userData = {userData} price={item?.price} student = {item?.maxStudents} />
              </div>
            </Card>
          </div>
        ))}
      {/* </Carousel> */}
    </div>
  );
};
export default SelfSubscription;
