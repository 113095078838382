import React, { useContext } from "react";
import { useState } from "react";
import { Button, InputField } from "../../../../components/_shared";
import portfolio from "../../../../services/portfolio";
import { notify } from "../../../../utils";
import CreatePortfolioModel from "./CreatePortfolioModel";
import {
	DeleteOutlined,
	SearchOutlined,
	ShareAltOutlined,
} from "@ant-design/icons";
import SharePortfolioModel from "./SharePortfolioModel";
import { useNavigate } from "react-router-dom";
import { ViewProfileContext } from "../../../../context/viewProfile";
import Loading from "../../../../components/_shared/Loader/Loading";
import moment from "moment";
import { UserContext } from "../../../../context/userInfo";

const MyPortfolio = ({ fetchMyPortoflio, portfolios }) => {
	const [showCreatePortfolio, setShowCreatePortfolio] = useState(false);
	const [showShare, setShowShare] = useState(false);
	const [portfolioIndex, setPortfolioIndex] = useState("");
	const navigate = useNavigate();
	const { profileToken } = useContext(ViewProfileContext);
	const { userData } = useContext(UserContext);
	const [searchField, setSearchField] = useState("");

	const createPortfolio = (e) => {
		portfolio
			.createPortfolio({ title: e?.title })
			.then((res) => {
				if (res.status === 200) {
					fetchMyPortoflio();
					setShowCreatePortfolio(false);
				}
			})
			.catch((err) => {
				notify("error", JSON.stringify(err));
			});
	};

	const deletePortfolio = (pid) => {
		if (window.confirm("Are you sure you want to delete this portfolio?")) {
			portfolio
				.deleteMyPortfolio(pid)
				.then((res) => {
					if (res.status === 200) {
						notify("Portfolio deleted sucessfully!");
						fetchMyPortoflio();
					}
				})
				.catch((err) => {
					notify("error", JSON.stringify(err));
				});
		}
	};
	return (
		<>
			<CreatePortfolioModel
				onCreatePortfolio={createPortfolio}
				visible={showCreatePortfolio}
				setVisible={setShowCreatePortfolio}
			/>
			<SharePortfolioModel
				fetchMyPortoflio={fetchMyPortoflio}
				visible={showShare}
				setVisible={setShowShare}
				portfolio={portfolios[portfolioIndex]}
			/>
			{!profileToken && userData?.allowPortfolio && (
				<div className="col-md-12 d-flex justify-content-end align-content-center mb-2">
					<Button
						lite
						title="New Portfolio"
						onClick={() => setShowCreatePortfolio(!showCreatePortfolio)}
					/>
				</div>
			)}

			<InputField
				value={searchField}
				onChange={(e) => setSearchField(e.target.value)}
				placeholder="Search"
				suffix={<SearchOutlined />}
				style={{ width: "350px" }}
			/>
			{portfolios?.map((portfolio, index) =>
				portfolio?.title.toLowerCase().includes(searchField.toLowerCase()) ? (
					<div className="row p-4 bg-secondary-gray mt-3  border-20">
						{!profileToken && (
							<div className="col-md-12 d-flex justify-content-end">
								<DeleteOutlined
									className="text-danger h5 cusror-pointer"
									onClick={() => deletePortfolio(portfolio?.id)}
								/>
							</div>
						)}
						<div className="col-md-1">
							<img
								src={`${portfolio?.portfolioPic}`}
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = `${process.env.PUBLIC_URL}user.png`;
								}}
								width={"80px"}
								alt=""
							/>
						</div>
						<div className="col-md-4 flex-column ">
							<div className="mt-1">
								<h5
									className="cursor-pointer"
									onClick={() => {
										if (!profileToken) navigate(`/portfolio/${portfolio?.id}`);
									}}
								>
									{" "}
									{portfolio?.title}
								</h5>
							</div>
							<div>
								<span>Created: {moment(portfolio?.date).format("LL")}</span>
							</div>
						</div>

						<div className="col-md-5 flex-column">
							{/* <div>
              <span>Shared with 305 Movitae users</span>
            </div> */}
							{!profileToken && (
								<div
									className="d-flex align-content-center mt-2"
									onClick={() => {
										setPortfolioIndex(index);
										setShowShare(!showShare);
									}}
								>
									<ShareAltOutlined className="text-success mt-1 mx-1 cursor-pointer" />{" "}
									<span className="text-success h6 cursor-pointer">share</span>
								</div>
							)}
						</div>
					</div>
				) : (
					<></>
				)
			)}
		</>
	);
};

export default MyPortfolio;
