export const ROUTES = {
	INDEX: "/",
	SIGNUP: "/signup",
	SIGNIN: "/signin",
	PRICING: "/pricing",
	ABOUT: "/about",
	FAQ: "/faq",
	WHY: "/why",
	GET_STARTED: "/get-started",
	TNC: "/tnc",
	PRIVACY: "/privacy",

	RESET_PASSWORD: "/reset-password/:token",
	// Nested Route
	TIMELINE: "timeline",
	TIMELINE_BY_ID: "/timeline/:id",
	GROUP: "group",
	PROGRAM: "program",
	PORTFOLIO: "portfolio",
	STUDIO_DETAILS: "/my-studio",
	PROFILE: "/profile",
	PROGRAM_DETAILS: "/program/:id/view",
	PURCHASED_PROGRAM_DETAILS: "/program/:id/purchased/details",
	PORTFOLIO_DETAILS: "/portfolio/:id",
	SHARE_PORTFOLIO_DETAILS: "/portfolio/:id/share",
	MANAGE_USER: "/manage-user",
	REQ_PROGRAM: "/req-program",
	INFO: "/info",
	FOURM: "/fourm",
	RECIEVED_PAYMENT: "/recieved-payment",
	PAYMENT_DETAILS: "/payment-details",
	TRANSACTION: "/transaction",
	ADMIN_LOGIN: "/admin/signin",
	FORGOT_PASSWORD: "/forgot-password",
	FOLDER_DETAILS: "/folder/:id/details",
	CURRENT_PLAN: "/current-plan",
	SELF_SUBSCRIPTION_PLAN: "/self-subscription",
	UPGRADE_STORAGE: "/upgrade-storage",
	UPGRADE_ADDONS: "/upgrade-addons",
	EDIT_CONTENT: "/edit-content",
	NOTIFICATION: "/notification",
	MOBILE_PLAYER: "/mobile-player",
	COUPONS: "/coupons",
};
