import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Select, Upload } from "antd";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { InputField, TextAreaField } from "../../../../components/_shared";

const { Option } = Select;

const Email = ({ onUpdate, guide }) => {
  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState('');
  const [paragraph, setParagraph] = useState('');
  const updateContent = (e) => {
    let encodedHtml = encodeURIComponent(editorState);
    e.description = encodedHtml
    e.paragraph = paragraph
    onUpdate({
      email: e,
    });
  };
  useEffect(() => {
    if (guide) {
      form.setFieldsValue(guide);
      setParagraph(guide.paragraph)
      let description = decodeURIComponent(guide.description);
      setEditorState(description);

    }
  }, [guide]);
  const handleChange = (content, delta, source, editor) => {
    setEditorState(content);
  };
  return (
    <div className="col-md-12">
      <p>EMAIL</p>
      <Form form={form} layout="vertical" onFinish={updateContent}>
        {() => (
          <div>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
              <Form.Item
                style={{ marginBottom: "15px" }}
                name={"subject"}
                label={"subject"}
              >
                <InputField placeholder="subject" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
              <Form.Item label="Description " >
                <ReactQuill
                  theme="snow"
                  value={editorState}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            {/* <Form.List name="paragraph" className="mb-0">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                          <Form.Item
                            style={{ marginBottom: "15px" }}
                            {...restField}
                            name={[name, "type"]}
                            fieldKey={[fieldKey, "type"]}
                            label={`type ${index + 1}`}
                            rules={[
                              {
                                required: true,
                                message: "Please select type",
                              },
                            ]}
                          >
                            <Select defaultValue="Select type">
                              {["text", "link"]?.map((i) => {
                                return <Option value={i}>{i}</Option>;
                              })}
                            </Select>
                          </Form.Item>
                          {form?.getFieldValue("paragraph")?.[index]?.type ===
                            "link" && (
                            <Form.Item
                              style={{ marginBottom: "15px" }}
                              {...restField}
                              name={[name, "text"]}
                              fieldKey={[fieldKey, "text"]}
                              label={`text ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter text",
                                },
                              ]}
                            >
                              <InputField placeholder="Please enter text to show" />
                            </Form.Item>
                          )}
                          <Form.Item
                            style={{ marginBottom: "15px" }}
                            {...restField}
                            name={[name, "p"]}
                            fieldKey={[fieldKey, "p"]}
                            label={`paragraph ${index + 1}`}
                          >
                            <InputField placeholder="" />
                          </Form.Item>
                          <div
                            className="float-right "
                            style={{ fontSize: "18px", marginTop: "-10px" }}
                          >
                            <DeleteOutlined
                              className="cursor-pointer"
                              onClick={() => remove(name)}
                            />
                          </div>
                        </Col>
                      </>
                    )
                  )}
                  <Col
                    xs={20}
                    sm={20}
                    md={20}
                    xl={20}
                    xxl={20}
                    span={20}
                    className=""
                  >
                    <Form.Item>
                      <div className="">
                        <div
                          className=" cursor-pointer d-flex align-content-center"
                          onClick={() => add()}
                        >
                          <PlusCircleOutlined className="mt-1 mx-1" />
                          <span>Add Paragraph</span>
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List> */}

            <div
              className="center-column"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                htmlType="submit"
                size={"large"}
                type="primary"
                className="rounded "
              // style = {{heigth : "200px"}}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default Email;
