import React, { useEffect } from "react";
import { Form, Row, Col, Button } from "antd";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { InputField, TextAreaField } from "../../../../components/_shared";

const Faq = ({ onUpdate, faq }) => {
  const [form] = Form.useForm();
  const updateContent = (e) => {
    onUpdate({
      faq: e,
    });
  };

  useEffect(() => {
    if (faq) form.setFieldsValue(faq);
  }, [faq]);

  return (
    <div className="col-md-12">
      <Form form={form} layout="vertical" onFinish={updateContent}>
        <Row gutter={16}>
          {/* <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
            <Form.Item
              label="Heading "
              style={{ marginBottom: "15px" }}
              name="heading1"
            >
              <InputField />
            </Form.Item>
          </Col> */}

    
          <div className="col-md-12 mt-2">
            <h4>Faq</h4>
          </div>
          {/* <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
            <Form.Item
              label="Title "
              style={{ marginBottom: "15px" }}
              name="cardTitle"
            >
              <InputField />
            </Form.Item>
          </Col> */}
          <Form.List name="faq" className="mb-0">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <>
           
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "title"]}
                        fieldKey={[fieldKey, "title"]}
                        label={`title`}
                      >
                        <InputField placeholder="" />
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "content"]}
                        fieldKey={[fieldKey, "content"]}
                        label={`content`}
                      >
                        <InputField placeholder="" />
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>
                          <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "p1"]}
                        fieldKey={[fieldKey, "p1"]}
                        label={`p1`}
                      >
                        <InputField placeholder="" />
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "p2"]}
                        fieldKey={[fieldKey, "p2"]}
                        label={`p2`}
                      >
                        <InputField placeholder="" />
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "p3"]}
                        fieldKey={[fieldKey, "p3"]}
                        label={`p3`}
                      >
                        <InputField placeholder="" />
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>

                          <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "p4"]}
                        fieldKey={[fieldKey, "p4"]}
                        label={`p4`}
                      >
                        <InputField placeholder="" />
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>

                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "p5"]}
                        fieldKey={[fieldKey, "p5"]}
                        label={`p5`}
                      >
                        <InputField placeholder="" />
                      </Form.Item>
                      <div
                        className="float-right "
                        style={{ fontSize: "18px", marginTop: "-10px" }}
                      >
                        <DeleteOutlined
                          className="cursor-pointer"
                          onClick={() => remove(name)}
                        />
                      </div>
                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>
                  </>
                ))}
                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  xl={20}
                  xxl={20}
                  span={20}
                  className=""
                >
                  <Form.Item>
                    <div className="">
                      <div
                        className=" cursor-pointer d-flex align-content-center"
                        onClick={() => add()}
                      >
                        <PlusCircleOutlined className="mt-1 mx-1" />
                        <span>Add faq</span>
                      </div>
                    </div>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>

  


          
        </Row>

        <div
          className="center-column"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            htmlType="submit"
            size={"large"}
            type="primary"
            className="rounded "
            // style = {{heigth : "200px"}}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Faq;
