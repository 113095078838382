import { call, put, takeLatest } from "redux-saga/effects";

import portfolioActionTypes from "../actionTypes/portfolio";
import portfolioApi from "../../services/portfolio";
import { notify } from "../../utils";

function* getPortfolio() {
  try {
    const res = yield call(portfolioApi.getPortfolio);

    if (res.status === 200) {
      yield put({ type: portfolioActionTypes.GET_PORTFOLIO_SUCCESS });
    }
  } catch (err) {
    // notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.GET_PORTFOLIO_FAILED });
  }
}

function* createPortfolio() {
  try {
    const res = yield call(portfolioApi.createPortfolio);

    if (res.status === 200) {
      yield put({ type: portfolioActionTypes.CREATE_PORTFOLIO_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.CREATE_PORTFOLIO_FAILED });
  }
}

function* updatePortfolio() {
  try {
    const res = yield call(portfolioApi.updatePortfolio);

    if (res.status === 200) {
      yield put({ type: portfolioActionTypes.UPDATE_PORTFOLIO_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.UPDATE_PORTFOLIO_FAILED });
  }
}

function* deletePortfolio() {
  try {
    const res = yield call(portfolioApi.deleteMyPortfolio);

    if (res.status === 200) {
      yield put({ type: portfolioActionTypes.DELETE_PORTFOLIO_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.DELETE_PORTFOLIO_FAILED });
  }
}

function* getShareMyPortfolio() {
  try {
    const res = yield call(portfolioApi.getShareMyPortfolio);

    if (res.status === 200) {
      yield put({ type: portfolioActionTypes.GET_SHARE_MY_PORTFOLIO_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.GET_SHARE_MY_PORTFOLIO_FAILED });
  }
}

function* sharePortfolio() {
  try {
    const res = yield call(portfolioApi.sharePortfolio);

    if (res.status === 200) {
      yield put({ type: portfolioActionTypes.SHARE_PORTFOLIO_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.SHARE_PORTFOLIO_FAILED });
  }
}

function* unSharePortfolio() {
  try {
    const res = yield call(portfolioApi.unSharePortfolio);

    if (res.status === 200) {
      yield put({ type: portfolioActionTypes.UN_SHARE_PORTFOLIO_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.UN_SHARE_PORTFOLIO_FAILED });
  }
}

function* getPortfolioTimeline() {
  try {
    const res = yield call(portfolioApi.getPortfolioTimeline);

    if (res.status === 200) {
      yield put({ type: portfolioActionTypes.GET_PORTFOLIO_TIMELINE_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.UN_SHARE_PORTFOLIO_FAILED });
  }
}

function* getPortfolioById() {
  try {
    const res = yield call(portfolioApi.getPortfolioById);

    if (res.status === 200) {
      yield put({ type: portfolioActionTypes.GET_PORTFOLIO_BY_ID_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.GET_PORTFOLIO_BY_ID_FAILED });
  }
}

function* deletePortfolioComment() {
  try {
    const res = yield call(portfolioApi.deleteComment);

    if (res.status === 200) {
      yield put({
        type: portfolioActionTypes.DELETE_PORTFOLIO_COMMENT_SUCCESS,
      });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.DELETE_PORTFOLIO_COMMENT_FAILED });
  }
}

function* createPortfolioComment() {
  try {
    const res = yield call(portfolioApi.addComment);

    if (res.status === 200) {
      yield put({
        type: portfolioActionTypes.CREATE_PORTFOLIO_COMMENT_SUCCESS,
      });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: portfolioActionTypes.CREATE_PORTFOLIO_COMMENT_FAILED });
  }
}

export function* watchPortfolio() {
  yield takeLatest(portfolioActionTypes.GET_PORTFOLIO_INIT, getPortfolio);
  yield takeLatest(portfolioActionTypes.CREATE_PORTFOLIO_INIT, createPortfolio);
  yield takeLatest(portfolioActionTypes.UPDATE_PORTFOLIO_INIT, updatePortfolio);
  yield takeLatest(portfolioActionTypes.DELETE_PORTFOLIO_INIT, deletePortfolio);
  yield takeLatest(
    portfolioActionTypes.GET_SHARE_MY_PORTFOLIO_INIT,
    getShareMyPortfolio
  );
  yield takeLatest(portfolioActionTypes.SHARE_PORTFOLIO_INIT, sharePortfolio);
  yield takeLatest(
    portfolioActionTypes.UN_SHARE_PORTFOLIO_INIT,
    unSharePortfolio
  );
  yield takeLatest(
    portfolioActionTypes.GET_PORTFOLIO_TIMELINE_INIT,
    getPortfolioTimeline
  );
  yield takeLatest(
    portfolioActionTypes.GET_PORTFOLIO_BY_ID_INIT,
    getPortfolioById
  );
  yield takeLatest(
    portfolioActionTypes.DELETE_PORTFOLIO_COMMENT_INIT,
    deletePortfolioComment
  );
  yield takeLatest(
    portfolioActionTypes.CREATE_PORTFOLIO_COMMENT_INIT,
    createPortfolioComment
  );
}
