import React from "react";
import { Navigate } from "react-router-dom";
import {isLogin} from "../utils";
import { ROUTES } from "./constant";
/**
 * 
 * @param {*} param0  component of page
 * @returns private route
 */
const PrivateRoute = ({ component: Component, ...props }) => {
  return (
    isLogin() ? <Component {...props} /> : <Navigate replace to={ROUTES.SIGNIN} />
  );
};

export default PrivateRoute;