import { createAction } from "redux-actions";
import studioActionTypes from "../actionTypes/sutdio";

const fetchJoinedStudioInit = createAction(
  studioActionTypes.FETCH_JOINED_STUDIO_INIT
);
const joinStudioInit = createAction(studioActionTypes.JOIN_STUDIO_INIT);
const leftStudioInit = createAction(studioActionTypes.LEFT_STUDIO_INIT);
const fetchStudioMembers = createAction(studioActionTypes.FETCH_STUDIO_MEMBERS_INIT)
const fetchMyGroupsInit = createAction(studioActionTypes.FETCH_STUDIO_GROUP_INIT)
const addGroupInit = createAction(studioActionTypes.ADD_GROUP_INIT)
const deleteGroupInit = createAction(studioActionTypes.DELETE_GROUP_INIT)
const addGroupMemberInit = createAction(studioActionTypes.ADD_GROUP_MEMBER_INIT)
const deleteGroupMemberInit = createAction(studioActionTypes.DELETE_GROUP_MEMBER_INIT)

export { fetchJoinedStudioInit, joinStudioInit, leftStudioInit,fetchStudioMembers ,fetchMyGroupsInit,addGroupInit, deleteGroupInit,
  addGroupMemberInit, deleteGroupMemberInit};

