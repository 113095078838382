import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import { Container } from "./component/component";
import { UserProfile, ChangePassword } from "./component";
import { StudioProfile } from "../Studio/component";
import { UserContext } from "../../../context/userInfo";
import useViewport from "../../../hooks/useViewport";
import Redeem from "./component/Redeem";

const { TabPane } = Tabs;

const Profile = () => {
	const [defaultActiveKey, setActivekey] = useState("1");
	const { userData, getUserinfo } = useContext(UserContext);
	const { isMobile } = useViewport();

	useEffect(() => {
		getUserinfo();
	}, []);

	return (
		<Container className="container bg-white mt-5 p-5 shadow">
			<div className="row ">
				<div className="col-md-12">
					<Tabs
						activeKey={String(defaultActiveKey)}
						onChange={(e) => {
							setActivekey(e);
						}}
						// defaultActiveKey={defaultActiveKey}
						type="line"
						tabBarGutter={0}
					>
						<TabPane
							className={defaultActiveKey == 1 ? "mb-1" : ""}
							tab={
								<div className={isMobile ? "tab_mobile" : "tab_tabName"}>
									Profile
								</div>
							}
							key="1"
						/>
						{userData?.role === "studioOwner" && (
							<>
								<TabPane
									className={defaultActiveKey == 2 ? "mb-1" : ""}
									tab={
										<div className={isMobile ? "tab_mobile" : "tab_tabName"}>
											Studio Details
										</div>
									}
									key="2"
								/>
								<TabPane
									className={defaultActiveKey == 2 ? "mb-1" : ""}
									tab={
										<div className={isMobile ? "tab_mobile" : "tab_tabName"}>
											Redeem Coupon
										</div>
									}
									key="3"
								/>
							</>
						)}

						<TabPane
							className={defaultActiveKey == 3 ? "mb-1" : ""}
							tab={
								<div className={isMobile ? "tab_mobile" : "tab_tabName"}>
									Change Password
								</div>
							}
							key="4"
						/>
					</Tabs>
				</div>
			</div>

			{defaultActiveKey == "1" && <UserProfile />}
			{defaultActiveKey == "2" && <StudioProfile />}
			{defaultActiveKey == "3" && <Redeem />}
			{defaultActiveKey == "4" && <ChangePassword />}
		</Container>
	);
};

export default Profile;
