import React, {useEffect} from "react";
import { Form, Row, Col, Button } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { InputField, TextAreaField } from "../../../../components/_shared";

const Pricing = ({ onUpdate , pricing}) => {
  const [form] = Form.useForm();
  const updateContent = (e) => {
    onUpdate({
      pricing: e,
    });
  };

  useEffect(() => {
    if(pricing)
    form.setFieldsValue(pricing);
    
  }, [pricing])

  return (
    <div className="col-md-12">
      <Form form = {form} layout="vertical" onFinish={updateContent}>
        <Row gutter={16}>
   

          <Form.List name="cards" className="mb-0">

            {(fields, { add, remove }) => (
              <>
                      
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <>
                      <div className="col-md-12">
              <h4>Card</h4>
            </div>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "price"]}
                        fieldKey={[fieldKey, "price"]}
                        label={`Price`}

                      >
                        <InputField placeholder="" type = "Number" min={1}/>
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "priceText"]}
                        fieldKey={[fieldKey, "priceText"]}
                        label={`Price Text`}

                      >
                        <InputField placeholder=""  />
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>

                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "storage"]}
                        fieldKey={[fieldKey, "storage"]}
                        label={`Storage`}

                      >
                        <InputField placeholder="" type = "Number" min={1}/>
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "storageText"]}
                        fieldKey={[fieldKey, "storageText"]}
                        label={`Storage Text`}

                      >
                        <InputField placeholder="" />
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "maxStudents"]}
                        fieldKey={[fieldKey, "maxStudents"]}
                        label={`Students`}

                      >
                        <InputField placeholder="" type = "Number" min={1}/>

                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "studentText"]}
                        fieldKey={[fieldKey, "studentText"]}
                        label={`Student Text`}
                      >
                        <InputField placeholder="" />
                      </Form.Item>
                      <div
                        className="float-right "
                        style={{ fontSize: "18px", marginTop: "-10px" }}
                      >
                        <DeleteOutlined
                          className="cursor-pointer"
                          onClick={() => remove(name)}
                        />
                      </div>
                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>
                  </>
                ))}
                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  xl={20}
                  xxl={20}
                  span={20}
                  className=""
                >
                  <Form.Item>
                    <div className="">
                      <div
                        className=" cursor-pointer d-flex align-content-center"
                        onClick={() => add()}
                      >
                        <PlusCircleOutlined className="mt-1 mx-1" />
                        <span>Add Card</span>
                      </div>
                    </div>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>

        
        </Row>

        <div
          className="center-column"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            htmlType="submit"
            size={"large"}
            type="primary"
            className="rounded "
            // style = {{heigth : "200px"}}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Pricing;
