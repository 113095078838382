import styled from "styled-components";
import Modal from "antd/lib/modal/Modal";


export const Wrapper = styled(Modal).attrs({
  className: "signin-form",
})`
  .ant-modal-body {
    padding: 0px !important;
  }
  .ant-modal-content {
    border-radius: 50px !important;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.isMobile ? "unset" : "auto")};
  margin: 20px auto;
  .card {
    background: white;
    display: flex;
    width: 100%;
    border-radius: ${(props) => (props.isMobile ? "unset" : "40px")};
    //   height: ${(props) => (props.isMobile ? "unset" : "89vh")};
    height: auto;
    justify-content: center;
    align-items: center;
    &.ant-card-bordered {
      border: ${(props) =>
        props.isMobile ? "none!important" : "1px solid #f0f0f0"};
    }
    .continue {
      border-radius: 20px;
      color: white;
      background: #0eaaa5;
      border: none;
      width: 150px;
      height: 60px;
      margin-top: 20px;
      padding: 14px 30px;
      text-transform: uppercase;
    }
    .tab_tabName {
      width: 8rem;
      text-align: center;
      font-size: 16px;
      font-family: Poppins;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #363636 !important;
      font-weight: 500;
    }
    .ant-tabs-tab-btn {
      color: #8a95ba;
      font-size: 10px;
      font-weight: 300;
    }
    .back_button {
      background-color: #fff;
      color: #0eaaa5;
      border: 1px solid #0eaaa5;
    }
    .already-register {
      text-align: center;
      margin-top: 5%;
    }
    &_google-sign {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 14px 30px;
      width: 320px;
      margin-left: auto;
      margin-right: auto;
      height: 60px;
      left: 290px;
      top: 154px;
      background: #0eaaa5;
      border-radius: 20px;
      cursor: pointer;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.06em;
      color: #ffffff;
      margin-bottom: 30px;
    }
    .devider span {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */
      text-align: center;
      letter-spacing: 0.06em;
      /* Black */
      color: #0c0e17;
    }
    .back_button {
      margin-right: 15px !important;
    }
    .ant-form-item-explain {
      margin-left: 10px !important;
    }
    @media only screen and (max-width: 428px) {
      .p-title {
        font-size: 28px !important;
      }
      .p-text {
        font-size: 13px !important;
      }
    }
    @media only screen and (max-width: 469px) {
      .n-title {
        font-size: 30px !important;
      }
    }
    .reset-block {
      display: flex !important;
      flex-direction: column !important;
    }
    .title {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      /* line-height: 54px; */
      letter-spacing: 0.06em;
      color: #0c0e17;
      position: relative;
      text-align: center;
    }
    .tnc-accept {
      font-size: 14px;
      line-height: 21px;
      font-family: Poppins;
      color: #0c0e17;
    }
  }

  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-tab {
   margin: auto;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
    
  }
  .tab_tabName {
    /* width: 15rem; */
    text-align: center;
    font-size: 16px;
    font-family: Poppins;
  }

  .tab_mobile {
    text-align: center;
    font-size: 12px;
    font-family: Poppins;
  }
  .ant-tabs-nav-list {
    font-size: 16px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #363636 !important;
    font-weight: 500;
  }
  .ant-tabs-tab-btn {
    color: #605a5b;
    font-size: 10px;
    font-weight: 400;
  }

    .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.7);
    background: #fff 0% 0% no-repeat padding-box;
    border-color: #0eaaa5;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #0eaaa5 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #0eaaa5 !important;
  }

  .ant-select-selector {
    height: 42px !important;
    border: 1px solid #b7b5b5;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    margin: auto auto;
  }

  .ant-select-arrow {
    font-size: 15px;
    color: #0eaaa5;
  }

  .ant-input-affix-wrapper > input.ant-input  {
    background : #dee3f3 !important

  }



`;