import "./App.less";
import Routers from "./route";
import { Elements } from "@stripe/react-stripe-js";
import store from "./store";
import { Provider } from "react-redux";
import ViewportProvider from "./context/viewport";
import UserProvider from "./context/userInfo";
import ViewProfileProvider from "./context/viewProfile";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from "./utils/server";
import VideoUploadProvider from "./context/UpdateVideo";
import api from "./services/auth";
import { useEffect } from "react";
import moment from "moment";
// import { chat } from "./utils";
const stripePromise = loadStripe(STRIPE_KEY);

function App() {
	useEffect(async () => {
		if (localStorage.getItem("authToken")) {
			if (localStorage.getItem("date") !== moment().format("DD-MM-YYYY")) {
				localStorage.setItem("date", moment().format("DD-MM-YYYY"));
				await api.autorenewal();
			}
		}

		// to disable right click on all app
		// document.addEventListener(
		// 	"contextmenu",
		// 	function (e) {
		// 		e.preventDefault();
		// 	},
		// 	false
		// );
	}, []);
	return (
		<Provider store={store}>
			<UserProvider>
				<VideoUploadProvider>
					<ViewportProvider>
						<ViewProfileProvider>
							<Elements stripe={stripePromise}>
								<Routers />
							</Elements>
						</ViewProfileProvider>
					</ViewportProvider>
				</VideoUploadProvider>
			</UserProvider>
		</Provider>
	);
}

export default App;
