import {
	DownloadOutlined,
	DownOutlined,
	EyeFilled,
	EyeOutlined,
	LikeFilled,
	LikeOutlined,
	StarFilled,
	StarOutlined,
	UnorderedListOutlined,
} from "@ant-design/icons";
import FileViewer from "react-file-viewer";

import { Card, Dropdown, Menu, Rate, Collapse, Divider, Tooltip } from "antd";
import Vimeo from "@u-wave/react-vimeo";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Button, InputField } from "../../../../components/_shared";
import { Wrapper } from "./component";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SERVER_URL } from "../../../../utils";
import { returnFileType } from "../../../../utils/imageAccept";
import { useDispatch, useSelector } from "react-redux";
import {
	makeSelectGroups,
	makeSelectTimelineComment,
} from "../../../../store/selectors";
import ReactHtmlParser from "react-html-parser";
import useViewport from "../../../../hooks/useViewport";
import timelineService from "../../../../services/timeline";

import Comment from "./comment";
import {
	deleteTimelineInit,
	favourteTimelineInit,
	likeTimelineInit,
	timeLinepinpostInit,
	unFavourteTimelineInit,
	unLikeTimelineInit,
	unWatchTimelineInit,
	watchTimelineInit,
} from "../../../../store/actions/timeline";
import { joinedUser } from "../../../../utils/joinedUser";
import moment from "moment";
import { getFileExt, MP3 } from "../../../../utils/helper";
import VimeoPlayer from "./VimeoPlayer";
import { useContext } from "react";
import ViewportProvider from "../../../../context/viewport";
import LoadingVideo from "../../../../components/_shared/Loader/LoadingVideo";
import ReactPlayer from "react-player";
import VimeoPercentage from "./VimeoPercent";
import axios from "axios";
import VideoPlayer from "../../../../components/VideoPlayer";
const { Panel } = Collapse;

const Post = ({
	timeline,
	onDeleteTimeline,
	fetchComment,
	userData,
	studios,
	studioMembers,
	setShareModalShow,
	setShareTimeline,
	refreshTimeline,
	profileToken,
	onMakeCopy,
	setShowPortfolioModal,
	setTid,
	setUpdateTimeline,
	setShowUpdate,
	setShowReport,
	setReportedTimeline,
	progress,
	timeline_id,
	hidden,
}) => {
	const groups = useSelector(makeSelectGroups());
	const [timelineId, setTimelineId] = useState("");
	const [limit, setLimit] = useState(3);
	const [post, setPost] = useState("");
	const comments = useSelector(makeSelectTimelineComment());
	const [commentId, setCommentId] = useState("");
	const [commentLimit, setCommentLimit] = useState(2);
	const [activeTimeline, setActiveTimeline] = useState([]);
	const [like, setLike] = useState(joinedUser(userData?.id, timeline?.like));
	const [watch, setWatch] = useState(joinedUser(userData?.id, timeline?.view));
	const [fav, setFav] = useState(joinedUser(userData?.id, timeline?.favourite));
	const dispatch = useDispatch();
	const { isMobile } = useViewport();

	const likeTimeline = (tid) => {
		dispatch(likeTimelineInit({ tid, setLike }));
	};

	const unLikeTimeline = (tid) => {
		dispatch(unLikeTimelineInit({ tid, setLike }));
	};

	const watchTimeline = (tid) => {
		dispatch(watchTimelineInit({ tid, setWatch }));
		setTimeout(() => {
			refreshTimeline();
		}, [100]);
	};

	const unWatchTimeline = (tid) => {
		dispatch(unWatchTimelineInit({ tid, setWatch }));
		setTimeout(() => {
			refreshTimeline();
		}, [100]);
	};

	const favTimeline = (tid) => {
		dispatch(favourteTimelineInit({ tid, setFav }));
	};

	const unFavTimelne = (tid) => {
		dispatch(unFavourteTimelineInit({ tid, setFav }));
	};

	const deleteVimeoVideo = async (vid) => {
		await timelineService.deleteVimeo(vid);
	};

	const deleteTimelineVideo = (videos) => {
		if (videos.length) {
			for (let video of videos) {
				deleteVimeoVideo(video);
			}
		}
	};

	const getVideoLink = (videoId) => {
		timelineService
			.getVideoLink({ videoId })
			.then((res) => {
				downloadVideo(res?.data?.request?.files?.progressive?.[0]?.url);
			})
			.catch((err) => {
				alert("Failed to download video");
			});
	};

	const downloadVideo = (link) => {
		var xhr = new XMLHttpRequest();
		xhr.open("GET", link, true);
		xhr.responseType = "blob";
		xhr.onload = function () {
			var urlCreator = window.URL || window.webkitURL;
			var imageUrl = urlCreator.createObjectURL(this.response);
			var tag = document.createElement("a");
			tag.href = imageUrl;
			tag.target = "_blank";
			tag.download = "video.mp4";
			document.body.appendChild(tag);
			tag.click();
			document.body.removeChild(tag);
		};
		xhr.onerror = (err) => {
			alert("Failed to download video");
		};
		xhr.send();
	};
	const menu = () => (
		<Menu>
			<Menu.Item
				onClick={async () => {
					if (hidden) {
						await timelineService.hideUnhide({
							tid: timeline?.id,
							type: "unhide",
						});
						refreshTimeline();
					} else {
						await timelineService.hideUnhide({
							tid: timeline?.id,
							type: "hide",
						});
						refreshTimeline();
					}
					// timelineAPI.hideUnhide({tid: timeline?.id, type: 'hide'});

					// setLimit(limit + 1);
				}}
			>
				<a>{hidden ? "Unhide" : "Hide"} </a>
			</Menu.Item>
			{userData?.id === timeline?.user?.id && (
				<Menu.Item
					onClick={() => {
						setUpdateTimeline(timeline);
						setShowUpdate(true);
					}}
				>
					<a>Edit</a>
				</Menu.Item>
			)}
			{(timeline.pin == false && userData?.role === "studioOwner") ||
			(timeline.pin == false && userData?.role === "teacher") ? (
				<Menu.Item>
					<a
						onClick={() => {
							const isPinned = {
								data: timeline,
								isPin: true,
							};
							pinUnpin(isPinned);
						}}
					>
						Pin
					</a>
				</Menu.Item>
			) : timeline.pin == true ? (
				<Menu.Item>
					<a
						onClick={() => {
							const isPinned = {
								data: timeline,
								isPin: false,
							};
							pinUnpin(isPinned);
						}}
					>
						Unpin
					</a>
				</Menu.Item>
			) : (
				""
			)}
			{userData?.role === "student" && (
				<Menu.Item
					onClick={() => {
						setReportedTimeline(timeline);
						setShowReport(true);
					}}
				>
					<a>Report this?</a>
				</Menu.Item>
			)}
			<Menu.Item
				onClick={() => {
					setShareTimeline({ id: timeline?.id, title: timeline?.title });
					setShareModalShow(true);
				}}
			>
				<a>Share</a>
			</Menu.Item>
			<Menu.Item>
				<a
					onClick={() => {
						setTid(timeline?.id);
						setShowPortfolioModal(true);
					}}
				>
					Add to Portfolio
				</a>
			</Menu.Item>
			{/* </>
      )} */}

			{/* {timeline?.makeCopy && (
        <Menu.Item
          onClick={() => {
            var link = document.createElement('a');
            link.target = '_blank';
            link.href = timeline?.postPic?.[0];
            link.download = timeline?.postPic?.[0];
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // onMakeCopy(timeline)
          }}
        >
          <a>Make a copy</a>
        </Menu.Item>
      )} */}
			{userData?.id === timeline?.user?.id ||
			userData?.role === "studioOwner" ? (
				<Menu.Item
					onClick={() => {
						onDeleteTimeline(timeline?.id);
						deleteTimelineVideo(timeline?.videoId);
					}}
					danger
				>
					Delete
				</Menu.Item>
			) : (
				""
			)}
		</Menu>
	);
	const checkWhereisme = groups
		?.map((i) => {
			return i.members.includes(userData?.id) && i;
		})
		?.filter((d) => d !== false);

	const groupsID = groups?.map((i) => i?.id);
	const groupsID2 = checkWhereisme?.map((i) => i?.id);
	const checkSharedGroup = timeline?.sharedGroup?.some((item) =>
		userData?.role === "studioOwner"
			? groupsID?.includes(item)
			: groupsID2?.includes(item)
	);
	const pinUnpin = (data) => {
		const isPin = {
			pin: data.isPin,
		};
		dispatch(
			timeLinepinpostInit({
				id: data.data?.id,
				isPin,
				refreshTimeline: refreshTimeline,
			})
		);
	};
	const checkSharedmember = timeline?.sharedMember?.includes(userData?.id);
	const checkPostbyme = timeline?.user?.id === userData?.id;
	const itsViewable =
		checkSharedGroup === true ||
		checkSharedmember === true ||
		checkPostbyme === true;
	useEffect(() => {
		if (
			!watch &&
			itsViewable &&
			moment(timeline?.timelineDate).diff(new Date(), "day s") <= 0
		) {
			dispatch(watchTimelineInit({ tid: timeline?.id, setWatch }));
		}

		// if (
		//   moment(timeline?.createdAt).diff(new Date(), "days") !== 0 &&
		//   moment(timeline?.createdAt).diff(new Date(), "days") < -90
		//   // timeline?.role === "student"
		// ) {
		//   dispatch(deleteTimelineInit(timeline?.id));
		//   deleteTimelineVideo(timeline?.videoId);
		// }
	}, []);
	console.log(timeline?.user?.id === userData?.id, "dtghthtgfh");
	return userData?.role === "admin" ? (
		moment(timeline?.timelineDate).diff(new Date(), "day s") <= 0 && (
			<div className={`container  mt-5 `}>
				<div className="container ">
					<Wrapper className="bg-primary-gray mx-auto shadow">
						<Card
							title={
								<div className="d-flex">
									<div>
										<img
											src={timeline?.user?.profilepic}
											onError={(e) => {
												e.target.onerror = null;
												e.target.src = `${process.env.PUBLIC_URL}user.png`;
											}}
											alt=""
											width="50"
											height={50}
											style={{ objectFit: "cover" }}
											className="rounded-circle"
										/>
									</div>
									<div
										className="mx-3 d-flex flex-column "
										style={{ width: "50%", whiteSpace: "pre-wrap" }}
									>
										<h6 className="mb-0 font-white">{timeline?.title}</h6>
										<span className="font-white">
											{moment(timeline?.timelineDate).format("DD/MM/YYYY")}
										</span>
									</div>
								</div>
							}
							extra={
								!profileToken ? (
									<Dropdown overlay={menu}>
										<UnorderedListOutlined className="font-white h4 cursor-pointer" />
									</Dropdown>
								) : (
									""
								)
							}
							style={{ width: "100%" }}
							bordered={false}
						>
							{/* {timeline?.isUploaded ? ( */}

							<div
								className="row"
								// to prevent right click on Timeline page
								onContextMenu={(event) => {
									event.preventDefault();
								}}
							>
								<Carousel showArrows={true}>
									{timeline?.isUploaded
										? timeline?.videoId?.map((id, index) => (
												<>
													{timeline?.makeCopy && (
														<div>
															<Tooltip
																title="Download"
																color={"#0eaaa5"}
																key={"download"}
															>
																<DownloadOutlined
																	// style={{ float: "left" }}
																	onClick={() => {
																		window.open(
																			`https://movitanz.s3.ap-southeast-2.amazonaws.com/${id}.mp4`,
																			"_blank"
																		);
																	}}
																	className=" h4 cursor-pointer"
																/>
															</Tooltip>
														</div>
													)}
													<VideoPlayer
														src={`https://dum3sasv4ib5u.cloudfront.net/${id}.mp4`}
														preload="auto"
														controls
														style={{ width: "100%", height: "400px" }}
														controlslist="nodownload"
													/>
												</>
										  ))
										: Array(
												Number(timeline?.countVideo ? timeline?.countVideo : 0)
										  )
												.join(".")
												.split(".")
												?.map((item, index) => (
													<div
														className="mx-auto"
														style={{ maxHeight: "600px", width: "100%" }}
													>
														<VimeoPercentage
															refreshTimeline={refreshTimeline}
															socketId={`${timeline?.id}${index}`}
															progress={progress}
															// showTitle={false}
															// showByline={fal  se}
														/>
														{/* <ReactPlayer
              controls
              width="100%"
              height={isMobile ? "100%" : 600}
              url={`https://vimeo.com/${Number(id)}`}
            /> */}
													</div>
												))}

									{timeline?.vimeoUrl?.map((url) => (
										<>
											{/* <Tooltip
                          title="Download"
                          color={"#0eaaa5"}
                          key={"download"}
                        >
                            <DownloadOutlined onClick = {() => getVideoLink(url)} className=" h4 cursor-pointer" />
                        </Tooltip> */}

											<Vimeo
												video={url}
												height={600}
												// showTitle={false}
												// showByline={false}
											/>
										</>
									))}

									{timeline?.postPic?.map((pic, index) => (
										<div
											className="mx-auto"
											style={{ maxHeight: "600px", height: 600 }}
										>
											{returnFileType(pic) == "jpg" ||
											returnFileType(pic) == "png" ||
											returnFileType(pic) == "jpeg" ||
											returnFileType(pic) == "svg" ? (
												<>
													{timeline?.makeCopy && (
														<div
															className=" primary-gray d-flex  "
															// style={{ marginLeft: "50px" }}
														>
															<Tooltip
																title="Download"
																color={"#0eaaa5"}
																key={"download"}
															>
																<a
																	href={pic}
																	target={"_blank"}
																	rel="noreferrer"
																>
																	<DownloadOutlined className=" h4 cursor-pointer" />
																</a>
															</Tooltip>
														</div>
													)}
													<img
														src={`${pic}`}
														onError={(e) => {
															e.target.onerror = null;
															e.target.src = `${process.env.PUBLIC_URL}user.png`;
														}}
														alt=""
														style={{
															maxWidth: "100%",
															maxHeight: "100%",
															objectFit: "contain",
														}}
													/>
												</>
											) : (
												<>
													{timeline?.makeCopy && (
														<div
															className=" primary-gray d-flex  "
															style={{ marginLeft: "50px" }}
														>
															<Tooltip
																title="Download"
																color={"#0eaaa5"}
																key={"download"}
															>
																<a
																	href={pic}
																	target={"_blank"}
																	rel="noreferrer"
																>
																	<DownloadOutlined className=" h4 cursor-pointer" />
																</a>
															</Tooltip>
														</div>
													)}
													{[
														"pdf",
														"csv",
														"xslx",
														"docx",
														"mp4",
														"webm",
														"mp3",
													]?.includes(getFileExt(pic)) ? (
														<FileViewer
															fileType={getFileExt(pic)}
															filePath={pic}
														/>
													) : (
														MP3.includes(getFileExt(pic)) && (
															<ReactPlayer
																width="100%"
																height={isMobile ? "100%" : "400px"}
																url={pic}
																controls
															/>
														)
													)}
												</>
											)}
											{/* {returnFileType(pic) == "pdf" && (
                <embed src={`${pic}`} width="100%" height="600px" />

                // </iframe>
              )} */}
										</div>
									))}

									{timeline?.youtubeUrl?.map((url) => (
										<div className="mx-auto" style={{ maxHeight: "600px" }}>
											<ReactPlayer
												width="100%"
												height={isMobile ? "100%" : 600}
												url={url}
											/>
										</div>
									))}
								</Carousel>
							</div>
							{/* ) : (
        <LoadingVideo isLoading={true} />
      )} */}
							<div className="row">
								{timeline?.description !== "undefined" && (
									<p className="font-light-gray ___render__html">
										{ReactHtmlParser(
											ReactHtmlParser(timeline?.description)
										)?.[0] === "[object Object]"
											? ReactHtmlParser(timeline?.description)
											: ReactHtmlParser(ReactHtmlParser(timeline?.description))}
									</p>
								)}
								{timeline?.googleData?.map((i) => {
									return (
										<a href={i} target="_blank">
											{i}
										</a>
									);
								})}
								{timeline?.dropboxData?.map((i) => {
									return (
										<a href={i} target="_blank">
											{i}
										</a>
									);
								})}
							</div>
							{!profileToken && (
								<div className="row mt-3">
									<div className="col-md-12 mb-0">
										<Tooltip title="Like" color={"#0eaaa5"} key={"1"}>
											{like ? (
												<LikeFilled
													className=" h4 mb- 0"
													onClick={() => unLikeTimeline(timeline?.id)}
												/>
											) : (
												<LikeOutlined
													className="h4 mb-0"
													onClick={() => likeTimeline(timeline?.id)}
												/>
											)}
										</Tooltip>
										{/* <Tooltip title="Watch" color={"#0eaaa5"} key={"2"}>
              {watch ? (
                <EyeFilled
                  className="h4 mx-3"
                  onClick={() => unWatchTimeline(timeline?.id)}
                />
              ) : (
                <EyeOutlined
                  className="h4 mx-3"
                  onClick={() => watchTimeline(timeline?.id)}
                />
              )}
            </Tooltip> */}

										<Tooltip title="Favourite" color={"#0eaaa5"} key={"3"}>
											{fav ? (
												<StarFilled
													className="h4 mx-3"
													onClick={() => unFavTimelne(timeline?.id)}
												/>
											) : (
												<StarOutlined
													className="h4 mx-3"
													onClick={() => favTimeline(timeline?.id)}
												/>
											)}
										</Tooltip>
									</div>
								</div>
							)}
							<div className="row mt-3">
								{/* <Collapse defaultActiveKey={["1"]}> */}
								<Comment
									timeline={timeline}
									timelineId={timelineId}
									setTimelineId={setTimelineId}
									limit={commentLimit}
									setLimit={setCommentLimit}
									commentId={commentId}
									setCommentId={setCommentId}
									comments={comments}
									fetchComment={fetchComment}
									activeTimeline={activeTimeline}
									setActiveTimeline={setActiveTimeline}
									userData={userData}
									studioMembers={studioMembers}
									studios={studios}
									profileToken={profileToken}
								/>
							</div>
						</Card>
					</Wrapper>
				</div>
			</div>
		)
	) : itsViewable && timeline?.user?.id === userData?.id ? (
		<div className={`container  mt-5 `}>
			<div className="container ">
				<Wrapper className="bg-primary-gray mx-auto shadow">
					<Card
						title={
							<div className="d-flex">
								<div>
									<img
										src={timeline?.user?.profilepic}
										onError={(e) => {
											e.target.onerror = null;
											e.target.src = `${process.env.PUBLIC_URL}user.png`;
										}}
										alt=""
										width="50"
										height={50}
										style={{ objectFit: "cover" }}
										className="rounded-circle"
									/>
								</div>
								<div
									className="mx-3 d-flex flex-column "
									style={{ width: "50%", whiteSpace: "pre-wrap" }}
								>
									<h6 className="mb-0 font-white">{timeline?.title}</h6>
									<span className="font-white">
										{moment(timeline?.timelineDate).format("DD/MM/YYYY")}
									</span>
								</div>
							</div>
						}
						extra={
							!profileToken ? (
								<Dropdown overlay={menu}>
									<UnorderedListOutlined className="font-white h4 cursor-pointer" />
								</Dropdown>
							) : (
								""
							)
						}
						style={{ width: "100%" }}
						bordered={false}
					>
						{/* {timeline?.isUploaded ? ( */}

						<div
							className="row"
							// to prevent right click on Timeline page
							onContextMenu={(event) => {
								event.preventDefault();
							}}
						>
							<Carousel showArrows={true}>
								{timeline?.isUploaded
									? timeline?.videoId?.map((id, index) =>
											timeline?.isS3 ? (
												<>
													{timeline?.makeCopy && (
														<div>
															<Tooltip
																title="Download"
																color={"#0eaaa5"}
																key={"download"}
															>
																<DownloadOutlined
																	// style={{ float: "left" }}
																	onClick={() => {
																		window.open(
																			`https://movitanz.s3.ap-southeast-2.amazonaws.com/${id}.mp4`,
																			"_blank"
																		);
																	}}
																	className=" h4 cursor-pointer"
																/>
															</Tooltip>
														</div>
													)}
													<VideoPlayer
														src={`https://dum3sasv4ib5u.cloudfront.net/${id}.mp4`}
														preload="auto"
														controls
														style={{ width: "100%", height: "400px" }}
														controlslist="nodownload"
													/>
												</>
											) : (
												<>
													{timeline?.makeCopy && (
														<div>
															<Tooltip
																title="Download"
																color={"#0eaaa5"}
																key={"download"}
															>
																<DownloadOutlined
																	style={{ float: "left" }}
																	onClick={() => {
																		window.open(
																			`https://movitanz.s3.ap-southeast-2.amazonaws.com/${id}.mp4`,
																			"_blank"
																		);
																	}}
																	className=" h4 cursor-pointer"
																/>
															</Tooltip>
														</div>
													)}
													<VideoPlayer
														src={`https://dum3sasv4ib5u.cloudfront.net/${id}.mp4`}
														preload="auto"
														controls
														style={{ width: "100%", height: "400px" }}
														controlslist="nodownload"
													/>
												</>
												//     <div
												//       // className="mx-auto"
												//       style={{ maxHeight: "600px", width: "100%" }}
												//     >
												//       {" "}
												//       <VimeoPlayer
												//         timeline={timeline}
												//         progress={progress}
												//         getVideoLink={getVideoLink}
												//         id={Number(id)}
												//         socketId={`${timeline?.id}${index}`}
												//         showTitle={false}
												//         showByline={false}
												//         refreshTimeline={refreshTimeline}
												//       />
												//       {/* <ReactPlayer
												//   controls
												//   width="100%"
												//   height={isMobile ? "100%" : 600}
												//   url={`https://vimeo.com/${Number(id)}`}
												// /> */}
												//     </div>
											)
									  )
									: Array(
											Number(timeline?.countVideo ? timeline?.countVideo : 0)
									  )
											.join(".")
											.split(".")
											?.map((item, index) => (
												<div
													className="mx-auto"
													style={{ maxHeight: "600px", width: "100%" }}
												>
													<VimeoPercentage
														refreshTimeline={refreshTimeline}
														socketId={`${timeline?.id}${index}`}
														progress={progress}
														// showTitle={false}
														// showByline={fal  se}
													/>
													{/* <ReactPlayer
                    controls
                    width="100%"
                    height={isMobile ? "100%" : 600}
                    url={`https://vimeo.com/${Number(id)}`}
                  /> */}
												</div>
											))}

								{timeline?.vimeoUrl?.map((url) => (
									<>
										{/* <Tooltip
                                title="Download"
                                color={"#0eaaa5"}
                                key={"download"}
                              >
                                  <DownloadOutlined onClick = {() => getVideoLink(url)} className=" h4 cursor-pointer" />
                              </Tooltip> */}

										<Vimeo
											video={url}
											height={600}
											// showTitle={false}
											// showByline={false}
										/>
									</>
								))}

								{timeline?.postPic?.map((pic, index) => (
									<div
										className="mx-auto"
										style={{ maxHeight: "600px", height: 600 }}
									>
										{returnFileType(pic) == "jpg" ||
										returnFileType(pic) == "png" ||
										returnFileType(pic) == "jpeg" ||
										returnFileType(pic) == "svg" ? (
											<>
												{timeline?.makeCopy && (
													<div
														className=" primary-gray d-flex  "
														// style={{ marginLeft: "50px" }}
													>
														<Tooltip
															title="Download"
															color={"#0eaaa5"}
															key={"download"}
														>
															<a href={pic} target={"_blank"} rel="noreferrer">
																<DownloadOutlined className=" h4 cursor-pointer" />
															</a>
														</Tooltip>
													</div>
												)}
												<img
													src={`${pic}`}
													onError={(e) => {
														e.target.onerror = null;
														e.target.src = `${process.env.PUBLIC_URL}user.png`;
													}}
													alt=""
													style={{
														maxWidth: "100%",
														maxHeight: "100%",
														objectFit: "contain",
													}}
												/>
											</>
										) : (
											<>
												{timeline?.makeCopy && (
													<div
														className=" primary-gray d-flex  "
														style={{ marginLeft: "50px" }}
													>
														<Tooltip
															title="Download"
															color={"#0eaaa5"}
															key={"download"}
														>
															<a href={pic} target={"_blank"} rel="noreferrer">
																<DownloadOutlined className=" h4 cursor-pointer" />
															</a>
														</Tooltip>
													</div>
												)}
												{[
													"pdf",
													"csv",
													"xslx",
													"docx",
													"mp4",
													"webm",
													"mp3",
												]?.includes(getFileExt(pic)) ? (
													<FileViewer
														fileType={getFileExt(pic)}
														filePath={pic}
													/>
												) : (
													MP3.includes(getFileExt(pic)) && (
														<ReactPlayer
															width="100%"
															height={isMobile ? "100%" : "400px"}
															url={pic}
															controls
														/>
													)
												)}
											</>
										)}
										{/* {returnFileType(pic) == "pdf" && (
                      <embed src={`${pic}`} width="100%" height="600px" />

                      // </iframe>
                    )} */}
									</div>
								))}

								{timeline?.youtubeUrl?.map((url) => (
									<div className="mx-auto" style={{ maxHeight: "600px" }}>
										<ReactPlayer
											width="100%"
											height={isMobile ? "100%" : 600}
											url={url}
										/>
									</div>
								))}
							</Carousel>
						</div>
						{/* ) : (
              <LoadingVideo isLoading={true} />
            )} */}
						<div className="row">
							{timeline?.description !== "undefined" && (
								<p className="font-light-gray ___render__html">
									{ReactHtmlParser(
										ReactHtmlParser(timeline?.description)
									)?.[0] === "[object Object]"
										? ReactHtmlParser(timeline?.description)
										: ReactHtmlParser(ReactHtmlParser(timeline?.description))}
								</p>
							)}
							{timeline?.googleData?.map((i) => {
								return (
									<a href={i} target="_blank">
										{i}
									</a>
								);
							})}
							{timeline?.dropboxData?.map((i) => {
								return (
									<a href={i} target="_blank">
										{i}
									</a>
								);
							})}
						</div>
						{!profileToken && (
							<div className="row mt-3">
								<div className="col-md-12 mb-0">
									<Tooltip title="Like" color={"#0eaaa5"} key={"1"}>
										{like ? (
											<LikeFilled
												className=" h4 mb- 0"
												onClick={() => unLikeTimeline(timeline?.id)}
											/>
										) : (
											<LikeOutlined
												className="h4 mb-0"
												onClick={() => likeTimeline(timeline?.id)}
											/>
										)}
									</Tooltip>
									{/* <Tooltip title="Watch" color={"#0eaaa5"} key={"2"}>
                    {watch ? (
                      <EyeFilled
                        className="h4 mx-3"
                        onClick={() => unWatchTimeline(timeline?.id)}
                      />
                    ) : (
                      <EyeOutlined
                        className="h4 mx-3"
                        onClick={() => watchTimeline(timeline?.id)}
                      />
                    )}
                  </Tooltip> */}

									<Tooltip title="Favourite" color={"#0eaaa5"} key={"3"}>
										{fav ? (
											<StarFilled
												className="h4 mx-3"
												onClick={() => unFavTimelne(timeline?.id)}
											/>
										) : (
											<StarOutlined
												className="h4 mx-3"
												onClick={() => favTimeline(timeline?.id)}
											/>
										)}
									</Tooltip>
								</div>
							</div>
						)}
						<div className="row mt-3">
							{/* <Collapse defaultActiveKey={["1"]}> */}
							<Comment
								timeline={timeline}
								timelineId={timelineId}
								setTimelineId={setTimelineId}
								limit={commentLimit}
								setLimit={setCommentLimit}
								commentId={commentId}
								setCommentId={setCommentId}
								comments={comments}
								fetchComment={fetchComment}
								activeTimeline={activeTimeline}
								setActiveTimeline={setActiveTimeline}
								userData={userData}
								studioMembers={studioMembers}
								studios={studios}
								profileToken={profileToken}
							/>
						</div>
					</Card>
				</Wrapper>
			</div>
		</div>
	) : (
		itsViewable &&
		moment(timeline?.timelineDate).diff(new Date(), "day s") <= 0 && (
			<div className={`container  mt-5 `}>
				<div className="container ">
					<Wrapper className="bg-primary-gray mx-auto shadow">
						<Card
							title={
								<div className="d-flex">
									<div>
										<img
											src={timeline?.user?.profilepic}
											onError={(e) => {
												e.target.onerror = null;
												e.target.src = `${process.env.PUBLIC_URL}user.png`;
											}}
											alt=""
											width="50"
											height={50}
											style={{ objectFit: "cover" }}
											className="rounded-circle"
										/>
									</div>
									<div
										className="mx-3 d-flex flex-column "
										style={{ width: "50%", whiteSpace: "pre-wrap" }}
									>
										<h6 className="mb-0 font-white">{timeline?.title}</h6>
										<span className="font-white">
											{moment(timeline?.timelineDate).format("DD/MM/YYYY")}
										</span>
									</div>
								</div>
							}
							extra={
								!profileToken ? (
									<Dropdown overlay={menu}>
										<UnorderedListOutlined className="font-white h4 cursor-pointer" />
									</Dropdown>
								) : (
									""
								)
							}
							style={{ width: "100%" }}
							bordered={false}
						>
							{/* {timeline?.isUploaded ? ( */}

							<div
								className="row"
								// to prevent right click on Timeline page
								onContextMenu={(event) => {
									event.preventDefault();
								}}
							>
								<Carousel showArrows={true}>
									{timeline?.isUploaded
										? timeline?.videoId?.map((id, index) =>
												timeline?.isS3 ? (
													<>
														{timeline?.makeCopy && (
															<div>
																<Tooltip
																	title="Download"
																	color={"#0eaaa5"}
																	key={"download"}
																>
																	<DownloadOutlined
																		// style={{ float: "left" }}
																		onClick={() => {
																			window.open(
																				`https://movitanz.s3.ap-southeast-2.amazonaws.com/${id}.mp4`,
																				"_blank"
																			);
																		}}
																		className=" h4 cursor-pointer"
																	/>
																</Tooltip>
															</div>
														)}
														<VideoPlayer
															src={`https://dum3sasv4ib5u.cloudfront.net/${id}.mp4`}
															preload="auto"
															controls
															style={{ width: "100%", height: "400px" }}
															controlslist="nodownload"
														/>
													</>
												) : (
													<>
														{timeline?.makeCopy && (
															<div>
																<Tooltip
																	title="Download"
																	color={"#0eaaa5"}
																	key={"download"}
																>
																	<DownloadOutlined
																		style={{ float: "left" }}
																		onClick={() => {
																			window.open(
																				`https://movitanz.s3.ap-southeast-2.amazonaws.com/${id}.mp4`,
																				"_blank"
																			);
																		}}
																		className=" h4 cursor-pointer"
																	/>
																</Tooltip>
															</div>
														)}
														<VideoPlayer
															src={`https://dum3sasv4ib5u.cloudfront.net/${id}.mp4`}
															preload="auto"
															controls
															style={{ width: "100%", height: "400px" }}
															controlslist="nodownload"
														/>
													</>
													//     <div
													//       // className="mx-auto"
													//       style={{ maxHeight: "600px", width: "100%" }}
													//     >
													//       {" "}
													//       <VimeoPlayer
													//         timeline={timeline}
													//         progress={progress}
													//         getVideoLink={getVideoLink}
													//         id={Number(id)}
													//         socketId={`${timeline?.id}${index}`}
													//         showTitle={false}
													//         showByline={false}
													//         refreshTimeline={refreshTimeline}
													//       />
													//       {/* <ReactPlayer
													//   controls
													//   width="100%"
													//   height={isMobile ? "100%" : 600}
													//   url={`https://vimeo.com/${Number(id)}`}
													// /> */}
													//     </div>
												)
										  )
										: Array(
												Number(timeline?.countVideo ? timeline?.countVideo : 0)
										  )
												.join(".")
												.split(".")
												?.map((item, index) => (
													<div
														className="mx-auto"
														style={{ maxHeight: "600px", width: "100%" }}
													>
														<VimeoPercentage
															refreshTimeline={refreshTimeline}
															socketId={`${timeline?.id}${index}`}
															progress={progress}
															// showTitle={false}
															// showByline={fal  se}
														/>
														{/* <ReactPlayer
                    controls
                    width="100%"
                    height={isMobile ? "100%" : 600}
                    url={`https://vimeo.com/${Number(id)}`}
                  /> */}
													</div>
												))}

									{timeline?.vimeoUrl?.map((url) => (
										<>
											{/* <Tooltip
                                title="Download"
                                color={"#0eaaa5"}
                                key={"download"}
                              >
                                  <DownloadOutlined onClick = {() => getVideoLink(url)} className=" h4 cursor-pointer" />
                              </Tooltip> */}

											<Vimeo
												video={url}
												height={600}
												// showTitle={false}
												// showByline={false}
											/>
										</>
									))}

									{timeline?.postPic?.map((pic, index) => (
										<div
											className="mx-auto"
											style={{ maxHeight: "600px", height: 600 }}
										>
											{returnFileType(pic) == "jpg" ||
											returnFileType(pic) == "png" ||
											returnFileType(pic) == "jpeg" ||
											returnFileType(pic) == "svg" ? (
												<>
													{timeline?.makeCopy && (
														<div
															className=" primary-gray d-flex  "
															// style={{ marginLeft: "50px" }}
														>
															<Tooltip
																title="Download"
																color={"#0eaaa5"}
																key={"download"}
															>
																<a
																	href={pic}
																	target={"_blank"}
																	rel="noreferrer"
																>
																	<DownloadOutlined className=" h4 cursor-pointer" />
																</a>
															</Tooltip>
														</div>
													)}
													<img
														src={`${pic}`}
														onError={(e) => {
															e.target.onerror = null;
															e.target.src = `${process.env.PUBLIC_URL}user.png`;
														}}
														alt=""
														style={{
															maxWidth: "100%",
															maxHeight: "100%",
															objectFit: "contain",
														}}
													/>
												</>
											) : (
												<>
													{timeline?.makeCopy && (
														<div
															className=" primary-gray d-flex  "
															style={{ marginLeft: "50px" }}
														>
															<Tooltip
																title="Download"
																color={"#0eaaa5"}
																key={"download"}
															>
																<a
																	href={pic}
																	target={"_blank"}
																	rel="noreferrer"
																>
																	<DownloadOutlined className=" h4 cursor-pointer" />
																</a>
															</Tooltip>
														</div>
													)}
													{[
														"pdf",
														"csv",
														"xslx",
														"docx",
														"mp4",
														"webm",
														"mp3",
													]?.includes(getFileExt(pic)) ? (
														<FileViewer
															fileType={getFileExt(pic)}
															filePath={pic}
														/>
													) : (
														MP3.includes(getFileExt(pic)) && (
															<ReactPlayer
																width="100%"
																height={isMobile ? "100%" : "400px"}
																url={pic}
																controls
															/>
														)
													)}
												</>
											)}
											{/* {returnFileType(pic) == "pdf" && (
                      <embed src={`${pic}`} width="100%" height="600px" />

                      // </iframe>
                    )} */}
										</div>
									))}

									{timeline?.youtubeUrl?.map((url) => (
										<div className="mx-auto" style={{ maxHeight: "600px" }}>
											<ReactPlayer
												width="100%"
												height={isMobile ? "100%" : 600}
												url={url}
											/>
										</div>
									))}
								</Carousel>
							</div>
							{/* ) : (
              <LoadingVideo isLoading={true} />
            )} */}
							<div className="row">
								{timeline?.description !== "undefined" && (
									<p className="font-light-gray ___render__html">
										{ReactHtmlParser(
											ReactHtmlParser(timeline?.description)
										)?.[0] === "[object Object]"
											? ReactHtmlParser(timeline?.description)
											: ReactHtmlParser(ReactHtmlParser(timeline?.description))}
									</p>
								)}
								{timeline?.googleData?.map((i) => {
									return (
										<a href={i} target="_blank">
											{i}
										</a>
									);
								})}
								{timeline?.dropboxData?.map((i) => {
									return (
										<a href={i} target="_blank">
											{i}
										</a>
									);
								})}
							</div>
							{!profileToken && (
								<div className="row mt-3">
									<div className="col-md-12 mb-0">
										<Tooltip title="Like" color={"#0eaaa5"} key={"1"}>
											{like ? (
												<LikeFilled
													className=" h4 mb- 0"
													onClick={() => unLikeTimeline(timeline?.id)}
												/>
											) : (
												<LikeOutlined
													className="h4 mb-0"
													onClick={() => likeTimeline(timeline?.id)}
												/>
											)}
										</Tooltip>
										{/* <Tooltip title="Watch" color={"#0eaaa5"} key={"2"}>
                    {watch ? (
                      <EyeFilled
                        className="h4 mx-3"
                        onClick={() => unWatchTimeline(timeline?.id)}
                      />
                    ) : (
                      <EyeOutlined
                        className="h4 mx-3"
                        onClick={() => watchTimeline(timeline?.id)}
                      />
                    )}
                  </Tooltip> */}

										<Tooltip title="Favourite" color={"#0eaaa5"} key={"3"}>
											{fav ? (
												<StarFilled
													className="h4 mx-3"
													onClick={() => unFavTimelne(timeline?.id)}
												/>
											) : (
												<StarOutlined
													className="h4 mx-3"
													onClick={() => favTimeline(timeline?.id)}
												/>
											)}
										</Tooltip>
									</div>
								</div>
							)}
							<div className="row mt-3">
								{/* <Collapse defaultActiveKey={["1"]}> */}
								<Comment
									timeline={timeline}
									timelineId={timelineId}
									setTimelineId={setTimelineId}
									limit={commentLimit}
									setLimit={setCommentLimit}
									commentId={commentId}
									setCommentId={setCommentId}
									comments={comments}
									fetchComment={fetchComment}
									activeTimeline={activeTimeline}
									setActiveTimeline={setActiveTimeline}
									userData={userData}
									studioMembers={studioMembers}
									studios={studios}
									profileToken={profileToken}
								/>
							</div>
						</Card>
					</Wrapper>
				</div>
			</div>
		)
	);
};

export default Post;
