import React, { useEffect, useState } from "react";
import Api from "../../services/auth";
import { notify } from "../../utils";
export const ViewProfileContext = React.createContext({});
/**
 *
 * @param {*} param0 component
 * @returns current user info provider
 */
const ViewProfileProvider = ({ children }) => {
  const [profileToken, setProfileToken] = useState("");
  const [userId, setUserId] = useState("");
  const [viewUser, setViewUser] = useState("")
  const [profileLoading, setProfileLoading] = useState(false)

  const genrateToken = async (id) => {
    try {

      setProfileLoading(true)
      const res = await Api.genrateProfileToken(id);
      setProfileToken(res?.data?.tokens);
      setProfileLoading(false)
      
      setViewUser(res?.data?.user)
    }
    catch(err) {
      notify("error", err)
      setProfileLoading(false)

    }
  };

  useEffect(() => {
    if(userId)
    genrateToken(userId);
  }, [userId]);

  return (
    <ViewProfileContext.Provider value={{ profileToken, setUserId, viewUser,setProfileToken ,setViewUser,profileLoading, setProfileLoading}}>
      {children}
    </ViewProfileContext.Provider>
  );
};

export default ViewProfileProvider;
