import React from "react";
import { Form, Input } from "antd";
import { useState } from "react";
import { Button } from "../../../../components/_shared";
import Loading from "../../../../components/_shared/Loader/Loading";
import profile from "../../../../services/profile";
import { notify } from "../../../../utils";

const Redeem = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [form] = Form.useForm();

	const onRedeem = async (values) => {
		profile
			.redeemCoupon(values)
			.then((res) => {
				console.log("res: ", res);
				notify("success", "Coupon redeem successfully");
				form.resetFields();
			})
			.catch((err) => {
				setIsLoading(false);
				notify("error", JSON.stringify(err));
			});
	};

	return (
		<div className="container bg-white p-5">
			<Loading isLoading={isLoading} />

			<Form
				form={form}
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600, margin: "auto" }}
				initialValues={{ remember: true }}
				onFinish={onRedeem}
				autoComplete="off"
			>
				<Form.Item
					label="Coupon Code"
					name="couponCode"
					rules={[{ required: true, message: "Please enter coupon." }]}
				>
					<Input
						onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
					/>
				</Form.Item>

				<div className="col-md-12 mt-4 ">
					<div className="d-flex justify-content-center">
						<Button
							htmlType="submit"
							lite
							// style={{ marginLeft: isMobile ? 0 : 20 }}
							title="Redeem"
							className=""
						/>
					</div>
				</div>
			</Form>
		</div>
	);
};

export default Redeem;
