import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Tabs, Form, Upload, Button, Select,DatePicker } from "antd";
import ModalWrapper from "../../../../components/Modal";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";

// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import {
  DatePickerField,
  InputField,
  TextAreaField,
} from "../../../../components/_shared";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { apiPost } from "../../../../utils/axios";
import { notify, SERVER_URL } from "../../../../utils";
import moment from "moment";
const { TabPane } = Tabs;
const { Option } = Select;

const TimelineModal = ({
  visible,
  setVisible,
  onSubmitTimeline,
  timelineLoading,
  setHideComment,
  setHideMakeCopy,
  setHideShare,
  description,
  setDescription,
  studios,
  award,
}) => {
  const [defaultActiveKey, setActivekey] = useState("1");
  const [formRef] = Form.useForm();
  const [openPicker, data] = useDrivePicker();
  const [googleData, setGoogleData] = useState([]);
  const [dropboxData, setDropboxData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const[mewdata,setMewdate] =useState(false)

  useEffect(() => {
    formRef?.setFieldsValue({
      date: moment(new Date()),
    });
  }, []);
  const handleOpenPicker = () => {
    openPicker({
      clientId:
        "639875352020-f19nq5ctpllqfuncmalp53lg2q8439mf.apps.googleusercontent.com",
      developerKey: "AIzaSyAWkJbOK5PsGBJSAF_Q_Aq8LU558_2YoHk",
      viewId: "DOCS",

      setIncludeFolders: true,
      setSelectFolderEnabled: true,

      token: data?.access_token,

      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,

      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        } else {
          setGoogleData(googleData?.concat(data?.docs?.map((i) => i?.url)));
        }
      },
    });
  };

  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <div className="row">
          <div className="col-md-12">
            <h3>{award ? "Award" : "New Post"}</h3>
          </div>
          <div className="col-md-12">
            <Divider />
          </div>
          <div className="col-md-12">
            <Form
              ref={formRef}
              layout="vertical"
              onFinish={(e) => {
                onSubmitTimeline(e, googleData, dropboxData);
              }}
            >
              <Row gutter={16}>
                {award && (
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                    <img
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "contain",
                      }}
                      src="https://movitanz.com/upload/static/movitanz.png"
                    />
                  </Col>
                )}
                <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your title.",
                      },
                    ]}
                  >
                    <InputField className="font-600" placeholder="Title" />
                  </Form.Item>
                </Col>

                {!award && (
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                    <Form.Item name="date" label="Timeline Date">
                      <DatePickerField
                        disabledDate={(current) => {
                          return (
                            current && current < moment().subtract(1, "day")
                          );
                        }}
                        defaultValue={moment(new Date())}
                        format="DD/MM/YYYY"
                        type="date"
                      />
                    </Form.Item>
                  </Col>
                )}

                {/* <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                  <Form.Item
                    name="signupCode"
                    label="Studio"
                    rules={[
                      {
                        required: true,
                        message: "Please select studio.",
                      },
                    ]}
                    placeholder = "Studios"
                  >
                    <Select
                 
                    >
                      {studios.map((stu, index) => (
                        <Option value={stu?.signupCode}>{stu?.studioName}</Option>
                      ))}
                    
                    </Select>
                  </Form.Item>
                </Col> */}

                <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                  <Form.Item label="Description ">
                    <ReactQuill
                      value={description}
                      onChange={(e) => setDescription(e)}
                    />
                  </Form.Item>
                </Col>

                {!award && (
                  <>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item name="videos">
                        <Upload
                          multiple={false}
                          previewFile={true}
                          beforeUpload={() => false}
                          accept="video/mp4,video/x-m4v,video/*"
                          // onChange = {onUpload}
                        >
                          <Button
                            type="primary"
                            size={"large"}
                            icon={<UploadOutlined className="h4" />}
                          >
                            Upload Videos
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item name="files">
                        <Upload
                          // accept={"image/*"}
                          multiple={false}
                          previewFile={true}
                          beforeUpload={() => false}
                        >
                          <Button
                            type="primary"
                            size={"large"}
                            icon={<UploadOutlined className="h4" />}
                          >
                            Upload Media{" "}
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    {/* <Col
                      style={{ marginBottom: 20 }}
                      xs={24}
                      sm={24}
                      md={24}
                      xl={24}
                      xxl={24}
                      span={24}
                    >
                      <Button
                        type="primary"
                        onClick={handleOpenPicker}
                        size={"large"}
                        icon={<UploadOutlined className="h4" />}
                      >
                        Google Drive
                      </Button>

                      {googleData?.map((i) => {
                        return (
                          <div
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginTop: 7,
                              width: "50%",
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <div>{i}</div>
                            <div style={{ marginLeft: 15 }}>
                              <DeleteOutlined
                                onClick={async () => {
                                  let remove = googleData?.filter(
                                    (id) => id !== i
                                  );
                                  setGoogleData(remove);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Col> */}
                    <Col
                      style={{ marginBottom: 20 }}
                      xs={24}
                      sm={24}
                      md={24}
                      xl={24}
                      xxl={24}
                      span={24}
                    >
                      <DropboxChooser
                        appKey={"p3wkker2jasirst"}
                        success={(files) => {
                          setDropboxData(
                            dropboxData?.concat(files?.map((i) => i?.link))
                          );
                        }}
                        multiselect={true}
                      >
                        <Button
                          type="primary"
                          size={"large"}
                          className="dropbox-button"
                          icon={<UploadOutlined className="h4" />}
                        >
                          Dropbox
                        </Button>
                      </DropboxChooser>
                      {dropboxData?.map((i) => {
                        return (
                          <div
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginTop: 7,
                              width: "50%",
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <div>{i}</div>
                            <div style={{ marginLeft: 15 }}>
                              <DeleteOutlined
                                onClick={async () => {
                                  let remove = dropboxData?.filter(
                                    (id) => id !== i
                                  );
                                  setDropboxData(remove);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                    <Form.List name="youtubeUrls" className="mb-0">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }, index) => (
                              <>
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  xl={24}
                                  xxl={24}
                                  span={24}
                                >
                                  <Form.Item
                                    style={{ marginBottom: "15px" }}
                                    {...restField}
                                    name={[name, "youtubeUrl"]}
                                    fieldKey={[fieldKey, "youtubeUrl"]}
                                    label={`Youtube Url ${index + 1}`}
                                  >
                                    <InputField placeholder="" />
                                  </Form.Item>
                                  <div
                                    className="float-right "
                                    style={{
                                      fontSize: "18px",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    <DeleteOutlined
                                      className="cursor-pointer"
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                  {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                                </Col>
                              </>
                            )
                          )}
                          <Col
                            xs={20}
                            sm={20}
                            md={20}
                            xl={20}
                            xxl={20}
                            span={20}
                            className=""
                          >
                            <Form.Item>
                              <div className="">
                                <div
                                  className=" cursor-pointer d-flex align-content-center"
                                  onClick={() => add()}
                                >
                                  <PlusCircleOutlined className="mt-1 mx-1" />
                                  <span>Add Youtube Url</span>
                                </div>
                              </div>
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Form.List>
                    <Form.List name="vimeoUrls">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }, index) => (
                              <>
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  xl={24}
                                  xxl={24}
                                  span={24}
                                >
                                  <Form.Item
                                    style={{ marginBottom: "15px" }}
                                    {...restField}
                                    name={[name, "vimeoUrl"]}
                                    fieldKey={[fieldKey, "vimeoUrl"]}
                                    label={`Vimeo Url ${index + 1}`}
                                  >
                                    <InputField placeholder="" />
                                  </Form.Item>
                                  <div
                                    className="float-right "
                                    style={{
                                      fontSize: "18px",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    <DeleteOutlined
                                      className="cursor-pointer"
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                  {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                                </Col>
                              </>
                            )
                          )}
                          <Col
                            xs={20}
                            sm={20}
                            md={20}
                            xl={20}
                            xxl={20}
                            span={20}
                            className=""
                          >
                            <Form.Item>
                              <div className="">
                                <div
                                  className=" cursor-pointer d-flex align-content-center"
                                  onClick={() => add()}
                                >
                                  <PlusCircleOutlined className="mt-1 mx-1" />
                                  <span>Add Vimeo Url</span>
                                </div>
                              </div>
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Form.List>
                    {/* <button onClick={handleOpenPicker} >Open</button> */}
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item name="hideComment">
                        <Checkbox
                          onChange={(e) => setHideComment(e?.target?.checked)}
                        />{" "}
                        <span className="mx-3 h5">Hide comments</span>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item name="hideShare">
                        <Checkbox
                          onChange={(e) => setHideShare(e?.target?.checked)}
                        />
                        <span className="mx-3 h5">
                          Hide who the item is shared with
                        </span>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        name="makeCopy"
                        onChange={(e) => setHideMakeCopy(e?.target?.checked)}
                      >
                        <Checkbox />
                        <span className="mx-3 h5">
                          Allow people to make a copy
                        </span>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item name="newdatacekh" >
                        <Checkbox
                          onChange={(e) => setMewdate(e?.target?.checked)}
                        />{" "}
                        <span className="mx-3 h5">Schedule post to delete</span>
                      </Form.Item>
                    </Col>
                    {mewdata == true ?
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                  <Form.Item name="timlineLastDate" rules={[
                        {
                          required: true,
                          message: "Date is mandatory",
                        }
                      ]} >
                    <DatePickerField
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </Form.Item>
                </Col>:''}

                  </>
                )}
              </Row>

              <div
                className="center-column"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  htmlType="submit"
                  size={"large"}
                  type="primary"
                  className="rounded "
                  disabled={timelineLoading ? true : false}
                  // style = {{heigth : "200px"}}
                >
                  <h5 className="font-white">
                    {timelineLoading ? "Uploading" : "Save Post"}
                  </h5>
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default TimelineModal;
