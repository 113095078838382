import { Form, Tabs, Button } from "antd";
import React from "react";
import { useState } from "react";
import ModalWrapper from "../../../../components/Modal";
import { InputField } from "../../../../components/_shared";

const { TabPane } = Tabs;

const AddGroupModal = ({ visible, setVisible,onAddGroup }) => {
  const [defaultActiveKey, setActivekey] = useState("1");
  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-center">
            <h3>Add Your Group</h3>
          </div>
          <Form
            layout="vertical"
            className="mt-4"
            className="row mt-4"
            // style={{ width: "100%" }}
            onFinish={onAddGroup}
          >
            <div className="col-md-12">
              <Form.Item
                name="gname"
                label="Group Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter you Group name.",
                  },
                ]}
              >
                <InputField className="font-600" placeholder="Group Name" />
              </Form.Item>
            </div>

            <div
              className="center-column col-md-12"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                htmlType="submit"
                size={"large"}
                type="primary"
                className="rounded "
                // style = {{heigth : "200px"}}
              >
                <h5 className="font-white"> Add Group </h5>
              </Button>
            </div>
            {/* <div className="col-md-12">
          <Tabs
            activeKey={String(defaultActiveKey)}
            onChange={(e) => {
              setActivekey(e);
            }}
            // defaultActiveKey={defaultActiveKey}
            type="line"
            tabBarGutter={0}
          >
            <TabPane
              className={defaultActiveKey == 1 ? "mb-4" : ""}
              tab={<div className="tab_tabName">All</div>}
              key="1"
            />

            <TabPane
              className={defaultActiveKey == 2 ? "mb-4" : ""}
              tab={<div className="tab_tabName">In this group</div>}
              key="2"
            />

            <TabPane
              className={defaultActiveKey == 3 ? "mb-4" : ""}
              tab={<div className="tab_tabName">Not in this group</div>}
              key="3"
            />
          </Tabs>
        </div> */}
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddGroupModal;
