import React from "react";
import { Route, Navigate } from "react-router-dom";
import { isLogin } from "../utils";
import { ROUTES } from "./constant";
/**
 *
 * @param {*} param0   component of page
 * @returns  public route
 */
const PublicRoute = ({ component: Component, restricted,...props }) => {
  return isLogin() && restricted ? (
    <Navigate replace to={ROUTES.TIMELINE} />
  ) : (
    <Component {...props} />
  );
};

export default PublicRoute;
