import { call, put, takeLatest } from "redux-saga/effects";

import authActionTypes from "../actionTypes/auth";
import authAPI from "../../services/auth";
import { notify } from "../../utils";
import profile from "../../services/profile";

function* signUp({ payload }) {
	try {
		const res = yield call(authAPI.signup, payload.form);

		if (res.status === 201) {
			notify("success", "Signup success.");
			payload.navigate("/signin");
			yield put({ type: authActionTypes.SIGNUP_SUCCESS });
		}
	} catch (err) {
		notify("error", JSON.stringify(err));
		yield put({ type: authActionTypes.SIGNUP_FAILED });
	}
}

function* login({ payload }) {
	try {
		const res = yield call(authAPI.signin, payload.form);

		if (res.status === 200) {
			if (payload?.adminLogin) {
				if (res?.data?.user?.role !== "admin") {
					yield put({ type: authActionTypes.LOGIN_FAILED });

					return notify("error", "Incorrect email or password");
				}
				notify("success", "Signin success");
				localStorage.setItem("authToken", res.data.tokens?.access?.token);
				if (res?.data?.user?.role === "admin") payload.navigate("/manage-user");
				yield put({ type: authActionTypes.LOGIN_SUCCESS });
			} else {
				if (res?.data?.user?.role === "admin") {
					yield put({ type: authActionTypes.LOGIN_FAILED });
					return notify("error", "Invalid email and password");
				}
				if (!res?.data?.user?.isAccountActive) {
					yield put({ type: authActionTypes.LOGIN_FAILED });
					return notify(
						"error",
						"Your account has been suspended. please contact to owner."
					);
				}

				notify("success", "Signin success");
				localStorage.setItem("authToken", res.data.tokens?.access?.token);
				localStorage.setItem("lastLogin", res.data.user?.lastLogin === null ?  res.data.user?.lastLogin : 'noValue');
				payload.navigate("/timeline");
				yield put({ type: authActionTypes.LOGIN_SUCCESS });
			}
		}
	} catch (err) {
		notify("error", JSON.stringify(err));
		yield put({ type: authActionTypes.LOGIN_FAILED });
	}
}

function* updateProfile({ payload }) {
	try {
		const res = yield call(profile.updateMyProfile, payload);

		if (res.status === 200) {
			if (!(payload?.isAutoRenew === false || payload?.isAutoRenew === true))
				notify("success", "Saved");
			yield put({ type: authActionTypes.UPDATE_PROFILE_SUCCESS });
		}
	} catch (err) {
		notify("error", JSON.stringify(err));
		yield put({ type: authActionTypes.UPDATE_PROFILE_FAILURE });
	}
}

function* dragDropGroup({ payload }) {
	try {
		const res = yield call(authAPI.dragGroup, payload);
		if (res.status === 200) {
			if (!(payload?.isAutoRenew === false || payload?.isAutoRenew === true))
				notify("success", "Saved");
			yield put({ type: authActionTypes.DRAG_DROP_SUCCESS });
		}
	} catch (err) {
		notify("error", JSON.stringify(err));
		yield put({ type: authActionTypes.DRAG_DROP_FAILED });
	}
}

export function* watchAuth() {
	yield takeLatest(authActionTypes.SIGNUP_INIT, signUp);
	yield takeLatest(authActionTypes.LOGIN_INIT, login);
	yield takeLatest(authActionTypes.UPDATE_PROFILE_INIT, updateProfile);
	yield takeLatest(authActionTypes.DRAG_DROP_INIT, dragDropGroup);
}
