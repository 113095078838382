import {
  CloseOutlined,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Card, Select } from "antd";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, InputField } from "../../../../components/_shared";
import { ViewProfileContext } from "../../../../context/viewProfile";
import portfolio from "../../../../services/portfolio";
import { notify, SERVER_URL } from "../../../../utils";
import Loading from "../../../../components/_shared/Loader/Loading";
import moment from "moment";
import { CreateFolderModal, FolderListModal } from ".";
import { UserContext } from "../../../../context/userInfo";
import { PortfolioWraaper } from "./component";
const { Option } = Select;

const SharedWithMe = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [searchField, setSearchField] = useState("");
  const { profileToken } = useContext(ViewProfileContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [folders, setFolders] = useState([]);
  const [showFolderList, setShowFolderList] = useState(false);
  const [portfolioId, setPortfolioId] = useState("");
  const [singleStudio, setSingleStudio] = useState("");
  const { userData, getUserinfo } = useContext(UserContext);

  const navigate = useNavigate();

  if (!profileToken?.access?.token && userData?.role === "admin") {
    navigate("/manage-user");
  }

  useEffect(() => {
    getUserinfo();
    fetchShareWithMe();
    getFolder();
  }, []);

  const fetchShareWithMe = () => {
    setIsLoading(true);
    portfolio
      .getShareMyPortfolio(profileToken?.access?.token)
      .then((res) => {
        setPortfolios(res?.data?.portfolios);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notify("error", JSON.stringify(err));
      });
  };

  const onCreateFolder = async (e) => {
    try {
      setIsLoading(true);
      const res = await portfolio.createFolder({
        folderName: e?.folderName,
      });
      if (res?.status === 200) {
        setIsLoading(false);
        setShowCreate(!showCreate);
        setTimeout(() => {
          getFolder();
        }, 100);
      }
    } catch (err) {
      notify("error", JSON.stringify(err));
    }
  };

  const getFolder = async () => {
    try {
      setIsLoading(true);
      const res = await portfolio.getFolder(profileToken?.access?.token);
      if (res?.status === 200) {
        setFolders(res?.data?.folders);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      notify("error", JSON.stringify(err));
    }
  };

  const onMove = (pid, fid) => {
    portfolio.moveToFolder(pid, fid).then(res => {
      if (res?.status === 200) {
        fetchShareWithMe();
        getFolder();
        setShowFolderList(!showFolderList);
      }
    }).catch(err => {
      notify("error", err);
    });
  };

  const onHide = (payload) => {
    portfolio.hideUnhide(payload).then(res => {
      if (res?.status === 200) {
        fetchShareWithMe();
      }
    }).catch(err => {
      notify("error", err);
    });
  };

  return (
    <div className="row">
      <Loading isLoading={isLoading} />

      {showCreate && (
        <CreateFolderModal
          onCreateFolder={onCreateFolder}
          visible={showCreate}
          setVisible={setShowCreate}
        />
      )}
      {showFolderList && (
        <FolderListModal
          folders={folders}
          onMove={onMove}
          pid={portfolioId}
          visible={showFolderList}
          setVisible={setShowFolderList}
        />
      )}
      <div className="col-md-10 mb-3">
        <div className="row">
          {folders?.map((folder) => (
            <div className="col-md-3 mt-2" onClick={() => navigate(`/folder/${folder?.id}/details`)}>
              <Card
                className=" shadow cursor-pointer"
                // extra={<DeleteOutlined className = "h6 cursor-pointer text-danger"/>}
                style={{
                  padding: "1px !important",
                  width: "200px",
                  backgroundColor: "#00a1a5",
                  borderRadius: "9px",
                  transition: "box-shadow 0.25s ease-in-out",
                  border: "1px solid #fff",
                  color: "#fff",
                  fontFamily: "Poppins",
                }}
              >
                <div className="row">
                  <span className="h6" style={{ fontWeight: 600 }}>
                    {folder?.folderName}
                  </span>
                  <span className="">
                    {folder?.portfolio?.length} portfolios
                  </span>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
      {!profileToken && (
        <div className="col-md-2 d-flex justify-content-end align-content-center mb-2">
          <Button
            lite
            title="New Folder"
            onClick={() => setShowCreate(!showCreate)}
          />
        </div>
      )}<PortfolioWraaper>
        <div className="row">
          <InputField
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            style={{ width: "350px" }}
          />
          <Select
            onChange={(e) => {
              // setDate("");
              setSingleStudio(e);
            }}
            style={{ width: "200px" }}
            showSearch
            placeholder="Filter Shared with me"
            optionFilterProp="children"
            value={singleStudio}
          >
            <Option key="">Filter</Option>
            <Option key="hidden">Show hidden</Option>
          </Select>
        </div>
      </PortfolioWraaper>
      {portfolios
        ?.filter((portfolio) =>
          `${portfolio?.title}`
            .toLowerCase()
            .includes(searchField.toLowerCase())
        )
        ?.map((portfolio, index) => (
          singleStudio === "hidden"
            ? portfolio?.hidden?.includes(userData?.id) == true
              ? (<div className="row p-4 bg-secondary-gray mt-3  border-20">
                <div className="col-md-1">
                  <img
                    src={`${portfolio?.portfolioPic}`}
                    width={"80px"}
                    alt=""
                  />
                </div>
                <div className="col-md-4 flex-column ">
                  <div>
                    <h5
                      className="cursor-pointer"
                      onClick={() => {
                        if (!profileToken)
                          navigate(`/portfolio/${portfolio?.id}/share`);
                      }}
                    >
                      {" "}
                      {portfolio?.title}
                    </h5>
                  </div>
                  <div>
                    <span>Created: {moment(portfolio?.date).format("LL")}</span>
                  </div>
                  <div>
                    <span>Created By: {portfolio?.name}</span>
                  </div>
                </div>
                <div className="col-md-5 flex-column">

                  {!profileToken && (
                    <div className="d-flex align-content-center mt-2">
                      <span
                        className="text-success h6 cursor-pointer"
                        onClick={() => { setPortfolioId(portfolio?.id); setShowFolderList(!showFolderList); }}
                      >
                        Move To
                      </span>
                    </div>
                  )}

                </div>
                <div className="col-md-2 flex-column">
                  {!profileToken && (
                    <div className="d-flex align-content-center mt-2">
                      <span
                        className="text-success h6 cursor-pointer"
                        onClick={async () => {
                          await onHide({
                            pid: portfolio?.id,
                            type: "unhide",
                          });
                        }}
                      >  Unhide
                      </span>
                    </div>
                  )}
                </div>
              </div>) : ''
            : portfolio?.hidden?.includes(userData?.id) == false
              ? (<div className="row p-4 bg-secondary-gray mt-3  border-20">
                <div className="col-md-1">
                  <img
                    src={`${portfolio?.portfolioPic}`}
                    width={"80px"}
                    alt=""
                  />
                </div>
                <div className="col-md-4 flex-column ">
                  <div>
                    <h5
                      className="cursor-pointer"
                      onClick={() => {
                        if (!profileToken)
                          navigate(`/portfolio/${portfolio?.id}/share`);
                      }}
                    >
                      {" "}
                      {portfolio?.title}
                    </h5>
                  </div>
                  <div>
                    <span>Created: {moment(portfolio?.date).format("LL")}</span>
                  </div>
                  <div>
                    <span>Created By: {portfolio?.name}</span>
                  </div>
                </div>
                <div className="col-md-5 flex-column">

                  {!profileToken && (
                    <div className="d-flex align-content-center mt-2">
                      <span
                        className="text-success h6 cursor-pointer"
                        onClick={() => { setPortfolioId(portfolio?.id); setShowFolderList(!showFolderList); }}
                      >
                        Move To
                      </span>
                    </div>
                  )}

                </div>
                <div className="col-md-2 flex-column">
                  {!profileToken && (
                    <div className="d-flex align-content-center mt-2">
                      <span
                        className="text-success h6 cursor-pointer"
                        onClick={async () => {
                          await onHide({
                            pid: portfolio?.id,
                            type: "hide",
                          });
                        }}
                      >
                        Hide
                      </span>
                    </div>
                  )}
                </div>
              </div>) : ''

        ))}
    </div>
  );
};

export default SharedWithMe;
