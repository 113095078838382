import React from "react";

import { Layout } from "antd";

const { Content } = Layout;

const AppContent = ({ children }) => {
  return <Content style={{ marginTop: "64px", paddingBottom : "30px" }}>{children}</Content>;
};

export default AppContent;
