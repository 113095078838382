import { Col, Row } from "antd";
import styled from "styled-components";
// import BgImage from "../../../assets/images/loginbg.png";
export const SignupWrapper = styled(Row).attrs({
  className: "signin-form",
})`
  background-image: ${(props) => (props.isMobile ? "#fff" : `#fff`)};
  background-size: cover;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  min-height: ${(props) => (props.isMobile ? "unset" : "calc(100vh - 64px)")};
  .img-side {
    width: 100%;
    object-fit: cover;
  }
  .signup_main_container {
    background-color: #ffff;
    height: ${(props) => (props.isMobile ? "unset" : "89vh")};
    border-radius: ${(props) => (props.isMobile ? "unset" : "40px")};
    box-shadow: ${(props) =>
      props.isMobile ? "unset" : " 0px 4px 30px rgb(0 0 0 / 5%)"};
    justify-content: center;
    align-items: center;
    display: flex;
    padding-bottom: 30px;
    padding-top: 30px;
    .title-heading {
      font-family: Poppins;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 54px;
      letter-spacing: 0.06em;
      color: #0c0e17;
      position: relative;
    }
    .block {
      text-align: left;
      padding-left: 60px;
      background: white;
      padding-right: 60px;

      .bg-white {
        background: white;
      }
      .bg-blue {
        background: #0eaaa5;
      }
      .card {
        cursor: pointer;
        border: 1px solid #0eaaa5;
        text-align: center;
        height: 180px;
        // width: 260px;
        padding: 50px;
        border-radius: 20px;
        .title {
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          font-family: Poppins;
          margin: 15px 0px;
          letter-spacing: 0.06em;
        }
        .color-blue {
          color: #0eaaa5;
        }
        .color-white {
          color: white;
        }
      }
      ._start-text {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.06em;
        color: #0c0e17;
        font-family: Poppins;
      }
    }
  }
  .continue {
    border-radius: 20px;
    color: white;
    background: #0eaaa5;
    border: none;
    width: 150px;
    height: 60px;
    margin-top: 50px;
    padding: 14px 30px;
    // text-transform: uppercase;
  }
  .already-register {
    margin-top: 20px;
    font-size: 14px;
    line-height: 21px;
    font-family: Poppins;
    color: #0c0e17;
  }
`;
export const Column = styled(Col).attrs({
  className: "center-column",
})`
  // .block {
  //   text-align: left;
  //   padding-left: 60px;
  //   background:white;
  //   padding-right: 60px;
  //   .bg-white {
  //     background: white;
  //   }
  //   .bg-blue {
  //     background: #0eaaa5;
  //   }
  //   .card {
  //     cursor: pointer;
  //     border: 1px solid #0eaaa5;
  //     text-align: center;
  //     height: 180px;
  //     // width: 260px;
  //     padding: 50px;
  //     border-radius: 20px;
  //     .title {
  //       font-size: 16px;
  //       line-height: 20px;
  //       text-align: center;
  //       font-family: Poppins;
  //       margin: 15px 0px;
  //       letter-spacing: 0.06em;
  //     }
  //     .color-blue {
  //       color: #0eaaa5;
  //     }
  //     .color-white {
  //       color: white;
  //     }
  //   }
  //   ._start-text {
  //     font-weight: 600;
  //     font-size: 18px;
  //     line-height: 27px;
  //     letter-spacing: 0.06em;
  //     color: #0c0e17;
  //     font-family: Poppins;
  //   }
  // }
  // .title {
  //   font-family: Poppins;
  //   font-style: normal;
  //   font-weight: 900;
  //   font-size: 36px;
  //   line-height: 54px;
  //   letter-spacing: 0.06em;
  //   color: #0c0e17;
  //   position: relative;
  //   .gradient {
  //     position: absolute;
  //     right: 0;
  //     background: linear-gradient(
  //       90deg,
  //       #4668d6 0%,
  //       #4668d6 0.01%,
  //       #974bad 100%
  //     );
  //     width: 140px;
  //     height: 15px;
  //     bottom: 5px;
  //     opacity: 0.7;
  //   }
  // }
`;

export const Container = styled.div`
  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-tab {
   margin: auto;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
    
  }
  .tab_tabName {
    /* width: 15rem; */
    text-align: center;
    font-size: 16px;
    font-family: Poppins;
  }

  .tab_mobile {
    text-align: center;
    font-size: 12px;
    font-family: Poppins;
  }
  .ant-tabs-nav-list {
    font-size: 16px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #363636 !important;
    font-weight: 500;
  }
  .ant-tabs-tab-btn {
    color: #605a5b;
    font-size: 10px;
    font-weight: 400;
  }
`;
