import React, { useEffect } from "react";
import { Form, Row, Col, Button } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { InputField, TextAreaField } from "../../../../components/_shared";

const Notification = ({ onUpdate, notification }) => {
  const [form] = Form.useForm();
  const updateContent = (e) => {
    onUpdate({
      notification: e,
    });
  };

  useEffect(() => {
    if (notification) form.setFieldsValue(notification);
  }, [notification]);

  return (
    <div className="col-md-12">
      <Form form={form} layout="vertical" onFinish={updateContent}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
            <Form.Item
              label="Notification "
              style={{ marginBottom: "15px" }}
              name="notification"
            >
              <InputField />
            </Form.Item>
          </Col>
        </Row>

        <div
          className="center-column"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            htmlType="submit"
            size={"large"}
            type="primary"
            className="rounded "
            // style = {{heigth : "200px"}}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Notification;
