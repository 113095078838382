import { SettingFilled, UsergroupAddOutlined } from "@ant-design/icons";
import { Card, Divider, Menu, Dropdown, Button } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GrayInputField, InputField } from "../../../components/_shared/Input";
import Loading from "../../../components/_shared/Loader/Loading";
import { UserContext } from "../../../context/userInfo";
import { ViewProfileContext } from "../../../context/viewProfile";
import {
  addGroupMemberInit,
  deleteGroupMemberInit,
  fetchJoinedStudioInit,
  joinStudioInit,
  leftStudioInit,
} from "../../../store/actions/studio";
import {
  makeSelectStudio,
  makeSelectStudioLoading,
} from "../../../store/selectors";
import { notify, parseJwt, SERVER_URL } from "../../../utils";
import { joinedUser } from "../../../utils/joinedUser";
import JoinGroupModal from "./components/JoinGroupModal";

const Groups = () => {
  const [showOrg, setShowOrg] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const dispatch = useDispatch();
  const studios = useSelector(makeSelectStudio());
  const { userData, getUserinfo } = useContext(UserContext);
  const [singleStudioData, setSingleStudioData] = useState("");
  const [signupCode, setSignupCode] = useState("");
  const navigate = useNavigate();
  const [groupIndex, setGroupIndex] = useState("");
  const { profileToken } = useContext(ViewProfileContext);
  const isLoading = useSelector(makeSelectStudioLoading());
  const [groupLoading, setGroupLoading] = useState(false);

  if (!profileToken?.access?.token && userData?.role === "admin") {
    navigate("/manage-user");
  }

  useEffect(() => {
    dispatch(
      fetchJoinedStudioInit({ profileToken: profileToken?.access?.token })
    );
    getUserinfo();
  }, []);

  const refreshStudio = () => {
    dispatch(fetchJoinedStudioInit());
  };
  const leftStudio = (code) => {
    dispatch(leftStudioInit(code));
    // setTimeout(() => {
    //   // refreshStudio();
    // }, 1000);
  };

  const joinStudio = (code) => {
    dispatch(joinStudioInit(code));
    // setTimeout(() => {
    //   // refreshStudio();
    // }, 1000);
  };

  const joinGroup = (gid, userId) => {
    dispatch(
      addGroupMemberInit({
        data: { userId },
        gid,
        isLoading: true,
        setIsLoading: setGroupLoading,
      })
    );
    // setTimeout(() => {
    //   // refreshStudio();
    // }, 1000);
  };

  const leftGroup = (gid, mid) => {
    dispatch(
      deleteGroupMemberInit({
        gid,
        mid,
        isLoading: true,
        setIsLoading: setGroupLoading,
      })
    );
    // setTimeout(() => {
    //   // refreshStudio();
    // }, 1000);
  };

  return (
    <>
      <JoinGroupModal
        userData={userData}
        studio={studios[groupIndex]}
        setStudio={setSingleStudioData}
        visible={showGroup}
        setVisible={setShowGroup}
        joinGroup={joinGroup}
        leftGroup={leftGroup}
      />
      <Loading isLoading={isLoading || groupLoading ? true : false} />
      <div className='container bg-white p-5'>
        <div className='row'>
          <div className='col-md-12'>
            <h5 className='font-weight-bold'> Organisations and Groups:</h5>
          </div>
          <div className='col-md-12 '>
            <span>
              Connect with new organisations and manage your class groups.
            </span>
          </div>
          {/* {!profileToken && (
            <div
              className="col-md-12 d-flex justify-content-end"
              onClick={() => setShowOrg(!showOrg)}
            >
              <Button type="primary mt-1 f-poppins mt-2 ">
                Add Orgranization
              </Button>
            </div>
          )} */}
          {showOrg && (
            <>
              <div className='col-md-3 mt-2'>
                <InputField
                  placeholder='Signup Code'
                  onChange={(e) => setSignupCode(e?.target.value)}
                />
              </div>
              <div className='col-md-2 d-flex my-auto '>
                <Button
                  type='primary'
                  className='mt-1'
                  onClick={() => {
                    if (!signupCode)
                      return notify("error", "Please enter your signup code.");
                    joinStudio({ signupCode });
                  }}
                >
                  Sign Up
                </Button>
              </div>
            </>
          )}
        </div>

        {studios?.map((studio, index) => (
          <Card
            className='mt-4 shadow'
            // extra={<DeleteOutlined className = "h6 cursor-pointer text-danger"/>}
            key={studio?._id}
            style={{
              width: "100%",
              backgroundColor: "#E3E4E4",
              borderRadius: "20px",
            }}
          >
            {!profileToken && (
              <>
                <div
                  style={{ width: "100%" }}
                  className='d-flex justify-content-end align-items-center'
                >
                  {!profileToken && (
                    <div
                      style={{
                        marginRight: 20,
                        float: "right",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <button
                        onClick={() => {
                          setSingleStudioData(studio);
                          setShowGroup(!showGroup);
                          setGroupIndex(index);
                        }}
                        className='btn btn-primary d-flex align-content-center  rounded '
                      >
                        <UsergroupAddOutlined
                          style={{ marginRight: "10px" }}
                          className=' h4 '
                        />
                        <span className='h4 ml-3 '>Join Group</span>
                      </button>
                    </div>
                  )}
                  {userData?.id !== studio?._id && (
                    <div
                      className='text-danger cursor-pointer'
                      style={{ marginRight: 20 }}
                      onClick={() =>
                        leftStudio({ signupCode: studio?.signupCode })
                      }
                    >
                      Leave Studio
                    </div>
                  )}
                  {userData?.role !== "student" && (
                    <div
                      style={{
                        marginRight: 20,
                        float: "right",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <button
                        onClick={() => navigate("/my-studio")}
                        className='btn btn-primary d-flex align-content-center  rounded '
                      >
                        <span className='h4 ml-3 '>Group Settings</span>
                      </button>
                    </div>
                    // <SettingFilled
                    //   className="h4 cursor-pointer "
                    //   onClick={() => navigate("/my-studio")}
                    // />
                  )}
                </div>
                {/* <div className="d-flex justify-content-end">
                  
                 
                 
                </div> */}
              </>
            )}
            <div className='row'>
              <div className='col-md-3'>
                <img
                  src={`${studio?.studioLogo}`}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = `${process.env.PUBLIC_URL}user.png`;
                  }}
                  alt=''
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              </div>

              <div className='col-md-12 mt-3'>
                <h2 className='h2 '>{studio?.studioName}</h2>{" "}
              </div>

              <div className='col-md-12'>
                <Divider />
              </div>
              {studio?.group?.map(
                (group) =>
                  joinedUser(
                    profileToken?.access?.token
                      ? parseJwt(profileToken?.access?.token)?.sub
                      : userData?.id,
                    group?.members
                  ) && (
                    <>
                      <div className='row' key={group?._id}>
                        <div
                          onClick={() => {
                            navigate(`/timeline?id=${group?._id}`);
                          }}
                          style={{ cursor: "pointer" }}
                          className='col-md-10 col-sm-6 '
                        >
                          <span className='h4'>{group?.groupName}</span>
                        </div>

                        {!profileToken && (
                          <div className='col-md-2 col-sm-6 d-flex justify-content-end '>
                            <span
                              className='text-danger h5 cursor-pointer'
                              onClick={() =>
                                leftGroup(group?._id, userData?.id)
                              }
                            >
                              Leave
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='col-md-12'>
                        <Divider />
                      </div>
                    </>
                  )
              )}
            </div>
          </Card>
        ))}
      </div>
    </>
  );
};

export default Groups;
