import { Table } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "../../components/_shared/Loader/Loading";
import program from "../../services/program";
import { notify } from "../../utils";

const RecievedPayment = () => {
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    program
      .getPurchasedMyProgram()
      .then((res) => {
        if (res?.status === 200) {
          setIsLoading(false)
          setPrograms(res?.data?.program);
        }
      })
      .catch((err) => {
        setIsLoading(false)
        notify("error",JSON.stringify(err));
      });
  }, []);

  const columns = [
    { title: "Program Title", dataIndex: "title", key: "title" },
    { title: "Purchased By", dataIndex: "buyer", key: "buyer" },
    { title: "Price", dataIndex: "price", key: "price" },
    {
      title: "Tax (10%)",
      dataIndex: "tax",
      key: "tax",
    },

    {
      title: "Recieved Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  let data = [];
  let index = 1;

  if (programs?.length)
    for (let i of programs) {
      data.push({
        key: index,
        title: i?.title,
        buyer: i?.name,
        price: <div>${i?.price}</div>,
        tax: <div>${(Number(i?.price) * 10) / 100}</div>,
        amount: <div>${Number(i?.price) - (Number(i?.price) * 10) / 100}</div>,
        status: (
          <span
            className={`${i?.isAmountRecived ? "text-success" : "text-danger"}`}
          >
            {i?.isAmountRecived ? "Paid" : "Pending"}
          </span>
        ),
      });

      index++;
    }

  return (
    <div className="p-5">
      <Loading isLoading = {isLoading}/>
      <Table columns={columns} pagination={true} dataSource={data} />
    </div>
  );
};

export default RecievedPayment;
