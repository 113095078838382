import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import { Container } from "./componet";
import { Outlet, useNavigate } from "react-router-dom";
// import { chat } from "../../../utils";
import { UserContext } from "../../../context/userInfo";
import moment from "moment";
import { isExpired } from "../../../utils/utils";
import { makeSelectStudio } from "../../../store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchJoinedStudioInit } from "../../../store/actions/studio";
const { TabPane } = Tabs;

const setDefaultActive = () => {
  const validActive = ["/timeline", "/portfolio", "/program", "/group"];
  const validIndex = validActive.findIndex(
    (activeKey) => activeKey === window.location.pathname
  );

  return validIndex + 1 ? validIndex + 1 : "1";
};

const HeaderTab = () => {
  const [defaultActiveKey, setActivekey] = useState(setDefaultActive());
  const navigate = useNavigate();
  const { userData, getUserinfo, setUserdata } = useContext(UserContext);
  const dispatch = useDispatch();
  const studios = useSelector(makeSelectStudio());

  useEffect(() => {
    getUserinfo();

    dispatch(fetchJoinedStudioInit());
  }, []);

  // useEffect(() => {
  //   if (studios?.length && userData) {
  //     for (let studio of studios) {
  //       if (studio?.signupCode === userData?.signupCode) {
  //         if (isExpired(moment(studio?.studioMonthExp))) {
  //           navigate("/self-subscription");
  //         }
  //       }
  //     }
  //   }
  // }, [studios, userData]);

  useEffect(async () => {
    if (userData?.role === "studioOwner") {
      // await chat();
    }

    if (userData?.role === "studioOwner") {
      if (isExpired(moment(userData?.studioMonthExp))&&window.location.pathname!=='/payment-details') {
        navigate("/upgrade-storage");
      }
    }

    // if(userData?.role === "student" )  {
    //   if(isExpired(moment(userData?.studioMonthExp)))  {
    //     navigate("/self-subscription")
    //   }

    // }
  }, [userData]);
  return (
    <>
      {/* <Container className="container  px-5 pt-5">
        <div className="row">
          <div className="col-md-5">
            <img
              src={process.env.PUBLIC_URL + "/movitae-logo.png"}
              alt=""
              width="200px"
            />
          </div>
          <div className="col-md-7">
            <Tabs
              activeKey={String(defaultActiveKey)}
              onChange={(e) => {
                setActivekey(e);
                if (e == "1") navigate("/timeline");
                if (e == "2") navigate("/portfolio");
                if (e == "3") navigate("/program");
                if (e == "4") navigate("/group");
              }}
              // defaultActiveKey={defaultActiveKey}
              type="line"
              tabBarGutter={0}
            >
              <TabPane
                className={defaultActiveKey == 1 ? "mb-4" : ""}
                tab={<div className="tab_tabName">TIMELINE</div>}
                key="1"
              />

              <TabPane
                className={defaultActiveKey == 2 ? "mb-4" : ""}
                tab={<div className="tab_tabName">PORTFOLIOS</div>}
                key="2"
              />

              <TabPane
                className={defaultActiveKey == 3 ? "mb-4" : ""}
                tab={<div className="tab_tabName">PROGRAMS</div>}
                key="3"
              />
              <TabPane
                className={defaultActiveKey == 4 ? "mb-4" : ""}
                tab={<div className="tab_tabName">GROUPS</div>}
                key="4"
              />
            </Tabs>
          </div>
        </div>
      </Container> */}
      <div className="pt-3"></div>
      <Outlet />
    </>
  );
};

export default HeaderTab;
