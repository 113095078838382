import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Select } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { InputField } from "../../../../components/_shared";
import AWS from "aws-sdk";
import { notify } from "../../../../utils";

const { Option } = Select;

const S3_BUCKET = "movitanz";
const REGION = "ap-south-1";

AWS.config.update({
	accessKeyId: "AKIAYP3Y7UZOTSVZK5PP",
	secretAccessKey: "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",
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const Guide = ({ onUpdate, guide, setIsLoading }) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState({
    profile: [],
    group: [],
    timeline: [],
    stuTimeline: [],
    createPost: [],
    protfolio: [],
    stuProtfolio: [],
    forum: [],
    shop: [],
  });
  // console.log(files,"filess")
  const updateContent = (e) => {
    onUpdate({
      guide: { ...e, files },
      // files: files,
    });
  };

  useEffect(() => {
    if (guide) {
      form.setFieldsValue(guide);
      setFiles(guide?.files);
    }
  }, [guide]);
  const customRequest = (file, index, type) => {
    setIsLoading(true);
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${type + index}.${file?.name?.split(".").pop()}`,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {})
      .send((err, data) => {
        if (err) {
          setIsLoading(false);
          notify(" upload fail");
        } else {
          setIsLoading(false);
          setFiles({
            ...files,
            [files?.[type]]: files?.[type].push({
              index: index,
              file: `https://movitanz.s3.ap-southeast-2.amazonaws.com/${
                type + index
              }.${file?.name?.split(".").pop()}`,
            }),
          });
        }
      });
  };
  return (
    <div className="col-md-12">
      <Form form={form} layout="vertical" onFinish={updateContent}>
        {() => (
          <div>
            <Row gutter={16}>
              <div className="col-md-12 ">
                <h4>Profile</h4>
              </div>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"profiletitle"}
                  label={"Profile Title"}
                >
                  <InputField placeholder="Profile Title" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"top"}
                  label={"Heading"}
                >
                  <InputField placeholder="Heading" />
                </Form.Item>
              </Col>
              <Form.List name="profile" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            span={24}
                          >
                            <Form.Item
                              style={{ marginBottom: "15px" }}
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              label={`type ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type",
                                },
                              ]}
                            >
                              <Select defaultValue="Select type">
                                {["video", "image", "text", "doc", "link"]?.map(
                                  (i) => {
                                    return <Option value={i}>{i}</Option>;
                                  }
                                )}
                              </Select>
                            </Form.Item>

                            {form?.getFieldValue("profile")?.[index]?.type ===
                            "link" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>
                              </>
                            ) : form?.getFieldValue("profile")?.[index]
                                ?.type !== "text" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>

                                {files?.["profile"]?.filter(
                                  (d) => d?.index === index
                                )?.length > 0 && (
                                  <a
                                    style={{ color: "black" }}
                                    className="lable-head margin-20  ml-10"
                                  >
                                    {
                                      files?.["profile"]?.filter(
                                        (d) => d?.index === index
                                      )?.[0]?.file
                                    }
                                  </a>
                                )}
                                <input
                                  type="file"
                                  // ref={hiddenFileInput}
                                  onChange={(e) => {
                                    customRequest(
                                      e.target.files[0],
                                      index,
                                      "profile"
                                    );
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                                
                              </>
                            )}
                            <div
                              className="float-right "
                              style={{ fontSize: "18px", marginTop: "-10px" }}
                            >
                              <DeleteOutlined
                                className="cursor-pointer"
                                onClick={() => remove(name)}
                              />
                            </div>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </>
                      )
                    )}
                    <Col
                      xs={20}
                      sm={20}
                      md={20}
                      xl={20}
                      xxl={20}
                      span={20}
                      className=""
                    >
                      <Form.Item>
                        <div className="">
                          <div
                            className=" cursor-pointer d-flex align-content-center"
                            onClick={() => add()}
                          >
                            <PlusCircleOutlined className="mt-1 mx-1" />
                            <span>Add Guide</span>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>

              <div className="col-md-12 mt-2 ">
                <h4>Groups</h4>
              </div>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"grouptitle"}
                  label={"Group Title"}
                >
                  <InputField placeholder="Group Title" />
                </Form.Item>
              </Col>
              <Form.List name="group" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            span={24}
                          >
                            <Form.Item
                              style={{ marginBottom: "15px" }}
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              label={`type ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type",
                                },
                              ]}
                            >
                              <Select defaultValue="Select type">
                                {["video", "image", "text", "doc", "link"]?.map(
                                  (i) => {
                                    return <Option value={i}>{i}</Option>;
                                  }
                                )}
                              </Select>
                            </Form.Item>
                            {form?.getFieldValue("group")?.[index]?.type ===
                            "link" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>
                              </>
                            ) : form?.getFieldValue("group")?.[index]?.type !==
                              "text" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>

                                {files?.["group"]?.filter(
                                  (d) => d?.index === index
                                )?.length > 0 && (
                                  <a
                                    style={{ color: "black" }}
                                    className="lable-head margin-20  ml-10"
                                  >
                                    {
                                      files?.["group"]?.filter(
                                        (d) => d?.index === index
                                      )?.[0]?.file
                                    }
                                  </a>
                                )}
                                <input
                                  type="file"
                                  // ref={hiddenFileInput}
                                  onChange={(e) => {
                                    customRequest(
                                      e.target.files[0],
                                      index,
                                      "group"
                                    );
                                  }}
                                  // style={{ display: "none" }}
                                />
                              </>
                            ) : (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                              
                              </>
                            )}
                            <div
                              className="float-right "
                              style={{ fontSize: "18px", marginTop: "-10px" }}
                            >
                              <DeleteOutlined
                                className="cursor-pointer"
                                onClick={() => remove(name)}
                              />
                            </div>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </>
                      )
                    )}
                    <Col
                      xs={20}
                      sm={20}
                      md={20}
                      xl={20}
                      xxl={20}
                      span={20}
                      className=""
                    >
                      <Form.Item>
                        <div className="">
                          <div
                            className=" cursor-pointer d-flex align-content-center"
                            onClick={() => add()}
                          >
                            <PlusCircleOutlined className="mt-1 mx-1" />
                            <span>Add Guide</span>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>

              <div className="col-md-12 mt-2 ">
                <h4>Timeline (For Studio Owner and teacher)</h4>
              </div>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"timelinestudiotitle"}
                  label={"Timeline Title"}
                >
                  <InputField placeholder="Timeline Title" />
                </Form.Item>
              </Col>
              <Form.List name="timeline" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            span={24}
                          >
                            <Form.Item
                              style={{ marginBottom: "15px" }}
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              label={`type ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type",
                                },
                              ]}
                            >
                              <Select defaultValue="Select type">
                                {["video", "image", "text", "doc", "link"]?.map(
                                  (i) => {
                                    return <Option value={i}>{i}</Option>;
                                  }
                                )}
                              </Select>
                            </Form.Item>
                            {form?.getFieldValue("timeline")?.[index]?.type ===
                            "link" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>
                              </>
                            ) : form?.getFieldValue("timeline")?.[index]
                                ?.type !== "text" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>

                                {files?.["timeline"]?.filter(
                                  (d) => d?.index === index
                                )?.length > 0 && (
                                  <a
                                    style={{ color: "black" }}
                                    className="lable-head margin-20  ml-10"
                                  >
                                    {
                                      files?.["timeline"]?.filter(
                                        (d) => d?.index === index
                                      )?.[0]?.file
                                    }
                                  </a>
                                )}
                                <input
                                  type="file"
                                  // ref={hiddenFileInput}
                                  onChange={(e) => {
                                    customRequest(
                                      e.target.files[0],
                                      index,
                                      "timeline"
                                    );
                                  }}
                                  // style={{ display: "none" }}
                                />
                              </>
                            ) : (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                         
                              </>
                            )}
                            <div
                              className="float-right "
                              style={{ fontSize: "18px", marginTop: "-10px" }}
                            >
                              <DeleteOutlined
                                className="cursor-pointer"
                                onClick={() => remove(name)}
                              />
                            </div>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </>
                      )
                    )}
                    <Col
                      xs={20}
                      sm={20}
                      md={20}
                      xl={20}
                      xxl={20}
                      span={20}
                      className=""
                    >
                      <Form.Item>
                        <div className="">
                          <div
                            className=" cursor-pointer d-flex align-content-center"
                            onClick={() => add()}
                          >
                            <PlusCircleOutlined className="mt-1 mx-1" />
                            <span>Add Guide</span>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>

              <div className="col-md-12 mt-2 ">
                <h4>Timeline (For Students)</h4>
              </div>

              <Form.List name="stuTimeline" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            span={24}
                          >
                            <Form.Item
                              style={{ marginBottom: "15px" }}
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              label={`type ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type",
                                },
                              ]}
                            >
                              <Select defaultValue="Select type">
                                {["video", "image", "text", "doc", "link"]?.map(
                                  (i) => {
                                    return <Option value={i}>{i}</Option>;
                                  }
                                )}
                              </Select>
                            </Form.Item>
                            {form?.getFieldValue("stuTimeline")?.[index]
                              ?.type === "link" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>
                              </>
                            ) : form?.getFieldValue("stuTimeline")?.[index]
                                ?.type !== "text" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>

                                {files?.["stuTimeline"]?.filter(
                                  (d) => d?.index === index
                                )?.length > 0 && (
                                  <a
                                    style={{ color: "black" }}
                                    className="lable-head margin-20  ml-10"
                                  >
                                    {
                                      files?.["stuTimeline"]?.filter(
                                        (d) => d?.index === index
                                      )?.[0]?.file
                                    }
                                  </a>
                                )}
                                <input
                                  type="file"
                                  // ref={hiddenFileInput}
                                  onChange={(e) => {
                                    customRequest(
                                      e.target.files[0],
                                      index,
                                      "stuTimeline"
                                    );
                                  }}
                                  // style={{ display: "none" }}
                                />
                              </>
                            ) : (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                             
                              </>
                            )}
                            <div
                              className="float-right "
                              style={{ fontSize: "18px", marginTop: "-10px" }}
                            >
                              <DeleteOutlined
                                className="cursor-pointer"
                                onClick={() => remove(name)}
                              />
                            </div>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </>
                      )
                    )}
                    <Col
                      xs={20}
                      sm={20}
                      md={20}
                      xl={20}
                      xxl={20}
                      span={20}
                      className=""
                    >
                      <Form.Item>
                        <div className="">
                          <div
                            className=" cursor-pointer d-flex align-content-center"
                            onClick={() => add()}
                          >
                            <PlusCircleOutlined className="mt-1 mx-1" />
                            <span>Add Guide</span>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>

              <div className="col-md-12 mt-2 ">
                <h4>Create a Post (For Studio Owner and Teacher)</h4>
              </div>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"posttitle"}
                  label={"Post Title"}
                >
                  <InputField placeholder="Post Title" />
                </Form.Item>
              </Col>
              <Form.List name="createPost" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            span={24}
                          >
                            <Form.Item
                              style={{ marginBottom: "15px" }}
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              label={`type ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type",
                                },
                              ]}
                            >
                              <Select defaultValue="Select type">
                                {["video", "image", "text", "doc", "link"]?.map(
                                  (i) => {
                                    return <Option value={i}>{i}</Option>;
                                  }
                                )}
                              </Select>
                            </Form.Item>
                            {form?.getFieldValue("createPost")?.[index]
                              ?.type === "link" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>
                              </>
                            ) : form?.getFieldValue("createPost")?.[index]
                                ?.type !== "text" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>

                                {files?.["createPost"]?.filter(
                                  (d) => d?.index === index
                                )?.length > 0 && (
                                  <a
                                    style={{ color: "black" }}
                                    className="lable-head margin-20  ml-10"
                                  >
                                    {
                                      files?.["createPost"]?.filter(
                                        (d) => d?.index === index
                                      )?.[0]?.file
                                    }
                                  </a>
                                )}
                                <input
                                  type="file"
                                  // ref={hiddenFileInput}
                                  onChange={(e) => {
                                    customRequest(
                                      e.target.files[0],
                                      index,
                                      "createPost"
                                    );
                                  }}
                                  // style={{ display: "none" }}
                                />
                              </>
                            ) : (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                             
                              </>
                            )}
                            <div
                              className="float-right "
                              style={{ fontSize: "18px", marginTop: "-10px" }}
                            >
                              <DeleteOutlined
                                className="cursor-pointer"
                                onClick={() => remove(name)}
                              />
                            </div>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </>
                      )
                    )}
                    <Col
                      xs={20}
                      sm={20}
                      md={20}
                      xl={20}
                      xxl={20}
                      span={20}
                      className=""
                    >
                      <Form.Item>
                        <div className="">
                          <div
                            className=" cursor-pointer d-flex align-content-center"
                            onClick={() => add()}
                          >
                            <PlusCircleOutlined className="mt-1 mx-1" />
                            <span>Add Guide</span>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>

              <div className="col-md-12 mt-2 ">
                <h4>Portfolios (For Studio Owner and Teacher)</h4>
              </div>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"portfoliotitle"}
                  label={"Portfolio Title"}
                >
                  <InputField placeholder="Portfolio Title" />
                </Form.Item>
              </Col>
              <Form.List name="protfolio" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            span={24}
                          >
                            <Form.Item
                              style={{ marginBottom: "15px" }}
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              label={`type ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type",
                                },
                              ]}
                            >
                              <Select defaultValue="Select type">
                                {["video", "image", "text", "doc", "link"]?.map(
                                  (i) => {
                                    return <Option value={i}>{i}</Option>;
                                  }
                                )}
                              </Select>
                            </Form.Item>
                            {form?.getFieldValue("protfolio")?.[index]?.type ===
                            "link" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>
                              </>
                            ) : form?.getFieldValue("protfolio")?.[index]
                                ?.type !== "text" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>

                                {files?.["protfolio"]?.filter(
                                  (d) => d?.index === index
                                )?.length > 0 && (
                                  <a
                                    style={{ color: "black" }}
                                    className="lable-head margin-20  ml-10"
                                  >
                                    {
                                      files?.["protfolio"]?.filter(
                                        (d) => d?.index === index
                                      )?.[0]?.file
                                    }
                                  </a>
                                )}
                                <input
                                  type="file"
                                  // ref={hiddenFileInput}
                                  onChange={(e) => {
                                    customRequest(
                                      e.target.files[0],
                                      index,
                                      "protfolio"
                                    );
                                  }}
                                  // style={{ display: "none" }}
                                />
                              </>
                            ) : (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                              
                              </>
                            )}
                            <div
                              className="float-right "
                              style={{ fontSize: "18px", marginTop: "-10px" }}
                            >
                              <DeleteOutlined
                                className="cursor-pointer"
                                onClick={() => remove(name)}
                              />
                            </div>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </>
                      )
                    )}
                    <Col
                      xs={20}
                      sm={20}
                      md={20}
                      xl={20}
                      xxl={20}
                      span={20}
                      className=""
                    >
                      <Form.Item>
                        <div className="">
                          <div
                            className=" cursor-pointer d-flex align-content-center"
                            onClick={() => add()}
                          >
                            <PlusCircleOutlined className="mt-1 mx-1" />
                            <span>Add Guide</span>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>

              <div className="col-md-12 mt-2 ">
                <h4>Portfolios (For Student)</h4>
              </div>

              <Form.List name="stuProtfolio" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            span={24}
                          >
                            <Form.Item
                              style={{ marginBottom: "15px" }}
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              label={`type ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type",
                                },
                              ]}
                            >
                              <Select defaultValue="Select type">
                                {["video", "image", "text", "doc", "link"]?.map(
                                  (i) => {
                                    return <Option value={i}>{i}</Option>;
                                  }
                                )}
                              </Select>
                            </Form.Item>
                            {form?.getFieldValue("stuProtfolio")?.[index]
                              ?.type === "link" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>
                              </>
                            ) : form?.getFieldValue("stuProtfolio")?.[index]
                                ?.type !== "text" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>

                                {files?.["stuProtfolio"]?.filter(
                                  (d) => d?.index === index
                                )?.length > 0 && (
                                  <a
                                    style={{ color: "black" }}
                                    className="lable-head margin-20  ml-10"
                                  >
                                    {
                                      files?.["stuProtfolio"]?.filter(
                                        (d) => d?.index === index
                                      )?.[0]?.file
                                    }
                                  </a>
                                )}
                                <input
                                  type="file"
                                  // ref={hiddenFileInput}
                                  onChange={(e) => {
                                    customRequest(
                                      e.target.files[0],
                                      index,
                                      "stuProtfolio"
                                    );
                                  }}
                                  // style={{ display: "none" }}
                                />
                              </>
                            ) : (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                            
                              </>
                            )}
                            <div
                              className="float-right "
                              style={{ fontSize: "18px", marginTop: "-10px" }}
                            >
                              <DeleteOutlined
                                className="cursor-pointer"
                                onClick={() => remove(name)}
                              />
                            </div>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </>
                      )
                    )}
                    <Col
                      xs={20}
                      sm={20}
                      md={20}
                      xl={20}
                      xxl={20}
                      span={20}
                      className=""
                    >
                      <Form.Item>
                        <div className="">
                          <div
                            className=" cursor-pointer d-flex align-content-center"
                            onClick={() => add()}
                          >
                            <PlusCircleOutlined className="mt-1 mx-1" />
                            <span>Add Guide</span>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>

              <div className="col-md-12 mt-2 ">
                <h4>Forum</h4>
              </div>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"forumtitle"}
                  label={"Forum Title"}
                >
                  <InputField placeholder="Forum Title" />
                </Form.Item>
              </Col>
              <Form.List name="forum" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            span={24}
                          >
                            <Form.Item
                              style={{ marginBottom: "15px" }}
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              label={`type ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type",
                                },
                              ]}
                            >
                              <Select defaultValue="Select type">
                                {["video", "image", "text", "doc", "link"]?.map(
                                  (i) => {
                                    return <Option value={i}>{i}</Option>;
                                  }
                                )}
                              </Select>
                            </Form.Item>
                            {form?.getFieldValue("forum")?.[index]?.type ===
                            "link" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>
                              </>
                            ) : form?.getFieldValue("forum")?.[index]?.type !==
                              "text" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>

                                {files?.["forum"]?.filter(
                                  (d) => d?.index === index
                                )?.length > 0 && (
                                  <a
                                    style={{ color: "black" }}
                                    className="lable-head margin-20  ml-10"
                                  >
                                    {
                                      files?.["forum"]?.filter(
                                        (d) => d?.index === index
                                      )?.[0]?.file
                                    }
                                  </a>
                                )}
                                <input
                                  type="file"
                                  // ref={hiddenFileInput}
                                  onChange={(e) => {
                                    customRequest(
                                      e.target.files[0],
                                      index,
                                      "forum"
                                    );
                                  }}
                                  // style={{ display: "none" }}
                                />
                              </>
                            ) : (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                             
                              </>
                            )}
                            <div
                              className="float-right "
                              style={{ fontSize: "18px", marginTop: "-10px" }}
                            >
                              <DeleteOutlined
                                className="cursor-pointer"
                                onClick={() => remove(name)}
                              />
                            </div>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </>
                      )
                    )}
                    <Col
                      xs={20}
                      sm={20}
                      md={20}
                      xl={20}
                      xxl={20}
                      span={20}
                      className=""
                    >
                      <Form.Item>
                        <div className="">
                          <div
                            className=" cursor-pointer d-flex align-content-center"
                            onClick={() => add()}
                          >
                            <PlusCircleOutlined className="mt-1 mx-1" />
                            <span>Add Guide</span>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>

              <div className="col-md-12 mt-2 ">
                <h4>Course Shop</h4>
              </div>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  name={"courcetitle"}
                  label={"Cource Title"}
                >
                  <InputField placeholder="Cource Title" />
                </Form.Item>
              </Col>
              <Form.List name="shop" className="mb-0">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            span={24}
                          >
                            <Form.Item
                              style={{ marginBottom: "15px" }}
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              label={`type ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type",
                                },
                              ]}
                            >
                              <Select defaultValue="Select type">
                                {["video", "image", "text", "doc", "link"]?.map(
                                  (i) => {
                                    return <Option value={i}>{i}</Option>;
                                  }
                                )}
                              </Select>
                            </Form.Item>
                            {form?.getFieldValue("shop")?.[index]?.type ===
                            "link" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>
                              </>
                            ) : form?.getFieldValue("shop")?.[index]?.type !==
                              "text" ? (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "linktext"]}
                                  fieldKey={[fieldKey, "linktext"]}
                                  label={`Link Text ${index + 1}`}
                                >
                                  <InputField placeholder="Link Text" />
                                </Form.Item>

                                {files?.["shop"]?.filter(
                                  (d) => d?.index === index
                                )?.length > 0 && (
                                  <a
                                    style={{ color: "black" }}
                                    className="lable-head margin-20  ml-10"
                                  >
                                    {
                                      files?.["shop"]?.filter(
                                        (d) => d?.index === index
                                      )?.[0]?.file
                                    }
                                  </a>
                                )}
                                <input
                                  type="file"
                                  // ref={hiddenFileInput}
                                  onChange={(e) => {
                                    customRequest(
                                      e.target.files[0],
                                      index,
                                      "shop"
                                    );
                                  }}
                                  // style={{ display: "none" }}
                                />
                              </>
                            ) : (
                              <>
                                <Form.Item
                                  style={{ marginBottom: "15px" }}
                                  {...restField}
                                  name={[name, "list"]}
                                  fieldKey={[fieldKey, "list"]}
                                  label={`guide ${index + 1}`}
                                >
                                  <InputField placeholder="" />
                                </Form.Item>
                              
                              </>
                            )}
                            <div
                              className="float-right "
                              style={{ fontSize: "18px", marginTop: "-10px" }}
                            >
                              <DeleteOutlined
                                className="cursor-pointer"
                                onClick={() => remove(name)}
                              />
                            </div>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </>
                      )
                    )}
                    <Col
                      xs={20}
                      sm={20}
                      md={20}
                      xl={20}
                      xxl={20}
                      span={20}
                      className=""
                    >
                      <Form.Item>
                        <div className="">
                          <div
                            className=" cursor-pointer d-flex align-content-center"
                            onClick={() => add()}
                          >
                            <PlusCircleOutlined className="mt-1 mx-1" />
                            <span>Add Guide</span>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>
            </Row>

            <div
              className="center-column"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                htmlType="submit"
                size={"large"}
                type="primary"
                className="rounded "
                // style = {{heigth : "200px"}}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default Guide;
