import { Divider } from "antd";
import React from "react";
import ModalWrapper from "../../../../components/Modal";
import { joinedUser } from "../../../../utils/joinedUser";
import JoinMember from "./JoinMember";

const JoinGroupModal = ({
  visible,
  setVisible,
  studio,
  userData,
  leftGroup,
  joinGroup,
}) => {
  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <div className="row">
          <div className="col-md-12">
            <h3>WELCOME TO {studio?.studioName}</h3>
          </div>
          <div className="col-md-12">
            <h6>
              {/* Please join your groups in {studio?.studioName}. This will
              ensure that you don't miss on out on viewing items shared with
              these groups. Thanks. */}
              Please join your class groups. This will ensure that you don't miss on out on viewing items shared with the groups. 
            </h6>
          </div>
          <div className="col-md-12">
            <Divider />
          </div>
          {studio?.group?.map((group) => (
            
            <JoinMember key = {group?._id} group = {group} userData = {userData} leftGroup = {leftGroup} joinGroup = {joinGroup} isJoin = {joinedUser(userData?.id, group.members)}/>
             
            
          ))}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default JoinGroupModal;
