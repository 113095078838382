// export const SERVER_URL ="http://localhost:8000/v1"
// export const SERVER_URL ="https://movitae-api.herokuapp.com/v1"
// export const SERVER_URL ="https://movitae-api-prod.herokuapp.com/v1"
// export const SERVER_URL ="http://3.21.35.243:1234/v1"
// export const SERVER_URL = "https://movitanz-web.herokuapp.com/v1";
export const SERVER_URL="https://web.movitanz.com/v1"
// export const SERVER_URL="https://movitanzweb.herokuapp.com/v1"

export const MEDIA_URL = "https://www.movitanz.com/upload/";

export const STRIPE_KEY =
  "pk_live_51KErcxLz5vQJHr8cdGLLsACQlQCdPYLcLStTckm36tHdFKzUTzH5LENuqSdsWDZjrypxSM09YlnCSBWimAooqFCL00JdaCju5P";

export const VIMEO_ACCESS_TOKEN = "506aec285b7e368cb866bc347850fdcb";
export const headerDelete = {
  Accept: "application/vnd.vimeo.*+json;version=3.4",
  Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
  "Content-Type": "application/x-www-form-urlencode",
};

export const headerPatch = {
  "Tus-Resumable": "1.0.0",
  "Upload-Offset": 0,
  "Content-Type": "application/offset+octet-stream",
  Accept: "application/vnd.vimeo.*+json;version=3.4",
};

export const headerPost = {
  Accept: "application/vnd.vimeo.*+json;version=3.4",
  Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
  "Content-Type": "application/json",
};

// export const STRIPE_KEY="pk_test_51K2uIQSJ9DARSEVv6a1HAexy7BVgXtlEM5yvcyRPOPEIiuRYeQcGARaHfv7tSRbPazjlLDqBbJMU1370DpXS0xnP00ELAfLrjD"
