import { Input, DatePicker } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

export const InputField = styled(Input).attrs({
  className: "font-source-sans",
})`
background:${props => props.background ? props.background : "#dee3f3"};
  border-radius: 20px;
  padding: 10px 20px;
  color: black;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  input {
    font-weight: 600 !important;
  }

  .ant-input-affix-wrapper { 
    background : #dee3f3 !important
  }
  &.search-input{
    input{
      background: #dee3f3 !important;
    }
  }


`;

export const GrayInputField = styled(Input).attrs({
  className: "font-source-sans",
})`
background:${props => props.background ? props.background : "#fff"};
  border-radius: 20px;
  border: 1px solid #9A9A9A;
  padding: 5px 10px;
  color: black;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  input {
    font-weight: 600 !important;
  }
  &.search-input{
    input{
      background: #fff !important;
    }
  }
`;



export const GrayTextAreaField = styled(TextArea).attrs({
  className: "font-source-sans",
})`
  background: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  color: black;
  height: 60px;
  font-weight: 500;
  font-size: 16px;
  
`;

export const TextAreaField = styled(TextArea).attrs({
  className: "font-source-sans",
})`
  background: #dee3f3;
  border-radius: 20px;
  padding: 10px 20px;
  color: black;
  height: 60px;
  font-weight: 500;
  font-size: 16px;
  
`;

export const DatePickerField = styled(DatePicker).attrs({
  className: "font-source-sans",
})`
  background: #dee3f3;
  border-radius: 20px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 16px !important;
  // color: #8a95ba !important;
  height: 60px;
  width: 100%;
  input {
    font-weight: 500;
    font-size: 16px !important;
    // color: #8a95ba !important;
  }

   .ant-picker-today-btn { 
    color : #0eaaa5 !important
  }
`;


export const GrayDatePicker = styled(DatePicker).attrs({
  className: "font-source-sans",
})`

.ant-picker-today-btn { 
    color : #0eaaa5 !important
  }
border-radius: 20px;
  border: 1px solid #9A9A9A;
  padding: 5px 10px;
  color: black;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  input {
    font-weight: 500;
    font-size: 16px !important;
    // color: #8a95ba !important;
  }

`;
