import { Button, Col, Row, Tooltip } from "antd";
import React, { useContext } from "react";
import styled from "styled-components";
import { InstagramOutlined } from "@ant-design/icons";
import { UserContext } from "../../context/userInfo";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../route/constant";
import { InputField } from "../_shared";
import { GrayInputField } from "../_shared/Input";
import { useState } from "react";
import { notify } from "../../utils";
import auth from "../../services/auth";
const H2 = styled.h2`
  color: rgb(14, 170, 165);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
`;
const AppFooter = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const [email, setEmail] = useState("");

  const onSubscribe = () => {
    if (!email) return notify("error", "Please enter your email");
    auth
      .subscribe({ email })
      .then((res) => {
        if (res?.status === 200) {
          notify("Success", "Success!");
          setEmail("");
        }
      })
      .catch((err) => {
        notify("error",JSON.stringify(err));
      });
  };
  useEffect(() => {
    getUserinfo();
  }, []);
  const navigate = useNavigate();

  return (
    !userData && (
      <div className="container" style={{ padding: 10 }}>
        <footer id="footer" className="dark">
          <div className="footer-wrap">
            <Row>
              <Col md={6} sm={24} xs={24}>
                <div className="footer-center">
                  <img
                    style={{ cursor: "pointer" }}
                    src={process.env.PUBLIC_URL + "/movitae-logo.png"}
                    alt=""
                    width="200px"
                  />
                </div>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <div className="footer-center">
                  <H2>About</H2>
                  <div>
                    <a
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(ROUTES.INDEX);
                      }}
                    >
                      Home
                    </a>
                  </div>
                  <div>
                    <a
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(ROUTES.WHY);
                      }}
                    >
                      Why use Movitanz?
                    </a>
                  </div>
                  <div>
                    <a
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(ROUTES.ABOUT);
                      }}
                    >
                      About us
                    </a>
                  </div>
                  <div>
                    <a href="mailto:info@movitanz.com" target={"_blank"}>
                      Contact
                    </a>
                  </div>
                  <div>
                    <a
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(ROUTES.FAQ);
                      }}
                    >
                      FAQ
                    </a>
                  </div>
                  <div>
                    <a
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(ROUTES.SIGNIN);
                      }}
                    >
                      Login
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <div className="footer-center">
                  <H2>LEGAL</H2>
                  <div>
                    <a
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(ROUTES.TNC);
                      }}
                    >
                      Terms and conditions
                    </a>
                  </div>
                  <div>
                    <a
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(ROUTES.PRIVACY);
                      }}
                    >
                      Privacy
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <div className="footer-center">
                  <H2>SOCIAL</H2>
                  <div>
                    <a href="https://www.instagram.com/movi_tanz/" target={"_blank"}>
                      <InstagramOutlined style={{ fontSize: 25 }} />
                    </a>
                  </div>
                </div>
{/* 
                <div className="footer-center mt-3">
                  <div className="row">
                    <H2>Subscribe To Our Newsletter</H2>
                    <div className="col-md-10">
                      <GrayInputField
                        placeholder="Email"
                        onChange={(e) => setEmail(e?.target?.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <Button type="primary" onClick={() => onSubscribe()}>
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </div> */}
              </Col>
            </Row>
          </div>
          <Tooltip
            title="Developed by Creative People Web. A web and mobile application development company from Melbourne."
            color={"#0eaaa5"}
            key={"2"}
          >
            <a
              style={{
                color: "rgb(3, 152, 158)",
                position: "absolute",
                right: 0,
              }}
              href="https://www.creativepeopleweb.com/"
              target={"_blank"}
            >
              {" "}
              <img
                className="mx-2"
                src={`${process.env.PUBLIC_URL}/cpw.png`}
                width={"30px"}
              />
            </a>
          </Tooltip>
          <div className="d-flex justify-content-between">
            <div className="bottom-bar" style={{ marginTop: 15 }}>
              © 2022 Movitanz
            </div>
          </div>
        </footer>
      </div>
    )
  );
};
export default AppFooter;
