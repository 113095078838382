export const isFileAccept = (file) => {
  if(!file) return true
  let fileExtenstionSplit = file.split(".")
  let fileExtenstion = fileExtenstionSplit[fileExtenstionSplit.length - 1]
  const validExtention = ["jpeg","jpg","png"]
  return validExtention.some(ext => ext.includes(fileExtenstion) )
}

export const returnFileType = (file) => {
  if(!file) return true
  let fileExtenstionSplit = file.split(".")
  let fileExtenstion = fileExtenstionSplit[fileExtenstionSplit.length - 1]
  return fileExtenstion?.toLowerCase()
}