import produce from "immer";
import actionTypes from "../actionTypes/timeline";
const initialState = {
  isProceeding: false,
  timeline: [],
  comment: [],
};

const timelineReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.GET_TIMELINE_SUCCESS:
        draft.isProceeding = false;
        draft.timeline = action.timeline;
        break;
      case actionTypes.GET_TIMELINE_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.CREATE_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.CREATE_TIMELINE_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.CREATE_TIMELINE_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UPDATE_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.UPDATE_TIMELINE_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.UPDATE_TIMELINE_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.DELETE_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.DELETE_TIMELINE_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.DELETE_TIMELINE_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.GET_COMMENT_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.GET_COMMENT_SUCCESS:
        draft.isProceeding = false;
        draft.comment = action.comment;
        break;
      case actionTypes.GET_COMMENT_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.ADD_COMMENT_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.ADD_COMMENT_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.ADD_COMMENT_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UPDATE_COMMENT_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.UPDATE_COMMENT_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.UPDATE_COMMENT_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.DELETE_COMMENT_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.DELETE_COMMENT_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.DELETE_COMMENT_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.ADD_REPLY_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.ADD_REPLY_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.ADD_REPLY_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UPDATE_REPLY_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.UPDATE_REPLY_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.UPDATE_REPLY_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.DELETE_REPLY_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.DELETE_REPLY_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.DELETE_REPLY_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.WATCH_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.WATCH_TIMELINE_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.WATCH_TIMELINE_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UN_WATCH_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.UN_WATCHTIMELINE_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.UN_WATCHTIMELINE_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.LIKE_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.LIKE_TIMELINE_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.LIKE_TIMELINE_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UN_LIKE_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.UN_LIKE_TIMELINE_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.UN_LIKE_TIMELINE_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.SHARE_GROUP_INIT:
        draft.isProceeding = false;
        break;
      case actionTypes.SHARE_GROUP_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.SHARE_GROUP_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UN_SHARE_GROUP_INIT:
        draft.isProceeding = false;
        break;
      case actionTypes.UN_SHARE_GROUP_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.UN_SHARE_GROUP_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.SHARE_MEMBER_INIT:
        draft.isProceeding = false;
        break;
      case actionTypes.SHARE_MEMBER_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.SHARE_MEMBER_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UN_SHARE_MEMBER_INIT:
        draft.isProceeding = false;
        break;
      case actionTypes.UN_SHARE_MEMBER_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.UN_SHARE_MEMBER_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.FAVOURTE_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.FAVOURTE_TIMELINE_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.FAVOURTE_TIMELINE_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UN_FAVOURTE_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.UN_FAVOURTE_TIMELINE_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.UN_FAVOURTE_TIMELINE_FAILED:
        draft.isProceeding = false;
        break;

        case actionTypes.TIMELINE_PIN_POST_INIT:
          draft.isProceeding = true;
          break;
        case actionTypes.TIMELINE_PIN_POST_SUCCESS:
          draft.isProceeding = false;
          break;
        case actionTypes.TIMELINE_PIN_POST_FAILED:
          draft.isProceeding = false;
          break;

      default:
        break;
    }
  });

export default timelineReducer;
