import produce from "immer";
import actionTypes from "../actionTypes/auth";
const initialState = {
  isProceeding: false,
  portfolios: [],
  portfolioTimeline: [],
  singlePortfolio: [],
};

const portfolioReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_PORTFOLIO_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.GET_PORTFOLIO_SUCCESS:
        draft.isProceeding = false;
        draft.portfolios = action.portfolios;
        break;
      case actionTypes.GET_PORTFOLIO_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.CREATE_PORTFOLIO_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.CREATE_PORTFOLIO_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.CREATE_PORTFOLIO_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UPDATE_PORTFOLIO_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.UPDATE_PORTFOLIO_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.UPDATE_PORTFOLIO_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.DELETE_PORTFOLIO_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.DELETE_PORTFOLIO_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.DELETE_PORTFOLIO_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.GET_SHARE_MY_PORTFOLIO_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.GET_SHARE_MY_PORTFOLIO_SUCCESS:
        draft.isProceeding = false;
        draft.portfolios = action.portfolios;
        break;
      case actionTypes.GET_SHARE_MY_PORTFOLIO_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.SHARE_PORTFOLIO_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.SHARE_PORTFOLIO_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.SHARE_PORTFOLIO_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UN_SHARE_PORTFOLIO_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.UN_SHARE_PORTFOLIO_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.UN_SHARE_PORTFOLIO_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.GET_PORTFOLIO_TIMELINE_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.GET_PORTFOLIO_TIMELINE_SUCCESS:
        draft.isProceeding = false;
        draft.portfolioTimeline = action.portfolioTimeline;
        break;
      case actionTypes.GET_PORTFOLIO_TIMELINE_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.GET_PORTFOLIO_BY_ID_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.GET_PORTFOLIO_BY_ID_SUCCESS:
        draft.isProceeding = false;
        draft.singlePortfolio = action.singlePortfolio;
        break;
      case actionTypes.GET_PORTFOLIO_BY_ID_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.DELETE_PORTFOLIO_COMMENT_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.DELETE_PORTFOLIO_COMMENT_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.DELETE_PORTFOLIO_COMMENT_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.CREATE_PORTFOLIO_COMMENT_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.CREATE_PORTFOLIO_COMMENT_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.CREATE_PORTFOLIO_COMMENT_FAILED:
        draft.isProceeding = false;
        break;

      default:
        break;
    }
  });

export default portfolioReducer;
