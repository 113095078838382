import React, { useContext, useState, useEffect } from "react";
import { Table, Select, Modal } from "antd";
import admin from "../../../services/admin";
import auth from "../../../services/auth";
import { notify } from "../../../utils";
import { ViewProfileContext } from "../../../context/viewProfile";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/_shared/Loader/Loading";
import { Button, InputField } from "../../../components/_shared";
import { SearchOutlined } from "@ant-design/icons";
// import axios from "axios";
import { UserContext } from "../../../context/userInfo";

const { Option } = Select;

const User = () => {
  const [users, setUsers] = useState([]);
  const { profileToken, setUserId, profileLoading } =
    useContext(ViewProfileContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchField, setSearchField] = useState("");
  const [usersData, setUserData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const { userData, getUserinfo } = useContext(UserContext);
  const [filter, setFilter] = useState("all");
  const [content, setContent] = useState({});
  const [downgrade, setDowngrade] = useState({
    show: false,
    id: "",
  });
  const [pricing, setPricing] = useState("");

  const handleViewProfile = (id) => {
    setUserId(id);
  };

  useEffect(() => {
    if (profileToken?.access?.token) navigate("/timeline");
  }, [profileToken]);

  useEffect(() => {
    // getUsers();
    getUserinfo();
  }, []);
  const getContent = () => {
    admin
      .getContent({
        pricing: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
        // notify("error",JSON.stringify(err));
      });
  };

  useEffect(() => {
    if (userData) {
      getUsers();
      getContent();
    }
  }, [userData, filter]);

  const accountActivation = (id, isActive) => {
    admin
      .activatationAccount(id, { isActive })
      .then((res) => {
        if (res.status === 200) {
          getUsers();
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  const getUsers = (page) => {
    setIsUpdate(!isUpdate);

    setIsLoading(true);
    admin
      .getUsers(page ? page : 1, 10, "", filter)
      .then((res) => {
        setUsers(res?.data);
        totalUsers(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notify("error", JSON.stringify(err));
      });
  };

  const searchGetUsers = (search) => {
    setIsLoading(true);

    admin
      .getUsers(1, 10000, search)
      .then((res) => {
        setIsLoading(false);

        setUsers(res?.data);
        totalUsers(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        notify("error", JSON.stringify(err));
      });
  };

  const onDeleteAccount = (id) => {
    if (window.confirm("Are you sure want to delete account!")) {
      admin
        .deleteUser(id)
        .then((res) => {
          if (res?.status === 200) {
            getUsers();
          }
        })
        .catch((err) => {
          notify("error", err);
        });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Type", dataIndex: "type", key: "type" },
    {
      title: "Account",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Portfolio",
      dataIndex: "allowPortfolio",
      key: "allowPortfolio",
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
    },
    {
      dataIndex: "delete",
      key: "delete",
    },
    {
      dataIndex: "downgrade",
      key: "downgrade",
    },
    {
      dataIndex: "subscriptionstatus",
      key: "subscriptionstatus",
    },
  ];

  const totalUsers = (usersList) => {
    let data = [];
    let index = 1;

    if (usersList?.totalResults)
      usersList?.results.forEach((user) => {
        data.push({
          key: index,
          name: user?.name,
          email: user?.email,
          type: user?.role,
          status: user?.isAccountActive ? (
            <button
              onClick={() =>
                accountActivation(user?.id, !user?.isAccountActive)
              }
              className=" cursor-pointer btn btn-danger"
            >
              Suspend
            </button>
          ) : (
            <button
              onClick={() =>
                accountActivation(user?.id, !user?.isAccountActive)
              }
              className="btn btn-success"
            >
              Activate
            </button>
          ),
          view: (
            <div
              onClick={() => handleViewProfile(user?.id)}
              className="cursor-pointer text-success"
            >
              View Profile
            </div>
          ),
          delete: (
            <button
              onClick={() => onDeleteAccount(user?.id)}
              className=" cursor-pointer btn btn-outline-danger"
            >
              Delete Account
            </button>
          ),
          downgrade: userData.role === "admin" &&
            user?.role === "studioOwner" && (
              <button
                onClick={() =>
                  setDowngrade({
                    show: true,
                    id: user,
                  })
                }
                className=" cursor-pointer btn btn-outline-danger"
              >
                Downgrade
              </button>
            ),
            subscriptionstatus: userData.role === "admin" &&
            user?.role === "studioOwner" && (
              <div
                className={
                  user?.isAutoRenew
                    ? "cursor-pointer text-success"
                    : "cursor-pointer text-danger"
                }
              >
                {user?.isAutoRenew ? "Active" : "Inactive"} <br />{" "}
                <span style={{ color: "black" }}>
                  {!user?.isAutoRenew && `Reason: ${user?.reason}`}
                </span>
              </div>
            ),
          allowPortfolio: userData.role === "studioOwner" && (
            <div
              onClick={async () => {
                await auth.updateportfolioshare(user?.id, {
                  allowPortfolio: user?.allowPortfolio !== true ? true : false,
                });
                await getUsers();
              }}
              className={
                user?.allowPortfolio === true
                  ? "cursor-pointer  text-success"
                  : "cursor-pointer text-danger"
              }
            >
              {user?.allowPortfolio === true ? "Active" : "Inavtive"} <br />{" "}
            </div>
          ),
        });
        index++;
      });
    setUserData(data);
  };

  const onUpgdrade = async () => {
    await auth.upgradeStorage({
      storage: Number(
        content?.pricing?.cards?.filter((i) => i.price === pricing)?.[0]
          ?.storage
      ),
      signupCode: downgrade?.id?.signupCode,
      studentEnrolledLimit: Number(
        content?.pricing?.cards?.filter((i) => i.price === pricing)?.[0]
          ?.maxStudents
      ),
      studioMonthExp: downgrade?.id?.studioMonthExp,
      price: Number(pricing),
    });
  };

  return (
    <>
      <Modal
        title="Downgrade"
        style={{ top: 20 }}
        visible={downgrade?.show}
        footer=""
        onCancel={() => {
          setDowngrade({
            show: false,
            id: "",
          });
        }}
      >
        <Select
          defaultValue={""}
          style={{ width: "100%" }}
          placeholder="Select price"
          value={pricing}
          onChange={(e) => {
            setPricing(e);
          }}
        >
          {content?.pricing?.cards?.map((i) => {
            return (
              downgrade?.id?.studentEnrolledLimit >= i?.price && (
                <Option value={i?.price}>{i?.priceText} </Option>
              )
            );
          })}
        </Select>
        <Button
          style={{ marginTop: 10, width: "100%" }}
          title="Save"
          type="primary"
          onClick={onUpgdrade}
          size={"large"}
        />
      </Modal>
      <Loading isLoading={isLoading || profileLoading ? true : false} />
      <div className="container bg-white p-2">
        <div className="row  d-flex ">
          <div className="col-md-6 mb-3 mt-3 ">
            {userData?.role === "admin" && (
              <Select
                defaultValue={"all"}
                style={{ width: "100%" }}
                value={filter}
                onChange={(e) => {
                  setFilter(e);
                }}
              >
                <Option value={"all"}>All</Option>
                <Option value={"student"}>Student</Option>
                <Option value={"studioOwner"}>Studio Owner</Option>
                <Option value={"teacher"}>Teacher</Option>
              </Select>
            )}
          </div>
          <div className="col-md-6 mb-3 mt-3 d-flex justify-content-end">
            <InputField
              // value={searchField}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchGetUsers(e.target.value);
                }
              }}
              onKeyUp={(e) => {
                console.log(e.target.value);

                if (e.key === "Backspace" && e.target.value.length == 0) {
                  getUsers();
                }
              }}
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              placeholder="Name, Email (Hit Enter) "
              suffix={<SearchOutlined />}
              style={{ width: "400px" }}
            />
          </div>
        </div>

        <Table
          columns={columns}
          pagination={{
            total: users?.totalResults,
            current: users?.page,
            pageSizeOptions: ["10"],
            onChange: (e) => {
              getUsers(e);
            },
          }}
          dataSource={usersData}
        />
      </div>
    </>
  );
};

export default User;
