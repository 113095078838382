import { Button, Divider, Typography } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import { Row, Col, Card, Carousel } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as ResponsiveCar } from "react-responsive-carousel";
import { ROUTES } from "../../route/constant";
import Second from "../../assets/images/7.jpeg";
import Third from "../../assets/images/2.jpg";
import dotstop2 from "../../assets/images/3.jpg";

import { HeroSection } from "./component";
import useViewport from "../../hooks/useViewport";
import { First, Second as SecondSvg, Third as ThirdSvg, Fourth } from "./icons";
import admin from "../../services/admin";
import { useState } from "react";
import { notify } from "../../utils";

const { Title } = Typography;
const { Meta } = Card;
const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const Home = () => {
  const navigate = useNavigate();
  const { isLaptop, isMobile } = useViewport();
  const [content, setContent] = useState({});
  useEffect(() => {
    getContent();
  }, []);
  const getContent = () => {
    admin
      .getContent({
        home: 1,
        getStarted: 0,
        why: 0,
        faq: 0,
        pricing: 0,
        guide: 0,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res?.data?.content, 'res?.data?.conten');
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
      });
  };
  return (
    !!content.home && <div style={{ width: "100%" }}>
      <HeroSection isMobile={isMobile} isLaptop={isLaptop}>
        <div
          style={{
            position: "relative",
            height: isMobile ? "40vh" : "100%",
            width: "100%",
          }}
        >
          {!!content.home.home1 && content.home.home1.length > 0 ? content.home.home1[0].type == 'image' ?
            <section
              style={{ backgroundImage: `url(${content?.home?.files?.home1[content.home.files.home1.length - 1]?.file})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
              className="hero-section-home">
              <div
                className="container d-flex align-items-center"
                style={{ height: "100%" }}
              >
                <div className="d-flex align-items-center align-middle">
                  <Button
                    onClick={() => {
                      navigate(ROUTES.SIGNIN);
                    }}
                    className="btn-home"
                    type="primary"
                    style={{ height: 50, width: 105 }}
                  >
                    Login
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(ROUTES.SIGNUP);
                    }}
                    className="btn-home"
                    type="primary"
                    style={{ height: 50, width: 105, marginLeft: 50 }}
                  >
                    Signup
                  </Button>
                </div>
              </div>
            </section>
            : content.home.home1[0].type == 'video' ?
              <>
                <video autoPlay loop muted playsInline preload="metadata"
                  style={{
                    objectFit: 'fill',
                    width: "100%",
                  }}
                ><source src={content?.home?.files?.home1[content.home.files.home1.length - 1]?.file} type='video/mp4' /></video>
                <section style={{ bottom: '285px', position: 'absolute', left: '98.5px' }}>
                  <div
                    className="container d-flex align-items-center"
                    style={{ height: "100%" }}
                  >
                    <div className="d-flex align-items-center align-middle">
                      <Button
                        onClick={() => {
                          navigate(ROUTES.SIGNIN);
                        }}
                        className="btn-home"
                        type="primary"
                        style={{ height: 50, width: 105 }}
                      >
                        Login
                      </Button>
                      <Button
                        onClick={() => {
                          navigate(ROUTES.SIGNUP);
                        }}
                        className="btn-home"
                        type="primary"
                        style={{ height: 50, width: 105, marginLeft: 50 }}
                      >
                        Signup
                      </Button>
                    </div>
                  </div>
                </section>
              </> : "" : <section style={{ backgroundImage: `url(${dotstop2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className="hero-section-home">
            <div
              className="container d-flex align-items-center"
              style={{ height: "100%" }}
            >
              <div className="d-flex align-items-center align-middle">
                <Button
                  onClick={() => {
                    navigate(ROUTES.SIGNIN);
                  }}
                  className="btn-home"
                  type="primary"
                  style={{ height: 50, width: 105 }}
                >
                  Login
                </Button>
                <Button
                  onClick={() => {
                    navigate(ROUTES.SIGNUP);
                  }}
                  className="btn-home"
                  type="primary"
                  style={{ height: 50, width: 105, marginLeft: 50 }}
                >
                  Signup
                </Button>
              </div>
            </div>
          </section>
          }

        </div>
        <div className="container">
          {" "}
          <section
            style={{ position: "relative" }}
            className="text-center mt-3 mb-3"
          >
            <Title
              className="heading"
              level={2}
              type="primary"
              style={{ color: "#0eaaa5" }}
            >
              {!!content?.home?.hometitle ? content?.home?.hometitle : "IN THE HANDS OF INSPIRED TEACHERS..."}
            </Title>
            <Divider style={{ padding: 5, margin: 0 }} />
            <Title
              className="subtitle"
              style={{ color: "#6b6b6b", color: "#0eaaa5" }}
              level={5}
            >
              {!!content?.home?.homeheading ? content?.home?.homeheading : "THE MOST ESSENTIAL ONLINE SUPPORT PLATFORM FOR DANCE STUDIOS"}
            </Title>
            <Button
              onClick={() => {
                navigate(ROUTES.PRICING);
              }}
              type="primary"
              className="mt-3 btn-home"
              style={{ height: 50 }}
            >
              {!!content?.home?.homebutton ? content?.home?.homebutton : "START A $1 TRIAL NOW"}
            </Button>
          </section>
        </div>
        {!!content.home.home2 && content.home.home2.length > 0 ? content.home.home2[0].type == 'image' ?
          <section
            style={{
              background: "#9e9e9e",
              height: 500,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img style={{ objectFit: "contain" }} src={content?.home?.files?.home2[content.home.files.home2.length - 1]?.file} height={490} width={isMobile ? "100%" : null} />
          </section>
          : content.home.home2[0].type == 'video' ?
            <video autoPlay loop muted playsInline preload="metadata"
              style={{
                objectFit: 'fill',
                width: "100%",
              }}
            ><source src={content?.home?.files?.home2[content.home.files.home2.length - 1]?.file} type='video/mp4' /></video> : "" :
          <section
            style={{
              background: "#9e9e9e",
              height: 500,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img style={{ objectFit: "contain" }} src={Second} height={490} width={isMobile ? "100%" : null} />
          </section>}
        <section style={{ background: "#03989e", paddingBottom: "30px" }}>
          <div className="container">
            <Row style={{ padding: isMobile ? 0 : 0 }}>
              {content?.home?.cards1?.map((card, index) => (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={{ span: 6, offset: 2 }}
                  className="text-center"
                >
                  <Card
                    className="  mt-5"
                    onClick={() => {
                      navigate(ROUTES.PRICING);
                    }}
                    hoverable
                    style={{ height: isMobile ? 300 : "450px" }}
                  >
                    <img
                      alt="example"
                      style={{ height: 100, width: 100 }}
                      src={!!content.home.files && content.home.files.cards1.length > 0 ? content.home.files.cards1.filter((i) => i?.index == index)[content.home.files.cards1.filter((i) => i?.index == index).length - 1]?.file : card.icon}
                    />
                    <div className="box-heading text-center  mt-3">
                      {card.title}
                    </div>
                    <div className="text-center box-subheading">
                      {card.description}
                    </div>
                  </Card>
                </Col>))}
            </Row>
          </div>
        </section>
        {!!content.home.home3 && content.home.home3.length > 0 ? content.home.home3[0].type == 'image' ?
          <section
            style={{
              background: "#9e9e9e",
              height: 500,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={content?.home?.files?.home3[content.home.files.home3.length - 1]?.file} height={490} style={{ objectFit: "contain" }} width={isMobile ? "100%" : null} />
          </section> : content.home.home3[0].type == 'video' ?
            <video autoPlay loop muted playsInline preload="metadata"
              style={{
                objectFit: 'fill',
                width: "100%",
              }}
            ><source src={
              content?.home?.files?.home3[content.home.files.home3.length - 1]?.file
            } type='video/mp4' /></video> : "" :
          <section
            style={{
              background: "#9e9e9e",
              height: 500,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={Third} height={490} style={{ objectFit: "contain" }} width={isMobile ? "100%" : null} />
          </section>}


        <section
          className="text-center "
          style={{ padding: isMobile ? 0 : 25, background: "#03989e" }}
        >
          <div className="container">
            <Title
              className="heading"
              level={2}
              type="primary"
              style={{ color: "white" }}
            >
              {!!content.home.home3title ? content.home.home3title : 'TAKE YOUR DANCE CLASS HOME'}
            </Title>
            <Divider style={{ padding: 5, margin: 0 }} />
            <Title className="subtitle" style={{ color: "white" }} level={5}>
              {!!content.home.home3heading ? content.home.home3heading : 'A SAFER MORE CONTROLLED ONLINE ENVIRONMENT'}
            </Title>

            <Row style={{ padding: isMobile ? 0 : 0 }}>
              {content?.home?.cards2?.map((card) => (
                <Col xs={24} sm={24} md={24} lg={{ span: 5, offset: 1 }}>
                  <Card
                    className=" text-center  mt-5"
                    hoverable
                    style={{ height: isMobile ? 210 : "350px" }}
                  >
                    <div className="box-heading2 text-center mt-3">
                      {" "}
                      {card?.title}
                    </div>
                    <div className="text-center box-subheading">
                      {card?.description}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </section>
      </HeroSection>
    </div>
  );
};
export default Home;

