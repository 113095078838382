import React, { useRef, useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import { notify } from "../../../utils";
import { apiPost } from "../../../utils/axios";
import { SERVER_URL, STRIPE_KEY } from "../../../utils/server";
import { StripeContainer } from "./component";
import { Modal } from "antd";
import moment from "moment";
import PaymentLoading from "../../../components/_shared/Loader/PaymentLoading";

const SignupCodeModal = ({ isModalVisible, setIsModalVisible, signupCode }) => {
  return (
    <Modal
      title="Payment Successful"
      visible={isModalVisible}
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}
    >
      <p>
        {" "}
        Thank you for choosing Movitanz. Please check your email for signup code{" "}
      </p>
    </Modal>
  );
};

const StripeCheckoutButton = ({
  extra,
  price,
  storage,
  studentEnrolledLimit,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [signupCode, setSignupCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const priceForStripe = Number(1) * 100;
  const publishableKey = STRIPE_KEY;

  const onToken = (token) => {
    setIsLoading(true);
    apiPost(SERVER_URL + "/auth/payment", {
      email: token.email,
      stripe: {
        source: token.id,
        amount: Number(1) * 100,
        currency: "aud",
        description: "description",
      },
      storageLimit: Number(extra?.storage),
      // studioMonthExp : moment().add(30, "day")
      type: "Paid",
      currentPlanPrice: Number(price),
      studioMonthExp: moment().add(44, "day"),
      studentEnrolledLimit: Number(extra?.studentEnrolledLimit),

      cardNumber: `**** **** **** ${token?.card?.last4}`,
      cardHolderName: token?.card?.id,
      expiry: `${token?.card?.exp_month} / ${token?.card?.exp_year}`,
      cvv: "***",
    })
      .then((res) => {
        setIsLoading(false);
        setIsModalVisible(true);
        setSignupCode(res?.data?.signupCode);
        // if (res.status === 200) {
        //   setIsModalVisible(true);
        //   setSignupCode(res?.data?.signupCode);
        // } else {
        //   notify("error", "Please enter valid card details");
        // }
      })
      .catch((err) => {
        setIsLoading(false);
        notify("error", "Please enter valid card details");
      });
  };

  return (
    <>
      <PaymentLoading isLoading={isLoading} />
      <StripeContainer>
        <SignupCodeModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          signupCode={signupCode}
        />
        <StripeCheckout
          label="JOIN AS STUDIO OWNER "
          name="Movitanz"
          billingAddress
          shippingAddress
          // image
          currency="AUD"
          description={`Your total is 1 AUD`}
          amount={priceForStripe}
          panelLabel="Pay now"
          token={onToken}
          stripeKey={publishableKey}
        />
      </StripeContainer>
    </>
  );
};

export default StripeCheckoutButton;
