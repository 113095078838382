import React, { useState } from "react";
import { Col, Divider, Collapse } from "antd";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Picker from "emoji-picker-react";
import InputEmoji from "react-input-emoji";
import {
  addCommentInit,
  addReplyInit,
  deleteCommentInit,
  deleteReplyInit,
} from "../../../../store/actions/timeline";
import { parseJwt, SERVER_URL } from "../../../../utils";
import {
  InputField,
  TextAreaField,
  Button,
} from "../../../../components/_shared";
import { joinedUser } from "../../../../utils/joinedUser";
import { SmileOutlined } from "@ant-design/icons";
import moment from "moment";
import timelineSerivice from "../../../../services/timeline";
import useFetchcomment from "../../../../hooks/useFetchcomment";

const { Panel } = Collapse;

const Comment = ({
  timeline,
  setCommentId,
  comments,
  commentId,
  timelineId,
  setTimelineId,
  fetchComment,
  limit,
  setLimit,
  activeTimeline,
  setActiveTimeline,
  userData,
  studioMembers,
  studios,
  profileToken,
}) => {
  const [showAddComment, setShowAddComment] = useState(false);
  const { count } = useFetchcomment(timeline?.id);

  const [showReply, setShowReply] = useState("");
  const [comment, setComment] = useState("");
  const [reply, setReply] = useState("");
  const dispatch = useDispatch();
  const [showEmoji, setShowEmoji] = useState(false);
  const [showEmojiReply, setShowEmojiReply] = useState(false);

  const addComment = (tid) => {
    dispatch(addCommentInit({ tid, commentBody: { comment } }));
    setShowAddComment(false);
    setComment("");
    setTimeout(() => {
      fetchComment(tid);
    }, 500);
  };

  const deleteComment = (tid, cid) => {
    dispatch(deleteCommentInit({ tid, cid }));
    setTimeout(() => {
      fetchComment(tid);
    }, 500);
  };

  const addReply = (tid, cid) => {
    dispatch(addReplyInit({ tid, cid, commentBody: { comment: reply } }));
    setReply("");
    setShowReply("");
    setTimeout(() => {
      fetchComment(tid);
    }, 500);
  };

  const deleteReply = (tid, cid, rid) => {
    dispatch(deleteReplyInit({ tid, cid, rid }));
    setTimeout(() => {
      fetchComment(tid);
    }, 500);
  };

  const sendEmailForComment = async () => {
    await timelineSerivice.sendEmailComment({
      userId: timeline?.user?.id,
      comment: comment,
      title: timeline?.title,
      tid: timeline?.id,
    });
  };

  const onEmojiClick = (event, emojiObject) => {
    setComment(comment + emojiObject?.emoji);
    setShowEmoji(false);
  };

  const onEmojiClickReply = (event, emojiObject) => {
    setReply(reply + emojiObject?.emoji);
    setShowEmojiReply(false);
  };

  const sharedCount = studios?.map((stu) =>
    stu.group.map(
      (group) =>
        joinedUser(group?._id, timeline?.sharedGroup) === true && group?.members
    )
  );
  const filterTotal = sharedCount?.[0]?.filter((i) => i !== false);
  let membrs = [];
  filterTotal?.map((i) => {
    i?.map((dd) => {
      membrs.push(dd);
    });
  });

  const list = membrs
    ?.concat(timeline?.sharedMember)
    ?.filter(function (item, pos) {
      return membrs.indexOf(item) == pos;
    })
    ?.filter((idsd) => idsd !== null);
  console.log(list?.length);
  const sum = list?.length;

  return (
    <Collapse
      key={timeline?.id}
      // defaultActiveKey={["1"]}
      destroyInactivePanel={true}
      activeKey={timeline?.id === timelineId ? activeTimeline : [""]}
      onChange={(e) => {
        setActiveTimeline(e);
        setTimelineId(timeline?.id);
        fetchComment(timeline?.id);
        setLimit(2);
      }}
    >
      {!timeline?.hideComment && (
        <Panel
          header={
            <h6 className='text-light-gray font-white mt-1'>
              Comments {`(${count})`}
            </h6>
          }
          key={timeline?.id}
        >
          {!comments.length ? (
            <>
              <div>No comment added</div>
              <Divider />
            </>
          ) : (
            <div
              id={`scrollableDiv$-{${timeline?.id}}`}
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "300px",
                overflow: "auto",
              }}
            >
              <InfiniteScroll
                scrollableTarget={`scrollableDiv$-{${timeline?.id}}`}
                dataLength={comments?.length}
                next={() => {
                  setLimit(limit + 2);
                }}
                hasMore={true}
              >
                {timelineId === timeline?.id && (
                  <>
                    {comments.map((comm) => (
                      <>
                        <div className='d-flex'>
                          <div>
                            <img
                              src={comm?.user?.profilepic}
                              onError={(e) => {
                                if (!comm?.user?.profilepic) {
                                  e.target.onerror = null;
                                  e.target.src = `${process.env.PUBLIC_URL}user.png`;
                                }
                              }}
                              alt=''
                              width='50'
                              height={50}
                              className='rounded-circle'
                            />
                          </div>
                          <div
                            className='mx-3 d-flex flex-column mt-1 '
                            style={{ width: "80%", whiteSpace: "pre-wrap" }}
                          >
                            <h6 className='mb-0 font-light-gray'>
                              {comm?.comment}
                            </h6>
                            <span className='font-light-gray mt-1'>
                              {comm?.name}
                              {!profileToken && (
                                <span
                                  className='mx-3 cursor-pointer'
                                  onClick={() => setShowReply(comm?.id)}
                                >
                                  Reply
                                </span>
                              )}
                              {parseJwt(localStorage.getItem("authToken"))
                                ?.sub === comm.user &&
                                !profileToken && (
                                  <span
                                    className=' text-danger cursor-pointer'
                                    onClick={() =>
                                      deleteComment(comm?.timelineId, comm?.id)
                                    }
                                  >
                                    Remove
                                  </span>
                                )}
                            </span>
                            <div className='d-flex'>
                              {moment(comm?.date).calendar()}
                            </div>
                          </div>
                        </div>

                        {comm?.reply?.map((rep) => (
                          <div className='container mt-4'>
                            <div className='d-flex'>
                              <div>
                                <img
                                  src={rep.profileUrl}
                                  onError={(e) => {
                                    if (!comm.profileUrl) {
                                      e.target.onerror = null;
                                      e.target.src = `${process.env.PUBLIC_URL}user.png`;
                                    }
                                  }}
                                  alt=''
                                  width='50'
                                  height={50}
                                  className='rounded-circle'
                                />
                              </div>
                              <div
                                className='mx-3 d-flex flex-column '
                                style={{ width: "80%", whiteSpace: "pre-wrap" }}
                              >
                                <h6 className='mb-0 font-light-gray'>
                                  {rep?.comment}
                                </h6>
                                <span className='font-light-gray mt-1'>
                                  {rep?.name}
                                  {parseJwt(localStorage.getItem("authToken"))
                                    ?.sub === rep?.user &&
                                    !profileToken && (
                                      <span
                                        className=' text-danger cursor-pointer mx-3'
                                        onClick={() =>
                                          deleteReply(
                                            timeline?.id,
                                            comm?.id,
                                            rep?._id
                                          )
                                        }
                                      >
                                        Remove
                                      </span>
                                    )}{" "}
                                </span>
                                <div className='  '>
                                  {moment(rep?.date).calendar()}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {comm?.id === showReply && (
                          <div className='container mt-3'>
                            <div className='row '>
                              <div className='col-md-1'>
                                <img
                                  src={userData?.profilepic}
                                  alt=''
                                  width={50}
                                  height={50}
                                  className='rounded-circle'
                                />
                              </div>
                              {!profileToken && (
                                <div className='col-md-6'>
                                  <InputEmoji
                                    value={reply}
                                    onChange={(e) => {
                                      setReply(e);
                                    }}
                                    placeholder='Reply'
                                  />
                                  {/* <InputField
                                    width="600px"
                                    placeholder="Reply"
                                    value={reply}
                                    onChange={(e) => setReply(e?.target.value)}
                                  />
                                  <SmileOutlined
                                    className="h4 mt-2"
                                    onClick={() =>
                                      setShowEmojiReply(!showEmojiReply)
                                    }
                                  />
                                  {showEmojiReply && (
                                    <Picker onEmojiClick={onEmojiClickReply} />
                                  )}{" "} */}
                                </div>
                              )}
                              <div className='col-md-3 '>
                                <Button
                                  htmlType='submit'
                                  lite
                                  size={"large"}
                                  onClick={() =>
                                    addReply(timeline?.id, comm?.id)
                                  }
                                  // style={{ marginLeft: isMobile ? 0 : 20 }}
                                  title='Post'
                                  className='mt-2'
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='col-md-12'>
                          <Divider />
                        </div>
                      </>
                    ))}
                  </>
                )}
              </InfiniteScroll>
            </div>
          )}
          {!profileToken && (
            <>
              <div className='row mt-2'>
                <div className='col-md-1'>
                  <img
                    src={userData?.profilepic}
                    onError={(e) => {
                      if (userData?.profilepic) {
                        e.target.onerror = null;
                        e.target.src = `${process.env.PUBLIC_URL}user.png`;
                      }
                    }}
                    alt=''
                    width={50}
                    height={50}
                    className='rounded-circle'
                  />
                </div>

                <div className='col-md-6'>
                  {/* <InputField
                    width="600px"
                    placeholder="Comment"
                    value={comment}
                    onChange={(e) => setComment(e?.target.value)}
                  /> */}
                  <InputEmoji
                    value={comment}
                    onChange={(e) => {
                      setComment(e);
                    }}
                    placeholder='Comment'
                  />
                  {/* <SmileOutlined
                    onClick={() => setShowEmoji(!showEmoji)}
                    className="h4 mt-2"
                  />
                  {showEmoji && <Picker onEmojiClick={onEmojiClick} />}{" "} */}
                </div>
                <div className='col-md-3 '>
                  <Button
                    htmlType='submit'
                    lite
                    size={"large"}
                    onClick={() => {
                      addComment(timeline?.id);
                      sendEmailForComment();
                    }}
                    // style={{ marginLeft: isMobile ? 0 : 20 }}
                    title='Post'
                    className='mt-2'
                  />
                </div>
              </div>
            </>
          )}
        </Panel>
      )}
      {/* + */}
      {/* Number(sharedCount?.[0]?.filter((i) => i === true)?.length */}
      {!timeline?.hideShare && (
        <Panel
          header={
            <h6 className='text-light-gray font-white mt-1'>
              Shared {`(${Number(sum)})`} Viewed {`(${timeline?.view?.length})`}
            </h6>
          }
          key='2'
        >
          <div className='row'>
            {/* {timeline?.sharedMember.length ? (
              <div className="col-md-12">
                <span className="h5"> Shared</span>
              </div>
            ) : (
              ""
            )} */}
            <div className='col-md-12'>
              <span className='h5'> Shared</span>
            </div>
            <div className='col-md-12 mt-3'>
              {studioMembers?.map(
                (member) =>
                  joinedUser(member?.id, timeline?.sharedMember) && (
                    <>
                      <img
                        src={`https://movitanz.com/upload/static/user.jpeg`}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `${process.env.PUBLIC_URL}user.png`;
                        }}
                        width={30}
                      />{" "}
                      <span className={`text-light-gray  mx-2`}>
                        {member?.name}
                      </span>
                    </>
                  )
              )}
            </div>
            <div className='col-md-12 mt-3'>
              {studios?.map((stu) =>
                stu.group.map(
                  (group) =>
                    joinedUser(group?._id, timeline?.sharedGroup) && (
                      <>
                        <img
                          src={`https://movitanz.com/upload/static/group.jpeg`}
                          width={30}
                        />{" "}
                        <span className={`text-light-gray mx-2 `}>
                          {group?.groupName}
                        </span>
                      </>
                    )
                )
              )}
            </div>
            {/* 
            {timeline?.view.length ? (
              <div className="col-md-12 mt-4">
                <span className="h5"> Viewed</span>
              </div>
            ) : (
              ""
            )} */}
            <div className='col-md-12 mt-4'>
              <span className='h5'> Viewed</span>
            </div>
            <div className='col-md-12 mt-3'>
              {studioMembers?.map(
                (member) =>
                  joinedUser(member?.id, timeline?.view) && (
                    <>
                      <img
                        src={`https://movitanz.com/upload/static/user.jpeg`}
                        //   onError={(e) => {
                        //   e.target.onerror = null;
                        //   e.target.src = `${process.env.PUBLIC_URL}user.png`;
                        // }}
                        width={30}
                      />{" "}
                      <span className={`text-light-gray  mx-2 `}>
                        {member?.name}
                      </span>
                    </>
                  )
              )}
            </div>
          </div>
        </Panel>
      )}
    </Collapse>
  );
};

export default Comment;
