import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import {  Form } from "antd";
import React, { useContext } from "react";
import { useState } from "react";
import { Button } from "../../../../components/_shared";
import {
  GrayInputField,
} from "../../../../components/_shared/Input";
import Loading from "../../../../components/_shared/Loader/Loading";
import profile from "../../../../services/profile";
import { notify } from "../../../../utils";


const ChangePassword = () => {

  const [showpass, setShowpass] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showOld, setShowOld] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm();


  const onChangePassword = async(e) => {
    try {
      setIsLoading(true)
        const res = await profile.changePassowrd({newPassword :e?.password, oldPassword : e?.oldPassword })
        if(res?.status === 200) {
          setIsLoading(false)
          form.resetFields();
          notify("success", "Password changed successfully!")
        } 
    }

    catch(err) {
      setIsLoading(false)
      notify("error", err)
    }
  }

  return (
    <div className="container bg-white p-5 ">
      <Loading isLoading={isLoading} />

      <Form
        layout="vertical"
        className="row "
        form = {form}
        // style={{ width: "100%" }}
        onFinish={onChangePassword}
      >
        <div className="col-md-4 ">
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[{ required: true, message: "Please enter old password." }]}
          >
            <GrayInputField
              type={showOld ? "text" : "password"}
              suffix={
                showOld ? (
                  <EyeInvisibleOutlined
                    onClick={() => {
                      setShowOld(!showOld);
                    }}
                  />
                ) : (
                  <EyeOutlined
                    onClick={() => {
                      setShowOld(!showOld);
                    }}
                  />
                )
              }
              className="font-600"
              placeholder="Old Password"
            />
          </Form.Item>
        </div>

        <div className="col-md-4 ">
          <Form.Item
            label="New Password"
            name="password"
            rules={[{ required: true, message: "Please enter new password" }]}
          >
            <GrayInputField
              type={showpass ? "text" : "password"}
              suffix={
                showpass ? (
                  <EyeInvisibleOutlined
                    onClick={() => {
                      setShowpass(!showpass);
                    }}
                  />
                ) : (
                  <EyeOutlined
                    onClick={() => {
                      setShowpass(!showpass);
                    }}
                  />
                )
              }
              className="font-600"
              placeholder="New Password"
            />
          </Form.Item>
        </div>

        <div className="col-md-4 ">
          <Form.Item
            label="Confirm New Password"
            name="cpassword"
            dependencies={["password"]}
            // hasFeedback
            rules={[
              { required: true, message: "please enter confirm new password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords entered do not match. Please try again,"
                    )
                  );
                },
              }),
            ]}
          >
            <GrayInputField
              type={showConfirm ? "text" : "password"}
              suffix={
                showConfirm ? (
                  <EyeInvisibleOutlined
                    onClick={() => {
                      setShowConfirm(!showConfirm);
                    }}
                  />
                ) : (
                  <EyeOutlined
                    onClick={() => {
                      setShowConfirm(!showConfirm);
                    }}
                  />
                )
              }
              className="font-600"
              placeholder="Confirm New Password"
            />
          </Form.Item>
        </div>

        <div className=" col-md-12 mt-4 ">
          <div className="d-flex justify-content-center">
            <Button
              htmlType="submit"
              lite
              size={"large"}
              // style={{ marginLeft: isMobile ? 0 : 20 }}
              title="Update Password"
              className=""
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ChangePassword;
