import { call, put, takeLatest } from "redux-saga/effects";

import studioActionTypes from "../actionTypes/sutdio";
import studioAPI from "../../services/studio";
import { notify } from "../../utils";

function* fetchStudio({ payload }) {
  try {
    const res = yield call(studioAPI.getJoinedStudio, payload);
    if (res.status === 200) {
      yield put({
        type: studioActionTypes.FETCH_JOINED_STUDIO_SUCCESS,
        studio: res?.data?.studio,
      });
      
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: studioActionTypes.FETCH_JOINED_STUDIO_FAILED });
  }
}

function* joinStudio({ payload }) {
  try {
    const res = yield call(studioAPI.joinStudio, payload);

    if (res.status === 200) {
      notify("success", "Studio joined sucessfully.");
      yield put({ type: studioActionTypes.JOIN_STUDIO_SUCCESS });
      yield call(fetchStudio,{payload : ""});
      yield call(fetchMyGroups, {payload : ""});
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: studioActionTypes.JOIN_STUDIO_FAILED });
  }
}

function* leftStudio({ payload }) {
  try {
    const res = yield call(studioAPI.leftStudio, payload);

    if (res.status === 200) {
      yield put({ type: studioActionTypes.LEFT_STUDIO_SUCCESS });
      yield call(fetchMyGroups, {payload : ""});
      yield call(fetchStudio,{payload : ""});
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: studioActionTypes.LEFT_STUDIO_FAILED });
  }
}

function* fetchStudioMembers({ payload }) {
  try {
    const res = yield call(studioAPI.getStudioMembers, payload);

    if (res?.status === 200) {
      yield put({
        type: studioActionTypes.FETCH_STUDIO_MEMBERS_SUCCESS,
        studioMembers: res?.data?.studioMembers,
      });
    }
  } catch (err) {
    // notify("error",JSON.stringify(err));
    yield put({ type: studioActionTypes.FETCH_STUDIO_MEMBERS_FAILED });
  }
}

function* fetchMyGroups() {
  try {
    const res = yield call(studioAPI.fetchMyGroups);

    if (res?.status === 200) {
      yield put({
        type: studioActionTypes.FETCH_STUDIO_GROUP_SUCCESS,
        groups: res?.data?.groups,
        studioName:res?.data?.owener
      });
    }
  } catch (err) {
    yield put({ type: studioActionTypes.FETCH_STUDIO_GROUP_FAILED });
  }
}

function* addGroup({ payload }) {
  try {
    const res = yield call(studioAPI.addGroup, payload?.data);

    if (res?.status === 200) {
      yield put({ type: studioActionTypes.ADD_GROUP_SUCCESS });
      payload.setShowAddGroup(false);
      yield call(fetchStudio,{payload : ""});
      yield call(fetchMyGroups, {payload : ""});
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: studioActionTypes.ADD_GROUP_FAILED });
  }
}

function* deleteGroup({ payload }) {
  try {
    const res = yield call(studioAPI.deleteGroup, payload?.data);

    if (res?.status === 200) {
      yield put({
        type: studioActionTypes.DELETE_GROUP_SUCESS,
        groups: res?.data?.groups,
      });
      payload.setShowManageGroup(false);
      yield call(fetchStudio,{payload : ""});
        yield call(fetchMyGroups, {payload : ""});
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: studioActionTypes.DELETE_GROUP_FAILED });
  }
}

function* addGroupMember({ payload }) {
  try {
    if(payload.isLoading) {
      payload.setIsLoading(true)
    }
    const res = yield call(
      studioAPI.addGroupMembers,
      payload.gid,
      payload.data
    );

    if (res?.status === 200) {
      yield put({ type: studioActionTypes.ADD_GROUP_MEMBER_SUCCESS });
      if (payload.showGroup) payload.setShowGroup(false);
      if(payload.isLoading) {
        payload.setIsLoading(false)
      }
      yield call(fetchStudio,{payload : ""});
        yield call(fetchMyGroups, {payload : ""});
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    if(payload.isLoading) {
      payload.setIsLoading(false)
    }
    yield put({ type: studioActionTypes.ADD_GROUP_MEMBER_FAILED });
  }
}

function* deleteGroupMember({ payload }) {
  try {
    if(payload.isLoading) {
      payload.setIsLoading(true)
    }
    const res = yield call(
      studioAPI.deleteGroupMember,
      payload?.gid,
      payload?.mid
    );

    if (res?.status === 200) {
      if(payload.isLoading) {
        payload.setIsLoading(false)
      }
      yield put({
        type: studioActionTypes.DELETE_GROUP_MEMBER_SUCESS,
        groups: res?.data?.groups,
      });
      if (payload.showGroup) payload.setShowGroup(false);
        yield call(fetchMyGroups, {payload : ""});
      yield call(fetchStudio,{payload : ""});
    }
  } catch (err) {
    if(payload.isLoading) {
      payload.setIsLoading(false)
    }
    notify("error",JSON.stringify(err));
    yield put({ type: studioActionTypes.DELETE_GROUP_MEMBER_FAILED });
  }
}

export function* watchStudio() {
  yield takeLatest(studioActionTypes.FETCH_JOINED_STUDIO_INIT, fetchStudio);
  yield takeLatest(studioActionTypes.JOIN_STUDIO_INIT, joinStudio);
  yield takeLatest(studioActionTypes.LEFT_STUDIO_INIT, leftStudio);
  yield takeLatest(
    studioActionTypes.FETCH_STUDIO_MEMBERS_INIT,
    fetchStudioMembers
  );
  yield takeLatest(studioActionTypes.FETCH_STUDIO_GROUP_INIT, fetchMyGroups);
  yield takeLatest(studioActionTypes.ADD_GROUP_INIT, addGroup);
  yield takeLatest(studioActionTypes.DELETE_GROUP_INIT, deleteGroup);
  yield takeLatest(studioActionTypes.ADD_GROUP_MEMBER_INIT, addGroupMember);
  yield takeLatest(
    studioActionTypes.DELETE_GROUP_MEMBER_INIT,
    deleteGroupMember
  );
}
