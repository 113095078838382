import { Tabs } from "antd";
import React, { useState } from "react";
import { CurrentMembers, Groups ,StudioProfile} from "./component";
import { Container } from "./component/component";
const { TabPane } = Tabs;

const Studio = () => {
  const [defaultActiveKey, setActivekey] = useState(localStorage.studioPathKey ?localStorage.studioPathKey  : "1");

  return (
    <Container className="container bg-white mt-5 p-5 shadow">
      <Tabs
        activeKey={String(defaultActiveKey)}
        onChange={(e) => {
          setActivekey(e);
          localStorage.setItem("studioPathKey", e)
        }}
        // defaultActiveKey={defaultActiveKey}
        type="line"
        tabBarGutter={0}
      >
        <TabPane
          className={defaultActiveKey == 1 ? "mb-4" : ""}
          tab={<div className="tab_tabName">Groups</div>}
          key="1"
        />

        <TabPane
          className={defaultActiveKey == 2 ? "mb-4" : ""}
          tab={<div className="tab_tabName">Current Members</div>}
          key="2"
        />

        {/* <TabPane
          className={defaultActiveKey == 3 ? "mb-4" : ""}
          tab={<div className="tab_tabName">Organization Details</div>}
          key="3"
        /> */}
      </Tabs>

       { defaultActiveKey == "1"&& <Groups/>}
       {defaultActiveKey == "2" && <CurrentMembers/>}
       {/* {defaultActiveKey == "3" && <StudioProfile/>} */}
    </Container>
  );
};

export default Studio;
