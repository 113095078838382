import { createConstants } from "redux-model-tools";

export default createConstants("portfolio", [
  "GET_PORTFOLIO_INIT",
  "GET_PORTFOLIO_FAILED",
  "GET_PORTFOLIO_SUCCESS",

  "CREATE_PORTFOLIO_INIT",
  "CREATE_PORTFOLIO_FAILED",
  "CREATE_PORTFOLIO_SUCCESS",

  "UPDATE_PORTFOLIO_INIT",
  "UPDATE_PORTFOLIO_FAILED",
  "UPDATE_PORTFOLIO_SUCCESS",

  "DELETE_PORTFOLIO_INIT",
  "DELETE_PORTFOLIO_FAILED",
  "DELETE_PORTFOLIO_SUCCESS",

  "GET_SHARE_MY_PORTFOLIO_INIT",
  "GET_SHARE_MY_PORTFOLIO_FAILED",
  "GET_SHARE_MY_PORTFOLIO_SUCCESS",

  "SHARE_PORTFOLIO_INIT",
  "SHARE_PORTFOLIO_FAILED",
  "SHARE_PORTFOLIO_SUCCESS",

  "UN_SHARE_PORTFOLIO_INIT",
  "UN_SHARE_PORTFOLIO_FAILED",
  "UN_SHARE_PORTFOLIO_SUCCESS",

  "GET_PORTFOLIO_TIMELINE_INIT",
  "GET_PORTFOLIO_TIMELINE_FAILED",
  "GET_PORTFOLIO_TIMELINE_SUCCESS",

  "GET_PORTFOLIO_BY_ID_INIT",
  "GET_PORTFOLIO_BY_ID_FAILED",
  "GET_PORTFOLIO_BY_ID_SUCCESS",

  "DELETE_PORTFOLIO_COMMENT_INIT",
  "DELETE_PORTFOLIO_COMMENT_FAILED",
  "DELETE_PORTFOLIO_COMMENT_SUCCESS",

  "CREATE_PORTFOLIO_COMMENT_INIT",
  "CREATE_PORTFOLIO_COMMENT_FAILED",
  "CREATE_PORTFOLIO_COMMENT_SUCCESS",
]);
