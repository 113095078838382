import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../../../components/_shared";
import Loading from "../../../../components/_shared/Loader/Loading";
import program from "../../../../services/program";
import { notify, SERVER_URL } from "../../../../utils";
import { joinedUser } from "../../../../utils/joinedUser";
import StripeProgram from "./StripeProgram";

const Store = ({ userData }) => {
  const [programs, setPrograms] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMyPrograms();
  }, []);

  const fetchMyPrograms = async () => {
    try {
      setIsLoading(true);

      const res = await program.fetchApprvedProgram();

      if (res.status === 200) {
        setPrograms(res?.data?.programs);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);

      notify("error", "Something went wrong");
    }
  };

  const subscribeProgram = async (id, payload) => {
    try {
      const res = await program.subscribeProgram(id, payload);
      if (res.status === 200) {
        notify("success", "Program subscribe successfully");
      }
    } catch (err) {
      notify("error", "Something went wrong");
    }
  };

  return (
    <>
      <InputField
        value={searchField}
        onChange={(e) => setSearchField(e.target.value)}
        placeholder="Search"
        suffix={<SearchOutlined />}
        style={{ width: "350px" }}
      />
      <Loading isLoading={isLoading} />

      {programs
        ?.filter((program) =>
          `${program?.title}`.toLowerCase().includes(searchField.toLowerCase())
        )
        ?.map((program, index) => !joinedUser(userData?.id, program?.purchasedBy  ) && (
          <div className="row p-4 bg-secondary-gray mt-3  border-20">
            <div className="col-md-12 d-flex justify-content-between mb-2">
              <span style={{ fontWeight: 600 }} className="h6">
                Price :<span> ${program?.price}</span>
              </span>
            </div>
            <div className="col-md-1 my-auto">
              <img
                src={`${program?.img}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${process.env.PUBLIC_URL}user.png`;
                }}
                width={"80px"}
                alt=""
              />
            </div>
            <div className="col-md-11 flex-column ">
              <div className="">
                <h5 className="cursor-pointer"> {program?.title}</h5>
              </div>
              <div>
                <span>{program?.description}</span>
              </div>
            </div>

            <div className="col-md-5 flex-column">
              {/* <div>
          <span>Shared with 305 Movitae users</span>
        </div> */}
            </div>  
            {userData?.id !== program?.user ? (
              <div className="col-md-12 d-flex justify-content-end mt-4">
                <StripeProgram
                  subscribeProgram={subscribeProgram}
                  id={program?.id}
                  title={program?.title}
                  price={program?.price}
                />
              </div>
            ) : (
              <div className="col-md-12 d-flex justify-content-end mt-4">
                <button
                  onClick={() => navigate(`/program/${program?.id}/view`)}
                  className="btn btn-success"
                >
                  View
                </button>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

export default Store;
