import React from "react";
import { Button as AppButton } from "antd";

const Button = React.forwardRef(
  ({ lite, size, type, title, ...props }, ref) => {
    if (ref) props.ref = ref;

    return lite ? (
      <AppButton
        style={{
          background: "white",
          color: "white",
          border: "1px solid #605A5B",
          background:" #605A5B",
          marginRight: 20,
          fontFamily : "Poppins"
        }}
        {...props}
        type={type}
        size={size}
      >
        {title}
      </AppButton>
    ) : (
      <AppButton
       
        {...props}
        type={type}
        size={size}
      >
        {title}
      </AppButton>
    );
  }
);

export default Button;
