import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import { Container } from "./component/component";
import { MyPortfolio, SharedWithMe } from "./component";
import { UserContext } from "../../../context/userInfo";
import { ViewProfileContext } from "../../../context/viewProfile";
import { useNavigate } from "react-router-dom";
import useViewport from "../../../hooks/useViewport";
import { notify } from "../../../utils";
import portfolio from "../../../services/portfolio";
import Loading from "../../../components/_shared/Loader/Loading";
const { TabPane } = Tabs;

const Studio = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const [defaultActiveKey, setActivekey] = useState(
    userData?.role !== "student" ? "1" : "2"
  );
  const { profileToken } = useContext(ViewProfileContext);
  const navigate = useNavigate();
  const { isMobile } = useViewport();

  const [portfolios, setPortfolios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);



  if (!profileToken?.access?.token && userData?.role === "admin") {
    navigate("/manage-user");
  }

  useEffect(() => {
    getUserinfo();
    fetchMyPortoflio()
  }, []);

  const fetchMyPortoflio = () => {
    setIsLoading(true);
    portfolio
      .getPortfolio(profileToken?.access?.token)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setPortfolios(res?.data?.portfolios);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        // notify("error", JSON.stringify(err));
      });
  };

  return (
    <Container className="container bg-white mt-5 p-5 shadow">
        <Loading isLoading = {isLoading}/>
      <div className="row ">
        <div className="col-md-12">
          <Tabs
            activeKey={String(defaultActiveKey)}
            onChange={(e) => {
              setActivekey(e);
              localStorage.setItem("studioPathKey", e);
            }}
            // defaultActiveKey={defaultActiveKey}
            type="line"
            tabBarGutter={0}
          >
            {/* {userData?.role !== "student" && ( */}
              <TabPane
                className={defaultActiveKey == 1 ? "mb-4" : ""}
                tab={
                  <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                   Shared with me 
                  </div>
                }
                key="1"
              />
            {/* // )} */}
            <TabPane
              className={defaultActiveKey == 2 ? "mb-4" : ""}
              tab={
                <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                  My Portfolios
                </div>
              }
              key="2"
            />
          </Tabs>
        </div>
      </div>
      {/* && userData?.role !== "student" &&  */}
      {defaultActiveKey == "1" &&(
        <SharedWithMe />
      )}
      {defaultActiveKey == "2" && <MyPortfolio fetchMyPortoflio={fetchMyPortoflio} portfolios={portfolios} /> }
    </Container>
  );
};

export default Studio;
