import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Tabs, Form, Upload, Button } from "antd";
import ModalWrapper from "../../../../components/Modal";

import Checkbox from "antd/lib/checkbox/Checkbox";
import { notify, SERVER_URL } from "../../../../utils";
import { joinedUser } from "../../../../utils/joinedUser";
import timeline from "../../../../services/timeline";

const PortfolioModal = ({ portfolios, visible, setVisible, timelineId,fetchMyPortoflio }) => {
  const [checkbox, setCheckbox] = useState([]);

  useEffect(() => {
    let arr = []
    for(let i of portfolios) {
      if(joinedUser(timelineId, i?.timeline)) {
        arr.push(i?.id)
      }
    }

    setCheckbox(arr)
  },[])


  const addToPortfolio = () => {
    timeline.addToPortfolio(timelineId, checkbox).then(res => {
      if(res?.status === 200) {
        fetchMyPortoflio()
        setVisible(false)
        notify("success",'Timeline added to portfolio successfully')
      }
    }).catch(err => {
      notify("error",err)
    })
  }

  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <div className="row">
          <div className="col-md-12">
            <h3>Add to Portfolio</h3>
          </div>
          <div className="col-md-12">
            <Divider />
          </div>
          <div className="col-md-12">
            {portfolios?.map((portfolio) => (
              <div className="row " key={portfolio?.id}>
                <div className="col-md-12 d-flex align-content-center">
                  <Checkbox
                    className="mx-1  mt-3"
                    onChange={(e) => {
                      if (e.target.checked)
                        setCheckbox([...checkbox, portfolio?.id]);
                      else
                        setCheckbox(
                          checkbox.filter((check) => portfolio?.id !== check)
                        );
                    }}
                    defaultChecked={joinedUser(timelineId, portfolio?.timeline)}
                  />
                  <img
                    width={60}
                    src={`${portfolio?.portfolioPic}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `${process.env.PUBLIC_URL}user.png`;
                    }}
                    className="mx-4 "
                  />
                  <span className="h5 mt-3"> {portfolio?.title}</span>
                </div>
                <div className="col-md-12">
                  <Divider />
                </div>
              </div>
            ))}

            <div
              className="center-column"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                htmlType="submit"
                size={"large"}
                type="primary"
                className="rounded "
                // style = {{heigth : "200px"}}
                onClick  = {addToPortfolio}
              >
                <h5 className="font-white">Save</h5>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default PortfolioModal;
