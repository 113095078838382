import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import {
	FileAddOutlined,
	PlusSquareOutlined,
	StarFilled,
	StarOutlined,
} from "@ant-design/icons";
import { HeaderTab } from "../components";
import { Button, Progress, Select } from "antd";
import { TimelineWraaper } from "./components/component";
import { Post, TimelineModal } from "./components";
import { useDispatch, useSelector } from "react-redux";

import {
	makeSelectMembers,
	makeSelectStudio,
	makeSelectTimeline,
	makeSelectTimelineComment,
	makeSelectTimelineLoading,
} from "../../../store/selectors";
import { UserContext } from "../../../context/userInfo";
import {
	createTimelineInit,
	deleteTimelineInit,
	getCommentInit,
	getTimelineInit,
	updateTimelneInit,
} from "../../../store/actions/timeline";
import { notify, SERVER_URL } from "../../../utils";
import ShareTimeline from "./components/ShareTimeline";
import {
	fetchJoinedStudioInit,
	fetchMyGroupsInit,
	fetchStudioMembers,
} from "../../../store/actions/studio";
import { ViewProfileContext } from "../../../context/viewProfile";
import { apiPost } from "../../../utils/axios";
import { InputField } from "../../../components/_shared";
import {
	GrayDatePicker,
	GrayInputField,
} from "../../../components/_shared/Input";
import portfolio from "../../../services/portfolio";
import PortfolioModal from "./components/PortfolioModal";
import UpdateTimelneModal from "./components/UpdateTImelineModal";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../components/_shared/Loader/Loading";
import { MEDIA_URL } from "../../../utils/server";
import axios from "axios";
import ReportModal from "./components/ReportModel";
import moment from "moment";
import timeline from "../../../services/timeline";
import { isExpired, sotrageUsedPercent } from "../../../utils/utils";
import { VideoUpdateContext } from "../../../context/UpdateVideo";
import OldPaymentDetailsUpdate from "./components/OldPaymentDetailsUpdate";
const { Option } = Select;

const FilterTestName = {
	title: "Title",
	name: "Tag",
	description: "Description",
};

const Timeline = () => {
	const location = useLocation();

	const params = new URLSearchParams(location.search);

	const [showTimelineModal, setShowTimelineModal] = useState(false);
	const [award, setAward] = useState(false);
	const { uoloadVideotoVimeo, progress, timeline_id } =
		useContext(VideoUpdateContext);

	const timelines = useSelector(makeSelectTimeline());
	const [timelineId, setTimelineId] = useState("");
	const [limit, setLimit] = useState(5);
	const [commentLimit, setCommentLimit] = useState(2);
	const { userData, getUserinfo, setIsLoggedIn } = useContext(UserContext);
	const [shareCheck, setShareCheck] = useState([]);
	const [shareTimeline, setShareTimeline] = useState({});
	const [shareModalShow, setShareModalShow] = useState(false);
	const studios = useSelector(makeSelectStudio());
	const studioMembers = useSelector(makeSelectMembers());
	const [singleStudio, setSingleStudio] = useState("");
	const [filterTest, setFilterTest] = useState("");
	const { profileToken, viewUser } = useContext(ViewProfileContext);
	const [groupFilter, setGroupFilter] = useState("");
	const [memberFilter, setMemberfilter] = useState("");
	const [favFilter, setFavFilter] = useState(false);
	const timelineLoading = useSelector(makeSelectTimelineLoading());
	const [vimeoLoading, setVimeoLoading] = useState(false);
	const [hideShare, setHideShare] = useState(false);
	const [makeHideCopy, setHideMakeCopy] = useState(false);
	const [hideComment, setHideComment] = useState(false);
	const [filterType, setFilterType] = useState("");
	const [portfolios, setPortfolios] = useState([]);
	const [showPortfolioModal, setShowPortfolioModal] = useState(false);
	const [tid, setTid] = useState("");
	const [updateTimeline, setUpdateTimeline] = useState("");
	const [showUpdate, setShowUpdate] = useState(false);
	const [date, setDate] = useState("");
	const [portfolioLoading, setPortfolioLoading] = useState(false);
	const [uploadLoading, setUploadLoading] = useState(false);
	const [showReport, setShowReport] = useState(false);
	const [search, setSearch] = useState("");
	const [description, setDescription] = useState("");
	const [timelineVimeo, setTimelineVimeo] = useState("");
	const [timelineVidoes, setTiemlineVideos] = useState([]);
	const [storageLimit, setStorageLimit] = useState("");
	const [reportedTimeline, setReportedTimeline] = useState("");

	const dispatch = useDispatch();
	const navigate = useNavigate();

	if (!profileToken?.access?.token && userData?.role === "admin") {
		navigate("/manage-user");
	}

	useEffect(() => {
		localStorage.getItem('authToken') && setIsLoggedIn(true);
		getUserinfo();
		dispatch(
			fetchJoinedStudioInit({ profileToken: profileToken?.access?.token })
		);
		dispatch(fetchStudioMembers({ profileToken: profileToken?.access?.token }));
		fetchMyPortoflio();
		if (params?.get("id")) {
			setSingleStudio(0);
			setGroupFilter(params?.get("id"));
		}
	}, []);

	useEffect(() => {
		// console.log( search ? search : "",)
		dispatch(
			getTimelineInit({
				page: 1,
				limit,
				profileToken: profileToken?.access?.token,
				fav: singleStudio == "1" ? "" : favFilter,
				notViewed: singleStudio != "1" ? (singleStudio == "3" ? true : "") : "",
				notShared: singleStudio != "1" ? (singleStudio == "4" ? true : "") : "",
				group: singleStudio != "1" ? (groupFilter ? groupFilter : "") : "",
				date: singleStudio != "1" ? (date ? date : false) : false,
				member: memberFilter ? memberFilter : "",
				search: search ? search : "",
				key: filterTest ? filterTest : "",
			})
		);
	}, [limit, groupFilter]);

	useEffect(() => {
		if (!shareModalShow) {
			refreshTimeline();
		}
	}, [shareModalShow, favFilter]);

	useEffect(() => {
		if (timelineVimeo) {
			// uploadTimelineInVimeo(timelineVimeo);
			uoloadVideotoVimeo(
				timelineVimeo,
				timelineVidoes,
				setTimelineVimeo,
				setTiemlineVideos
			);
		}
	}, [timelineVimeo]);

	// const uploadTimelineInVimeo = (tid) => {
	//   let formData = new FormData();
	//   for (let video of timelineVidoes) {
	//     // let videoId = await onUpload(video?.originFileObj);
	//     formData.append("videoId", video?.originFileObj);
	//   }

	//   formData.append("tid", tid);

	//   timeline
	//     .uploadTimelineVimeo(formData)
	//     .then((res) => {
	//       setTimelineVimeo("");
	//       setTiemlineVideos("");
	//     })
	//     .catch((err) => {});
	// };

	const handleFilterByGroup = (e) => {
		setGroupFilter(e);
		setFavFilter(false);
		setDate("");
		dispatch(
			getTimelineInit({
				page: 1,
				limit,
				profileToken: profileToken?.access?.token,
				group: e,
				notViewed: e != "1" ? (e == "3" ? true : false) : false,
				notShared: e != "1" ? (e == "4" ? true : false) : false,
				group: false,
			})
		);
	};

	const handleFilterByMember = (e) => {
		setMemberfilter(e);
		setFavFilter(false);
		setDate("");

		dispatch(
			getTimelineInit({
				page: 1,
				limit,
				profileToken: profileToken?.access?.token,
				member: e,
				notViewed: false,
				notShared: false,
				group: false,
			})
		);
	};

	useEffect(() => {
		if (singleStudio == "1" || singleStudio == "3" || singleStudio == "4") {
			setFavFilter(false);
			setGroupFilter("");
			refreshTimeline();
		}
	}, [singleStudio]);

	const refreshTimeline = () => {
		// setSearch("");
		dispatch(
			getTimelineInit({
				page: 1,
				limit,
				profileToken: profileToken?.access?.token,
				fav: singleStudio == "1" ? false : favFilter,
				notViewed:
					singleStudio != "1" ? (singleStudio == "3" ? true : false) : false,
				notShared:
					singleStudio != "1" ? (singleStudio == "4" ? true : false) : false,
				group:
					singleStudio != "1" ? (groupFilter ? groupFilter : false) : false,
				date: singleStudio != "1" ? (date ? date : false) : false,
				member: memberFilter ? memberFilter : "",
				search: search ? search : "",
				key: filterTest ? filterTest : "",
			})
		);
	};

	const onSubmitTimeline = async (e, googledata, dropboxData) => {
		setUploadLoading(true);
		let size = 0;
		// let formData = new FormData();

		let formData = {
			postPic: award ? ["https://movitanz.com/upload/static/movitanz.png"] : [],
			youtubeUrl: [],
			vimeoUrl: [],
			title: e?.title,
			timelineDate: e?.date ? moment(e?.date) : moment(new Date()),
			hideComment,
			description: description ? description : "",
			hideShare,
			makeCopy: makeHideCopy,
			totalVideos: e?.videos?.fileList.length,
			signupCode: userData?.signupCode,
			isS3: true,
			googleData: googledata,
			dropboxData: dropboxData,
		};

		if (e.timlineLastDate) {
			formData.timlineLastDate = moment(e?.timlineLastDate);
		}

		if (e?.files?.fileList.length)
			for (let file of e?.files?.fileList) {
				const url = await onFileUpload(file?.originFileObj);
				size = size + file?.size / 1024;
				formData.postPic.push(url);
			}
		if (e?.youtubeUrls)
			for (let url of e?.youtubeUrls) formData.youtubeUrl.push(url?.youtubeUrl);
		// formData.append("youtubeUrl", url?.youtubeUrl);
		if (e?.vimeoUrls) {
			//  formData.append("vimeoUrl", url?.vimeoUrl);
			for (let url of e?.vimeoUrls) formData?.vimeoUrl.push(url?.vimeoUrl);
		}

		if (e?.videos?.fileList?.length) {
			// formData.append("totalVideos", e?.videos?.fileList?.length);

			for (let video of e?.videos?.fileList) {
				size = size + video?.size / 1024;
			}
			setTiemlineVideos(e?.videos?.fileList);
		}

		// if(userData?.role === "studioOwner") {

		//   if(userData?.storageLimitRemaining <= 0) {
		//     navigate("/upgrade-storage")
		//   }
		// }

		// if (e?.videos?.fileList?.length) {
		//   for (let video of e?.videos?.fileList) {
		//     // let videoId = await onUpload(video?.originFileObj);
		//     formData.append("totalVideos", video?.originFileObj);
		//   }
		// }

		// if(e?.videos?.fileList?.length){
		//           formData.append("totalVideos", e?.videos?.fileList?.length);
		//           setTiemlineVideos(e?.videos?.fileList)

		// }

		// formData.append("title", e?.title);
		// formData.append("timelineDate", moment(e?.date));
		// formData.append("hideComment", hideComment);
		// formData.append("description", description ? description : "");
		// formData.append("makeCopy", makeHideCopy);
		// formData.append("hideShare", hideShare);

		dispatch(
			createTimelineInit({
				formData: { ...formData, size },
				setVisible: setShowTimelineModal,
				setShareTimeline,
				setShareModalShow,
				shareModalShow: true,
				setTimelineVimeo,
			})
		);

		setUploadLoading(false);
		setDescription("");

		// else
		//   dispatch(
		//     createTimelineInit({
		//       youtubeUrl: e?.youtubeUrls ,
		//       title: e?.title,
		//       vimeoUrl: e?.vimeoUrls,
		//       description: description,
		//       timelineDate: e?.date,
		//     })
		//   );

		setTimeout(() => {
			refreshTimeline();
			getUserinfo();
		}, 100);
	};

	const onFileUpload = async (file) => {
		try {
			let formData = new FormData();
			formData.append("avatar", file);
			const res = await axios.post(MEDIA_URL, formData);
			return res?.data?.message;
		} catch (err) {
			notify("error", "Something went wrong");
		}
	};

	const onUpdateTimeline = async (
		e,
		video,
		oldUploadedpost,
		googleData,
		dropboxData
	) => {
		let size = 0;
		let formData = {
			title: e?.title,
			timelineDate: moment(e?.date),
			hideComment: e?.hideComment,
			description: description ? description : "",
			makeCopy: e?.makeCopy,
			hideShare: e?.hideShare,
			vimeoUrl: [],
			youtubeUrl: [],
			postPic: [],
			videoId: video,
			isUploaded: e?.videos?.fileList.length > 0 ? false : true,
			countVideo:
				e?.videos?.fileList.length > 0 ? e?.videos?.fileList.length : 0,
			googleData: googleData,
			dropboxData: dropboxData,
		};

		if (e?.youtubeUrls)
			for (let url of e?.youtubeUrls)
				formData?.youtubeUrl.push(url?.youtubeUrl);
		if (e?.vimeoUrls)
			for (let url of e?.vimeoUrls) formData?.vimeoUrl.push(url?.vimeoUrl);
		let oldData = [...oldUploadedpost];
		if (e?.files?.fileList.length) {
			for (let file of e?.files?.fileList) {
				const url = await onFileUpload(file?.originFileObj);
				oldData?.push(url);
			}
		}
		if (!!e.timlineLastDate) {
			formData.timlineLastDate = moment(e?.timlineLastDate);
		} else {
			formData.timlineLastDate = null;
		}
		formData.postPic = oldData;
		if (e?.videos?.fileList?.length) {
			// for (let video of e?.videos?.fileList) {
			//   formData.videoId = [];
			//   let videoId = await onUpload(video?.originFileObj);
			//   formData?.videoId?.push(videoId);
			// }
			for (let video of e?.videos?.fileList) {
				size = size + video?.size / 1024;
			}
			setTiemlineVideos(e?.videos?.fileList);
		}
		setTimelineVimeo(updateTimeline?.id);
		dispatch(
			updateTimelneInit({
				id: updateTimeline?.id,
				formData,
				setVisible: setShowUpdate,
				refreshTimeline: refreshTimeline,
			})
		);

		setDescription("");

		// else
		//   dispatch(
		//     createTimelineInit({
		//       youtubeUrl: e?.youtubeUrls ,
		//       title: e?.title,
		//       vimeoUrl: e?.vimeoUrls,
		//       description: description,
		//       timelineDate: e?.date,
		//     })
		//   );
		setDescription("");
	};

	const makeCopy = async (e) => {
		let formData = {
			timelineDate: e?.timelineDate,
			youtubeUrl: e?.youtubeUrl,
			vimeoUrl: e?.vimeoUrl,
			videoId: e?.videoId,
			title: e?.title,
			description: description,
			isPrivate: e?.isPrivate,
			postPic: e?.postPic,
			size: e?.size,
			signupCode: e?.signupCode,
		};

		dispatch(
			createTimelineInit({
				formData,
				setVisible: setShowTimelineModal,
				setShareTimeline,
				setShareModalShow,
			})
		);

		setTimeout(() => {
			refreshTimeline();
			getUserinfo();
		}, 2000);
	};

	const deleteTimeline = (id) => {
		if (window.confirm("Are you sure you want to delete this post?")) {
			dispatch(deleteTimelineInit(id));
			setTimeout(() => {
				refreshTimeline();
				getUserinfo();
			}, 2000);
		}
	};

	const fetchComment = (tid) => {
		dispatch(
			getCommentInit({
				tid,
				limit: commentLimit,
				page: 1,
				profileToken: profileToken?.access?.token,
			})
		);
	};

	useEffect(() => {
		if (timelineId) fetchComment(timelineId);
	}, [commentLimit]);
	useEffect(() => {
		dispatch(fetchMyGroupsInit());
	}, []);

	useEffect(() => {
		if (!filterTest && !search) {
			refreshTimeline();
		}
	}, [filterTest, search]);

	const onUpload = async (file) => {
		try {
			let formData = new FormData();
			formData.append("file", file);
			let res = await apiPost(
				SERVER_URL + "/timeline/vimeo/upload-video",
				formData
			);
			return res.data?.videoId;
		} catch (err) {
			notify("error", JSON.stringify(err));
		}
	};

	const fetchMyPortoflio = () => {
		setPortfolioLoading(true);
		portfolio
			.getPortfolio()
			.then((res) => {
				if (res.status === 200) {
					setPortfolioLoading(false);

					setPortfolios(res?.data?.portfolios);
				}
			})
			.catch((err) => {
				setPortfolioLoading(false);
				// notify("error", JSON.stringify(err));
			});
	};

	const filterByDate = (date, dateString) => {
		setFavFilter(false);
		setDate(date);
		dispatch(
			getTimelineInit({
				page: 1,
				limit,
				profileToken: profileToken?.access?.token,
				date: date && new Date(date),
			})
		);
	};

	const handleKeyDown = (event) => {
		// setSearch(event?.traget?.value);
		if (event.key === "Enter") {
			dispatch(
				getTimelineInit({
					page: 1,
					limit,
					profileToken: profileToken?.access?.token,
					fav: singleStudio == "1" ? false : favFilter,
					notViewed:
						singleStudio != "1" ? (singleStudio == "3" ? true : false) : false,
					notShared:
						singleStudio != "1" ? (singleStudio == "4" ? true : false) : false,
					group:
						singleStudio != "1" ? (groupFilter ? groupFilter : false) : false,
					date: singleStudio != "1" ? (date ? date : false) : false,
					member: memberFilter ? memberFilter : "",
					search: search ? search : "",
					key: filterTest ? filterTest : "",
				})
			);
			// dispatch(
			//   getTimelineInit({
			//     page: 1,
			//     limit: 3,
			//     profileToken: profileToken?.access?.token,
			//     search: event?.target?.value,
			//   })
			// );
		}
	};

	// console.log(studioMembers)

	return (
		<>
			{showTimelineModal && (
				<TimelineModal
					onSubmitTimeline={onSubmitTimeline}
					visible={showTimelineModal}
					checkbox={shareCheck}
					setCheckbox={setShareCheck}
					setVisible={setShowTimelineModal}
					timelineLoading={timelineLoading && vimeoLoading}
					setHideShare={setHideShare}
					setHideMakeCopy={setHideMakeCopy}
					setDescription={setDescription}
					description={description}
					setHideComment={setHideComment}
					studios={studios}
					award={award}
				/>
			)}
			{shareModalShow && (
				<ShareTimeline
					visible={shareModalShow}
					setVisible={setShareModalShow}
					shareTimeline={shareTimeline}
				/>
			)}
			{showPortfolioModal && (
				<PortfolioModal
					fetchMyPortoflio={fetchMyPortoflio}
					timelineId={tid}
					portfolios={portfolios}
					visible={showPortfolioModal}
					setVisible={setShowPortfolioModal}
				/>
			)}
			{showUpdate && (
				<UpdateTimelneModal
					onUpdateTimeline={onUpdateTimeline}
					visible={showUpdate}
					checkbox={shareCheck}
					setCheckbox={setShareCheck}
					timeline={updateTimeline}
					setVisible={setShowUpdate}
					timelineLoading={timelineLoading && vimeoLoading}
					setHideShare={setHideShare}
					setHideMakeCopy={setHideMakeCopy}
					setHideComment={setHideComment}
					setDescription={setDescription}
					description={description}
				/>
			)}
			{showReport && (
				<ReportModal
					title={reportedTimeline?.title}
					visible={showReport}
					setVisible={setShowReport}
				/>
			)}

			{userData?.role === "studioOwner" && !userData?.isPaymentUpdated ? (
				<OldPaymentDetailsUpdate
					getUserinfo={getUserinfo}
					userData={userData}
				/>
			) : (
				""
			)}
			<Loading
				isLoading={
					timelineLoading || portfolioLoading || uploadLoading ? true : false
				}
			/>
			{userData?.role === "studioOwner" || viewUser?.role === "studioOwner" ? (
				<div className="container">
					<div className="row d-flex justify-content-end">
						<div className="col-md-3">
							Storage
							<Progress
								className=" cursor-pointer"
								onClick={() => navigate("/current-plan")}
								trailColor="#fff"
								percent={
									profileToken
										? sotrageUsedPercent(
												viewUser?.storageLimit,
												viewUser?.storageLimitRemaining
										  ).percent
										: sotrageUsedPercent(
												userData?.storageLimit,
												userData?.storageLimitRemaining
										  ).percent
								}
								strokeColor={`${
									profileToken
										? sotrageUsedPercent(
												viewUser?.storageLimit,
												viewUser?.storageLimitRemaining
										  ).color
										: sotrageUsedPercent(
												userData?.storageLimit,
												userData?.storageLimitRemaining
										  ).color
								}`}
								showInfo={false}
								width={20}
							/>
							<div className="row d-flex justify-content-end">
								{profileToken
									? sotrageUsedPercent(
											viewUser?.storageLimit,
											viewUser?.storageLimitRemaining
									  ).percent.toFixed(2)
									: sotrageUsedPercent(
											userData?.storageLimit,
											userData?.storageLimitRemaining
									  ).percent.toFixed(2)}
								%
							</div>
						</div>
					</div>
				</div>
			) : (
				""
			)}
			<TimelineWraaper className="container-fluid bg-white p-2   mt-3">
				<div className="container px-5 py-3">
					<div className="row">
						<div className="col-md-3">
							<img
								src={
									userData?.role === "admin"
										? viewUser?.profilepic
										: userData?.profilepic
								}
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = `${process.env.PUBLIC_URL}user.png`;
								}}
								style={{
									maxWidth: "100%",
									maxHeight: "100%",
									objectFit: "cover",
									borderRadius: "10px",
								}}
								alt=""
								height="250"
							/>
						</div>
						<div className="col-md-9">
							<div className="row">
								<div className="col-md-12 mt-1">
									{profileToken ? (
										<h5>
											{viewUser?.role === "studioOwner"
												? "Studio Owner"
												: viewUser?.role === "teacher"
												? "Teacher"
												: viewUser?.role === "admin"
												? "Admin"
												: "Student"}
										</h5>
									) : (
										<h5>
											{userData?.role === "studioOwner"
												? userData?.studioName
												: userData?.role === "teacher"
												? "Teacher"
												: userData?.role === "admin"
												? "Admin"
												: "Student"}
										</h5>
									)}
								</div>

								<div className="col-md-6">
									<p className="text-secondary">
										{profileToken ? viewUser?.name : userData?.name}
									</p>
								</div>
								{userData?.role !== "student" && (
									<div className="col-md-6" style={{ position: "absolute" }}>
										<img
											onClick={() => {
												setShowTimelineModal(!showTimelineModal);
												setShareCheck([]);
												setAward(true);
											}}
											src="https://movitanz.com/upload/static/cup.png"
											style={{
												height: 40,
												width: 40,
												borderRadius: 10,
												float: "right",
												cursor: "pointer",
											}}
										/>
									</div>
								)}
								{/* {userData?.role !== "student" && (
                  <div className="col-md-12 mt-3 ">
                    {userData?.role !== "admin" && (
                      <Button
                        type="primary"
                        size="large"
                        className="rounded"
                        onClick={() => {
                          setShowTimelineModal(!showTimelineModal);
                          setShareCheck([]);
                          setAward(true);
                        }}
                      >
                        <FileAddOutlined className="h5 " />{" "}
                        <span className="my-auto f-poppins">Add Award</span>
                      </Button>
                    )}
                  </div>
                )} */}

								{/* {userData?.role !== "student" && ( */}
								<div className="col-md-12 ">
									{userData?.role !== "admin" && (
										<Button
											type="primary"
											size="large"
											className="rounded"
											onClick={() => {
												setShowTimelineModal(!showTimelineModal);
												setShareCheck([]);
												setAward(false);
											}}
										>
											<FileAddOutlined className="h5 " />{" "}
											<span className="my-auto f-poppins">Add Post</span>
										</Button>
									)}
								</div>
								{/* )} */}
							</div>

							<div className="col-md-12 mt-2">
								<Select
									onChange={(e) => {
										setDate("");
										setSingleStudio(e);
									}}
									style={{ width: "200px" }}
									showSearch
									placeholder="Filter Timeline Items"
									optionFilterProp="children"
									value={singleStudio}
								>
									<Option key="">Filter</Option>
									<Option key="1">Clear all filters</Option>
									{studios.map((stu, index) => (
										<Option value={index}>{stu?.studioName}</Option>
									))}{" "}
									<Option key="3">Not Viewed</Option>
									<Option key="4">Not Shared</Option>
									{userData?.role !== "student" && (
										<Option key="bymember">By member</Option>
									)}
									<Option key="hidden">Show hidden</Option>
								</Select>
							</div>

							{singleStudio === "bymember" && (
								<div className="col-md-12 mt-2">
									<Select
										onChange={(e) => {
											handleFilterByMember(e);
										}}
										style={{ width: "200px" }}
										showSearch
										placeholder="Filter By Member"
										optionFilterProp="children"
										value={memberFilter ? memberFilter : null}
									>
										{studioMembers?.map((group, index) => (
											<Option value={group?.id}>{group?.name}</Option>
										))}
									</Select>
								</div>
							)}

							{studios[singleStudio]?.group?.length ? (
								<div className="col-md-12 mt-2">
									<Select
										onChange={(e) => {
											handleFilterByGroup(e);
										}}
										style={{ width: "200px" }}
										showSearch
										placeholder="Filter By Group"
										optionFilterProp="children"
										value={groupFilter}
									>
										{studios[singleStudio]?.group
											?.map((i) => {
												return i.members.includes(userData?.id) && i;
											})
											?.filter((d) => d !== false)
											?.map((group, index) => (
												<Option value={group?._id}>{group?.groupName}</Option>
											))}
									</Select>
								</div>
							) : (
								""
							)}

							<div className="col-md-12 d-flex mt-3">
								{favFilter ? (
									<StarFilled
										onClick={() => {
											setFavFilter(!favFilter);
											setGroupFilter("");
										}}
										className="h4 cursor-pointer mt-1 "
									/>
								) : (
									<StarOutlined
										onClick={() => setFavFilter(!favFilter)}
										className="h4 cursor-pointer mt-1 "
									/>
								)}
								<GrayDatePicker
									format="DD/MM/YYYY"
									onChange={filterByDate}
									placeholder="Filter by date"
									type="date"
									style={{ width: "170px", marginLeft: "12px" }}
								/>
							</div>
							<div className="col-md-12 mt-2">
								<div className="col-md-12 mt-2">
									<Select
										onChange={(e) => {
											if (e === "") {
												setSearch("");
											}
											setDate("");
											setFilterTest(e);
										}}
										style={{ width: "200px" }}
										showSearch
										placeholder="Filter by title, tag and description"
										optionFilterProp="children"
										value={filterTest}
									>
										<Option key="" value="">
											Filter by title, tag and description
										</Option>
										<Option key="1" value="title">
											Title
										</Option>
										<Option key="2" value="name">
											Tag
										</Option>
										<Option key="3" value="description">
											Description
										</Option>
									</Select>
								</div>
								{filterTest ? (
									<GrayInputField
										onKeyDown={handleKeyDown}
										value={search}
										placeholder={`${FilterTestName[filterTest]} (hit enter)`}
										style={{ width: "400px", marginTop: "10px" }}
										onChange={(e) => {
											setSearch(e.target.value);
										}}
									/>
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
				</div>
			</TimelineWraaper>
			<InfiniteScroll
				dataLength={timelines?.length}
				// style = {{paddingBottom : "15px !important"}}
				next={() => {
					if (search?.length === 0) {
						setLimit(limit + 2);
					} else {
						setLimit(limit + 2);
					}
				}}
				hasMore={true}
			>
				{timelines?.map((timeline) =>
					singleStudio === "hidden"
						? timeline?.hidden?.includes(userData?.id) && (
								<Post
									profileToken={profileToken?.access?.token}
									refreshTimeline={refreshTimeline}
									userData={userData}
									studios={studios}
									studioMembers={studioMembers}
									fetchComment={fetchComment}
									onDeleteTimeline={deleteTimeline}
									key={timeline?.id}
									timeline={timeline}
									setShareModalShow={setShareModalShow}
									setShareTimeline={setShareTimeline}
									onMakeCopy={makeCopy}
									setShowPortfolioModal={setShowPortfolioModal}
									setTid={setTid}
									setUpdateTimeline={setUpdateTimeline}
									setShowUpdate={setShowUpdate}
									setShowReport={setShowReport}
									setReportedTimeline={setReportedTimeline}
									progress={progress}
									timeline_id={timeline_id}
									hidden={singleStudio === "hidden"}
								/>
						  )
						: timeline?.hidden?.includes(userData?.id) === false && (
								<Post
									profileToken={profileToken?.access?.token}
									refreshTimeline={refreshTimeline}
									userData={userData}
									studios={studios}
									studioMembers={studioMembers}
									fetchComment={fetchComment}
									onDeleteTimeline={deleteTimeline}
									key={timeline?.id}
									timeline={timeline}
									setShareModalShow={setShareModalShow}
									setShareTimeline={setShareTimeline}
									onMakeCopy={makeCopy}
									setShowPortfolioModal={setShowPortfolioModal}
									setTid={setTid}
									setUpdateTimeline={setUpdateTimeline}
									setShowUpdate={setShowUpdate}
									setShowReport={setShowReport}
									setReportedTimeline={setReportedTimeline}
									progress={progress}
									timeline_id={timeline_id}
								/>
						  )
				)}
			</InfiniteScroll>
		</>
	);
};

export default Timeline;
