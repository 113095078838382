import React, { useState } from "react";
import { Col, Row, Form, Divider, Select, Menu, Dropdown } from "antd";

import SigninModal from "../../../components/Modal/Signin";
import { Container, SignupWrapper } from "../components";
import useViewport from "../../../hooks/useViewport";
import {
  DatePickerField,
  GrayDatePicker,
  GrayInputField,
  InputField,
} from "../../../components/_shared/Input";
import { Link, useNavigate } from "react-router-dom";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button } from "../../../components/_shared";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { signupInit } from "../../../store/actions/auth";
import { countryList } from "../../../utils";
import { makeSelectAuthLoading } from "../../../store/selectors";
import Loading from "../../../components/_shared/Loader/Loading";
import { ROUTES } from "../../../route/constant";

const { TabPane } = Tabs;
const { Option } = Select;

const Signup = () => {
  const [showpass, setShowpass] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  const [defaultActiveKey, setActivekey] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector(makeSelectAuthLoading());
  const { isMobile } = useViewport();

  const onSignup = (e) => {
    dispatch(
      signupInit({
        form: {
          signupCode: e?.code.trim(),
          studioName: e?.sname ? e?.sname : "",
          name: `${e?.fname} ${e?.lname}`,
          role:
            defaultActiveKey == "1"
              ? "studioOwner"
              : defaultActiveKey == "2"
                ? "teacher"
                : "student",
          email: e?.email,
          password: e?.password,
          country: e?.country,
          // dob: e?.dob,
          businessNumber: e?.bnumber,
        },
        navigate,
      })
    );
  };

  return (
    <Container className="container  mt-5 bg-white p-5">
      <Loading isLoading={isLoading} />

      <div className="row">
        {/* <div className="col-md-6">
          <img
            src={process.env.PUBLIC_URL + "/movitae-logo.png"}
            alt=""
            width="200px"
          />
        </div> */}
        <div className="col-md-12 ">
          {/* <Tabs
            onChange={(e) => {
              setActivekey(e);
            }}
            defaultActiveKey={defaultActiveKey}
            type="line"
            tabBarGutter={0}
          >
            <TabPane
              className={defaultActiveKey === 1 ? "mb-4" : ""}
              tab={<div className= {isMobile? "tab_mobile" : "tab_tabName"}>Studio Owner</div>}
              key="1"
            />

            <TabPane
              className={defaultActiveKey === 2 ? "mb-4" : ""}
              tab={<div className= {isMobile? "tab_mobile" : "tab_tabName"}>Teacher</div>}
              key="2"
            />

            <TabPane
              className={defaultActiveKey === 3 ? "mb-4" : ""}
              tab={<div className= {isMobile? "tab_mobile" : "tab_tabName"}>Student</div>}
              key="3"
            />
          </Tabs> */}
        </div>

        <div className=" col-md-12 mt-0 bg-secondary-gray py-2   mt-4 ">
          <h5 className="primary-green my-auto">Not a member yet?</h5>
        </div>
      </div>

      {defaultActiveKey == 1 && (
        <div className=" col-md-12    mt-4 ">
          <h6 style={{ fontFamily: "Poppins", fontWeight: 500 }}>
            Remember to go to the{" "}
            <span
              style={{
                fontWeight: 500,
                fontFamily: "Poppins",
                color: "#0eaaa5",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                window.scrollTo(0, 0);
                navigate(ROUTES.PRICING);
              }}
            >
              pricing
            </span>{" "}
            tab to view and buy a plan to receive a studio signup code.
          </h6>
        </div>
      )}

      <Form
        layout="vertical"
        // className="mt-4"
        className="row mt-2"
        form={form}
        // style={{ width: "100%" }}
        onFinish={onSignup}
      >
        {" "}
        <div className="col-md-12 mt-3 mb-3">
          <Select
            showSearch
            // defaultValue={"Studio Owner"}
            style={{ width: "100%" }}
            placeholder="Select User Type"
            optionFilterProp="children"
            onChange={(e) => {
              setActivekey(e);
            }}
          >
            <Option value={1}>Studio Owner</Option>
            <Option value={2}>Teacher</Option>
            <Option value={3}>Student</Option>
          </Select>
        </div>
        {defaultActiveKey && <>
          <div className="col-md-4 ">
            <Form.Item
              name="code"
              label="Signup Code"
              rules={[
                {
                  required: true,
                  message: "Please enter signup code.",
                },
              ]}
            >
              <GrayInputField className="font-600" placeholder="Signup code" />
            </Form.Item>
          </div>
          {defaultActiveKey == 1 && (
            <>
              <div className="col-md-4">
                <Form.Item
                  name="sname"
                  label="Studio Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter studio name.",
                    },
                  ]}
                >
                  <GrayInputField
                    className="font-600"
                    placeholder="Studio Name"
                  />
                </Form.Item>
              </div>
              {/* <div className="col-md-4">
                <Form.Item
                  name="bnumber"
                  label="ABN/NZBN"
                  rules={[
                    {
                      required: true,
                      message: "Please enter ABN/NZBN.",
                    },
                  ]}
                >
                  <GrayInputField className="font-600" placeholder="ABN/NZBN" />
                </Form.Item>
              </div> */}
            </>
          )}
          <div className="col-md-4">
            <Form.Item
              name="fname"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please enter first name.",
                },
              ]}
            >
              <GrayInputField className="font-600" placeholder="First Name" />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item
              name="lname"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please enter last name.",
                },
              ]}
            >
              <GrayInputField className="font-600" placeholder="Last Name" />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter valid email.",
                },
              ]}
            >
              <GrayInputField
                type="email"
                className="font-600"
                placeholder="Email"
              />
            </Form.Item>
          </div>
          {/* <div className="offset-md-6"></div> */}
          <div className="col-md-4 ">
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please enter password" }]}
            >
              <GrayInputField
                type={showpass ? "text" : "password"}
                suffix={
                  showpass ? (
                    <EyeInvisibleOutlined
                      onClick={() => {
                        setShowpass(!showpass);
                      }}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setShowpass(!showpass);
                      }}
                    />
                  )
                }
                className="font-600"
                placeholder="Password"
              />
            </Form.Item>
          </div>
          <div className="col-md-4 ">
            <Form.Item
              label="Confirm password"
              name="cpassword"
              dependencies={["password"]}
              // hasFeedback
              rules={[
                { required: true, message: "please enter confirm password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords entered do not match. Please try again,"
                      )
                    );
                  },
                }),
              ]}
            >
              <GrayInputField
                type={showConfirm ? "text" : "password"}
                suffix={
                  showConfirm ? (
                    <EyeInvisibleOutlined
                      onClick={() => {
                        setShowConfirm(!showConfirm);
                      }}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setShowConfirm(!showConfirm);
                      }}
                    />
                  )
                }
                className="font-600"
                placeholder="Confirm Password"
              />
            </Form.Item>
          </div>
          {/* <div className="col-md-4">
          <Form.Item
            name="dob"
            label="DOB"
            rules={[
              {
                required: true,
                message: "Please select your dob.",
              },
            ]}
          >
            <GrayDatePicker
              format="'DD/MM/YYYY'"
              type="date"
              className="font-600"
            />
          </Form.Item>
        </div> */}
          <div className="col-md-4">
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Please select your country.",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a Country"
                optionFilterProp="children"
              >
                {countryList?.map((list) => (
                  <Option value={list?.name}>{list?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className=" col-md-12 mt-5 ">
            <div className="d-flex justify-content-center">
              <Button
                lite
                onClick={() => form.resetFields()}
                size={"large"}
                // style={{ marginLeft: isMobile ? 0 : 20 }}
                title="Reset"
                className=""
              />
              <Button
                htmlType="submit"
                lite
                size={"large"}
                // style={{ marginLeft: isMobile ? 0 : 20 }}
                title="Sign Up"
                className=""
              />
            </div>
          </div>
        </>}
      </Form>
      <div className="col-md-12">
        <Divider />
      </div>
    </Container>

    // <SignupWrapper isMobile={isMobile}>
    //   <div className={isMobile ? "" : "container  containerSignin"} style = {{width : "700px"}}>
    //     <SignupModal />
    //   </div>
    // </SignupWrapper>
  );
};

export default Signup;
