import { Drawer, Dropdown, Layout, List, Menu, Popover } from "antd";
import Sider from "antd/lib/layout/Sider";
import {
  BellOutlined,
  CloseOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../../context/userInfo";
import { ViewProfileContext } from "../../context/viewProfile";
import useViewport from "../../hooks/useViewport";
import { fetchJoinedStudioInit } from "../../store/actions/studio";
import { makeSelectStudio } from "../../store/selectors";
import { isLogin, notify, SERVER_URL } from "../../utils";
import { isExpired } from "../../utils/utils";
import { DropDown } from "../_shared";
import api from "../../services/profile";
import { ROUTES } from "../../route/constant";
const { Header } = Layout;

const HeaderStyle = styled(Header)`
  background: white;
  padding: 0px 45px;
  position: fixed;
  top: 0px;
  z-index: 100;
  width: 100%;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
  display: flex;
  align-items: center;

  .ant-menu {
    margin-left: auto;
  }

  li {
    font-size: 16px;
    height: 64px;
    font-family: "Lato";
  }

  .active {
    color: #0eaaa5;
    cursor: pointer;
  }
  a {
    color: black;
    margin-right: 30px;

    &:hover {
      color: #0eaaa5;
      font-size: 16px;
    }
  }
  svg {
    font-size: 26px;
  }
  /* svg {
    display: none;
  } */
`;

const setDefaultActive = () => {
  const validActive = ["/timeline", "/portfolio", "/program", "/group"];
  const validIndex = validActive.findIndex(
    (activeKey) => activeKey === window.location.pathname
  );

  return validIndex + 1 ? validIndex + 1 : "1";
};

const AppHeader = () => {
  const [active, setActive] = useState(isLogin() ? "" : "home");
  const { userData, getUserinfo, setUserdata, setIsLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();
  const { setProfileToken, setViewUser, profileToken, setUserId } =
    useContext(ViewProfileContext);
  const studios = useSelector(makeSelectStudio());
  const [showStorage, setShowStorage] = useState(false);
  const { isMobile } = useViewport();
  const [showSidebar, setShowSidebar] = useState(true);

  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [visible, setVisible] = useState(false);
  
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
    if(!newVisible){
      localStorage.setItem("lastLogin", 'noValue');
    }
  };
  const handleClose = () => {
    setVisible(false);
  };
  const getNotifcaions = async () => {
    const notifications = await api.getNotifications();
    setNotifications(
      notifications?.data?.notifications?.length > 0
        ? notifications?.data?.notifications
        : []
    );
  };
  const content = (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h6 className="my-auto">Welcome to the Movitanz Timeline!</h6>
        <CloseOutlined className="my-auto" onClick={handleClose} style={{ cursor: 'pointer' }} />
      </div>
      <p></p>
      <p>To get started, please go to the GUIDE Tab.</p>
      <p>There, you'll find step-by-step instructions on </p>
      <p>personalizing your portal and joining your groups.</p>
    </div>
  );

  useEffect(() => {
    getUserinfo();
    getNotifcaions();
  }, []);

  // useEffect(() => {
  //   if (studios?.length && userData) {
  //     for (let studio of studios) {
  //       if (studio?.signupCode === userData?.signupCode) {
  //         if (studio?.storageLimitRemaining <= 0) {
  //           if (userData?.role !== "studioOwner") {
  //             setShowStorage(true);
  //             notify("error", "Please upgrade the stroage or contact to owner");
  //           }
  //         }
  //       }
  //     }
  //   }
  // }, [studios, userData]);

  useEffect(() => {
    const lastLogin = localStorage.getItem("lastLogin");
    if(lastLogin === 'null'){
      setVisible(true)
    }
    if (window.Tawk_API && !userData) {
      window.Tawk_API.hideWidget();
    } else {
      if (userData?.role === "studioOwner" && window.Tawk_API)
        window.Tawk_API.showWidget();
    }
  }, [userData]);

  const menu = (
    <Menu>
      {userData?.role !== "admin" && (
        <Menu.Item>
          <Link
            className={`${active === "signup" ? "active" : ""}`}
            to="/profile"
          >
            Profile
          </Link>
        </Menu.Item>
      )}
      {userData?.role !== "student" &&
      userData?.role !== "admin" &&
      !profileToken ? (
        <>
          <Menu.Item>
            <Link
              className={`${active === "payment" ? "active" : ""}`}
              to="/payment-details"
            >
              Payment Details
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link
              className={`${active === "recieved" ? "active" : ""}`}
              to="/recieved-payment"
            >
              Recieved Payment
            </Link>
          </Menu.Item>

          {userData?.role === "studioOwner" ? (
            <Menu.Item>
              <Link
                className={`${active === "recieved" ? "active" : ""}`}
                to="/manage-user"
              >
                Manage User
              </Link>
            </Menu.Item>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <Menu.Item danger>
        <li
          onClick={() => {
            localStorage.clear();
            setUserdata("");
            setProfileToken("");
            setUserId("");
            setViewUser("");
            navigate("/signin");
            setIsLoggedIn(false)
          }}
        >
          Signout
        </li>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <HeaderStyle>
        <Drawer
          title="Notifications"
          placement={"right"}
          width={500}
          onClose={() => {
            setShowNotification(false);
          }}
          open={showNotification}
        >
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={(item) => (
              <List.Item
                onClick={async () => {
                  if (item?.redirect === "TIMELINE") {
                    if (item?.redirectId) {
                      navigate(`${ROUTES.TIMELINE}/${item?.redirectId}`);
                    } else {
                      navigate(ROUTES.TIMELINE);
                    }
                  }

                  if (item?.redirect === "GROUP") {
                    navigate("/group");
                  }
                  if (item?.redirect === "PORTFOLIOS") {
                    if (item?.redirectId) {
                      navigate(`/portfolio/${item?.redirectId}/share`);
                    } else {
                      navigate("/portfolio");
                    }
                  }
                  setShowNotification(false);
                  const update = await api.updateNotifications({
                    id: item?.id,
                    isRead: item?.isRead ? false : true,
                  });
                  if (update?.data?.update) {
                    getNotifcaions();
                  }
                }}
                style={{ cursor: "pointer" }}
                className={
                  item?.isRead
                    ? "ant-list-item-inactive"
                    : "ant-list-item-active"
                }
                actions={[
                  <div
                    onClick={async () => {
                      const update = await api.updateNotifications({
                        id: item?.id,
                        isRead: item?.isRead ? false : true,
                      });
                      if (update?.data?.update) {
                        getNotifcaions();
                      }
                    }}
                    style={{ cursor: "pointer" }}
                    key="list-loadmore-edit"
                  >
                    {item?.isRead ? "Unread" : "Read"}
                  </div>,
                ]}
              >
                <List.Item.Meta title={item?.title} />
              </List.Item>
            )}
          />
        </Drawer>
        {isMobile && (
          <div>
            <MenuFoldOutlined
              onClick={() => setShowSidebar(!showSidebar)}
              className="h4"
            />
          </div>
        )}
        <div style={{ margin: `${isMobile ? "auto" : ""}` }}>
          <img
            onClick={() => {
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
            src={process.env.PUBLIC_URL + "/movitae-logo.png"}
            alt=""
            width="200px"
          />
        </div>

        {!isMobile && (
          <Menu
            theme="light"
            respo
            mode="horizontal"
            onClick={(e) => {
              setActive(e.key);
            }}
            defaultSelectedKeys={["1"]}
          >
            {showStorage && (
              <li key="upgrade" onClick={() => setActive("upgrade")}>
                <Link
                  className={`${active === "upgrade" ? "active" : ""}`}
                  to="/upgrade-storage"
                >
                  <span className="text-danger">Upgrade</span>
                </Link>
              </li>
            )}
            {!isLogin() ? (
              <>
                <li key="home" onClick={() => setActive("home")}>
                  <Link
                    className={`${active === "home" ? "active" : ""}`}
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li key="why" onClick={() => setActive("why")}>
                  <Link
                    className={`${active === "why" ? "active" : ""}`}
                    to="/why"
                  >
                    Why Movitanz?
                  </Link>
                </li>
                <li key="getstarted" onClick={() => setActive("get-started")}>
                  <Link
                    className={`${active === "get-started" ? "active" : ""}`}
                    to="/get-started"
                  >
                    Get started
                  </Link>
                </li>

                <li key="pricing" onClick={() => setActive("pricing")}>
                  <Link
                    className={`${active === "pricing" ? "active" : ""}`}
                    to="/pricing"
                  >
                    Pricing
                  </Link>
                </li>
              </>
            ) : (
              <>
                {profileToken || !(userData?.role === "admin") ? (
                  <>
                    {!profileToken && visible && (
                     
                        <li key="info" onClick={() => setActive("info")}>
                          <Link
                            className={`${active === "info" ? "active" : ""}`}
                            to="/info"
                          >
                             <Popover content={content} 
                              trigger="click"
                              visible={visible}
                              onVisibleChange={handleVisibleChange}
                              >
                                Guide
                              </Popover>
                          </Link>
                        </li>
                      
                    )}
                    {!profileToken && !visible && (
                      <li key="info" onClick={() => setActive("info")}>
                      <Link
                        className={`${active === "info" ? "active" : ""}`}
                        to="/info"
                      >
                        Guide
                      </Link>
                    </li>
                    )}
                    <li key="timeline" onClick={() => setActive("timeline")}>
                      <Link
                        className={`${active === "timeline" ? "active" : ""}`}
                        to="/timeline"
                      >
                        Timeline
                      </Link>
                    </li>

                    <li
                      key="portfolios"
                      onClick={() => setActive("portfolios")}
                    >
                      <Link
                        className={`${active === "portfolios" ? "active" : ""}`}
                        to="/portfolio"
                      >
                        Portfolios
                      </Link>
                    </li>

                    <li key="programs" onClick={() => setActive("programs")}>
                      <Link
                        className={`${active === "programs" ? "active" : ""}`}
                        to="/program"
                      >
                        Course Shop
                      </Link>
                    </li>

                    <li key="groups" onClick={() => setActive("groups")}>
                      <Link
                        className={`${active === "groups" ? "active" : ""}`}
                        to="/group"
                      >
                        Groups
                      </Link>
                    </li>
                    {!profileToken && (
                      <li key="fourm" onClick={() => setActive("fourm")}>
                        <Link
                          className={`${active === "fourm" ? "active" : ""}`}
                          to="/fourm"
                        >
                          Forum
                        </Link>
                      </li>
                    )}
                    {!profileToken && (
                     <li key="fourm" 
                        onClick={() => {
                          setShowNotification(true);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          marginRight: 25,
                        }}
                        className="bell-icon active"
                      >
                        {" "}
                        Notifications
                        {/* <BellOutlined
                          style={{ fontSize: "10px", color: "#0daaa5" }}
                        /> */}
                      </li>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            )}

            {profileToken && (
              <li
                key="manage"
                onClick={() => {
                  setProfileToken("");
                  setUserId("");
                  setActive("manage");
                }}
              >
                <Link
                  className={`${active === "manage" ? "active" : ""}`}
                  to="/manage-user"
                >
                  Manage Users
                </Link>
              </li>
            )}
            {!isLogin() && (
              <li key="signup" onClick={() => setActive("signup")}>
                <Link
                  className={`${active === "signup" ? "active" : ""}`}
                  to="/signup"
                >
                  Signup
                </Link>
              </li>
            )}
            {isLogin() && (
              <Dropdown overlay={menu} placement="bottomCenter">
                <li className="cursor-pointer">
                  <img
                    src={`${userData?.profilepic}`}
                    onError={(e) => {
                      if (userData?.profilepic) {
                        e.target.onerror = null;
                        e.target.src = `${process.env.PUBLIC_URL}user.png`;
                      }
                    }}
                    alt=""
                    width={40}
                    className="rounded-circle"
                    style={{
                      objectFit: "cover",
                      height: 50,
                      width: 50,
                    }}
                  />
                </li>
                {/* <li key="profile" onClick={() => setActive("profile")}>
            <Link
              className={`${active === "profile" ? "active" : ""}`}
              to={userData?.role !== "admin" && `/profile`}
            >
              <img
                src={`${userData?.profilepic}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${process.env.PUBLIC_URL}user.png`;
                }}
                alt=""
                width={40}
                className="rounded-circle"
              />{" "}
              <span className="mx-1"></span> {userData?.name}
            </Link>
          </li> */}
              </Dropdown>
            )}

            <li
              className="cursor-pointer"
              key="3"
              onClick={() => setActive("3")}
            >
              {isLogin() ? (
                <></>
              ) : (
                <Link
                  to="/signin"
                  className={`${active === "3" ? "active" : ""}`}
                >
                  Login
                </Link>
              )}
            </li>
          </Menu>
        )}

        {isMobile && (
          <Sider
            collapsedWidth={0}
            trigger={null}
            collapsible
            collapsed={showSidebar}
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
              background: "#fff",
              // marginTop: "64.5px",
            }}
          >
            <Menu
              onClick={() => setShowSidebar(!showSidebar)}
              style={{ margin: "0 10px" }}
            >
              <MenuUnfoldOutlined
                onClick={() => setShowSidebar(!showSidebar)}
                className=" d-flex justify-content-end mt-3 mx-3"
                style={{ marginBottom: "25px" }}
              />
              {isLogin() && (
                <>
                  {showStorage && (
                    <Menu.Item>
                      <li key="upgrade" onClick={() => setActive("upgrade")}>
                        <Link
                          className={`${active === "upgrade" ? "active" : ""}`}
                          to="/upgrade-storage"
                        >
                          <span className="text-danger">Upgrade</span>
                        </Link>
                      </li>
                    </Menu.Item>
                  )}
                  {!isLogin() ? (
                    <>
                      <Menu.Item>
                        <li key="home" onClick={() => setActive("home")}>
                          <Link
                            className={`${active === "home" ? "active" : ""}`}
                            to="/"
                          >
                            Home
                          </Link>
                        </li>
                      </Menu.Item>
                      <Menu.Item>
                        <li key="pricing" onClick={() => setActive("pricing")}>
                          <Link
                            className={`${
                              active === "pricing" ? "active" : ""
                            }`}
                            to="/pricing"
                          >
                            Pricing
                          </Link>
                        </li>
                      </Menu.Item>
                    </>
                  ) : (
                    <>
                      {profileToken || !(userData?.role === "admin") ? (
                        <>
                          {!profileToken && (
                            <Menu.Item>
                              <li key="info" onClick={() => setActive("info")}>
                                <Link
                                  className={`${
                                    active === "info" ? "active" : ""
                                  }`}
                                  to="/info"
                                >
                                  Guide
                                </Link>
                              </li>
                            </Menu.Item>
                          )}
                          <Menu.Item>
                            <li
                              key="timeline"
                              onClick={() => setActive("timeline")}
                            >
                              <Link
                                className={`${
                                  active === "timeline" ? "active" : ""
                                }`}
                                to="/timeline"
                              >
                                Timeline
                              </Link>
                            </li>
                          </Menu.Item>
                          <Menu.Item>
                            <li
                              key="portfolios"
                              onClick={() => setActive("portfolios")}
                            >
                              <Link
                                className={`${
                                  active === "portfolios" ? "active" : ""
                                }`}
                                to="/portfolio"
                              >
                                Portfolios
                              </Link>
                            </li>
                          </Menu.Item>

                          <Menu.Item>
                            <li
                              key="programs"
                              onClick={() => setActive("programs")}
                            >
                              <Link
                                className={`${
                                  active === "programs" ? "active" : ""
                                }`}
                                to="/program"
                              >
                                Course Shop
                              </Link>
                            </li>
                          </Menu.Item>
                          <Menu.Item>
                            <li
                              key="groups"
                              onClick={() => setActive("groups")}
                            >
                              <Link
                                className={`${
                                  active === "groups" ? "active" : ""
                                }`}
                                to="/group"
                              >
                                Groups
                              </Link>
                            </li>
                          </Menu.Item>

                          {!profileToken && (
                            <Menu.Item>
                              <li
                                key="fourm"
                                onClick={() => setActive("fourm")}
                              >
                                <Link
                                  className={`${
                                    active === "fourm" ? "active" : ""
                                  }`}
                                  to="/fourm"
                                >
                                  Forum
                                </Link>
                              </li>
                            </Menu.Item>
                          )}

                          {!profileToken && (
                            <Menu.Item>
                              <li
                                key="fourm"
                                onClick={() => setShowNotification(true)}
                              >
                                Notifications
                              </li>
                            </Menu.Item>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {userData?.role !== "admin" && (
                        <Menu.Item>
                          <Link
                            className={`${active === "signup" ? "active" : ""}`}
                            to="/profile"
                          >
                            Profile
                          </Link>
                        </Menu.Item>
                      )}
                      {userData?.role !== "student" &&
                      userData?.role !== "admin" &&
                      !profileToken ? (
                        <>
                          <Menu.Item>
                            <Link
                              className={`${
                                active === "payment" ? "active" : ""
                              }`}
                              to="/payment-details"
                            >
                              Payment Details
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link
                              className={`${
                                active === "recieved" ? "active" : ""
                              }`}
                              to="/recieved-payment"
                            >
                              Recieved Payment
                            </Link>
                          </Menu.Item>

                          {userData?.role === "studioOwner" && (
                            <Menu.Item>
                              <Link
                                className={`${
                                  active === "recieved" ? "active" : ""
                                }`}
                                to="/manage-user"
                              >
                                Manage User
                              </Link>
                            </Menu.Item>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}{" "}
              {profileToken && (
                <Menu.Item>
                  <li
                    key="manage"
                    onClick={() => {
                      setProfileToken("");
                      setUserId("");
                      setActive("manage");
                    }}
                  >
                    <Link
                      className={`${active === "manage" ? "active" : ""}`}
                      to="/manage-user"
                    >
                      Manage Users
                    </Link>
                  </li>
                </Menu.Item>
              )}
              {!isLogin() && (
                <>
                  <Menu.Item>
                    <li key="home" onClick={() => setActive("home")}>
                      <Link
                        className={`${active === "home" ? "active" : ""}`}
                        to="/"
                      >
                        Home
                      </Link>
                    </li>
                  </Menu.Item>
                  <Menu.Item>
                    <li key="login" onClick={() => setActive("why")}>
                      <Link
                        className={`${active === "why" ? "active" : ""}`}
                        to="/why"
                      >
                        Why Movitanz?
                      </Link>
                    </li>
                  </Menu.Item>
                  <Menu.Item>
                    <li key="login" onClick={() => setActive("login")}>
                      <Link
                        className={`${
                          active === "get-started" ? "active" : ""
                        }`}
                        to="/get-started"
                      >
                        Get started
                      </Link>
                    </li>
                  </Menu.Item>

                  <Menu.Item>
                    <li key="pricing" onClick={() => setActive("pricing")}>
                      <Link
                        className={`${active === "pricing" ? "active" : ""}`}
                        to="/pricing"
                      >
                        Pricing
                      </Link>
                    </li>
                  </Menu.Item>

                  <Menu.Item>
                    <li key="login" onClick={() => setActive("login")}>
                      <Link
                        className={`${active === "login" ? "active" : ""}`}
                        to="/signin"
                      >
                        Login
                      </Link>
                    </li>
                  </Menu.Item>
                  <Menu.Item>
                    <li key="signup" onClick={() => setActive("signup")}>
                      <Link
                        className={`${active === "signup" ? "active" : ""}`}
                        to="/signup"
                      >
                        Signup
                      </Link>
                    </li>
                  </Menu.Item>
                </>
              )}
              {isLogin() && (
                <Menu.Item danger>
                  <li
                    onClick={() => {
                      localStorage.clear();
                      setUserdata("");
                      setProfileToken("");
                      setUserId("");
                      setViewUser("");
                      navigate("/signin");
                      setIsLoggedIn(false)
                    }}
                  >
                    Signout
                  </li>
                </Menu.Item>
              )}
            </Menu>
          </Sider>
        )}
      </HeaderStyle>
    </>
  );
};
export default AppHeader;
