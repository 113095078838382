import { Form, Tabs, Button } from "antd";
import React from "react";
import { useState } from "react";
import ModalWrapper from "../../../../components/Modal";
import { InputField } from "../../../../components/_shared";

const { TabPane } = Tabs;

const CreateFolderModal = ({ visible, setVisible,onCreateFolder }) => {
  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-center">
            <h3>Create Folder</h3>
          </div>
          <Form
            layout="vertical"
            className="mt-4"
            className="row mt-4"
            // style={{ width: "100%" }}
            onFinish={onCreateFolder}
          >
            <div className="col-md-12">
              <Form.Item
                name="folderName"
                label="Folder Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter you Folder name.",
                  },
                ]}
              >
                <InputField className="font-600" placeholder="Folder name" />
              </Form.Item>
            </div>

            <div
              className="center-column col-md-12 "
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                htmlType="submit"
                size={"large"}
                type="primary"
                className="rounded "
                // style = {{heigth : "200px"}}
              >
                <h5 className="font-white"> Create Folder </h5>
              </Button>
            </div>
     
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CreateFolderModal;
