import { SERVER_URL } from "../utils";
import { apiGet, apiPatch, apiPost } from "../utils/axios";

const USER = SERVER_URL + "/users";
const GET_USER = SERVER_URL + "/users/profile";
const UPDATE_PROFILE = SERVER_URL + "/auth/update-my-profile";
const GET_NOTIFICATIONS = SERVER_URL + "/notification";

export default {
	getmyProfile: () => apiGet(GET_USER),
	updateMyProfile: (payload) => apiPatch(UPDATE_PROFILE, payload),
	changePassowrd: (payload) => apiPatch(`${USER}/change-password`, payload),
	getNotifications: (payload) => apiGet(GET_NOTIFICATIONS),
	updateNotifications: (payload) => apiPatch(GET_NOTIFICATIONS, payload),
	redeemCoupon: (payload) => apiPost(`${USER}/redeem-coupon`, payload),
};
