import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import InputEmoji from "react-input-emoji";

import { UserContext } from "../../../context/userInfo";
import fourm from "../../../services/fourm";
import { notify } from "../../../utils";
import { FourmWrapper } from "./component/component";
import Loading from "../../../components/_shared/Loader/Loading";
import useViewport from "../../../hooks/useViewport";

const Fourm = () => {
  const ref = useRef();
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const { userData, getUserinfo } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useViewport();

  useEffect(() => {
    getUserinfo();
    getFourmComment();
  }, []);

  const getFourmComment = async () => {
    try {
      setIsLoading(true);
      const res = await fourm.getFourmComment();
      if (res.status === 200) {
        setIsLoading(false);
        setComments(res?.data?.comments);
      }
    } catch (e) {
      setIsLoading(false);
      notify("error", e);
    }
  };

  const createComment = async () => {
    try {
      if (!comment) return notify("error", "Please Enter your message");
      const res = await fourm.crateComment({ comment });
      if (res?.status === 200) {
        setComment("");
        getFourmComment();
      }
    } catch (e) {
      notify("error", e);
    }
  };

  const deleteFourmComment = async (cid) => {
    try {
      const res = await fourm.deleteComment(cid);
      if (res?.status === 200) {
        getFourmComment();
      }
    } catch (e) {
      notify("error", e);
    }
  };

  useEffect(() => {
    if (comments) {
      if (ref) {
        ref.current.addEventListener("DOMNodeInserted", (event) => {
          const { currentTarget: target } = event;
          target.scroll({ top: target.scrollHeight, behavior: "smooth" });
        });
      }
    }
  }, [comments]);
  return (
    <FourmWrapper className='container bg-white'>
      <Loading isLoading={isLoading} />
      <div className='messaging'>
        <div className='inbox_msg'>
          <div className='mesgs'>
            <div ref={ref} className='msg_history'>
              {comments?.map((comment) => (
                <>
                  {userData?.id === comment?.user ? (
                    <div className='outgoing_msg'>
                      <div className='sent_msg'>
                        <p>{comment?.comment}</p>
                        <span className='time_date'>
                          {" "}
                          {moment(comment?.date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}{" "}
                          <span
                            className='text-danger mx-2 cursor-pointer'
                            onClick={() => deleteFourmComment(comment?.id)}
                          >
                            Remove
                          </span>
                        </span>{" "}
                      </div>
                    </div>
                  ) : (
                    <div className='incoming_msg'>
                      <div className='received_msg mt-1'>
                        <span className='mb-0 text-secondary'>
                          {comment?.name}
                        </span>
                        <div className='received_withd_msg'>
                          <p>{comment?.comment}</p>
                          <span className='time_date'>
                            {" "}
                            {moment(comment?.date).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}{" "}
                            {userData?.role === "studioOwner" ||
                            (userData?.role === "teacher" &&
                              comment?.role === "student") ? (
                              <span
                                className='text-danger mx-2 cursor-pointer'
                                onClick={() => deleteFourmComment(comment?.id)}
                              >
                                Remove
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className='type_msg'>
              <div className='input_msg_write'>
                {/* <input
                  type="text"
                  className="write_msg"
                  value={comment}
                  placeholder="Type a message"
                  onChange={(e) => setComment(e?.target?.value)}
                /> */}
                <div style={{ width: `${isMobile ? "90%" : "97%"}` }}>
                  <InputEmoji
                    value={comment}
                    onChange={(e) => {
                      setComment(e);
                    }}
                    cleanOnEnter
                    onEnter={createComment}
                    placeholder='Type a message'
                  />
                </div>
                <button
                  className='msg_send_btn'
                  type='button'
                  onClick={createComment}
                >
                  <i className='fa fa-paper-plane-o' aria-hidden='true' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FourmWrapper>
  );
};

export default Fourm;
