
import { User } from './component';
import AdminLayout from '../../components/AdminLayout';

const ManageUser  = () =>  {  


    return (
     <AdminLayout>
       <User/>
     </AdminLayout>
    );
  
}


export default ManageUser