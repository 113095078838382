import React, { useContext, useState, useEffect } from "react";
import { Form, Table } from "antd";
import admin from "../../../services/admin";
import { notify } from "../../../utils";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/_shared/Loader/Loading";
import AdminLayout from "../../../components/AdminLayout";
import moment from "moment";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import CreateCoupon from "./components/CreateCoupon";

const User = () => {
	const [coupons, setCoupons] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [form] = Form.useForm();

	useEffect(() => {
		getCoupons();
	}, []);

	const getCoupons = () => {
		setIsLoading(true);
		admin
			.getAllCoupons()
			.then((res) => {
				setCoupons(res?.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				notify("error", JSON.stringify(err));
			});
	};

	const deleteCoupon = (couponId) => {
		setIsLoading(true);
		admin
			.deleteCoupon(couponId)
			.then((res) => {
				notify("success", "Coupon deleted successfully");
				setIsLoading(false);
				getCoupons();
			})
			.catch((err) => {
				setIsLoading(false);
				notify("error", JSON.stringify(err));
			});
	};

	const createCoupon = (data) => {
		setIsLoading(true);
		admin
			.createCoupon(data)
			.then((res) => {
				notify("success", "Coupon created successfully");
				setIsLoading(false);
				form.resetFields();
				setIsModalVisible(false);
				getCoupons();
			})
			.catch((err) => {
				setIsLoading(false);
				notify("error", JSON.stringify(err));
			});
	};

	const onFormSubmit = (values) => {
		createCoupon(values);
	};

	const columns = [
		{
			title: "Coupon code",
			dataIndex: "couponCode",
			key: "couponCode",
		},
		{
			title: "Total Months",
			dataIndex: "totalRedemptionMonths",
			key: "totalRedemptionMonths",
		},
		{
			title: "Discount Money",
			dataIndex: "discountAmount",
			key: "discountAmount",
		},
		{
			title: "Created At",
			dataIndex: "createdAt",
			key: "createdAt",
			sortOrder: "descend",
			render: (date) => new Date(date).toLocaleString(),
			sorter: (a, b) =>
				new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
		},
		{
			title: "Delete",
			dataIndex: "delete",
			render: (date, coupon) => (
				<DeleteFilled
					style={{ color: "#cf2525" }}
					onClick={() => {
						deleteCoupon(coupon?.id);
					}}
				/>
			),
			width: 10,
		},
	];

	return (
		<AdminLayout>
			<Loading isLoading={isLoading} />
			<div className="d-flex justify-content-end mb-3">
				<button
					onClick={() => setIsModalVisible(true)}
					className="cursor-pointer btn btn-danger d-flex justity-content-center align-items-center"
				>
					<PlusOutlined className="me-1" />
					<span>Create Coupon</span>
				</button>
			</div>
			<Table columns={columns} dataSource={coupons} />

			<CreateCoupon
				form={form}
				isModalVisible={isModalVisible}
				hideModal={() => setIsModalVisible(false)}
				onSubmit={onFormSubmit}
			/>
		</AdminLayout>
	);
};

export default User;
