import React, { useState } from "react";
import useViewport from "../../hooks/useViewport";
import { Wrapper } from "./components/component";




const ModalWrapper = ({children, visible,setVisible, title}) => {
  const { isMobile } = useViewport();
 
  return (
    <Wrapper
    title = {title}
      isMobile={isMobile}
      closable={true}
      onCancel = {() => setVisible(!visible)  }
      centered
      maskStyle={{
        background: "rgba(138, 149, 186, 0.4)",
        backdropFilter: "blur(10px)",
      }}
      style={{ height: "auto", padding: 0 }}
      visible={visible}
      width={900}
      footer={""}
      bodyStyle={{
        borderRadius: 50,
      }}
    >
       {children}
    </Wrapper>
  );
};
export default ModalWrapper;
