import { Button, Card, Divider } from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userInfo";
import useViewport from "../../hooks/useViewport";
import { byteConvert } from "../../utils/utils";
import moment from "moment";
import Carousel from "../Pricing/slider";
import StripeStorage from "./component/StripeStorage";
import { isExpired } from "../../utils/helper";

const AddOnsupdgrade = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const { isMobile } = useViewport();
  useEffect(() => {
    getUserinfo();
  }, []);
  return (
    <div className="d-flex justify-content-around" style={{ width: "100%" }}>
      <div className="row p-3">
        {[
          {
            price: 5,
            storage: 5,
            student: 5,
          },
          {
            price: 10,
            storage: 10,
            student: 10,
          },
          {
            price: 20,
            storage: 20,
            student: 20,
          },
          {
            price: 30,
            storage: 30,
            student: 30,
          },
          {
            price: 40,
            storage: 40,
            student: 40,
          },
          {
            price: 50,
            storage: 50,
            student: 50,
          },
        ].map((item, index) => (
          <div style={{ padding: 8 }} className="col-md-3">
            <Card hoverable key={index} className="  mt-5">
              <Meta
                title={
                  <div className="d-flex justify-content-center mt-4">
                    {" "}
                    <h3>
                      {" "}
                      {item?.price ? item?.price : ""}{" "}
                      {item?.price ? "AUD" : "FREE"}
                    </h3>{" "}
                  </div>
                }
                // description={
                //   <div className="d-flex justify-content-center">
                //     <h5 className="text-secondary"> {item?.storage} GB</h5>
                //   </div>
                // }
              />
              <Divider />
              <div className="mt-3">
                <ul>
                  {/* <li className="text-secondary font-weight-bold">
                  {item?.title}
                </li> */}
                  <li className="text-secondary font-weight-bold">
                    {item?.student} students
                  </li>

                  <li className="text-secondary font-weight-bold">
                    {item?.storage} GB storage
                  </li>
                  {/* <li className="text-secondary font-weight-bold mt-1">
                    Lorem ipsum dolor sit, amet sdkndkgnd
                  </li>
                  <li className="text-secondary font-weight-bold mt-1">
                    Lorem ipsum dolor sit, amet sdkndkgnd
                  </li> */}
                </ul>
              </div>
              <Divider />
              <div className="d-flex justify-content-center">
              <StripeStorage
                    maxStudents={item?.student}
                    getUserinfo={getUserinfo}
                    userData={userData}
                    price={item?.price}
                    storage={item?.storage}
                  />
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};
export default AddOnsupdgrade;
