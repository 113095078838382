import { call, put, takeLatest } from "redux-saga/effects";

import timelineActionTypes from "../actionTypes/timeline";
import timelineApi from "../../services/timeline";
import { notify } from "../../utils";

function* getTimeline({ payload }) {
  try {
    const res = yield call(timelineApi.getTimeline, payload);

    if (res.status === 200) {
      yield put({
        type: timelineActionTypes.GET_TIMELINE_SUCCESS,
        timeline: res.data?.timelines?.results,
      });
    }
  } catch (err) {
    yield put({ type: timelineActionTypes.GET_TIMELINE_FAILED });
  }
}

function* createTimeline({ payload }) {
  try {
    const res = yield call(timelineApi.createTimeline, payload?.formData);

    if (res.status === 200) {
      // notify("success", "Timeline created.");
      yield put({ type: timelineActionTypes.CREATE_TIMELINE_SUCCESS });
      payload.setVisible(false);
      if(payload?.shareModalShow)
      payload.setShareModalShow(true)
      payload.setShareTimeline(res?.data)
      payload.setTimelineVimeo(res?.data?.id)
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.CREATE_TIMELINE_FAILED });
  }
}

function* updateTimeline({ payload }) {
  try {
    const res = yield call(
      timelineApi.updateTimeline,
      payload?.id,
      payload?.formData
    );

    if (res.status === 200) {
      notify("success", "Timeline updated.");
      payload.setVisible(false)
      yield put({ type: timelineActionTypes.UPDATE_TIMELINE_SUCCESS });
      if(payload?.refreshTimeline){
        payload?.refreshTimeline()
      }
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.UPDATE_TIMELINE_FAILED });
  }
}

function* deleteTimeline({ payload }) {
  try {
    const res = yield call(timelineApi.deleteTimeline, payload);

    if (res.status === 200) {
      yield put({ type: timelineActionTypes.DELETE_TIMELINE_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.DELETE_TIMELINE_FAILED });
  }
}

function* getComment({ payload }) {
  try {
    const res = yield call(timelineApi.getComment, payload);

    if (res.status === 200) {
      yield put({
        type: timelineActionTypes.GET_COMMENT_SUCCESS,
        comment: res.data?.comments.results,
      });
    }
  } catch (err) {
    yield put({ type: timelineActionTypes.GET_COMMENT_FAILED });
  }
}

function* AddComment({ payload }) {
  try {
    const res = yield call(
      timelineApi.addComment,
      payload.tid,
      payload.commentBody
    );

    if (res.status === 200) {
      notify("success", "Comment added.");
      yield put({ type: timelineActionTypes.ADD_COMMENT_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.ADD_COMMENT_FAILED });
  }
}

function* updateComment({ payload }) {
  try {
    const res = yield call(
      timelineApi.updateComment,
      payload?.tid,
      payload?.cid,
      payload?.commentBody
    );

    if (res.status === 200) {
      notify("success", "Comment updated.");
      yield put({ type: timelineActionTypes.UPDATE_COMMENT_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.UPDATE_COMMENT_FAILED });
  }
}

function* deleteComment({ payload }) {
  try {
    const res = yield call(
      timelineApi.deleteComment,
      payload?.tid,
      payload?.cid
    );

    if (res.status === 200) {
      notify("success", "Comment deleted.");
      yield put({ type: timelineActionTypes.DELETE_COMMENT_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.DELETE_COMMENT_FAILED });
  }
}

function* addReply({ payload }) {
  try {
    const res = yield call(
      timelineApi.replyToComment,
      payload.tid,
      payload.cid,
      payload.commentBody
    );

    if (res.status === 200) {
      notify("success", "Reply added.");
      yield put({ type: timelineActionTypes.ADD_REPLY_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.ADD_REPLY_FAILED });
  }
}

function* updateReply({ payload }) {
  try {
    const res = yield call(
      timelineApi.updateReply,
      payload?.tid,
      payload?.cid,
      payload?.rid,
      payload?.commentBody
    );

    if (res.status === 200) {
      notify("success", "Reply updated.");
      yield put({ type: timelineActionTypes.UPDATE_REPLY_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.UPDATE_REPLY_FAILED });
  }
}

function* deleteReply({ payload }) {
  try {
    const res = yield call(
      timelineApi.deleteReply,
      payload?.tid,
      payload?.cid,
      payload?.rid
    );

    if (res.status === 200) {
      notify("success", "Reply deleted.");
      yield put({ type: timelineActionTypes.DELETE_REPLY_SUCCESS });
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.DELETE_REPLY_FAILED });
  }
}

function* timelineWatch({ payload }) {
  try {
    const res = yield call(timelineApi.watchTimeline, payload?.tid);

    if (res.status === 200) {
      yield put({type : timelineActionTypes.WATCH_TIMELINE_SUCCESS});
      payload.setWatch(true)
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.WATCH_TIMELINE_FAILED });
  }
}

function* unWatchTimeline({ payload }) {
  try {
    const res = yield call(timelineApi.unWatchTimeline, payload?.tid);
    if (res.status === 200) {
      yield put({type : timelineActionTypes.UN_WATCH_TIMELINE_SUCCESS});
      payload.setWatch(false)

    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.UN_WATCH_TIMELINE_FAILED });
  }
}

function* likeTimeline({ payload }) {
  try {
    const res = yield call(timelineApi.likeTimeline, payload?.tid)
    if(res.status === 200) {
      yield put({type : timelineActionTypes.LIKE_TIMELINE_SUCCESS})
      payload.setLike(true)

    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.LIKE_TIMELINE_FAILED });
  }
}

function* unLikeTimeline({ payload }) {
  try {
    const res = yield call(timelineApi.unlikeTimeline,payload?.tid)
    if(res.status === 200) {
      payload.setLike(false)

      yield put({type : timelineActionTypes.UN_LIKE_TIMELINE_SUCCESS})
      payload.fetchTimelineById(payload?.tid)
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.UN_LIKE_TIMELINE_FAILED });
  }
}

function* shareGroup({ payload }) {
  try {
    const res = yield call(timelineApi.shareGroup, payload.tid, payload.gid)
    if(res.status === 200) {
      yield put({type : timelineActionTypes.SHARE_GROUP_SUCCESS})
      // payload.fetchTimelineById(payload?.tid)

    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.SHARE_GROUP_FAILED });
  }
}

function* unShareGroup({ payload }) {
  try {
    const res = yield call(timelineApi.unShareGroup, payload.tid, payload.gid)
    if(res.status === 200) {
      yield put({type : timelineActionTypes.UN_SHARE_GROUP_SUCCESS})
      // payload.fetchTimelineById(payload?.tid)


    }
  } catch (err) {
    console.log(err,'errconsole')
    // notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.UN_SHARE_GROUP_FAILED });
  }
}

function* shareMember({ payload }) {
  try {
    const res = yield call(timelineApi.shareMember, payload.tid, payload.mid)

    if(res.status === 200) {
    yield put({type : timelineActionTypes.SHARE_MEMBER_SUCCESS})
    // payload.fetchTimelineById(payload?.tid)


    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.SHARE_MEMBER_FAILED });
  }
}

function* unShareMember({ payload }) {
  try {
    const res = yield call(timelineApi.unShareMember, payload.tid, payload.mid)
    if(res.status === 200) {
      yield put({type : timelineActionTypes.UN_SHARE_MEMBER_SUCCESS})
      // payload.fetchTimelineById(payload?.tid)


    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.UN_SHARE_MEMBER_FAILED });
  }
}


function* favourteTimeline({ payload }) {
  try {
    const res = yield call(timelineApi.favourteTimeline, payload?.tid)
    if(res.status === 200) {
      yield put({type : timelineActionTypes.FAVOURTE_TIMELINE_SUCCESS})
      payload.setFav(true)

    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.FAVOURTE_TIMELINE_FAILED });
  }
}

function* unFavourteTimeline({ payload }) {
  try {
    const res = yield call(timelineApi.unfavourteTimeline,payload?.tid)
    if(res.status === 200) {
      payload.setFav(false)

      yield put({type : timelineActionTypes.UN_FAVOURTE_TIMELINE_SUCCESS})
    }
  } catch (err) {
    notify("error",JSON.stringify(err));
    yield put({ type: timelineActionTypes.UN_FAVOURTE_TIMELINE_FAILED });
  }
}

function* pinUnpinTimeline({payload}) {

  try {
    const res = yield call(
      timelineApi.postPinUnpin,
      payload?.id,
      payload?.isPin,
    );

    if (res.status === 200) {
      var value = payload?.isPin?.pin == true ? 'Pin' : 'Unpin'
      notify('success', `${value} Successfully`);
      yield put({type: timelineActionTypes.TIMELINE_PIN_POST_SUCCESS});
      if(payload?.refreshTimeline){
        payload?.refreshTimeline()
      }
      
    }
  } catch (err) {
    notify('error', JSON.stringify(err));
    yield put({type: timelineActionTypes.TIMELINE_PIN_POST_FAILED});
  }
}
export function* watchTimeline() {
  yield takeLatest(timelineActionTypes.GET_TIMELINE_INIT, getTimeline);
  yield takeLatest(timelineActionTypes.CREATE_TIMELINE_INIT, createTimeline);
  yield takeLatest(timelineActionTypes.UPDATE_TIMELINE_INIT, updateTimeline);
  yield takeLatest(timelineActionTypes.DELETE_TIMELINE_INIT, deleteTimeline);
  yield takeLatest(timelineActionTypes.GET_COMMENT_INIT, getComment);
  yield takeLatest(timelineActionTypes.ADD_COMMENT_INIT, AddComment);
  yield takeLatest(timelineActionTypes.UPDATE_COMMENT_INIT, updateComment);
  yield takeLatest(timelineActionTypes.DELETE_COMMENT_INIT, deleteComment);
  yield takeLatest(timelineActionTypes.ADD_REPLY_INIT, addReply);
  yield takeLatest(timelineActionTypes.UPDATE_REPLY_INIT, updateReply);
  yield takeLatest(timelineActionTypes.DELETE_REPLY_INIT, deleteReply);
  yield takeLatest(timelineActionTypes.WATCH_TIMELINE_INIT, timelineWatch);
  yield takeLatest(timelineActionTypes.UN_WATCH_TIMELINE_INIT, unWatchTimeline);
  yield takeLatest(timelineActionTypes.LIKE_TIMELINE_INIT, likeTimeline);
  yield takeLatest(timelineActionTypes.UN_LIKE_TIMELINE_INIT, unLikeTimeline);
  yield takeLatest(timelineActionTypes.SHARE_GROUP_INIT, shareGroup);
  yield takeLatest(timelineActionTypes.UN_SHARE_GROUP_INIT, unShareGroup);
  yield takeLatest(timelineActionTypes.SHARE_MEMBER_INIT, shareMember);
  yield takeLatest(timelineActionTypes.UN_SHARE_MEMBER_INIT, unShareMember);
  yield takeLatest(timelineActionTypes.FAVOURTE_TIMELINE_INIT, favourteTimeline);
  yield takeLatest(timelineActionTypes.UN_FAVOURTE_TIMELINE_INIT, unFavourteTimeline);
  yield takeLatest(timelineActionTypes.TIMELINE_PIN_POST_INIT, pinUnpinTimeline);
}
