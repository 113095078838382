import React from "react";
import { Button, Form, Input, Modal } from "antd";

function CreateCoupon({ form, isModalVisible, hideModal, onSubmit }) {
	return (
		<Modal
			title="Create Coupon"
			visible={isModalVisible}
			onOk={hideModal}
			onCancel={hideModal}
			footer={null}
		>
			<Form
				form={form}
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				onFinish={onSubmit}
				autoComplete="off"
			>
				<Form.Item
					label="Coupon Code"
					name="couponCode"
					rules={[{ required: true, message: "Please enter Coupon Code!" }]}
				>
					<Input
						onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
					/>
				</Form.Item>

				<Form.Item
					label="Total Months"
					name="totalRedemptionMonths"
					rules={[{ required: true, message: "Please enter Total Months" }]}
				>
					<Input
						type="number"
						onInput={(e) => (e.target.value = e.target.value.slice(0, 2))}
					/>
				</Form.Item>

				<Form.Item
					label="Discount Amount"
					name="discountAmount"
					rules={[{ required: true, message: "Please enter Discount Amount" }]}
				>
					<Input type="number" />
				</Form.Item>

				<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
					<Button
						type="secondary"
						htmlType="button"
						className="ms-3"
						onClick={hideModal}
					>
						Cancel
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default CreateCoupon;
