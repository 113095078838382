import styled from "styled-components";

export const Container = styled.div`
  /* .ant-tabs-nav {
    width: 100% !important;
  } */

  /* .ant-tabs-tab {
   margin: auto;
  } */
/* 
  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
    
  } */
  .tab_tabName {
    /* width: 15rem; */
    text-align: center;
    font-size: 16px;
    font-family: Poppins;
  }
  .ant-tabs-nav-list {
    font-size: 16px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #363636 !important;
    font-weight: 500;
  }
  .ant-tabs-tab-btn {
    color: #605a5b;
    font-size: 14px;
    font-weight: 400;
  }
  .ant-input-affix-wrapper > input.ant-input {
    background: #dee3f3 !important;
  }
`;
