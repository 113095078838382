import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Form, Tabs, Button, Divider } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useEffect } from "react";
import { useState } from "react";
import ModalWrapper from "../../../../components/Modal";
import { InputField } from "../../../../components/_shared";
import useViewport from "../../../../hooks/useViewport";
import { notify } from "../../../../utils";
import { joinedUser } from "../../../../utils/joinedUser";
import studio from "../../../../services/studio";

const { TabPane } = Tabs;

const ManageGroupModal = ({
  visible,
  setVisible,
  studioMembers,
  groupDetails,
  onDeleteGroup,
  deleteGroupMember,
  addGroupMember,
  userData,
  refreshGroup,
}) => {
  const [defaultActiveKey, setActivekey] = useState("1");
  const [check, setCheck] = useState(false);
  const [searchField, setSearchField] = useState("");
  const { isMobile } = useViewport();
  const [groupName, setGroupName] = useState(groupDetails?.groupName);
  const [group, setGroup] = useState(groupDetails);
  const [isUpdate, setIsUpdate] = useState(false);
  const [gmembers, setMember] = useState(
    groupDetails.members?.length ? groupDetails.members : []
  );

  useEffect(() => {
    setGroup(groupDetails);
  }, [groupDetails]);

  const shareGroupMember = (mid) => {
    setIsUpdate(!isUpdate);
    var newGroupMember = gmembers;
    newGroupMember = [...gmembers, mid];
    setMember(newGroupMember);
  };

  const unShareGroupMember = (mid) => {
    setIsUpdate(!isUpdate);

    var newGroupMember = gmembers.filter((id) => mid != id);
    setMember(newGroupMember);
  };

  const updateGroup = () => {
    studio
      .updateGroup(group?.id, { name: groupName })
      .then((res) => {
        if (res?.status === 200) {
          refreshGroup();
          notify("success", "Group Updated!");
        }
      })
      .catch((err) => {
        notify("error", err);
      });
  };

  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-center">
            <h3>Manage Group</h3>
          </div>
          <Form
            layout="vertical"
            // className="mt-4"
            className="row mt-4"
            // style={{ width: "100%" }}
            // onFinish={onSignup}
          >
            <div className="col-md-8">
              <Form.Item label="Group Name">
                <InputField
                  onChange={(e) => setGroupName(e?.target?.value)}
                  value={groupName}
                  className="font-600"
                  placeholder="Group Name"
                />
              </Form.Item>
            </div>
            <div className="col-md-4">
              <Button
                htmlType="submit"
                size={"large"}
                type="primary"
                className="rounded "
                style={{ marginTop: isMobile ? "" : "37px" }}
              >
                <h5 className="font-white" onClick={updateGroup}>
                  Save
                </h5>
              </Button>
            </div>

            <div className="col-md-12 d-flex justify-content-end">
              <DeleteOutlined
                className="text-danger h4 cursor-pointer"
                onClick={() => onDeleteGroup(group?.id)}
              />
            </div>

            <div className="col-md-6 d-flex ">
              <InputField
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                placeholder="Search"
                suffix={<SearchOutlined />}
                style={{ width: `${isMobile ? "" : "350px"}` }}
              />
            </div>

            <div
              style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
              className="col-md-6 d-flex "
            >
              <Button
                size={"large"}
                type="primary"
                className="rounded "
                // style = {{heigth : "200px"}}
              >
                <h5 className="font-white" onClick={() => setVisible(false)}>
                  {" "}
                  Share
                </h5>
              </Button>
            </div>
            <div className="col-md-12">
              <Tabs
                activeKey={String(defaultActiveKey)}
                onChange={(e) => {
                  setActivekey(e);
                }}
                type="line"
                tabBarGutter={0}
              >
                <TabPane
                  className={defaultActiveKey == 1 ? "mb-4" : ""}
                  tab={<div className="tab_tabName">All</div>}
                  key="1"
                />

                <TabPane
                  className={defaultActiveKey == 2 ? "mb-4" : ""}
                  tab={<div className="tab_tabName">In this group</div>}
                  key="2"
                />

                <TabPane
                  className={defaultActiveKey == 3 ? "mb-4" : ""}
                  tab={<div className="tab_tabName">Not in this group</div>}
                  key="3"
                />
              </Tabs>
            </div>

            {defaultActiveKey == "1" &&
              studioMembers
                ?.filter((member) =>
                  `${member?.name}`
                    .toLowerCase()
                    .includes(searchField.toLowerCase())
                )
                ?.map(
                  (member) =>
                    userData?.id !== member?.id && (
                      <div className="row " key={member?.id}>
                        <div className="col-md-12 d-flex align-content-center">
                          <Checkbox
                            onChange={(e) => {
                              setCheck(e?.target.checked);
                              e?.target.checked
                                ? shareGroupMember(member?.id)
                                : unShareGroupMember(member?.id);
                              e?.target.checked
                                ? addGroupMember(group?.id, member?.id)
                                : deleteGroupMember(group?.id, member?.id);
                            }}
                            checked={joinedUser(member?.id, gmembers)}
                            className="mx-1  mt-3"
                          />
                          <img
                            width={60}
                            src={`${member?.profilepic}`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${process.env.PUBLIC_URL}user.png`;
                            }}
                            className="mx-4 "
                          />
                          <span className="h5 mt-3"> {member?.name}</span>
                        </div>
                        <div className="col-md-12">
                          <Divider />
                        </div>
                      </div>
                    )
                )}

            {defaultActiveKey == "2" &&
              studioMembers
                ?.filter((member) =>
                  `${member?.name}`
                    .toLowerCase()
                    .includes(searchField.toLowerCase())
                )
                ?.map(
                  (member) =>
                    userData?.id !== member?.id &&
                    joinedUser(member?.id, group?.members) && (
                      <div className="row " key={member?.id}>
                        <div className="col-md-12 d-flex align-content-center">
                          <Checkbox
                            className="mx-1  mt-3"
                            onChange={(e) => {
                              setCheck(e?.target.checked);
                              e?.target.checked
                                ? shareGroupMember(member?.id)
                                : unShareGroupMember(member?.id);
                              e?.target.checked
                                ? addGroupMember(group?.id, member?.id)
                                : deleteGroupMember(group?.id, member?.id);
                            }}
                            checked={joinedUser(member?.id, gmembers)}
                          />
                          <img
                            width={60}
                            src={`${member?.profilepic}`}
                            className="mx-4 "
                          />
                          <span className="h5 mt-3"> {member?.name}</span>
                        </div>
                        <div className="col-md-12">
                          <Divider />
                        </div>
                      </div>
                    )
                )}

            {defaultActiveKey == "3" &&
              studioMembers
                ?.filter((member) =>
                  `${member?.name}`
                    .toLowerCase()
                    .includes(searchField.toLowerCase())
                )
                ?.map(
                  (member) =>
                    userData?.id !== member?.id &&
                    !joinedUser(member?.id, group?.members) && (
                      <div className="row " key={member?.id}>
                        <div className="col-md-12 d-flex align-content-center">
                          <Checkbox
                            className="mx-1  mt-3"
                            onChange={(e) => {
                              setCheck(e?.target.checked);
                              e?.target.checked
                                ? shareGroupMember(member?.id)
                                : unShareGroupMember(member?.id);
                              e?.target.checked
                                ? addGroupMember(group?.id, member?.id)
                                : deleteGroupMember(group?.id, member?.id);
                            }}
                            checked={joinedUser(member?.id, gmembers)}
                          />
                          <img
                            width={60}
                            src={`${member?.profilepic}`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${process.env.PUBLIC_URL}user.png`;
                            }}
                            className="mx-4 "
                          />
                          <span className="h5 mt-3"> {member?.name}</span>
                        </div>
                        <div className="col-md-12">
                          <Divider />
                        </div>
                      </div>
                    )
                )}
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ManageGroupModal;
