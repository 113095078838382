import { SearchOutlined, SettingFilled } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../../../components/_shared";
import Loading from "../../../../components/_shared/Loader/Loading";
import useViewport from "../../../../hooks/useViewport";
import {
  fetchMyGroupsInit,
  fetchStudioMembers,
} from "../../../../store/actions/studio";
import {
  makeSelectGroups,
  makeSelectMembers,
  makeSelectStudioLoading,
} from "../../../../store/selectors";
import { joinedUser } from "../../../../utils/joinedUser";
import ManageMemberModal from "./ManageMemberModal";

const CurrentMembers = () => {
  const { isMobile } = useViewport();
  const dispatch = useDispatch();
  const studioMembers = useSelector(makeSelectMembers());
  const isLoading = useSelector(makeSelectStudioLoading());
  const groups = useSelector(makeSelectGroups());
  const [searchField, setSearchField] = useState("");
  const [currentmember,setCurrentMember] =useState("")
  const [showmanageModal,setShowManageModal] =useState(false)

  useEffect(() => {
    dispatch(fetchStudioMembers());
    dispatch(fetchMyGroupsInit());
  }, []);

  return (
    <div className="row">
      <Loading isLoading={isLoading} />
      <div className="col-md-12 d-flex ">
        <InputField
          value={searchField}
          onChange={(e) => setSearchField(e.target.value)}
          placeholder="Search"
          suffix={<SearchOutlined />}
          style={{ width: `${isMobile ? "" : "100%"}` }}
        />
        <ManageMemberModal visible={showmanageModal} currentmember={currentmember} setVisible={setShowManageModal} />
      </div>
      {studioMembers
        ?.filter((member) =>
          `${member?.name}`.toLowerCase().includes(searchField.toLowerCase())
        )
        ?.map((member) => (
          <div className="col-md-4 d-flex mt-3 ">
            <div className="container   bg-secondary-gray shadow p-3">
              <div className="col-md-12 d-flex justify-content-end cursor-pointer">
                <SettingFilled
                  className="h5"
                  onClick={() => {
                    setShowManageModal(!showmanageModal);
                    // setGroupDetails(group);
                    // setGroupIndex(index);
                    setCurrentMember(member)
                  }}
                />
              </div>
              <div className="col-md-12  d-flex align-self-stretch">
                <div className="">
                  <img src={`${member?.profilepic}`} width={70} />
                </div>
                <div className="d-flex flex-column mt-1">
                  <span className="h5 mx-3 font-light-gray">
                    {member?.name}
                  </span>
                  <span className="mx-3">
                    Groups :{" "}
                    {groups?.map(
                      (grp) =>
                        joinedUser(member?.id, grp?.members) && (
                          <bold
                            style={{ fontWeight: 500, fontFamily: "Poppins" }}
                          >
                            {" "}
                            {grp?.groupName},
                          </bold>
                        )
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CurrentMembers;
