import { Button, Card, Divider, Alert } from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userInfo";
import useViewport from "../../hooks/useViewport";
import { byteConvert } from "../../utils/utils";
import moment from "moment";
import Carousel from "../Pricing/slider";
import StripeStorage from "./component/StripeStorage";
import { isExpired } from "../../utils/helper";
import admin from "../../services/admin";
import OldPaymentDetailsUpdate from "../Dashboard/Timeline/components/OldPaymentDetailsUpdate";

const StorageUpgrade = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const { isMobile } = useViewport();
  const [content, setContent] = useState({});
  useEffect(() => {
    getUserinfo();
    getContent();
  }, []);

  const getContent = () => {
    admin
      .getContent({
        home: 1,
        getStarted: 1,
        why: 1,
        faq: 1,
        pricing: 1,
        guide: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
        // notify("error",JSON.stringify(err));
      });
  };

  return (
    <>
   
      {isExpired(userData?.studioMonthExp) && (
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-12">
              <Alert
                message={
                  <span style={{ fontWeight: 500 }}>
                    Your current subscription has been expired. Please upgrade.{" "}
                  </span>
                }
                type="error"
              />
            </div>
          </div>
        </div>
      )}

      {userData?.role === "studioOwner" && !userData?.isPaymentUpdated ? (
        <OldPaymentDetailsUpdate
          getUserinfo={getUserinfo}
          userData={userData}
        />
      ) : (
        ""
      )}

      <div className="d-flex justify-content-around" style={{ width: "100%" }}>
        <div className="row p-3" style={{ width: "100%" }}>
          {content?.pricing?.cards?.map(
            (item, index) =>
              userData?.studentEnrolledLimit <= item?.price && (
                <div style={{ padding: 8 }} className="col-md-3">
                  <Card
                    hoverable
                    key={index}
                    style={{
                      background:
                        userData?.studentEnrolledLimit == item?.price
                          ? "#0eaaa561"
                          : "",
                    }}
                    className="  mt-5"
                  >
                    <Meta
                      title={
                        <div className="d-flex justify-content-center mt-4">
                          {" "}
                          <h3>{item?.priceText}</h3>
                          <small className="text-secondary mt-3">Month</small>
                        </div>
                      }
                      // description={
                      //   <div className="d-flex justify-content-center">
                      //     <h5 className="text-secondary"> {item?.storage} GB</h5>
                      //   </div>
                      // }
                    />
                    <Divider />
                    <div className="mt-3">
                      <ul>
                        <li className="text-secondary font-weight-bold">
                          {item?.studentText}
                        </li>
                        <li className="text-secondary font-weight-bold">
                          {item?.storageText}
                        </li>
                        {/* <li className="text-secondary font-weight-bold mt-1">
                    Lorem ipsum dolor sit, amet sdkndkgnd
                  </li>
                  <li className="text-secondary font-weight-bold mt-1">
                    Lorem ipsum dolor sit, amet sdkndkgnd
                  </li> */}
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center">
                      {userData?.studentEnrolledLimit == item?.price &&
                      isExpired(userData?.studioMonthExp) === false ? (
                        <div
                          style={{ flexDirection: "column", display: "flex" }}
                        >
                          <Button type="primary">Current Plan</Button>
                          Expire :{" "}
                          {moment(userData?.studioMonthExp).format("LL")}
                        </div>
                      ) : (
                        <StripeStorage
                          maxStudents={Number(item?.maxStudents)}
                          getUserinfo={getUserinfo}
                          userData={userData}
                          price={Number(item?.price)}
                          storage={Number(item?.storage)}
                        />
                      )}
                    </div>
                  </Card>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};
export default StorageUpgrade;
