import { createConstants } from "redux-model-tools";

export default createConstants("auth", [
  "SIGNUP_INIT",
  "SIGNUP_SUCCESS",
  "SIGNUP_FAILED",

  "LOGIN_INIT",
  "LOGIN_SUCCESS",
  "LOGIN_FAILED",

  'UPDATE_PROFILE_INIT',
  'UPDATE_PROFILE_SUCCESS',
  'UPDATE_PROFILE_FAILURE',

  'DRAG_DROP_INIT',
  'DRAG_DROP_SUCCESS',
  'DRAG_DROP_FAILED'
]);
