import styled from 'styled-components'


export const TimelineWraaper = styled.div`
.ant-select-selector {
  height: 42px !important;
  border: 1px solid #b7b5b5;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  margin: auto auto;
}

.ant-select-arrow {
  font-size: 15px;
  color: #0eaaa5;
}


`

export const Wrapper = styled.div`
  width : 90%;
 .ant-card-head {
  background: #605A5B;
 } 
 .ant-card-body {
   background  : #F4F1EC
 }


.ant-collapse-header {
  background: #6C6C6C;
}
.ant-collapse-content  {
  background  : #F4F1EC
}

.ant-select-selector {
    height: 42px !important;
    border: 1px solid #b7b5b5;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    margin: auto auto;
  }

  .ant-select-arrow {
    font-size: 15px;
    color: #0eaaa5;
  }


`