import { createConstants } from "redux-model-tools";

export default createConstants("studio", [
  'FETCH_JOINED_STUDIO_INIT',
  'FETCH_JOINED_STUDIO_SUCCESS',
  'FETCH_JOINED_STUDIO_FAILED',

  'JOIN_STUDIO_INIT',
  'JOIN_STUDIO_SUCCESS',
  'JOIN_STUDIO_FAILED',

  'LEFT_STUDIO_INIT',
  'LEFT_STUDIO_SUCCESS',
  'LEFT_STUDIO_FAILED',

  'JOIN_GROUP_INIT',
  'JOIN_GROUP_SUCCESS',
  'JOIN_GROUP_FAILED',

  'LEFT_GROUP_INIT',
  'LEFT_GROUP_SUCCESS',
  'LEFT_GROUP_FAILED',

  'ADD_GROUP_INIT',
  'ADD_GROUP_SUCCESS',
  'ADD_GROUP_FAILED',

  'DELETE_GROUP_INIT',
  'DELETE_GROUP_SUCESS',
  'DELETE_GROUP_FAILED',

  'ADD_GROUP_MEMBER_INIT',
  'ADD_GROUP_MEMBER_SUCCESS',
  'ADD_GROUP_MEMBER_FAILED',

  'DELETE_GROUP_MEMBER_INIT',
  'DELETE_GROUP_MEMBER_SUCESS',
  'DELETE_GROUP_MEMBER_FAILED',

  'FETCH_STUDIO_MEMBERS_INIT',
  'FETCH_STUDIO_MEMBERS_SUCCESS',
  'FETCH_STUDIO_MEMBERS_FAILED',

  "FETCH_STUDIO_GROUP_INIT",
  "FETCH_STUDIO_GROUP_SUCCESS",
  "FETCH_STUDIO_GROUP_FAILED"

]);
