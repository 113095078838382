import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  FileAddOutlined,
  PlusSquareOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { HeaderTab } from "../components";
import { Button, Progress, Select } from "antd";
import { TimelineWraaper } from "../components/component";
import { Post, TimelineModal } from "../components";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectGroups,
  makeSelectMembers,
  makeSelectStudio,
  makeSelectTimeline,
  makeSelectTimelineComment,
  makeSelectTimelineLoading,
} from "../../../../store/selectors";
import { UserContext } from "../../../../context/userInfo";
import {
  createTimelineInit,
  deleteTimelineInit,
  getCommentInit,
  getTimelineInit,
  updateTimelneInit,
} from "../../../../store/actions/timeline";
import { notify, SERVER_URL } from "../../../../utils";
import ShareTimeline from "../components/ShareTimeline";
import {
  fetchJoinedStudioInit,
  fetchMyGroupsInit,
  fetchStudioMembers,
} from "../../../../store/actions/studio";
import { ViewProfileContext } from "../../../../context/viewProfile";
import { apiPost } from "../../../../utils/axios";
import { InputField } from "../../../../components/_shared";
import {
  GrayDatePicker,
  GrayInputField,
} from "../../../../components/_shared/Input";
import portfolio from "../../../../services/portfolio";
import PortfolioModal from "../components/PortfolioModal";
import UpdateTimelneModal from "../components/UpdateTImelineModal";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../components/_shared/Loader/Loading";
import { MEDIA_URL } from "../../../../utils/server";
import axios from "axios";
import ReportModal from "../components/ReportModel";
import moment from "moment";
import timeline from "../../../../services/timeline";
import { VideoUpdateContext } from "../../../../context/UpdateVideo";
const { Option } = Select;

const TimelineDetails = () => {
  const [showTimelineModal, setShowTimelineModal] = useState(false);
  const { uoloadVideotoVimeo } = useContext(VideoUpdateContext);

  const timelines = useSelector(makeSelectTimeline());
  const [timelineId, setTimelineId] = useState("");
  const [limit, setLimit] = useState(3);
  const [commentLimit, setCommentLimit] = useState(2);
  const { userData, getUserinfo } = useContext(UserContext);
  const [shareCheck, setShareCheck] = useState([]);
  const [shareTimeline, setShareTimeline] = useState({});
  const [shareModalShow, setShareModalShow] = useState(false);
  const studios = useSelector(makeSelectStudio());
  const studioMembers = useSelector(makeSelectMembers());
  const [singleStudio, setSingleStudio] = useState("");
  const { profileToken, viewUser } = useContext(ViewProfileContext);
  const [groupFilter, setGroupFilter] = useState("");
  const [favFilter, setFavFilter] = useState(false);
  const timelineLoading = useSelector(makeSelectTimelineLoading());
  const [vimeoLoading, setVimeoLoading] = useState(false);
  const [hideShare, setHideShare] = useState(false);
  const [makeHideCopy, setHideMakeCopy] = useState(false);
  const [hideComment, setHideComment] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [portfolios, setPortfolios] = useState([]);
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);
  const [tid, setTid] = useState("");
  const [updateTimeline, setUpdateTimeline] = useState("");
  const [showUpdate, setShowUpdate] = useState(false);
  const [date, setDate] = useState("");
  const [portfolioLoading, setPortfolioLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [search, setSearch] = useState("");
  const [description, setDescription] = useState("");
  const [timelineVimeo, setTimelineVimeo] = useState("");
  const [timelineVidoes, setTiemlineVideos] = useState([]);
  const [storageLimit, setStorageLimit] = useState("");
  const [reportedTimeline, setReportedTimeline] = useState("");
  const [timelineData, setTimelineData] = useState({});
  const groups = useSelector(makeSelectGroups());
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleted, setISdeleted] = useState(false);

  const query = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!profileToken?.access?.token && userData?.role === "admin") {
    navigate("/manage-user");
  }

  const fetchTimelineById = (id) => {
    setIsLoading(true);
    timeline
      .fetcTimelineById(id)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.timeline) {
          setTimelineData(res?.data?.timeline);
        } else {
          setISdeleted(true);
        }
      })
      .catch((err) => {
        setISdeleted(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserinfo();
    dispatch(
      fetchJoinedStudioInit({ profileToken: profileToken?.access?.token })
    );
    dispatch(fetchStudioMembers({ profileToken: profileToken?.access?.token }));
    fetchMyPortoflio();
  }, []);

  useEffect(() => {
    fetchTimelineById(query?.id);
    refresh();
  }, [userData]);

  const refresh = () => {
    dispatch(fetchMyGroupsInit());
  };

  const groupsID = groups?.map((i) => i?.id);
  const checkSharedGroup = timelineData?.sharedGroup?.some((item) =>
    groupsID?.includes(item)
  );
  const checkSharedmember = timelineData?.sharedMember?.includes(userData?.id);
  const checkPostbyme = timelineData?.user?.id === userData?.id;
  const itsViewable =
    checkSharedGroup === true ||
    checkSharedmember === true ||
    checkPostbyme === true;

  // const uploadTimelineInVimeo = (tid) => {
  //   let formData = new FormData();
  //   for (let video of timelineVidoes) {
  //     // let videoId = await onUpload(video?.originFileObj);
  //     formData.append("videoId", video?.originFileObj);
  //   }

  //   formData.append("tid", tid);

  //   timeline
  //     .uploadTimelineVimeo(formData)
  //     .then((res) => {
  //       setTimelineVimeo("");
  //       setTiemlineVideos("");
  //     })
  //     .catch((err) => {});
  // };

  const handleFilterByGroup = (e) => {
    setGroupFilter(e);
    setFavFilter(false);
    setDate("");
    dispatch(
      getTimelineInit({
        page: 1,
        limit,
        profileToken: profileToken?.access?.token,
        group: e,
        notViewed: e != "1" ? (e == "3" ? true : false) : false,
        notShared: e != "1" ? (e == "4" ? true : false) : false,
        group: e != "1" ? (e ? e : false) : false,
      })
    );
  };

  useEffect(() => {
    if (singleStudio == "1" || singleStudio == "3" || singleStudio == "4") {
      setFavFilter(false);
      setGroupFilter("");
      // refreshTimeline();
    }
  }, [singleStudio]);

  const refreshTimeline = () => {
    setSearch("");
    dispatch(
      getTimelineInit({
        page: 1,
        limit,
        profileToken: profileToken?.access?.token,
        fav: singleStudio == "1" ? false : favFilter,
        notViewed:
          singleStudio != "1" ? (singleStudio == "3" ? true : false) : false,
        notShared:
          singleStudio != "1" ? (singleStudio == "4" ? true : false) : false,
        group:
          singleStudio != "1" ? (groupFilter ? groupFilter : false) : false,
        date: singleStudio != "1" ? (date ? date : false) : false,
      })
    );
  };

  const onSubmitTimeline = async (e) => {
    setUploadLoading(true);

    let size = 0;
    // let formData = new FormData();

    let formData = {
      postPic: [],
      youtubeUrl: [],
      vimeoUrl: [],
      title: e?.title,
      timelineDate: moment(e?.date),
      hideComment,
      description: description ? description : "",
      hideShare,
      makeCopy: makeHideCopy,
      totalVideos: e?.videos?.fileList.length,
      signupCode: userData?.signupCode,
    };

    if (e?.files?.fileList.length)
      for (let file of e?.files?.fileList) {
        const url = await onFileUpload(file?.originFileObj);
        size = size + file?.size / 1024;
        formData.postPic.push(url);
      }
    if (e?.youtubeUrls)
      for (let url of e?.youtubeUrls) formData.youtubeUrl.push(url?.youtubeUrl);
    // formData.append("youtubeUrl", url?.youtubeUrl);
    if (e?.vimeoUrls) {
      //  formData.append("vimeoUrl", url?.vimeoUrl);
      for (let url of e?.vimeoUrls) formData?.vimeoUrl.push(url?.vimeoUrl);
    }

    if (e?.videos?.fileList?.length) {
      // formData.append("totalVideos", e?.videos?.fileList?.length);

      for (let video of e?.videos?.fileList) {
        size = size + video?.size / 1024;
      }
      setTiemlineVideos(e?.videos?.fileList);
    }

    // if(userData?.role === "studioOwner") {

    //   if(userData?.storageLimitRemaining <= 0) {
    //     navigate("/upgrade-storage")
    //   }
    // }

    // if (e?.videos?.fileList?.length) {
    //   for (let video of e?.videos?.fileList) {
    //     // let videoId = await onUpload(video?.originFileObj);
    //     formData.append("totalVideos", video?.originFileObj);
    //   }
    // }

    // if(e?.videos?.fileList?.length){
    //           formData.append("totalVideos", e?.videos?.fileList?.length);
    //           setTiemlineVideos(e?.videos?.fileList)

    // }

    // formData.append("title", e?.title);
    // formData.append("timelineDate", moment(e?.date));
    // formData.append("hideComment", hideComment);
    // formData.append("description", description ? description : "");
    // formData.append("makeCopy", makeHideCopy);
    // formData.append("hideShare", hideShare);

    dispatch(
      createTimelineInit({
        formData: { ...formData, size },
        setVisible: setShowTimelineModal,
        setShareTimeline,
        setShareModalShow,
        shareModalShow: true,
        setTimelineVimeo,
      })
    );

    setUploadLoading(false);
    setDescription("");

    // else
    //   dispatch(
    //     createTimelineInit({
    //       youtubeUrl: e?.youtubeUrls ,
    //       title: e?.title,
    //       vimeoUrl: e?.vimeoUrls,
    //       description: description,
    //       timelineDate: e?.date,
    //     })
    //   );

    setTimeout(() => {
      // refreshTimeline();
      getUserinfo();
    }, 100);
  };

  const onFileUpload = async (file) => {
    try {
      let formData = new FormData();
      formData.append("avatar", file);
      const res = await axios.post(MEDIA_URL, formData);
      return res?.data?.message;
    } catch (err) {
      notify("error", "Something went wrong");
    }
  };

  const onUpdateTimeline = async (e) => {
    let formData = {
      title: e?.title,
      timelineDate: moment(e?.date),
      hideComment: e?.hideComment,
      description: description ? description : "",
      makeCopy: e?.makeCopy,
      hideShare: e?.hideShare,
      vimeoUrl: [],
      youtubeUrl: [],
      // postPic: updateTimeline?.postPic,
      // videoId: updateTimeline?.videoId,
    };

    if (e?.youtubeUrls)
      for (let url of e?.youtubeUrls)
        formData?.youtubeUrl.push(url?.youtubeUrl);
    if (e?.vimeoUrls)
      for (let url of e?.vimeoUrls) formData?.vimeoUrl.push(url?.vimeoUrl);

    // if (e?.files?.fileList.length)
    //   for (let file of e?.files?.fileList) {
    //     formData.postPic = [];
    //     const url = await onFileUpload(file?.originFileObj);

    //     formData.postPic.push(url);
    //   }

    // if (e?.videos?.fileList?.length) {
    //   for (let video of e?.videos?.fileList) {
    //     formData.videoId = [];
    //     let videoId = await onUpload(video?.originFileObj);
    //     formData?.videoId?.push(videoId);
    //   }
    // }

    dispatch(
      updateTimelneInit({
        id: updateTimeline?.id,
        formData,
        setVisible: setShowUpdate,
      })
    );

    setDescription("");

    // else
    //   dispatch(
    //     createTimelineInit({
    //       youtubeUrl: e?.youtubeUrls ,
    //       title: e?.title,
    //       vimeoUrl: e?.vimeoUrls,
    //       description: description,
    //       timelineDate: e?.date,
    //     })
    //   );
    setDescription("");
    setTimeout(() => {
      refreshTimeline();
    }, 100);
  };

  const makeCopy = async (e) => {
    let formData = {
      timelineDate: e?.timelineDate,
      youtubeUrl: e?.youtubeUrl,
      vimeoUrl: e?.vimeoUrl,
      videoId: e?.videoId,
      title: e?.title,
      description: description,
      isPrivate: e?.isPrivate,
      postPic: e?.postPic,
      size: e?.size,
      signupCode: e?.signupCode,
    };

    dispatch(
      createTimelineInit({
        formData,
        setVisible: setShowTimelineModal,
        setShareTimeline,
        setShareModalShow,
      })
    );

    setTimeout(() => {
      refreshTimeline();
      getUserinfo();
    }, 2000);
  };

  const deleteTimeline = (id) => {
    dispatch(deleteTimelineInit(id));
    setTimeout(() => {
      refreshTimeline();
      getUserinfo();
    }, 2000);
  };

  const fetchComment = (tid) => {
    dispatch(
      getCommentInit({
        tid,
        limit: commentLimit,
        page: 1,
        profileToken: profileToken?.access?.token,
      })
    );
  };

  useEffect(() => {
    if (timelineId) fetchComment(timelineId);
  }, [commentLimit]);

  const onUpload = async (file) => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      let res = await apiPost(
        SERVER_URL + "/timeline/vimeo/upload-video",
        formData
      );
      return res.data?.videoId;
    } catch (err) {
      notify("error", JSON.stringify(err));
    }
  };

  const fetchMyPortoflio = () => {
    setPortfolioLoading(true);
    portfolio
      .getPortfolio()
      .then((res) => {
        if (res.status === 200) {
          setPortfolioLoading(false);

          setPortfolios(res?.data?.portfolios);
        }
      })
      .catch((err) => {
        setPortfolioLoading(false);
        // notify("error", JSON.stringify(err));
      });
  };

  const filterByDate = (date, dateString) => {
    setFavFilter(false);
    setDate(date);
    dispatch(
      getTimelineInit({
        page: 1,
        limit,
        profileToken: profileToken?.access?.token,
        date: new Date(date),
      })
    );
  };

  const handleKeyDown = (event) => {
    setSearch(event?.traget?.value);
    if (event.key === "Enter") {
      dispatch(
        getTimelineInit({
          page: 1,
          limit: 1000,
          profileToken: profileToken?.access?.token,
          search: event?.target?.value,
        })
      );
    }
  };
// console.log(itsViewable && timelineData?.user?.id === userData?.id)
  return itsViewable && timelineData?.user?.id === userData?.id ? (
    <>
      {showTimelineModal && (
        <TimelineModal
          onSubmitTimeline={onSubmitTimeline}
          visible={showTimelineModal}
          checkbox={shareCheck}
          setCheckbox={setShareCheck}
          setVisible={setShowTimelineModal}
          timelineLoading={timelineLoading && vimeoLoading}
          setHideShare={setHideShare}
          setHideMakeCopy={setHideMakeCopy}
          setDescription={setDescription}
          description={description}
          setHideComment={setHideComment}
          studios={studios}
        />
      )}
      {shareModalShow && (
        <ShareTimeline
          visible={shareModalShow}
          setVisible={setShareModalShow}
          shareTimeline={shareTimeline}
        />
      )}
      {showPortfolioModal && (
        <PortfolioModal
          fetchMyPortoflio={fetchMyPortoflio}
          timelineId={tid}
          portfolios={portfolios}
          visible={showPortfolioModal}
          setVisible={setShowPortfolioModal}
        />
      )}
      {showUpdate && (
        <UpdateTimelneModal
          onUpdateTimeline={onUpdateTimeline}
          visible={showUpdate}
          checkbox={shareCheck}
          setCheckbox={setShareCheck}
          timeline={updateTimeline}
          setVisible={setShowUpdate}
          timelineLoading={timelineLoading && vimeoLoading}
          setHideShare={setHideShare}
          setHideMakeCopy={setHideMakeCopy}
          setHideComment={setHideComment}
          setDescription={setDescription}
          description={description}
        />
      )}
      {showReport && (
        <ReportModal
          title={reportedTimeline?.title}
          visible={showReport}
          setVisible={setShowReport}
        />
      )}

      <Loading
        isLoading={
          timelineLoading || portfolioLoading || uploadLoading ? true : false
        }
      />

      {timelineData?.id && (
        <Post
          profileToken={profileToken?.access?.token}
          refreshTimeline={refreshTimeline}
          userData={userData}
          studios={studios}
          studioMembers={studioMembers}
          fetchComment={fetchComment}
          onDeleteTimeline={deleteTimeline}
          key={timelineData?.id}
          timeline={timelineData}
          setShareModalShow={setShareModalShow}
          setShareTimeline={setShareTimeline}
          onMakeCopy={makeCopy}
          setShowPortfolioModal={setShowPortfolioModal}
          setTid={setTid}
          setUpdateTimeline={setUpdateTimeline}
          setShowUpdate={setShowUpdate}
          setShowReport={setShowReport}
          setReportedTimeline={setReportedTimeline}
        />
      )}
    </>
  ) :itsViewable && moment(timelineData?.timelineDate) <= moment() ? (
    <>
      {showTimelineModal && (
        <TimelineModal
          onSubmitTimeline={onSubmitTimeline}
          visible={showTimelineModal}
          checkbox={shareCheck}
          setCheckbox={setShareCheck}
          setVisible={setShowTimelineModal}
          timelineLoading={timelineLoading && vimeoLoading}
          setHideShare={setHideShare}
          setHideMakeCopy={setHideMakeCopy}
          setDescription={setDescription}
          description={description}
          setHideComment={setHideComment}
          studios={studios}
        />
      )}
      {shareModalShow && (
        <ShareTimeline
          visible={shareModalShow}
          setVisible={setShareModalShow}
          shareTimeline={shareTimeline}
        />
      )}
      {showPortfolioModal && (
        <PortfolioModal
          fetchMyPortoflio={fetchMyPortoflio}
          timelineId={tid}
          portfolios={portfolios}
          visible={showPortfolioModal}
          setVisible={setShowPortfolioModal}
        />
      )}
      {showUpdate && (
        <UpdateTimelneModal
          onUpdateTimeline={onUpdateTimeline}
          visible={showUpdate}
          checkbox={shareCheck}
          setCheckbox={setShareCheck}
          timeline={updateTimeline}
          setVisible={setShowUpdate}
          timelineLoading={timelineLoading && vimeoLoading}
          setHideShare={setHideShare}
          setHideMakeCopy={setHideMakeCopy}
          setHideComment={setHideComment}
          setDescription={setDescription}
          description={description}
        />
      )}
      {showReport && (
        <ReportModal
          title={reportedTimeline?.title}
          visible={showReport}
          setVisible={setShowReport}
        />
      )}

      <Loading
        isLoading={
          timelineLoading || portfolioLoading || uploadLoading ? true : false
        }
      />

      {timelineData?.id && (
        <Post
          profileToken={profileToken?.access?.token}
          refreshTimeline={refreshTimeline}
          userData={userData}
          studios={studios}
          studioMembers={studioMembers}
          fetchComment={fetchComment}
          onDeleteTimeline={deleteTimeline}
          key={timelineData?.id}
          timeline={timelineData}
          setShareModalShow={setShareModalShow}
          setShareTimeline={setShareTimeline}
          onMakeCopy={makeCopy}
          setShowPortfolioModal={setShowPortfolioModal}
          setTid={setTid}
          setUpdateTimeline={setUpdateTimeline}
          setShowUpdate={setShowUpdate}
          setShowReport={setShowReport}
          setReportedTimeline={setReportedTimeline}
        />
      )}
    </>
  ) : (
    isLoading === false && (
      <h6 className="text-center mt-4" style={{ color: "gray" }}>
        {isDeleted
          ? "This post has been deleted. Please refer to future notifications."
          :itsViewable &&  moment(timelineData?.timelineDate) > moment()
          ? `You can see this post on ${moment(
              timelineData?.timelineDate
            ).format("DD-MM-YYYY")}`
          : " Checking permissions…. if the post does not appear, please check you are logged into the correct account and try again."}
      </h6>
    )
  );
};

export default TimelineDetails;
