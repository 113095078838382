import React, { useEffect } from "react";

import { Button, Typography, List, Row, Col, Divider } from "antd";
import { HeroPanel } from "./component";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../route/constant";
import Img from "../../assets/images/get-started-2.jpg";
import { useState } from "react";
import admin from "../../services/admin";
import { notify } from "../../utils";

const GetStarted = () => {
  const navigate = useNavigate();
  const { Title } = Typography;
  const [content, setContent] = useState({});

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    admin
      .getContent({
        home: 1,
        getStarted: 1,
        why: 1,
        faq: 1,
        pricing: 1,
        guide: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  return (
    <HeroPanel>
      <div className="hero-image">
        <div className="hero-text">
          <h1 className="title"> {content?.started?.title}</h1>
          <h4 className="hero-subtitle"> {content?.started?.subtitle} </h4>
        </div>
      </div>
      <section style={{ background: "#0eaaa5", padding: 50 }}>
        <div className="container">
          <Row gutter={[48, 16]}>
            <Col
              xl={12}
              sm={24}
              md={24}
              xxl={12}
              lg={12}
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Title
                style={{
                  color: "white",
                  fontWeight: "400",
                  textAlign: "center",
                }}
                level={5}
              >
                MOVITANZ is a collaborative environment to help dancers get the
                most out of dance. It works best when everyone plays their part.
                Getting started with MOVITANZ depends a bit on which part you
                play!
              </Title>
            </Col>
            <Col
              xl={12}
              sm={24}
              md={24}
              xxl={12}
              lg={12}
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Title
                style={{
                  color: "white",
                  fontWeight: "400",
                  textAlign: "center",
                }}
                level={1}
              >
                Already have a sign up code?{" "}
              </Title>
              <Title
                style={{
                  color: "white",
                  fontWeight: "400",
                  textAlign: "center",
                }}
                level={5}
              >
                If your dance teacher has given you a sign up code you can
                register by entering this code on the sign up page… it will
                connect you directly with your school and ask you to choose
                which classes you belong to.
              </Title>
              <Button
                onClick={() => {
                  navigate(ROUTES.SIGNUP);
                }}
                type="primary"
                style={{ background: "black" }}
              >
                sign up
              </Button>
            </Col>
          </Row>
        </div>
      </section>
      {/* <Row>
          <Col span={6} >
            <h4>
              MOVITANZ is a collaborative environment to help dancers get the
              most out of dance. It works best when everyone plays their part.
              Getting started with MOVITANZ depends a bit on which part you play!
            </h4>
          </Col>
          <Col span={6} >
            <h2>Already have a sign up code? </h2>
            <p>
              If your dance teacher has given you a sign up code you can
              register by entering this code on the sign up page… it will
              connect you directly with your school and ask you to choose which
              classes you belong to.
            </p>
            <Button type="primary">sign up</Button>
          </Col>
        </Row> */}

      <section className=" mt-3 mb-3">
        <div className="container">
          <Title
            className="heading text-center"
            level={2}
            type="primary"
            style={{ color: "#0eaaa5" }}
          >
            To find out how to get started, tell us which group best describes
            you …
          </Title>
          <Divider style={{ padding: 5, margin: 0 }} />
          <ul>
            {content?.started?.started?.map((i) => {
              return (
                <li
                  style={{
                    color: "#0eaaa5",
                    fontSize: 18,
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  {i?.heading}
                </li>
              );
            })}
          </ul>
          {/* <Button style={{ marginLeft: "500px" }}> Studio Owners</Button>
              <Button> Dance teacherx</Button>
              <Button> parent</Button>
              <Button> i run additional</Button>{" "}
              <Button> Syllabus providers</Button> */}
        </div>
      </section>
      <section
        style={{
          background: "#9e9e9e",
          height: 500,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {/* <Vimeo video="183079640" height={490} /> */}
        <img
          src={Img}
          height={490}
          style={{ objectFit: "contain" }}
          width="100%"
        />
      </section>
      {content?.started?.started?.map((item) => (
        <section className=" mt-3 mb-3" style={{ padding: 10 }}>
          <div className="container">
            <Title
              className="heading text-center"
              level={2}
              type="primary"
              style={{ color: "#0eaaa5" }}
            >
              {item?.heading}
            </Title>
            <Divider style={{ padding: 5, margin: 0 }} />
            <Title
              className="subtitle"
              style={{ color: "#6b6b6b", color: "#0eaaa5", fontWeight: 500 }}
              level={5}
            >
              {item?.subHeading}
            </Title>
            {item?.p1 && (
              <Title
                style={{ color: "#6b6b6b", fontWeight: "normal" }}
                level={5}
              >
                {item?.p1}
              </Title>
            )}
            {item?.p2 && (
              <Title
                style={{ color: "#6b6b6b", fontWeight: "normal" }}
                level={5}
              >
                {item?.p2}
              </Title>
            )}
            {item?.bold && (
              <Title
                style={{
                  color: "#6b6b6b",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
                level={5}
              >
                {item?.bold}
              </Title>
            )}
            {item?.p3 && (
              <Title
                style={{ color: "#6b6b6b", fontWeight: "normal" }}
                level={5}
              >
                {item?.p3}
              </Title>
            )}
            {item?.p4 && (
              <Title
                style={{ color: "#6b6b6b", fontWeight: "normal" }}
                level={5}
              >
                {item?.p4}
              </Title>
            )}

            {item?.p5 && (
              <Title
                style={{ color: "#6b6b6b", fontWeight: "normal" }}
                level={5}
              >
                {item?.p5}
              </Title>
            )}
            <a href="mailto:info@movitanz.com"> info@movitanz.com</a>
          </div>
        </section>
      ))}
      {/* 
      <section className=" mt-3 mb-3" style={{ padding: 10 }}>
        <div className="container">
          <Title
            className="heading text-center"
            level={2}
            type="primary"
            style={{ color: "#0eaaa5" }}
          >
            DANCE TEACHERS
          </Title>
          <Divider style={{ padding: 5, margin: 0 }} />
          <Title className="subtitle" style={{ color: "#6b6b6b", color : '#0eaaa5', fontWeight : 500 }} level={5}>
            GROW A REWARDING CAREER OF EXCELLENCE
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            We want to help you be that teacher that inspires a generation of
            young dancers. Engage and motivate your students with individual
            feedback and access to excellent dance-specific resources.
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            Create your own training and conditioning videos that enhance their
            lesson outcomes. Set goals that focus on success, and develop your
            dancers by sharing the dance world beyond the studio from your own
            resources.
          </Title>
          <Title
            style={{
              color: "#6b6b6b",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
            level={5}
          >
            How do you get started?
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            If the studio you are working for is already on Movitanz, then ask
            them for their signup code. Sign up to Movitanz with the code here:
            SIGN UP NOW.
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            After you have signed up, you will need to be given “staff”
            permissions within Movitanz by an administrator.
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            {" "}
            If the studio is not yet on Movitanz, you can sign up as a “TEACHER
            STUDIO”. You will be given your own access code, and your students
            can self subscribe to that for $4.99 per month.
          </Title>
          <a href="mailto:info@movitanz.com"> info@movitanz.com</a>
        </div>
      </section>

      <section className=" mt-3 mb-3" style={{ padding: 10 }}>
        <div className="container">
          <Title
            className="heading text-center"
            level={2}
            type="primary"
            style={{ color: "#0eaaa5" }}
          >
            DANCE STUDENTS
          </Title>
          <Divider style={{ padding: 5, margin: 0 }} />
          <Title className="subtitle" style={{ color: "#6b6b6b", color : '#0eaaa5', fontWeight : 500 }} level={5}>
            TAKE YOUR DANCE CLASS HOME
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            Dancers, take your dance class home. Be empowered, and access
            wonderful dance resources.
          </Title>

          <Title
            style={{
              color: "#6b6b6b",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
            level={5}
          >
            How do you get started?
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            If the studio you dance at is already on Movitanz, then ask them for
            their Movitanz signup code. SIGN UP HERE with your code.
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            If the studio is not yet on Movitanz, then get in touch with us. We
            can provide the information which will help them make a decision.
          </Title>
          <a href="mailto:info@movitanz.com"> info@movitanz.com</a>
        </div>
      </section>

      <section className=" mt-3 mb-3" style={{ padding: 10 }}>
        <div className="container">
          <Title
            className="heading text-center"
            level={2}
            type="primary"
            style={{ color: "#0eaaa5" }}
          >
            PARENTS
          </Title>
          <Divider style={{ padding: 5, margin: 0 }} />
          <Title className="subtitle" style={{ color: "#6b6b6b", color : '#0eaaa5', fontWeight : 500 }} level={5}>
            GET MORE INVOLVED
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            If your dance school is on Movitanz, then encourage your child to
            use it.
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            They will get better faster and also enjoy a greater sense of
            community within their classes – all within a controlled online
            environment that doesn’t cross over into traditional social
            networks.
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            Share in the learning process and help your child be the best they
            can be. Encourage your child to take control of their learning and
            empower their dancing.
          </Title>

          <Title
            style={{
              color: "#6b6b6b",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
            level={5}
          >
            How do you do it?
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            If the studio your child dances at is already on Movitanz, then ask
            them for their Movitanz signup code. Sign your child up to Movitanz
            with the code here: SIGN UP NOW
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            You will be given the opportunity to enter your own details as well,
            but we recommend you keep control of the username and password so
            you can monitor communication within the class and keep involved.
          </Title>
          <a href="mailto:info@movitanz.com"> info@movitanz.com</a>
        </div>
      </section>

      <section className=" mt-3 mb-3" style={{ padding: 10 }}>
        <div className="container">
          <Title
            className="heading text-center"
            level={2}
            type="primary"
            style={{ color: "#0eaaa5" }}
          >
SYLLABUS AND COURSE CREATORS          </Title>
          <Divider style={{ padding: 5, margin: 0 }} />
          <Title className="subtitle" style={{ color: "#6b6b6b", color : '#0eaaa5', fontWeight : 500 }} level={5}>
          Movitanz provides teachers the opportunity to deliver their knowledge & skill beyond the studio.           </Title>
        
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
          Create additional income by sharing your content as a coaching course or unique syllabus to enhance students' training.
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
          We will help you to reach and grow your target audience in a whole new way.Upload your content as a Vimeo or Youtube file in our Course Shop.Movitanz users anywhere in the world can then subscribe to your program.
          </Title>

    
                <a href="mailto:info@movitanz.com"> info@movitanz.com</a>
        </div>
      </section>

      <section className=" mt-3 mb-3" style={{ padding: 10 }}>
        <div className="container">
          <Title
            className="heading text-center"
            level={2}
            type="primary"
            style={{ color: "#0eaaa5" }}
          >
            AUDITIONS and COMPETITIONS
          </Title>
          <Divider style={{ padding: 5, margin: 0 }} />
          <Title className="subtitle" style={{ color: "#6b6b6b", color : '#0eaaa5', fontWeight : 500 }} level={5}>
            STREAMLINE YOUR AUDITION PROCESS
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            Movitanz is the perfect tool to allow applicants to submit video
            footage and images.
          </Title>
          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            You may find that you attract more applicants because of the number
            and enthusiasm of dancers already using Movitanz.
          </Title>
          <Title
            style={{
              color: "#6b6b6b",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
            level={5}
          >
            How do you get started?
          </Title>

          <Title style={{ color: "#6b6b6b", fontWeight: "normal" }} level={5}>
            We should talk. Get in touch and we can tell you how the process
            works.
          </Title>
          <a href="mailto:info@movitanz.com"> info@movitanz.com</a>
        </div>
      </section> */}
    </HeroPanel>
  );
};
export default GetStarted;
