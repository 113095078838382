import { Card, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import program from "../../../../services/program";
import { notify, SERVER_URL } from "../../../../utils";
import { Wrapper } from "../../Timeline/components/component";
import { returnFileType } from "../../../../utils/imageAccept";
import Loading from "../../../../components/_shared/Loader/Loading";
import useViewport from "../../../../hooks/useViewport";
import ReactPlayer from "react-player";

const ProgramDetails = () => {
  const query = useParams();
  const [programDetails, setProgramDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useViewport();


  useEffect(() => {
    fetchPurchasedProgram();
  }, []);

  const fetchPurchasedProgram = async () => {
    try {
      setIsLoading(true);
      let split =window.location.pathname.split("/")
      if (split[split?.length - 1] === "details") {
        const res = await program.getPurchasedProgramById(query?.id);
        if (res.status === 200) {
          setProgramDetails(res?.data?.program);
          setIsLoading(false);
        }
      } else {
        const res = await program.getProgramDetailsById(query?.id);
        if (res.status === 200) {
          setProgramDetails(res?.data?.program);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);

      notify("error");
    }
  };

  return (
    <div className="container  mt-5 bg-white p-5">
      <Loading isLoading={isLoading} />
      <div className="row">
        <div className=" col-md-12 mt-0 bg-secondary-gray p-3">
          <h5 className="primary-green my-auto">{programDetails?.title}</h5>
        </div>

        <div className="col-md-6 d-flex flex-column mt-4">
          <div className="col-md-12">
            <h5 className="primary-green my-auto">Description</h5>
          </div>
          <div className="col-md-12 mt-3">
            <span>{programDetails?.description}</span>
          </div>
        </div>
        <div className="col-md-6 mt-4">
          <img
            width={"100%"}
            src={`${programDetails?.img}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${process.env.PUBLIC_URL}user.png`;
            }}
            alt=""
          />
        </div>

        {programDetails?.program?.map((pro) => (
          <div className="container">
            <Divider />
            <Wrapper
              className="bg-primary-gray mx-auto shadow mt-5"
              key={pro?._id}
            >
              {" "}
              <Card
                title={
                  <div className="d-flex">
                    <div
                      className="mx-3 d-flex flex-column "
                      style={{ width: "100%", whiteSpace: "pre-wrap" }}
                    >
                      <h6 className="mb-0 font-white">{pro?.title}</h6>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
                bordered={false}
              >
                <div className="row">
                  <Carousel showArrows={true}>
                    {pro?.img?.map((pic, index) => (
                      <div className="mx-auto" style={{ maxHeight: "600px" }}>
                        <img
                          src={`${pic}`}
                        
                          alt=""
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                        )
                      </div>
                    ))}

                    <div className="mx-auto" style={{ maxHeight: "600px" }}>
                    <ReactPlayer width = "100%" height={ isMobile ? "100%" : 600} url={pro?.video} />

                    </div>

                    {/* {timeline?.youtubeUrl?.map((url) => (
                    <div className="mx-auto" style={{ height: "600px" }}>
                      <iframe
                        width="700"
                        height="500"
                        src={url}
                        allowfullscreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen"
                        msallowfullscreen="msallowfullscreen"
                        oallowfullscreen="oallowfullscreen"
                        webkitallowfullscreen="webkitallowfullscreen"
                      ></iframe>
                    </div>
                  ))} */}
                  </Carousel>
                </div>
                <div className="row">
                  <p className="font-light-gray">{pro?.description}</p>
                </div>
              </Card>
            </Wrapper>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramDetails;
