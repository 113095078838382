import { SERVER_URL } from "../utils";
import { apiDelete, apiGet, apiPatch, apiPost } from "../utils/axios";

const TIMELINE = SERVER_URL + "/timeline";

export default {
	getTimeline: (payload) => {
		if (payload?.member?.length > 0) {
			return apiGet(
				`${TIMELINE}?page=${payload.page}&limit=${payload.limit}&member=${
					payload?.member
				}&fav=${payload?.fav ? payload?.fav : false}&clear=${
					payload?.clear ? payload?.clear : false
				}&group=${payload?.group ? payload?.group : false}&notViewed=${
					payload?.notViewed ? payload?.notViewed : false
				}&notShared=${payload?.notShared ? payload?.notShared : false}&date=${
					payload?.date ? payload?.date : false
				}`,
				payload?.profileToken
			);
		} else if (payload?.search?.length > 0) {
			return apiGet(
				`${TIMELINE}?page=${payload.page}&limit=${payload.limit}&fav=${
					payload?.fav ? payload?.fav : false
				}&clear=${payload?.clear ? payload?.clear : false}&group=${
					payload?.group ? payload?.group : false
				}&notViewed=${
					payload?.notViewed ? payload?.notViewed : false
				}&notShared=${payload?.notShared ? payload?.notShared : false}&date=${
					payload?.date ? payload?.date : false
				}&search=${payload?.search ? payload?.search : false}&key=${
					payload?.key ? payload?.key : false
				}`,
				payload?.profileToken
			);
		} else {
			return apiGet(
				`${TIMELINE}?page=${payload.page}&limit=${payload.limit}&fav=${
					payload?.fav ? payload?.fav : false
				}&clear=${payload?.clear ? payload?.clear : false}&group=${
					payload?.group ? payload?.group : false
				}&notViewed=${
					payload?.notViewed ? payload?.notViewed : false
				}&notShared=${payload?.notShared ? payload?.notShared : false}&date=${
					payload?.date ? payload?.date : false
				}&search=${payload?.search ? payload?.search : false}&key=${
					payload?.key ? payload?.key : false
				}`,
				payload?.profileToken
			);
		}
	},
	createTimeline: (payload) => apiPost(TIMELINE, payload),
	updateTimeline: (id, payload) => apiPatch(`${TIMELINE}/${id}`, payload),
	getTimelineById: (id) => apiGet(`${TIMELINE}/${id}`),
	fetcTimelineById: (id) => apiGet(`${TIMELINE}/${id}/timeline`),

	deleteTimeline: (id) => apiDelete(`${TIMELINE}/${id}`),
	addComment: (tid, payload) => apiPost(`${TIMELINE}/${tid}/comment`, payload),
	getComment: (payload) =>
		apiGet(
			`${TIMELINE}/${payload.tid}/comment?page=${payload.page}&limit=${payload.limit}`,
			payload?.profileToken
		),
	updateComment: (tid, cid, payload) =>
		apiPatch(`${TIMELINE}/${tid}/comment/${cid}`, payload),
	deleteComment: (tid, cid) => apiDelete(`${TIMELINE}/${tid}/comment/${cid}`),
	replyToComment: (tid, cid, payload) =>
		apiPost(`${TIMELINE}/${tid}/comment/${cid}/reply`, payload),
	updateReply: (tid, cid, rid, payload) =>
		apiPatch(`${TIMELINE}/${tid}/comment/${cid}/reply/${rid}`, payload),
	deleteReply: (tid, cid, rid) =>
		apiDelete(`${TIMELINE}/${tid}/comment/${cid}/reply/${rid}`),
	watchTimeline: (tid) => apiPost(`${TIMELINE}/${tid}/view`),
	unWatchTimeline: (tid) => apiDelete(`${TIMELINE}/${tid}/view`),
	likeTimeline: (tid) => apiPost(`${TIMELINE}/${tid}/like`),
	unlikeTimeline: (tid) => apiDelete(`${TIMELINE}/${tid}/like`),
	shareGroup: (tid, gid) => apiPost(`${TIMELINE}/${tid}/share/group/${gid}`),
	unShareGroup: (tid, gid) =>
		apiDelete(`${TIMELINE}/${tid}/share/group/${gid}`),
	shareMember: (tid, mid) => apiPost(`${TIMELINE}/${tid}/share/member/${mid}`),
	unShareMember: (tid, mid) =>
		apiDelete(`${TIMELINE}/${tid}/share/member/${mid}`),
	favourteTimeline: (tid) => apiPost(`${TIMELINE}/${tid}/favourte`),
	unfavourteTimeline: (tid) => apiDelete(`${TIMELINE}/${tid}/favourte`),
	getMyTimeline: () => apiGet(`${TIMELINE}/my-timeline`),
	addToPortfolio: (tid, payload) =>
		apiPatch(`${TIMELINE}/${tid}/add-to-portfolio`, payload),
	uploadTimelineVimeo: (payload) =>
		apiPost(`${TIMELINE}/upload-vimeo1`, payload),
	deleteVimeo: (vid) => apiDelete(`${TIMELINE}/vimeo/${vid}`),
	sendShareEmail: (payload) => apiPost(`${TIMELINE}/share/send-email`, payload),
	sendEmailComment: (payload) =>
		apiPost(`${TIMELINE}/comment/send-email`, payload),
	sendEmailToGroup: (payload) =>
		apiPost(`${TIMELINE}/share/send-email-group`, payload),
	shareAllMembers: (payload) =>
		apiPost(`${TIMELINE}/share/all-member`, payload),
	shareAllGroup: (payload) => apiPost(`${TIMELINE}/share/all-group`, payload),
	getVideoLink: (payload) => apiPost(`${TIMELINE}/get-video-link`, payload),
	hideUnhide: (payload) => apiPost(`${TIMELINE}/hide`, payload),
	removeoldvideo: (payload) =>
		apiPost(`${TIMELINE}/remove/vimeo/video`, payload),
	postPinUnpin: (id, payload) =>
		apiPatch(`${TIMELINE}/pinunpin/${id}`, payload),
};
