import React, { useState } from "react";
import { useEffect } from "react";
import AdminLayout from "../../../components/AdminLayout";
import Loading from "../../../components/_shared/Loader/Loading";
import program from "../../../services/program";
import { notify, SERVER_URL } from "../../../utils";

const ReqProgram = () => {
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchMyPrograms()
  },[]);

  const submitReq = async (id, type) => {
    try {
      const res = await program.updateStatus(id, { type });
      if (res?.status === 200) {
        if (type === "reject") notify("success", "Program has been rejected.");
        else notify("success", "Program has been apporoved.");
        fetchMyPrograms();
      }
    } catch (err) {
      notify("error",JSON.stringify(err));
    }
  };

  const fetchMyPrograms = async () => {
    try {
      setIsLoading(true)
      const res = await program.fetchPendingProgram();

      if (res.status === 200) {
        setIsLoading(false)

        setPrograms(res?.data?.programs);
      }
    } catch (err) {
      setIsLoading(false)

      notify("error", "Something went wrong");
    }
  };

  return (
    <AdminLayout>
      <Loading isLoading = {isLoading}/>
      <div className="container bg-white mt-5 p-5 shadow">
        <div className="row h5">
          Request Program
        </div>
        {!programs?.length ?  
          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              <span className = "h6">No Recrod Found</span>
            </div>
          </div>
        : ""}
        {programs?.map((program, index) => (
          <div className="row p-4 bg-secondary-gray mt-3  border-20">
            <div className="col-md-12 d-flex justify-content-end">
              <span
                className={`${
                  program?.status === "unApproved"
                    ? "text-secondary"
                    : program?.status === "approved"
                    ? "text-success"
                    : program?.status === "pending"
                    ? "text-warning"
                    : "text-danger"
                }`}
              >
                {program?.status === "unApproved"
                  ? "Unapproved"
                  : program?.status === "approved"
                  ? "Approved"
                  : program?.status === "pending"
                  ? "Pending"
                  : "Reject"}{" "}
              </span>
            </div>
            <div className="col-md-1 my-auto">
              <img
                src={`${program?.img}`}
            
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${process.env.PUBLIC_URL}user.png`;
                }}
                width={"80px"}
                alt=""
              />
            </div>
            <div className="col-md-11 flex-column ">
              <div className="">
                <h5 className="cursor-pointer"> {program?.title}</h5>
              </div>
              <div>
                <span>{program?.description}</span>
              </div>
            </div>

            <div className="col-md-5 flex-column">
              {/* <div>
              <span>Shared with 305 Movitae users</span>
            </div> */}
            </div>

            <div className="col-md-12 mt-4">
              <button
                onClick={() => submitReq(program?.id, "reject")}
                className="btn btn-outline-danger"
              >
                Reject
              </button>

              <button
                onClick={() => submitReq(program?.id, "approved")}
                className="btn btn-success mx-3"
              >
                Approved{" "}
              </button>
            </div>
          </div>
        ))}
      </div>
    </AdminLayout>
  );
};

export default ReqProgram;
