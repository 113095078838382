import produce from "immer";
import actionTypes from "../actionTypes/auth";
const initialState = {
  isProceeding: false,
  user: null,
};

const authReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SIGNUP_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.SIGNUP_SUCCESS:
        draft.isProceeding = false;
        draft.user = action.user;
        break;
      case actionTypes.SIGNUP_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.LOGIN_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.LOGIN_SUCCESS:
        draft.isProceeding = false;
        draft.user = action.user;
        break;
      case actionTypes.LOGIN_FAILED:
        draft.isProceeding = false;
        break;

      case actionTypes.UPDATE_PROFILE_INIT:
          draft.isProceeding = true;
          break;
        case actionTypes.UPDATE_PROFILE_SUCCESS:
          draft.isProceeding = false;
          break;
        case actionTypes.UPDATE_PROFILE_FAILURE:
          draft.isProceeding = false;
          break;

      case actionTypes.DRAG_DROP_INIT:
        draft.isProceeding = true;
        break;
      case actionTypes.DRAG_DROP_SUCCESS:
        draft.isProceeding = false;
        break;
      case actionTypes.DRAG_DROP_FAILURE:
        draft.isProceeding = false;
        break;
     
      default:
        break;
    }
  });

export default authReducer;
