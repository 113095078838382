import {
  BackwardOutlined,
  CloseOutlined,
  DeleteOutlined,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Card, Tooltip } from "antd";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, InputField } from "../../../../components/_shared";
import { ViewProfileContext } from "../../../../context/viewProfile";
import portfolio from "../../../../services/portfolio";
import { notify, SERVER_URL } from "../../../../utils";
import Loading from "../../../../components/_shared/Loader/Loading";
import moment from "moment";
import { CreateFolderModal, FolderListModal } from ".";
import { Container } from "../component/component";

const FolderDetails = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [searchField, setSearchField] = useState("");
  const { profileToken } = useContext(ViewProfileContext);
  const [isLoading, setIsLoading] = useState(false);
  const [folder, setFolder] = useState("");

  const query = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getPortfolio();
  }, []);

  const getPortfolio = () => {
    setIsLoading(true);
    portfolio
      .getFolderDetails(query?.id, profileToken?.access?.token)
      .then((res) => {
        setPortfolios(res?.data?.portfolios);
        setFolder(res?.data?.folder);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // notify("error",JSON.stringify(err));
      });
  };

  const exitFolder = (fid) => {
    setIsLoading(true);
    portfolio
      .exitFolder(query?.id, fid)
      .then((res) => {
        if (res?.status === 200) {
          getPortfolio();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        notify("error",JSON.stringify(err));
        setIsLoading(false);
      });
  };

  const deleteFolder = () => {
    setIsLoading(true) 
    portfolio.deleteFolder(query?.id).then(res => {
      if(res?.status === 200) {
        navigate('/portfolio')
      }
    }).catch(err => {
      notify("error". err)
    })
  }

  return (
    <Container className="container bg-white mt-5 p-5 shadow">
      <div className="row">
        <Loading isLoading={isLoading} />

        <div className="col-md-12">
          <Tooltip title="back">
            <BackwardOutlined
              onClick={() => navigate("/portfolio")}
              className="h3 primary-gray cursor-pointer"
            />
          </Tooltip>
        </div>

        {!profileToken && (
          <div className="col-md-12 d-flex justify-content-end align-content-center" >
            <DeleteOutlined className="h4 text-danger cursor-pointer" onClick={ () =>deleteFolder()}/>
          </div>
        )}

        <div className="col-md-12">
          <h4>{folder?.folderName}</h4>
        </div>

        {portfolios
          ?.filter((portfolio) =>
            `${portfolio?.title}`
              .toLowerCase()
              .includes(searchField.toLowerCase())
          )
          ?.map((portfolio, index) => (
            <div className="row p-4 bg-secondary-gray mt-3  border-20">
              <div className="col-md-1">
                <img src={`${portfolio?.portfolioPic}`} width={"80px"} alt="" />
              </div>
              <div className="col-md-4 flex-column ">
                <div>
                  <h5
                    className="cursor-pointer"
                    onClick={() => {
                      if (!profileToken)
                        navigate(`/portfolio/${portfolio?.id}/share`);
                    }}
                  >
                    {" "}
                    {portfolio?.title}
                  </h5>
                </div>
                <div>
                  <span>Created: {moment(portfolio?.date).format("LL")}</span>
                </div>
                <div>
                  <span>Created By: {portfolio?.name}</span>
                </div>
              </div>
              <div className="col-md-5 flex-column">
                {/* <div>
              <span>Shared with 305 Movitae users</span>
            </div> */}
                {!profileToken && (
                  <div className="d-flex align-content-center mt-2">
                    <span
                      className="text-success h6 cursor-pointer"
                      onClick={() => {
                        exitFolder(portfolio?.id);
                      }}
                    >
                      Exit
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </Container>
  );
};

export default FolderDetails;
