import { SERVER_URL } from "../utils";
import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from "../utils/axios";

const PORTFOLIO = SERVER_URL + "/portfolio";
const MY_PORTOFLIO = SERVER_URL + "/portfolio/my";
const SHARE_WITH_ME = SERVER_URL + "/portfolio/shared-with-me";

export default {
    getPortfolio: (profileToken) => apiGet(MY_PORTOFLIO, profileToken),
    createPortfolio: (payload) => apiPost(PORTFOLIO, payload),
    updatePortfolio: (id, payload) => apiPatch(`${PORTFOLIO}/${id}`, payload),
    deleteMyPortfolio: (pid) => apiDelete(`${PORTFOLIO}/${pid}`),
    getShareMyPortfolio: (profileToken) => apiGet(SHARE_WITH_ME, profileToken),
    sharePortfolio: (pid, payload) => apiPost(`${PORTFOLIO}/${pid}/share`, payload),
    unSharePortfolio: (pid, payload) => apiPost(`${PORTFOLIO}/${pid}/un-share`, payload),
    sharePortfolioMember: (pid, payload) => apiPost(`${PORTFOLIO}/${pid}/share-member`, payload),
    unSharePortfolioMember: (pid, payload) => apiPost(`${PORTFOLIO}/${pid}/un-share-member`, payload),
    getPortfolioTimeline: (pid) => apiGet(`${PORTFOLIO}/${pid}/get-timeline`),
    uploadPortfoliPic: (pid, payload) => apiPost(`${PORTFOLIO}/${pid}/upload-pic`, payload),
    getPortfolioById: (pid) => apiGet(`${PORTFOLIO}/${pid}`),
    addComment: (pid, payload) => apiPost(`${PORTFOLIO}/${pid}/comment`, payload),
    deleteComment: (pid, cid) => apiDelete(`${PORTFOLIO}/${pid}/comment/${cid}`),
    createFolder: (payload) => apiPost(`${PORTFOLIO}/folder`, payload),
    getFolder: (profileToken) => apiGet(`${PORTFOLIO}/folder`, profileToken),
    moveToFolder: (pid, fid) => apiPost(`${PORTFOLIO}/${pid}/folder/${fid}/move-to`),
    getFolderDetails: (fid, profileToken) => apiGet(`${PORTFOLIO}/folder/${fid}/portfolio`, profileToken),
    exitFolder: (fid, pid) => apiDelete(`${PORTFOLIO}/${pid}/folder/${fid}/move-to`),
    deleteFolder: (fid) => apiDelete(`${PORTFOLIO}/folder/${fid}`),
    hideUnhide: (payload) => apiPost(`${PORTFOLIO}/hide`, payload),
};