import React, { useContext, useEffect, useState } from "react";
import { ViewProfileContext } from "../context/viewProfile";
import timelineAPi from "../services/timeline";

/**
 *
 * @returns return width ,height of current device and check mobile device status
 */
const useFetchcomment = (tid) => {
  const { profileToken } = useContext(ViewProfileContext);
  const [commentsss, setComments] = useState([]);
  const fetchComment = async () => {
    const data = await timelineAPi.getComment({
      tid,
      limit: 100,
      page: 1,
      profileToken: profileToken?.access?.token,
    });
    setComments(data?.data);
  };
  useEffect(() => {
    fetchComment();
  }, [tid]);

  return { count:commentsss?.comments?.results?.length };
};

export default useFetchcomment;
