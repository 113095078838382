import React, { useContext } from "react";
import { useEffect } from "react";
import moment from "moment";
import AdminLayout from "../../../components/AdminLayout";
import { UserContext } from "../../../context/userInfo";
import {
  byteConvert,
  isExpired,
  sotrageUsedPercent,
} from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { Button, Input, Progress } from "antd";
import { updateProfileInit } from "../../../store/actions/auth";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSelector } from "react-redux";
import { makeSelectAuthLoading } from "../../../store/selectors";
import Loading from "../../../components/_shared/Loader/Loading";
import { ViewProfileContext } from "../../../context/viewProfile";
import ModalWrapper from "../../../components/Modal";

const { TextArea } = Input;

const CurrentPlan = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const isLoading = useSelector(makeSelectAuthLoading());
  const { profileToken, viewUser } = useContext(ViewProfileContext);
  const [visible, setVisible] = useState(false);
  const [reason, setReason] = useState("");

  const navigate = useNavigate();

  if (!profileToken?.access?.token && userData?.role === "admin") {
    navigate("/manage-user");
  }

  const dispatch = useDispatch();
  useEffect(() => {
    getUserinfo();
  }, []);

  const onUpdateProfile = () => {
    dispatch(
      updateProfileInit({
        isAutoRenew: !userData?.isAutoRenew,
        reason: reason,
      })
    );
    setVisible(false);
    setTimeout(() => {
      getUserinfo();
      setVisible(false);
      setReason("");
    }, 1500);
  };

  return (
    <AdminLayout>
      <Loading isLoading={isLoading} />

      <ModalWrapper visible={visible} setVisible={setVisible}>
        <div className="container p-5">
          <div className="row">
            <div className="col-md-12">
              <h3>Reason </h3>
            </div>
            <div className="col-md-12">
              <TextArea
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                rows={4}
              />
              <Button
                onClick={onUpdateProfile}
                size={"large"}
                type="primary"
                className="rounded mt-3"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </ModalWrapper>
      {/* <div className="container bg-white p-5">
        <div className="row">
          <div className="col-md-12">
            <h4 style={{ fontWeight: 500 }}>Self Subscription Plan</h4>
            <p className="mt-2">
              Expire : {moment(userData?.studioMonthExp).format("LL")}
            </p>
          </div>
          <div className="col-md-12">
            Max Students Regsitration Limit : {userData?.studentEnrolledLimit}
          </div>
          <div className="col-md-12 d-flex justify-content-end">
            <button
              className="btn btn-success"
              onClick={() => navigate("/self-subscription")}
            >
              Renew
            </button>
          </div>
        </div>
      </div> */}

      <div className="container mt-3 bg-white p-5">
        <div className="row">
          {/* <div className="col-md-12 d-flex justify-content-end">
            <Button
              className="btn btn-success"
              type="primary"
              onClick={() => navigate("/upgrade-addons")}
            >
              Upgrade Storage
            </Button>
          </div> */}
          <div className="col-md-12 mt-2">
            <h4 style={{ fontWeight: 500 }}>Storage</h4>

            <div className="col-md-12">
              <Progress
                percent={
                  profileToken
                    ? sotrageUsedPercent(
                        viewUser?.storageLimit,
                        viewUser?.storageLimitRemaining
                      ).percent
                    : sotrageUsedPercent(
                        userData?.storageLimit,
                        userData?.storageLimitRemaining
                      ).percent
                }
                strokeColor={`${
                  profileToken
                    ? sotrageUsedPercent(
                        viewUser?.storageLimit,
                        viewUser?.storageLimitRemaining
                      ).color
                    : sotrageUsedPercent(
                        userData?.storageLimit,
                        userData?.storageLimitRemaining
                      ).color
                }`}
                showInfo={false}
              />

              <div className="row d-flex justify-content-end">
                {profileToken
                  ? sotrageUsedPercent(
                      viewUser?.storageLimit,
                      viewUser?.storageLimitRemaining
                    ).percent.toFixed(2)
                  : sotrageUsedPercent(
                      userData?.storageLimit,
                      userData?.storageLimitRemaining
                    ).percent.toFixed(2)}
                %
              </div>
            </div>
            <p className="mt-1 mb-0">
              Total Storage :{" "}
              {profileToken ? viewUser?.storageLimit : userData?.storageLimit}{" "}
              GB
            </p>
            <p className="mb-0">
              Used Storage :
              {profileToken
                ? (
                    viewUser?.storageLimit - viewUser?.storageLimitRemaining
                  ).toFixed(2)
                : (
                    userData?.storageLimit - userData?.storageLimitRemaining
                  ).toFixed(2)}
              GB
            </p>
            <p className="mb-0">
              Remaining Storage :{" "}
              {profileToken
                ? viewUser?.storageLimitRemaining?.toFixed(2)
                : userData?.storageLimitRemaining?.toFixed(2)}{" "}
              GB
            </p>
          </div>

          <div className="col-md-12">
            Max Students Regsitration Limit :{" "}
            {profileToken
              ? viewUser?.studentEnrolledLimit
              : userData?.studentEnrolledLimit}
          </div>

          <div className="col-md-12">
            Available Students Regsitration Limit :{" "}
            {profileToken
              ? Number(viewUser?.studentEnrolledLimit) -
                Number(viewUser?.totalStudent)
              : Number(userData?.studentEnrolledLimit) -
                Number(userData?.totalStudent)}
          </div>
          <div className="col-md-12">
            <p className="">
              Expire :{" "}
              {profileToken
                ? moment(viewUser?.studioMonthExp).format("LL")
                : moment(userData?.studioMonthExp).format("LL")}
            </p>
          </div>
          {!profileToken?.access?.token && (
            <div className="col-md-12 d-flex justify-content-end">
              <btn
                className={`btn ${
                  userData?.isAutoRenew ? "btn-danger" : "btn-warning"
                } btn-sm px-3`}
                type="primary"
                onClick={() => {
                  if (userData?.isAutoRenew) {
                    setVisible(true);
                  } else {
                    onUpdateProfile();
                  }
                  // onUpdateProfile
                }}
              >
                {userData?.isAutoRenew
                  ? "Cancel Auto Renewal"
                  : "Activate Auto Renewal"}
              </btn>
              <Button
                className="btn btn-success mx-3"
                type="primary"
                onClick={() => navigate("/upgrade-storage")}
              >
                Upgrade Membership
              </Button>
            </div>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default CurrentPlan;
