import React, { useState } from "react";
import { Col, Divider, Row, Tabs, Form, Upload, Button, Select } from "antd";
import ModalWrapper from "../../../../components/Modal";
import { InputField, TextAreaField } from "../../../../components/_shared";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useEffect } from "react";
import {
  fetchJoinedStudioInit,
  fetchStudioMembers,
} from "../../../../store/actions/studio";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  makeSelectMembers,
  makeSelectStudio,
} from "../../../../store/selectors";
import { joinedUser } from "../../../../utils/joinedUser";
import { notify, SERVER_URL } from "../../../../utils";
import timeline from "../../../../services/timeline";
import {
  shareGroupInit,
  shareMemberInit,
  unShareGroupInit,
  unShareMemberInit,
} from "../../../../store/actions/timeline";
import Loading from "../../../../components/_shared/Loader/Loading";
import useViewport from "../../../../hooks/useViewport";
const { TabPane } = Tabs;

const { Option } = Select;

const ShareTimeline = ({
  visible,
  setVisible,
  onSubmitTimeline,
  shareTimeline,
}) => {
  const [defaultActiveKey, setActivekey] = useState("1");
  const dispatch = useDispatch();
  const studios = useSelector(makeSelectStudio());
  const [singleStudio, setSingleStudio] = useState(0);
  const studioMembers = useSelector(makeSelectMembers());
  const [timelineData, setTimelineData] = useState({});
  const [searchField, setSearchField] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useViewport();
  // const [isUpdate, setIsUpdate] = useState(false);

  const [availableGroups, setAvailableGroups] = useState(
    studios[singleStudio]?.group
  );
  const [availableMembers, setAvailableMembers] = useState([]);

  const selectGroups = (gid, check, type) => {
    let data = [];
    if (type === "all") {
      data = availableGroups?.map((p) => ({ ...p, check: check }));
    } else {
      data = availableGroups?.map((p) =>
        p._id === gid ? { ...p, check: check } : p
      );
    }
    setAvailableGroups(data);
  };

  const selectMember = (memberId, check) => {
    let data = [];
    data = availableMembers?.map((p) =>
      p.id === memberId ? { ...p, check: check } : p
    );
    setAvailableMembers(data);
  };

  useEffect(() => {
    dispatch(fetchJoinedStudioInit());
    dispatch(fetchStudioMembers());
  }, []);
  useEffect(() => {
    let data = [];
    data = studios?.[singleStudio]?.group?.map((p) =>
      joinedUser(
        p?._id,
        timelineData?.sharedGroup ? timelineData?.sharedGroup : []
      )
        ? { ...p, check: true }
        : p
    );
    setAvailableGroups(data);
    let data2 = [];
    data2 = studioMembers?.map((p) =>
      joinedUser(
        p?.id,
        timelineData?.sharedMember ? timelineData?.sharedMember : []
      )
        ? { ...p, check: true }
        : p
    );
    setAvailableMembers(data2);
  }, [singleStudio, studios, studioMembers, timelineData]);

  useEffect(() => {
    if (shareTimeline?.id) fetchTimelineById(shareTimeline?.id);
  }, [shareTimeline]);

  // const shareGroup = (tid, gid) => {
  //   setIsUpdate(!isUpdate);
  //   dispatch(shareGroupInit({ tid, gid, fetchTimelineById }));
  //   let newTimeline = timelineData;
  //   newTimeline.sharedGroup.push(gid);
  //   setTimelineData(newTimeline);
  // };

  // const unShareGroup = (tid, gid) => {
  //   setIsUpdate(!isUpdate);

  //   dispatch(unShareGroupInit({ tid, gid, fetchTimelineById }));
  //   let newTimelineGroup = timelineData.sharedGroup.filter((id) => gid != id);
  //   let newTimeline = timelineData;
  //   newTimeline.sharedGroup = newTimelineGroup;
  //   setTimelineData(newTimeline);
  // };

  const sharedTimelineMember = (tid, mid) => {
    // setIsUpdate(!isUpdate);
    dispatch(shareMemberInit({ tid, mid, fetchTimelineById: () => {} }));
    // let newTimeline = timelineData;
    // newTimeline.sharedMember.push(mid);
    // setTimelineData(newTimeline);
  };

  const unShareTimelineMember = (tid, mid) => {
    // setIsUpdate(!isUpdate);

    dispatch(unShareMemberInit({ tid, mid, fetchTimelineById: () => {} }));
    // let newTimelineMember = timelineData.sharedMember.filter((id) => mid != id);

    // let newTimeline = timelineData;
    // newTimeline.sharedMember = newTimelineMember;
    // setTimelineData(newTimeline);
  };

  const fetchTimelineById = (id) => {
    timeline
      .getTimelineById(id)
      .then((res) => {
        setIsLoading(false);
        setTimelineData(res?.data?.timeline);
      })
      .catch((err) => {});
  };

  const shareTimelineEmail = (name, email) => {
    timeline
      .sendShareEmail({
        title: shareTimeline?.title,
        email,
        name,
        tid: shareTimeline?.id,
      })
      .then((res) => {})
      .catch((err) => {});
  };

  const shareTimelineEmailToGroup = (gid) => {
    timeline
      .sendEmailToGroup({
        title: shareTimeline?.title,
        gid: gid?.length > 0 ? gid : [],
        tid: shareTimeline?.id,
      })
      .then((res) => {})
      .catch((err) => {});
  };

  // const shareAllMember = (type) => {
  //   timeline
  //     .shareAllMembers({
  //       type,
  //       signupCode: studios[singleStudio]?.signupCode,
  //       title: shareTimeline?.title,
  //       tid: shareTimeline?.id,
  //     })
  //     .then((res) => {})
  //     .catch((err) => {});
  // };

  // const shareAllGroup = (type) => {
  //   setIsLoading(true);
  //   timeline
  //     .shareAllGroup({
  //       type,
  //       signupCode: studios[singleStudio]?.signupCode,
  //       tid: shareTimeline?.id,
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         fetchTimelineById(shareTimeline?.id);

  //         if (type === "all") {
  //           res.data?.groupId.forEach((id) => {
  //             shareTimelineEmailToGroup(id);
  //           });
  //         }
  //         setTimeout(() => {
  //           setIsLoading(false);
  //         }, [1000]);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);

  //       notify("error", err);
  //     });
  // };

  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <Loading isLoading={isLoading} />
      <div className="container p-5">
        <Form layout="vertical" onFinish={onSubmitTimeline}>
          <div className="row">
            <div className="col-md-12">
              <h3>{shareTimeline?.title}</h3>
            </div>

            <div className="col-md-12">
              <InputField
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                placeholder="Search"
                suffix={<SearchOutlined />}
                style={{ width: `${isMobile ? "" : "350px"}` }}
              />
            </div>
            <div className="col-md-12">
              <Tabs
                activeKey={String(defaultActiveKey)}
                onChange={(e) => {
                  setActivekey(e);
                }}
                // defaultActiveKey={defaultActiveKey}
                type="line"
                tabBarGutter={0}
              >
                <TabPane
                  className={defaultActiveKey == 1 ? "mb-4" : ""}
                  tab={
                    <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                      Share Group
                    </div>
                  }
                  key="1"
                />

                <TabPane
                  className={defaultActiveKey == 2 ? "mb-4" : ""}
                  tab={
                    <div className={isMobile ? "tab_mobile" : "tab_tabName"}>
                      Share Member
                    </div>
                  }
                  key="2"
                />
              </Tabs>
            </div>
            <>
              {defaultActiveKey == 1 && (
                <div className="row  ">
                  <div className="col-md-12">
                    <Select
                      showSearch
                      defaultValue={studios?.[0]?.studioName}
                      style={{ width: "100%" }}
                      // placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={(e) => {
                        setSingleStudio(e);
                      }}
                    >
                      {studios.map((stu, index) => (
                        <Option value={index}>{stu?.studioName}</Option>
                      ))}
                    </Select>
                  </div>

                  <div className="col-md-12">
                    <Divider />
                  </div>

                  <div className="col-md-6 mt-2 d-flex align-content-center">
                    <Checkbox
                      onChange={(e) => {
                        selectGroups("", e?.target?.checked, "all");
                        // shareAllGroup(e?.target?.checked ? "all" : "");
                        // if (
                        //   joinedUser(
                        //     group?._id,
                        //     timelineData?.sharedGroup
                        //       ? timelineData?.sharedGroup
                        //       : []
                        //   )
                        // ) {
                        //   unShareGroup(shareTimeline?.id, group?._id);
                        // } else {
                        //   shareTimelineEmailToGroup(group?._id);
                        //   shareGroup(shareTimeline?.id, group?._id);
                        // }
                      }}
                      // checked={joinedUser(
                      //   studios[singleStudio]?.group.length === timeline
                      // )}
                      className="mx-1  mt-3"
                      //  checked={joinedUser(member?.id, group?.members)}
                    />

                    {/* <span className="h5 mt-3"> {member?.name}</span> */}
                    <span className="h6 mt-3 mx-3">Select All</span>
                  </div>

                  <div
                    className="col-md-6 mt-2 d-flex align-content-center"
                    style={{
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      htmlType="submit"
                      size={"large"}
                      type="primary"
                      className="rounded "
                      // style = {{heigth : "1000px"}}
                    >
                      <h5
                        className="font-white"
                        onClick={() => {
                          const sharableGroup = availableGroups?.filter(
                            (i) => i?.check === true
                          );
                          const unsharableGroup = availableGroups?.filter(
                            (i) => i?.check !== true
                          );
                          const ids = sharableGroup?.map((i) => i?._id);
                          shareTimelineEmailToGroup(ids);
                          sharableGroup?.map((i) => {
                            dispatch(
                              shareGroupInit({
                                tid: shareTimeline?.id,
                                gid: i?._id,
                                fetchTimelineById: () => {},
                              })
                            );
                          });

                          unsharableGroup?.map((i) => {
                            dispatch(
                              unShareGroupInit({
                                tid: shareTimeline?.id,
                                gid: i?._id,
                                fetchTimelineById: () => {},
                              })
                            );
                          });
                          setVisible(false);
                          // dispatch(
                          //   unShareGroupInit({ tid, gid, fetchTimelineById })
                          // );
                          // if (
                          //   !timelineData?.sharedGroup?.length &&
                          //   defaultActiveKey == 1
                          // ) {
                          //   shareAllMember("email");
                          //   shareAllMember("");

                          //   // };
                          // }
                          // setVisible(false);
                        }}
                      >
                        Share
                      </h5>
                    </Button>
                  </div>
                  {availableGroups
                    ?.filter((group) =>
                      `${group?.groupName}`
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                    )
                    .map((group) => (
                      <div className="col-md-12 mt-2 d-flex align-content-center">
                        <Checkbox
                          onChange={(e) => {
                            selectGroups(group?._id, e.target.checked);
                            // if (
                            //   joinedUser(
                            //     group?._id,
                            //     timelineData?.sharedGroup
                            //       ? timelineData?.sharedGroup
                            //       : []
                            //   )
                            // ) {
                            //   unShareGroup(shareTimeline?.id, group?._id);
                            // } else {
                            //   shareTimelineEmailToGroup(group?._id);
                            //   shareGroup(shareTimeline?.id, group?._id);
                            // }
                          }}
                          checked={group?.check}
                          className="mx-1  mt-3"
                          // checked={joinedUser(
                          //   group?._id,
                          //   timelineData?.sharedGroup
                          //     ? timelineData?.sharedGroup
                          //     : []
                          // )}
                        />
                        <img
                          width={50}
                          src={`https://movitanz.com/upload/static/group.jpeg`}
                          className="mx-4 "
                        />
                        {/* <span className="h5 mt-3"> {member?.name}</span> */}
                        <span className="h5 mt-3">{group?.groupName}</span>
                      </div>
                    ))}
                  <div className="col-md-12">
                    <Divider />
                  </div>
                </div>
              )}

              {defaultActiveKey == 2 && (
                <div className="row  ">
                  <div
                    className="col-md-12 mt-2 d-flex align-content-center"
                    style={{
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      htmlType="submit"
                      size={"large"}
                      type="primary"
                      className="rounded "
                      // style = {{heigth : "1000px"}}
                    >
                      <h5
                        className="font-white"
                        onClick={() => {
                          // unShareTimelineMember(shareTimeline?.id, member?.id);
                          // sharedTimelineMember(shareTimeline?.id, member?.id);
                          // shareTimelineEmail(member?.name, member?.email);

                          availableMembers?.filter((i) => i?.check === true);
                          const sharableMember = availableMembers?.filter(
                            (i) => i?.check === true
                          );
                          const unsharableMember = availableMembers?.filter(
                            (i) => i?.check !== true
                          );
                          sharableMember?.map((i) => {
                            sharedTimelineMember(shareTimeline?.id, i?.id);
                            shareTimelineEmail(i?.name, i?.email);
                          });
                          unsharableMember?.map((i) => {
                            unShareTimelineMember(shareTimeline?.id, i?.id);
                          });
                          setVisible(false);

                          // if (
                          //   !timelineData?.sharedGroup?.length &&
                          //   defaultActiveKey == 1
                          // ) {
                          //   shareAllMember("email");
                          //   shareAllMember("");
                          // }
                        }}
                      >
                        {" "}
                        Share
                      </h5>
                    </Button>
                  </div>
                  {defaultActiveKey == "2" &&
                    availableMembers
                      ?.filter((member) =>
                        `${member?.name}`
                          .toLowerCase()
                          .includes(searchField.toLowerCase())
                      )
                      ?.map((member) => (
                        <div className="row " key={member?.id}>
                          <div className="col-md-12 d-flex align-content-center">
                            <Checkbox
                              className="mx-1  mt-3"
                              onChange={(e) => {
                                selectMember(member?.id, e.target.checked);
                                // if (
                                //   joinedUser(
                                //     member?.id,
                                //     timelineData?.sharedMember
                                //       ? timelineData?.sharedMember
                                //       : []
                                //   )
                                // ) {
                                //   unShareTimelineMember(
                                //     shareTimeline?.id,
                                //     member?.id
                                //   );
                                // } else {
                                //   sharedTimelineMember(
                                //     shareTimeline?.id,
                                //     member?.id
                                //   );
                                //   shareTimelineEmail(
                                //     member?.name,
                                //     member?.email
                                //   );
                                // }
                              }}
                              checked={member?.check}
                              // checked={joinedUser(
                              //   member?.id,
                              //   timelineData?.sharedMember
                              //     ? timelineData?.sharedMember
                              //     : []
                              // )}
                            />
                            <img
                              width={60}
                              src={`${member?.profilepic}`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.PUBLIC_URL}user.png`;
                              }}
                              className="mx-4 "
                            />
                            <span className="h5 mt-3"> {member?.name}</span>
                          </div>
                          <div className="col-md-12">
                            <Divider />
                          </div>
                        </div>
                      ))}
                </div>
              )}
            </>
          </div>
        </Form>
      </div>
    </ModalWrapper>
  );
};

export default ShareTimeline;
