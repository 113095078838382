import React, { useState } from "react";
import { Col, Divider, Row, Tabs, Form, Upload, Button, Select } from "antd";
import ModalWrapper from "../../../../components/Modal";
import { InputField, TextAreaField } from "../../../../components/_shared";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useEffect } from "react";
import {
  fetchJoinedStudioInit,
  fetchStudioMembers,
} from "../../../../store/actions/studio";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  makeSelectMembers,
  makeSelectStudio,
} from "../../../../store/selectors";
import { joinedUser } from "../../../../utils/joinedUser";

import portfolioApi from "../../../../services/portfolio";
import { notify } from "../../../../utils";
import useViewport from "../../../../hooks/useViewport";

const { TabPane } = Tabs;

const { Option } = Select;

const SharePortfolioModel = ({
  visible,
  setVisible,
  portfolio,
  fetchMyPortoflio,
}) => {
  const [defaultActiveKey, setActivekey] = useState("1");
  const dispatch = useDispatch();
  const studios = useSelector(makeSelectStudio());
  const [singleStudio, setSingleStudio] = useState(0);
  const studioMembers = useSelector(makeSelectMembers());
  const { isMobile } = useViewport();
  const [searchField, setSearchField] = useState("");

  const [availableMembers, setAvailableMembers] = useState([]);

  const [availableGroups, setAvailableGroups] = useState(
    studios[singleStudio]?.group
  );

  const selectGroups = (gid, check, type) => {
    let data = [];

    data = availableGroups?.map((p) =>
      p._id === gid ? { ...p, check: check } : p
    );

    setAvailableGroups(data);
  };

  const selectMember = (memberId, check) => {
    let data = [];
    data = availableMembers?.map((p) =>
      p.id === memberId ? { ...p, check: check } : p
    );
    setAvailableMembers(data);
  };

  useEffect(() => {
    dispatch(fetchJoinedStudioInit());
    dispatch(fetchStudioMembers());
  }, []);

  useEffect(() => {
    let data = [];
    data = studios?.[singleStudio]?.group?.map((p) =>
      joinedUser(p?._id, portfolio?.share ? portfolio?.share : [])
        ? { ...p, check: true }
        : p
    );
    setAvailableGroups(data);
    let data2 = [];
    data2 = studioMembers?.map((p) =>
      joinedUser(p?.id, portfolio?.shareMember ? portfolio?.shareMember : [])
        ? { ...p, check: true }
        : p
    );
    setAvailableMembers(data2);
  }, [singleStudio, studios, studioMembers, portfolio]);

  const sharePortfolio = (gid) => {
    portfolioApi
      .sharePortfolio(portfolio?.id, { gid })
      .then((res) => {
        if (res.status === 200) {
          // fetchMyPortoflio();
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  const unSharePortfolio = (gid) => {
    portfolioApi
      .unSharePortfolio(portfolio?.id, { gid })
      .then((res) => {
        if (res.status === 200) {
          // fetchMyPortoflio();
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  const sharePortfolioMember = (mid) => {
    portfolioApi
      .sharePortfolioMember(portfolio?.id, { mid })
      .then((res) => {
        if (res.status === 200) {
          // fetchMyPortoflio();
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  const unSharePortfolioMember = (mid) => {
    portfolioApi
      .unSharePortfolioMember(portfolio?.id, { mid })
      .then((res) => {
        if (res.status === 200) {
          // fetchMyPortoflio();
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  return (
    <ModalWrapper visible={visible} setVisible={setVisible}>
      <div className="container p-5">
        <Form layout="vertical">
          <div className="row">
            <div className="col-md-12">
              <h3>{portfolio?.title}</h3>
            </div>

            <div className="col-md-12">
              <InputField
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                placeholder="Search"
                suffix={<SearchOutlined />}
                style={{ width: `${isMobile ? "" : "350px"}` }}
              />
            </div>
            <div className="col-md-12">
              <Tabs
                activeKey={String(defaultActiveKey)}
                onChange={(e) => {
                  setActivekey(e);
                }}
                // defaultActiveKey={defaultActiveKey}
                type="line"
                tabBarGutter={0}
              >
                <TabPane
                  className={defaultActiveKey == 1 ? "mb-4" : ""}
                  tab={<div className="tab_tabName">Share Group</div>}
                  key="1"
                />

                <TabPane
                  className={defaultActiveKey == 2 ? "mb-4" : ""}
                  tab={<div className={"tab_tabName"}>Share Member</div>}
                  key="2"
                />
              </Tabs>
            </div>
            <div
              className="center-column"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
            >
              <Button
                htmlType="submit"
                size={"large"}
                type="primary"
                className="rounded "
                // style = {{heigth : "200px"}}
              >
                <h5
                  className="font-white"
                  onClick={() => {
                    if (defaultActiveKey == 1) {
                      const sharableGroup = availableGroups?.filter(
                        (i) => i?.check === true
                      );
                      const unsharableGroup = availableGroups?.filter(
                        (i) => i?.check !== true
                      );

                      sharableGroup?.map((i) => {
                        sharePortfolio(i?._id);
                      });

                      unsharableGroup?.map((i) => {
                        unSharePortfolio(i?._id);
                      });
                      setVisible(false);
                    } else {
                      availableMembers?.filter((i) => i?.check === true);
                      const sharableMember = availableMembers?.filter(
                        (i) => i?.check === true
                      );
                      const unsharableMember = availableMembers?.filter(
                        (i) => i?.check !== true
                      );
                      sharableMember?.map((i) => {
                        sharePortfolioMember(i?.id);
                      });
                      unsharableMember?.map((i) => {
                        unSharePortfolioMember(i?.id);
                      });
                      setVisible(false);
                    }

                    setTimeout(() => {
                      fetchMyPortoflio();
                    }, 2500);
                  }}
                >
                  {" "}
                  Share
                </h5>
              </Button>
            </div>
            <>
              {defaultActiveKey == 1 && (
                <div className="row  ">
                  <div className="col-md-12">
                    <Select
                      showSearch
                      defaultValue={studios?.[0]?.studioName}
                      style={{ width: "100%" }}
                      // placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={(e) => {
                        setSingleStudio(e);
                      }}
                    >
                      {studios.map((stu, index) => (
                        <Option value={index}>{stu?.studioName}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-md-12">
                    <Divider />
                  </div>
                  {availableGroups
                    ?.filter((group) =>
                      `${group?.groupName}`
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                    )
                    ?.map((group) => (
                      <div className="col-md-12 mt-2 d-flex align-content-center">
                        <Checkbox
                          onChange={(e) => {
                            selectGroups(group?._id, e.target.checked);
                            // if (
                            //   joinedUser(
                            //     group?._id,
                            //     portfolio?.share ? portfolio?.share : []
                            //   )
                            // ) {
                            //   unSharePortfolio(group?._id);
                            // } else {
                            //   sharePortfolio(group?._id);
                            // }
                          }}
                          // checked={joinedUser(
                          //   group?._id,
                          //   portfolio?.share ? portfolio?.share : []
                          // )}
                          checked={group?.check}
                          className="mx-1  mt-3"
                        />
                        <img
                          width={50}
                          src={`${process.env.PUBLIC_URL}group.png`}
                          className="mx-4 "
                        />
                        {/* <span className="h5 mt-3"> {member?.name}</span> */}
                        <span className="h5 mt-3">{group?.groupName}</span>
                      </div>
                    ))}
                  <div className="col-md-12">
                    <Divider />
                  </div>
                </div>
              )}
              <div className="row  ">
                {defaultActiveKey == "2" &&
                  availableMembers
                    ?.filter((member) =>
                      `${member?.name}`
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                    )
                    ?.map((member) => (
                      <div className="row " key={member?.id}>
                        <div className="col-md-12 d-flex align-content-center">
                          <Checkbox
                            className="mx-1  mt-3"
                            onChange={(e) => {
                              selectMember(member?.id, e.target.checked);
                              // if (
                              //   joinedUser(
                              //     member?.id,
                              //     portfolio?.shareMember
                              //       ? portfolio?.shareMember
                              //       : []
                              //   )
                              // ) {
                              //   unSharePortfolioMember(member?.id);
                              // } else {
                              //   sharePortfolioMember(member?.id);
                              // }
                            }}
                            checked={member?.check}
                            // checked={joinedUser(
                            //   member?.id,
                            //   portfolio?.shareMember
                            //     ? portfolio?.shareMember
                            //     : []
                            // )}
                          />
                          <img
                            width={60}
                            src={`${member?.profilepic}`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${process.env.PUBLIC_URL}user.png`;
                            }}
                            className="mx-4 "
                          />
                          <span className="h5 mt-3"> {member?.name}</span>
                        </div>
                        <div className="col-md-12">
                          <Divider />
                        </div>
                      </div>
                    ))}
              </div>
            </>
          </div>
        </Form>
      </div>
    </ModalWrapper>
  );
};

export default SharePortfolioModel;
