import styled from "styled-components";

export const PaymentContainer = styled.div`

 input {
  border-radius: 20px;
  padding: 10px 20px;
  color: black;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
 
  background: #dee3f3 !important;
border : none;
width : 100% !important;

&:focus {
  border : none
}
 }
`