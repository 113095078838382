import React, { useEffect } from "react";
import { Form, Row, Col, Button } from "antd";
import { DeleteOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { InputField, TextAreaField } from "../../../../components/_shared";

const Why = ({ onUpdate, why }) => {
  const [form] = Form.useForm();
  const updateContent = (e) => {

    onUpdate({
      why: e,
    });
  };

  useEffect(() => {
    if (why) form.setFieldsValue(why);
  }, [why]);

  return (
    <div className="col-md-12">
      <Form form={form} layout="vertical" onFinish={updateContent}>
        <Row gutter={16}>
        <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
            <Form.Item
              label="Heading 1"
              style={{ marginBottom: "15px" }}
              name="heading1"
            >
              <InputField />
            </Form.Item>
          </Col>

             <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
            <Form.Item
              label="Heading 2 "
              style={{ marginBottom: "15px" }}
              name="heading2"
            >
              <InputField />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
            <Form.Item
              label="Subheading "
              style={{ marginBottom: "15px" }}
              name="subheading"
            >
              <InputField />
            </Form.Item>
          </Col>
          <div className="col-md-12 mt-2">
            <h4>Cards</h4>
          </div>
          {/* <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
            <Form.Item
              label="Title "
              style={{ marginBottom: "15px" }}
              name="cardTitle"
            >
              <InputField />
            </Form.Item>
          </Col> */}
          <Form.List name="started" className="mb-0">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "heading"]}
                        fieldKey={[fieldKey, "heading"]}
                        label={`heading`}
                      >
                        <InputField placeholder="" />
                      </Form.Item>
               
                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>


                    <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
                      <Form.Item
                        style={{ marginBottom: "15px" }}
                        {...restField}
                        name={[name, "content"]}
                        fieldKey={[fieldKey, "content"]}
                        label={`content`}
                      >
                        <InputField placeholder="" />
                      </Form.Item>
                      <div
                        className="float-right "
                        style={{ fontSize: "18px", marginTop: "-10px" }}
                      >
                        <DeleteOutlined
                          className="cursor-pointer"
                          onClick={() => remove(name)}
                        />
                      </div>
                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </Col>



           
                  </>
                ))}
                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  xl={20}
                  xxl={20}
                  span={20}
                  className=""
                >
                  <Form.Item>
                    <div className="">
                      <div
                        className=" cursor-pointer d-flex align-content-center"
                        onClick={() => add()}
                      >
                        <PlusCircleOutlined className="mt-1 mx-1" />
                        <span>Add Cards</span>
                      </div>
                    </div>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>


        </Row>

        <div
          className="center-column"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            htmlType="submit"
            size={"large"}
            type="primary"
            className="rounded "
            // style = {{heigth : "200px"}}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Why;
