import { Select, Form, Upload } from "antd";
import Axios from "axios";
import React, { useContext } from "react";
import { useState, createRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../components/_shared";
import {
  GrayInputField,

} from "../../../../components/_shared/Input";
import Loading from "../../../../components/_shared/Loader/Loading";
import { UserContext } from "../../../../context/userInfo";
import { updateProfileInit } from "../../../../store/actions/auth";
import {
  makeSelectAuthLoading,
} from "../../../../store/selectors";
import { countryList, notify } from "../../../../utils";
import { MEDIA_URL } from "../../../../utils/server";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { CropDiv } from "./component";
const fs = require('fs');
const { Option } = Select;
const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const UserProfile = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector(makeSelectAuthLoading());
  const [profileLoading, setProfileLoading] = useState(false);
  const [pickImage, setPickImage] = useState(false);

  const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState("#");
  const cropperRef = createRef(null);

  const onChange = (e) => {
    setPickImage(true);
    // e.preventDefault();
    // setProfileLoading(true);
    let files = e.file;
    // if (e.dataTransfer) {
    //   files = e.dataTransfer.files;
    // } else if (e.target) {
    //   files = e.target.files;
    // }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    // setImage(files);
    reader.readAsDataURL(files);
  };

  const saveImage = async () => {
    const imgSrc = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
    setProfileLoading(true);
    const blob = await fetch(imgSrc).then((res) => res.blob());
    let formData = new FormData();
    formData.append("avatar", blob);
    Axios.post(MEDIA_URL, formData)
      .then((res) => {
        if (res?.status === 200) {
          setPickImage(false);
          setProfileLoading(false);
          uploadProfile(res?.data?.message);
        }
      })
      .catch((err) => {
        setPickImage(false);
        setProfileLoading(false);
        notify("error", "Something went wrong");
      });
  };

  const cancelImage = async () => {
    setPickImage(false);
  };

  const uploadProfile = (url) => {
    dispatch(
      updateProfileInit({
        profilepic: url,
      })
    );

    setTimeout(() => {
      getUserinfo();
    }, 500);
  };

  const onUpdateProfile = (e) => {
    dispatch(
      updateProfileInit({
        // currentLocation: e?.clocation,
        country: e?.country,
        // dob: moment (e?.dob),
        // parentsEmail: e?.pemail,
        name: e?.name,
        // parentsName: e?.pname,
        // description: e?.description,
        email: e?.email
      })
    );

    setTimeout(() => {
      getUserinfo();
    }, 1500);
  };

  useEffect(() => {
    getUserinfo();
  }, []);

  useEffect(() => {
    if (userData?.id) {
      form.setFieldsValue({
        // clocation: userData?.currentLocation,
        country: userData?.country,
        // dob:moment(userData?.dob),
        // pemail: userData?.parentsEmail,
        email: userData?.email,
        name: userData?.name,
        // pname: userData?.parentsName,
        // description: userData?.description,
      });
    }
  }, [userData]);
  return (
    <div className="container bg-white p-5 mt-2">
      <Loading isLoading={profileLoading} />
      <CropDiv>
        {pickImage ?
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center">
              <Cropper
                ref={cropperRef}
                style={{ width: '25%', objectFit: 'cover' }}
                initialAspectRatio={1}
                aspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
              />
            </div>
            {/* <div className="col-md-4">
            <div className="box" style={{ width: "70%", float: "right" }}>
              <h1>Preview</h1>
              <div
                className="img-preview"
                style={{ width: "150px", float: "left", height: "150px" }}
              />
            </div>

          </div> */}
            <br style={{ clear: "both" }} />
            <div className=" col-md-12 mt-4 ">
              <div className="d-flex justify-content-center">
                <Button
                  lite
                  size={"large"}
                  title="Save Image"
                  onClick={saveImage}
                />
                <Button
                  lite
                  size={"large"}
                  title="Cancel"
                  onClick={cancelImage}
                />
              </div>
            </div>
          </div>
          : <div className="col-md-12 d-flex justify-content-center">
            <Upload
              beforeUpload={() => false}
              onChange={onChange}
              multiple={false}
              showUploadList={false}
            >
              <img
                src={`${userData?.profilepic}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${process.env.PUBLIC_URL}user.png`;
                }}
                style={{ objectFit: "cover" }}
                width={150}
                className="rounded cursor-pointer"
                alt=""
              />
            </Upload>
          </div>}

      </CropDiv>
      <Form
        layout="vertical"
        // className="mt-4"
        className="row mt-4"
        // style={{ width: "100%" }}
        form={form}
        onFinish={onUpdateProfile}
      >

        <div
          className={`  ${userData?.role === "student" ? "col-md-6" : "col-md-6"
            }`}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter your name.",
              },
            ]}
          >
            <GrayInputField className="font-600" placeholder="Name" />
          </Form.Item>
        </div>
        <div className="col-md-6">
          <Form.Item name="email" label="Email">
            <GrayInputField
              placeholder="Email"
              className="font-600"
            />
          </Form.Item>
        </div>

        {/* <div
          className={`  ${
            userData?.role === "student" ? "col-md-4" : "col-md-4"
          }`}
        >
          <Form.Item
            name="dob"
            label="DOB"
            rules={[
              {
                required: true,
                message: "Please enter your DOB.",
              },
            ]}
          >
            <GrayDatePicker
              // defaultValue={moment(userData?.dob)}
              format="DD/MM/YYYY"
              type="date"
            />
          </Form.Item>
        </div> */}
        {/* {userData?.role === "student" && (
          <>
            <div className="col-md-4">
              <Form.Item name="pname" label="Parents Name">
                <GrayInputField
                  className="font-600"
                  placeholder="Parents Name"
                />
              </Form.Item>
            </div>


            <div className="col-md-4">
              <Form.Item name="pemail" label="Parents Email">
                <GrayInputField
                  placeholder="Parents Email"
                  className="font-600"
                />
              </Form.Item>
            </div>
          </>
        )} */}
        {/* <div
          className={`  ${
            userData?.role === "student" ? "col-md-4" : "col-md-4"
          }`}
        >
          <Form.Item name="clocation" label="Current Location">
            <GrayInputField
              placeholder="Current Location"
              className="font-600"
            />
          </Form.Item>
        </div> */}

        <div
          className={`  ${userData?.role === "student" ? "col-md-6" : "col-md-6"
            }`}
        >
          <Form.Item name="country" label="Country">
            <Select
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
            >
              {countryList?.map((list) => (
                <Option value={list?.name}>{list?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        {/* <div className="col-md-12">
          <Form.Item name="description" label="Description">
            <GrayTextAreaField placeholder="Description" />
          </Form.Item>
        </div> */}

        <div className=" col-md-12 mt-4 ">
          <div className="d-flex justify-content-center">
            <Button
              htmlType="submit"
              lite
              size={"large"}
              // style={{ marginLeft: isMobile ? 0 : 20 }}
              title="Save"
              className=""
            />
          </div>
        </div>
      </Form>
    </div>
  );
  return (
    <div className="container bg-white p-5 mt-4">
      <Loading isLoading={isLoading || profileLoading} />
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">

          {/* <Upload
            beforeUpload={() => false}
            onChange={onUpload}
            multiple={false}
            showUploadList={false}
          >
            <img
              src={`${userData?.profilepic}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.PUBLIC_URL}user.png`;
              }}
              style={{ objectFit: "cover" }}
              width={150}
              className="rounded cursor-pointer"
              alt=""
            />
          </Upload> */}
        </div>
      </div>
      <Form
        layout="vertical"
        // className="mt-4"
        className="row mt-4"
        // style={{ width: "100%" }}
        form={form}
        onFinish={onUpdateProfile}
      >

        <div
          className={`  ${userData?.role === "student" ? "col-md-6" : "col-md-6"
            }`}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter your name.",
              },
            ]}
          >
            <GrayInputField className="font-600" placeholder="Name" />
          </Form.Item>
        </div>
        <div className="col-md-6">
          <Form.Item name="email" label="Email">
            <GrayInputField
              placeholder="Email"
              className="font-600"
            />
          </Form.Item>
        </div>

        {/* <div
          className={`  ${
            userData?.role === "student" ? "col-md-4" : "col-md-4"
          }`}
        >
          <Form.Item
            name="dob"
            label="DOB"
            rules={[
              {
                required: true,
                message: "Please enter your DOB.",
              },
            ]}
          >
            <GrayDatePicker
              // defaultValue={moment(userData?.dob)}
              format="DD/MM/YYYY"
              type="date"
            />
          </Form.Item>
        </div> */}
        {/* {userData?.role === "student" && (
          <>
            <div className="col-md-4">
              <Form.Item name="pname" label="Parents Name">
                <GrayInputField
                  className="font-600"
                  placeholder="Parents Name"
                />
              </Form.Item>
            </div>


            <div className="col-md-4">
              <Form.Item name="pemail" label="Parents Email">
                <GrayInputField
                  placeholder="Parents Email"
                  className="font-600"
                />
              </Form.Item>
            </div>
          </>
        )} */}
        {/* <div
          className={`  ${
            userData?.role === "student" ? "col-md-4" : "col-md-4"
          }`}
        >
          <Form.Item name="clocation" label="Current Location">
            <GrayInputField
              placeholder="Current Location"
              className="font-600"
            />
          </Form.Item>
        </div> */}

        <div
          className={`  ${userData?.role === "student" ? "col-md-6" : "col-md-6"
            }`}
        >
          <Form.Item name="country" label="Country">
            <Select
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
            >
              {countryList?.map((list) => (
                <Option value={list?.name}>{list?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        {/* <div className="col-md-12">
          <Form.Item name="description" label="Description">
            <GrayTextAreaField placeholder="Description" />
          </Form.Item>
        </div> */}

        <div className=" col-md-12 mt-4 ">
          <div className="d-flex justify-content-center">
            <Button
              htmlType="submit"
              lite
              size={"large"}
              // style={{ marginLeft: isMobile ? 0 : 20 }}
              title="Save"
              className=""
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UserProfile;
