import React, { useContext } from "react";
import { useEffect, useState } from "react";
import portfolio from "../../../../../services/portfolio";
import { notify, SERVER_URL } from "../../../../../utils";
import { BoxInputFiled, BoxTextArea, Wrapper } from "./component/component";
import Vimeo from "@u-wave/react-vimeo";
import { Button, Card, Col, Row, Upload, Form, Tooltip } from "antd";
import { Carousel } from "react-responsive-carousel";
import ReactHtmlParser from "react-html-parser";
import FileViewer from "react-file-viewer";

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  MailOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons/lib/icons";
import styled from "styled-components";
import { UserContext } from "../../../../../context/userInfo";
import { useParams } from "react-router-dom";
import { returnFileType } from "../../../../../utils/imageAccept";
import moment from "moment";
import Loading from "../../../../../components/_shared/Loader/Loading";
import { getFileExt, MP3 } from "../../../../../utils/helper";
import ReactPlayer from "react-player";
import useViewport from "../../../../../hooks/useViewport";

const { Meta } = Card;

const CardWrapper = styled(Card)`
  .ant-card-body {
    padding: 0px !important;
  }
`;
const PortfolioDetails = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const query = useParams();

  const [color, setColor] = useState("red");
  const [showcolor, setShowcolor] = useState(false);
  const [edit, setShowedit] = useState(false);
  const [timelines, setTimelines] = useState([]);
  const [showTimelineModel, setShowTimelineModel] = useState(false);
  const [myPortoflio, setMyPortfolio] = useState({});
  const [primary, setPrimary] = useState({ show: false, color: "#C6BAA3" });
  const [secondary, setSecondary] = useState({ show: false, color: "#F4F1EC" });
  const [border, setBorder] = useState({ show: false, color: "#9A9A9A" });
  const [heading, setHeading] = useState({ show: false, color: "#FFFFFF" });
  const [text, setText] = useState({ show: false, color: "#666666" });
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useViewport();

  useEffect(() => {
    setPrimary({
      show: false,
      color: myPortoflio?.primaryColor ? myPortoflio.primaryColor : "#C6BAA3",
    });
    setSecondary({
      show: false,
      color: myPortoflio?.secondaryColor
        ? myPortoflio.secondaryColor
        : "#F4F1EC",
    });
    setBorder({
      show: false,
      color: myPortoflio?.borderColor ? myPortoflio.borderColor : "#9A9A9A",
    });

    setHeading({
      show: false,
      color: myPortoflio?.headingColor ? myPortoflio.headingColor : "#FFFFFF",
    });

    setText({
      show: false,
      color: myPortoflio?.textColor ? myPortoflio.textColor : "#666666",
    });
  }, [myPortoflio]);

  useEffect(() => {
    getPortfolioTimeline();
    getUserinfo();
    getPortfolioById();
  }, []);

  const getPortfolioTimeline = () => {
    setIsLoading(true);

    portfolio
      .getPortfolioTimeline(query?.id)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);

          setTimelines(res?.data?.timelines);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        notify("error", JSON.stringify(err));
      });
  };

  const getPortfolioById = () => {
    setIsLoading(true);

    portfolio
      .getPortfolioById(query.id)
      .then((res) => {
        if (res.status === 200) {
          setMyPortfolio(res?.data?.portfolio);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        notify("error", JSON.stringify(err));
      });
  };

  const handleAddComment = () => {
    if (!comment) return notify("Please enter your comment");

    portfolio
      .addComment(query?.id, { comment })
      .then((res) => {
        if (res.status === 200) {
          getPortfolioById();
          setComment("");
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  const handleDeleteComment = (cid) => {
    portfolio
      .deleteComment(query?.id, cid)
      .then((res) => {
        if (res.status === 200) {
          getPortfolioById();
        }
      })
      .catch((err) => {
        notify("error", JSON.stringify(err));
      });
  };

  return (
    <Wrapper
      primary={primary.color}
      secondary={secondary.color}
      border={border.color}
      heading={heading.color}
      text={text.color}
    >
      <Loading isLoading={isLoading} />
      <Form layout="vertical">
        <div className="container bg-white p-2   ">
          <div style={{ position: "relative" }}>
            <img
              className="cover"
              src={`${
                myPortoflio?.portfolioPic
                  ? myPortoflio?.portfolioPic
                  : `${process.env.PUBLIC_URL}user.png`
              }`}
            />
            <img
              src={`${
                myPortoflio?.user?.profilepic
                  ? myPortoflio?.user?.profilepic
                  : `${process.env.PUBLIC_URL}user.png`
              }`}
              width={150}
              className="rounded cursor-pointer profile-left"
              alt=""
            />
          </div>
          <div className="secondary-color p-3" style={{ width: "100%" }}>
            <Row gutter={16} className="mt-3">
              <Col xs={24} sm={24} md={24} xl={7} xxl={7} span={7}>
                <CardWrapper>
                  <div className="primary-color" style={{ padding: 10 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: text?.color,
                          width: "100%",
                          fontSize: "30px",
                        }}
                      >
                        {myPortoflio?.title}
                      </div>
                    </div>
                    <div
                      style={{
                        color: "white",
                        marginTop: "10px",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                      }}
                    >
                      <span>
                        Created: {moment(portfolio?.date).format("LL")}
                      </span>
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                      }}
                    >
                      By {myPortoflio?.name}
                    </div>
                    <hr color={border.color} style={{ margin: 3 }} />
                    <div
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                    >
                      <span className="text-color">
                        {myPortoflio?.subtitle}
                      </span>
                    </div>
                  </div>
                  <div style={{ background: "white", padding: 10 }}>
                    {/* <div style={{ color: "black" }}>Testing</div> */}

                    <span className="text-color">
                      {myPortoflio?.description}
                    </span>
                  </div>
                </CardWrapper>

                <CardWrapper className="mt-3">
                  <div className="primary-color" style={{ padding: 10 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: heading.color,
                        }}
                      >
                        Contact
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: 10 }}>
                    {myPortoflio?.contact?.map((info) => (
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h6 className="text-color">{info?.contact}</h6>
                        <MailOutlined
                          color="white"
                          style={{ fontSize: 30, color: text.color }}
                        />
                      </div>
                    ))}
                  </div>
                </CardWrapper>

                {/* <CardWrapper className="mt-3">
                  <div className="primary-color" style={{ padding: 10 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: heading.color,
                        }}
                      >
                        Comments
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: 10 }}>
                    <div className="row">
                      {myPortoflio?.comments?.map((comm) => (
                        <>
                          <div className="col-md-12 mt-3 d-flex justify-content-between">
                            <h6
                              style={{ fontWeight: "600" }}
                              className="text-color"
                            >
                              {comm?.name}
                            </h6>
                            {comm?.user === userData?.id && (
                              <DeleteOutlined
                                onClick={() => handleDeleteComment(comm?._id)}
                                className="text-color h6"
                              />
                            )}
                          </div>
                          <div className="col-md-12">
                            <span className="text-color">{comm?.comment}</span>
                          </div>
                          <div className="col-md-12">
                            <span className="text-color">
                              {moment(comm?.date).calendar()}
                            </span>
                          </div>
                        </>
                      ))}
                      <div className="col-md-12 mt-4">
                        <BoxTextArea
                          onChange={(e) => setComment(e?.target?.value)}
                          border={border?.color}
                          text={text?.color}
                          placeholder="Comments"
                        />
                      </div>

                      <div className="col-md-12 d-flex justify-content-end mt-3">
                        <Button
                          type="primary"
                          onClick={handleAddComment}
                          size="large"
                        >
                          Post
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardWrapper> */}
              </Col>

              <Col xs={24} sm={24} md={24} xl={17} xxl={17} span={17}>
                {timelines?.map((timeline) => (
                  <div className="bg-primary-gray mx-auto shadow mt-4">
                    <Card
                      title={
                        <div className="d-flex">
                          <div>
                            <img
                              src={
                                timeline?.user?.profilepic
                                  ? timeline?.user?.profilepic
                                  : `${process.env.PUBLIC_URL}user.png`
                              }
                              alt=""
                              width="50"
                              height={50}
                              className="rounded-circle"
                            />
                          </div>
                          <div
                            className="mx-3 d-flex flex-column "
                            style={{ width: "50%", whiteSpace: "pre-wrap" }}
                          >
                            <h6 className="mb-0 font-white text-color">
                              {timeline?.title}
                            </h6>
                            <span className="font-white text-color">
                              {moment(timeline?.timelineDate).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                      }
                      style={{ width: "100%" }}
                      bordered={false}
                    >
                      <div className="row">
                        <Carousel showArrows={true}>
                          {timeline?.vimeoUrl?.map((url) => (
                            <div
                              className="mx-auto"
                              style={{ height: "600px" }}
                            >
                              <Vimeo
                                // width={"100%"}
                                video={url}
                                height={600}
                                // responsive={true}
                                showTitle={false}
                                showByline={false}
                              />
                            </div>
                          ))}

                          {timeline?.videoId?.map((id) =>
                            timeline?.isS3 ? (
                              <>
                                {timeline?.makeCopy && (
                                 <div style={{marginLeft:50}}>
                                    <Tooltip
                                      title="Download"
                                      color={"#0eaaa5"}
                                      key={"download"}
                                    >
                                      <DownloadOutlined
                                        style={{
                                          marginLeft: 50,
                                          marginBottom: 40,
                                        }}
                                        onClick={() => {
                                          window.open(
                                            `https://movitanz.s3.ap-southeast-2.amazonaws.com/${id}.mp4`,
                                            "_blank"
                                          );
                                        }}
                                        className=" h4 cursor-pointer"
                                      />
                                    </Tooltip>
                                  </div>
                                )}

                                <video
                                  src={`https://dum3sasv4ib5u.cloudfront.net/${id}.mp4`}
                                  preload="auto"
                                  controls
                                  style={{
                                    width: "100%",
                                    height: "400px",
                                  }}
                                  controlslist="nodownload"
                                />
                              </>
                            ) : (
                              // <div
                              //   className="mx-auto"
                              //   style={{ height: "600px" }}
                              // >
                              //   <Vimeo
                              //     // width={"100%"}
                              //     video={id}
                              //     // responsive={true}
                              //     height={600}
                              //     showTitle={false}
                              //     showByline={false}
                              //   />
                              // </div>
                              <>
                                {timeline?.makeCopy && (
                               <div style={{marginLeft:50}}>
                                    <Tooltip
                                      title="Download"
                                      color={"#0eaaa5"}
                                      key={"download"}
                                    >
                                      <DownloadOutlined
                                        style={{
                                          marginLeft: 50,
                                          marginBottom: 40,
                                        }}
                                        onClick={() => {
                                          window.open(
                                            `https://movitanz.s3.ap-southeast-2.amazonaws.com/${id}.mp4`,
                                            "_blank"
                                          );
                                        }}
                                        className=" h4 cursor-pointer"
                                      />
                                    </Tooltip>
                                  </div>
                                )}

                                <video
                                  src={`https://dum3sasv4ib5u.cloudfront.net/${id}.mp4`}
                                  preload="auto"
                                  controls
                                  style={{
                                    width: "100%",
                                    height: "400px",
                                  }}
                                  controlslist="nodownload"
                                />
                              </>
                            )
                          )}
                          {timeline?.postPic?.map((pic, index) => (
                            <div
                              className="mx-auto"
                              style={{ height: "600px" }}
                            >
                              {returnFileType(pic) == "jpg" ||
                              returnFileType(pic) == "png" ||
                              returnFileType(pic) == "jpeg" ||
                              returnFileType(pic) == "svg" ? (
                                <>
                                  {timeline?.makeCopy && (
                                    <div style={{marginLeft:50}}>
                                      <Tooltip
                                        title="Download"
                                        color={"#0eaaa5"}
                                        key={"download"}
                                      >
                                        <DownloadOutlined
                                          style={{
                                            marginLeft: 50,
                                            marginBottom: 40,
                                          }}
                                          onClick={() => {
                                            window.open(pic, "_blank");
                                          }}
                                          className=" h4 cursor-pointer"
                                        />
                                      </Tooltip>
                                    </div>
                                  )}
                                  <img
                                    src={pic}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = `${process.env.PUBLIC_URL}uploadDefault.png`;
                                    }}
                                    alt=""
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                    }}
                                  />
                                </>
                              ) : [
                                  "pdf",
                                  "csv",
                                  "xslx",
                                  "docx",
                                  "mp4",
                                  "webm",
                                  "mp3",
                                ]?.includes(getFileExt(pic)) ? (
                                <div style={{ height: "600px" }}>
                                  {timeline?.makeCopy && (
                                    <div style={{marginLeft:50}}>
                                      <Tooltip
                                        title="Download"
                                        color={"#0eaaa5"}
                                        key={"download"}
                                      >
                                        <DownloadOutlined
                                          style={{
                                            marginLeft: 50,
                                            marginBottom: 40,
                                          }}
                                          onClick={() => {
                                            window.open(pic, "_blank");
                                          }}
                                          className=" h4 cursor-pointer"
                                        />
                                      </Tooltip>
                                    </div>
                                  )}
                                  <FileViewer
                                    fileType={getFileExt(pic)}
                                    filePath={pic}
                                  />
                                </div>
                              ) : (
                                MP3.includes(getFileExt(pic)) && (
                                  <>
                                    {timeline?.makeCopy && (
                                     <div style={{marginLeft:50}}>
                                        <Tooltip
                                          title="Download"
                                          color={"#0eaaa5"}
                                          key={"download"}
                                        >
                                          <DownloadOutlined
                                            style={{
                                              marginLeft: 50,
                                              marginBottom: 40,
                                            }}
                                            onClick={() => {
                                              window.open(pic, "_blank");
                                            }}
                                            className=" h4 cursor-pointer"
                                          />
                                        </Tooltip>
                                      </div>
                                    )}
                                    <ReactPlayer
                                      width="100%"
                                      height={isMobile ? "100%" : "400px"}
                                      url={pic}
                                      controls
                                    />
                                  </>
                                )
                              )}
                            </div>
                          ))}

                          {timeline?.youtubeUrl?.map((url) => (
                            <div
                              className="mx-auto"
                              style={{ maxHeight: "600px" }}
                            >
                              <ReactPlayer
                                width="100%"
                                height={isMobile ? "100%" : 600}
                                url={url}
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>
                      <div className="row">
                        <p className="font-light-gray">
                          {ReactHtmlParser(
                            ReactHtmlParser(timeline?.description)
                          )?.[0] === "[object Object]"
                            ? ReactHtmlParser(timeline?.description)
                            : ReactHtmlParser(
                                ReactHtmlParser(timeline?.description)
                              )}{" "}
                        </p>
                        {timeline?.googleData?.map((i) => {
                                      return (
                                        <a href={i} target="_blank">
                                          {i}
                                        </a>
                                      );
                                    })}
                                      {timeline?.dropboxData?.map((i) => {
                                      return (
                                        <a href={i} target="_blank">
                                          {i}
                                        </a>
                                      );
                                    })}
                      </div>
                    </Card>
                  </div>
                ))}
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Wrapper>
  );
};

export default PortfolioDetails;
