import { SERVER_URL } from "../utils";
import { apiGet, apiPatch, apiPost } from "../utils/axios";

const AUTH = SERVER_URL + "/auth";
const SIGNUP = SERVER_URL + "/auth/register";
const SIGNIN = SERVER_URL + "/auth/login";
const USER = SERVER_URL + "/users";
const AUTO_RENEWAL = SERVER_URL + "/users/auto-renew";

export default {
  signup: (payload) => apiPost(SIGNUP, payload),
  signin: (payload) => apiPost(SIGNIN, payload),
  genrateProfileToken: (id) => apiGet(`${AUTH}/${id}/genrate-token`),
  forgotPassword: (payload) => apiPost(`${AUTH}/forgot-password`, payload),
  resetPassword: (token, payload) =>
    apiPost(`${AUTH}/reset-password?token=${token}`, payload),
  selfSubscription: (payload) => apiPost(`${AUTH}/self-subscription`, payload),
  upgradeStorage: (payload) => apiPost(`${AUTH}/upgrade-storage`, payload),
  upgradeAddons: (payload) => apiPost(`${AUTH}/upgrade-addons`, payload),

  reportSent: (payload) => apiPost(`${AUTH}/send-report`, payload),
  subscribe: (payload) => apiPost(`${USER}/subscribe`, payload),
  oldPaymentDetailsUpdate: (payload) =>
    apiPost(`${AUTH}/payment-details-update`, payload),
  autorenewal: () => apiGet(AUTO_RENEWAL),
  updateportfolioshare: (id, payload) => apiPatch(`${USER}/${id}`, payload),
  dragGroup : (payload) => apiPatch(`${AUTH}/group-index`, payload),
};
