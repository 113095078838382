import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import { Container } from "./component/component";
import { Button } from "../../../components/_shared";
import { UserContext } from "../../../context/userInfo";
import MyProgram from "./component/MyProgram";
import Store from "./component/Store";
import PurchasedProgram from "./component/PurchasedProgram";
import { ViewProfileContext } from "../../../context/viewProfile";
import { useNavigate } from "react-router-dom";
import useViewport from "../../../hooks/useViewport";
const { TabPane } = Tabs;

const Program = () => {
  const { userData, getUserinfo } = useContext(UserContext);
  const [defaultActiveKey, setActivekey] = useState(
    userData?.role !== "student" ? "1" : "2"
  );
  const navigate = useNavigate()
  const { isMobile } = useViewport();



  const {profileToken}  = useContext(ViewProfileContext)

  if(!profileToken?.access?.token && userData?.role === "admin") {
    navigate('/manage-user')
  }  

  useEffect(() => {
    getUserinfo();
  }, []);

  return (
    <Container className="container bg-white mt-5 p-5 shadow">
      <div className="row ">
        <div className="col-md-12">
          <Tabs
            activeKey={String(defaultActiveKey)}
            onChange={(e) => {
              setActivekey(e);
              localStorage.setItem("studioPathKey", e);
            }}
            // defaultActiveKey={defaultActiveKey}
            type="line"
            tabBarGutter={0}
          >
            {userData?.role !== "student" && (
              <TabPane
                className={defaultActiveKey == 1 ? "mb-4" : ""}
                tab={<div  className= {isMobile? "tab_mobile" : "tab_tabName"}
>My Program</div>}
                key="1"
              />
            )}

            <TabPane
              className={defaultActiveKey == 2 ? "mb-4" : ""}
              tab={<div  className= {isMobile? "tab_mobile" : "tab_tabName"}
>Purchased Program</div>}
              key="2"
            />

       {   !profileToken &&  <TabPane
              className={defaultActiveKey == 3 ? "mb-4" : ""}
              tab={<div  className= {isMobile? "tab_mobile" : "tab_tabName"}
>Store</div>}
              key="3"
            />}
          </Tabs>
        </div>
      </div>

      {defaultActiveKey == "1" && userData?.role !== "student" && <MyProgram />}
      {defaultActiveKey == "2" && <PurchasedProgram />}
      {defaultActiveKey == "3" && <Store userData = {userData}/>}
    </Container>
  );
};

export default Program;
