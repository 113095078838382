import React, { useContext, useEffect, useState } from "react";
import { Alert, Layout } from "antd";

import AppHeader from "./Header";
import AppFooter from "./Footer";
import AppContent from "./Content";
import admin from "../../services/admin";
import { UserContext } from "../../context/userInfo";

const AppLayout = ({ children }) => {
  const [content, setContent] = useState({});
  const { isLoggedIn } = useContext(UserContext);
  const getContent = () => {
    admin
      .getContent({
        home: 1,
        getStarted: 1,
        why: 1,
        faq: 1,
        pricing: 1,
        guide: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          setContent(res?.data?.content);
        }
      })
      .catch((err) => {
        // notify("error", JSON.stringify(err));
      });
  };
  useEffect(() => {
    getContent();
  }, []);
  return (
    <Layout style={{ minHeight: "100vh", overflow: "auto" }}>
      <AppHeader />
      <AppContent>
        {isLoggedIn && content?.notification?.notification && (
          <Alert
            message={
              <span style={{ fontWeight: 500 }}>
                {content?.notification?.notification}
              </span>
            }
            type="success"
          />
        )}

        {children}
      </AppContent>
      <AppFooter />
    </Layout>
  );
};

export default AppLayout;
